import { Stack, Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Layer2Box, ProductImage, Tag, Text, Tooltip } from "@includes";
import { comparisonUtils, formatUtils, productUtils } from "@utils";
import { RootState } from "@store";
import { IProductCard } from "./types";
import { excludedProps } from "./enums";
import { getTraduction } from "@/utils/helpers/traductions";

const ProductCard: React.FC<IProductCard> = ({
  product,
  productPerRow,
  currency,
}) => {
  const theme = useTheme();

  const { t }: i18translateType = useTranslation();

  const additionnalProperties = useSelector(
    (state: RootState) => state.product.properties
  );

  const ref = useRef(null);

  const [boxSize, setBoxSize] = useState(0);

  useEffect(() => {
    if (ref) setBoxSize((ref.current as any).clientWidth);
  }, [productPerRow]);

  const hasPrice =
    additionnalProperties?.find(comparisonUtils.areEquals("price")) &&
    !!product?.salePrice;

  const hasStrokePrice =
    additionnalProperties?.find(comparisonUtils.areEquals("stroke_price")) &&
    !!product?.stroke_price;

  const hasDiscountRate =
    additionnalProperties?.find(comparisonUtils.areEquals("discount_rate")) &&
    !!product?.discount_rate;

  const hasCategory =
    additionnalProperties?.find(comparisonUtils.areEquals("category")) &&
    !!product?.category;

  const hasTags =
    additionnalProperties?.find(comparisonUtils.areEquals("tags")) &&
    !!product?.tags;

  const hasCategoriesIds =
    additionnalProperties?.find(comparisonUtils.areEquals("categories_ids")) &&
    !!product?.categories_ids;

  const productProps = additionnalProperties?.filter(
    (p: string) => !excludedProps.find(comparisonUtils.areEquals(p))
  );
  return (
    <Box
      ref={ref}
      sx={{
        position: "relative",
        width: `calc(${100 / productPerRow}% - 10px)`,
        minWidth: `calc(${100 / productPerRow}% - 10px)`,
        flex: `calc(${100 / productPerRow}% - 5px)`,
        display: "flex",
        justifyContent: "center",
        height: "90%",
        transition: "all 0.2s ease-in",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-around"
        sx={{ width: "100%", height: "100%" }}
      >
        <Layer2Box
          sx={{
            border: "1px solid",
            color: theme.customPalette.others.background,
            width: boxSize,
            maxWidth: 160,
            position: "relative",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            borderRadius: 2,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              // width: boxSize,
              height: "fit-content",
              maxWidth: 160,
              maxHeight: "60%",
              position: "relative",
            }}
          >
            {hasDiscountRate && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  right: -5,
                  top: 5,
                }}
              >
                <Tag tagVariant="success">
                  {productUtils.formatProductProperties(
                    product?.discount_rate,
                    "discount_rate",
                    currency
                  )}
                </Tag>
              </Box>
            )}
            {product?.isDeleted && (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 30,
                  left: -5,
                  top: 5,
                }}
              >
                <Tooltip title={t("product_deleted_tooltip")}>
                  <Box>
                    <Tag tagVariant="error">{t("product_deleted")}</Tag>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {product?.data?.no_image ? (
              <Box
                sx={{
                  width: "100%",
                  minHeight: 150,
                  maxHeight: 150,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text>{t("no_image")}</Text>
              </Box>
            ) : (
              <ProductImage
                src={product?.imgLink ?? "no-image"}
                onDragStart={(e) => {
                  e.preventDefault();
                }}
                inactive={product?.inactive ?? false}
                available={product?.isAvailable ?? true}
              />
            )}
          </Box>

          <Stack
            direction="row"
            sx={{ width: "100%", position: "relative" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Tooltip title={`${t("title")}: ${product?.title}`}>
              <Box sx={{ width: "100%" }}>
                <Text variant="body1" noWrap customStyle={{ maxWidth: "85%" }}>
                  {product?.title}
                </Text>
              </Box>
            </Tooltip>
          </Stack>
          {hasCategory && (
            <Tooltip title={`${t("category")}: ${product?.category}`}>
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.category}
                </Text>
              </Box>
            </Tooltip>
          )}
          {hasTags && (
            <Tooltip title={`${t("tags")} : ${product?.tags}`}>
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.tags}
                </Text>
              </Box>
            </Tooltip>
          )}
          {hasCategoriesIds && (
            <Tooltip
              title={`${t("categories_ids")} : ${product?.categories_ids}`}
            >
              <Box sx={{ maxWidth: "100%" }}>
                <Text textType="secondary" noWrap>
                  {product?.categories_ids}
                </Text>
              </Box>
            </Tooltip>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            {hasPrice && (
              <Text
                variant="body2"
                customStyle={{ color: theme.customPalette.bluePurple.dark }}
              >
                {formatUtils.formatPrice(product?.salePrice, currency)}
              </Text>
            )}
            {hasStrokePrice && (
              <Text
                variant="body2"
                customStyle={{
                  textDecoration: "line-through",
                  color: theme.customPalette.grey.light,
                }}
              >
                {formatUtils.formatPrice(product?.price, currency)}
              </Text>
            )}
          </Stack>

          {productProps?.map((property: string) => {
            const prop = productUtils.getFormattedProperty(property);

            if (!!product[prop] || typeof product[prop] === "boolean")
              return (
                <div key={property}>
                  <Tooltip title={getTraduction(t, property)}>
                    <div>
                      <Text
                        textType="secondary"
                        noWrap={prop !== "formattedStockPerSize"}
                      >
                        {productUtils.formatProductProperties(
                          product[prop],
                          prop,
                          currency
                        )}
                      </Text>
                    </div>
                  </Tooltip>
                </div>
              );
          })}
        </Layer2Box>
      </Stack>
    </Box>
  );
};

export default ProductCard;
