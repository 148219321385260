import { Box, Stack, Text } from "@includes";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { INoData } from "./types";

// Display a message + image when no data (used in api calls when running collections)
const NoData: React.FC<INoData> = ({ text }) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const embedText: string = text || t("no_data");
  return (
    <Stack
      sx={{ width: "100%", height: "100%", minHeight: "150px" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{ width: 55, height: 55 }}
        component="img"
        src={`/${theme.customPalette.mode}/status/empty.svg`}
      />
      <Text variant="body1">{embedText}</Text>
    </Stack>
  );
};

NoData.defaultProps = {
  text: undefined,
};

export default NoData;
