interface IPerformances {
  color?: string;
}

const Performances: React.FC<IPerformances> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1022_22258"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1022_22258)">
        <path
          d="M3.5 21.5L2 20L9.5 12.5L13.5 16.5L20.6 8.5L22 9.9L13.5 19.5L9.5 15.5L3.5 21.5ZM3.5 15.5L2 14L9.5 6.5L13.5 10.5L20.6 2.5L22 3.9L13.5 13.5L9.5 9.5L3.5 15.5Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

Performances.defaultProps = {
  color: "transparent",
};

export default Performances;
