import { gql } from "@apollo/client";

export const GET_ABTESTS = gql`
  query campaigns(
    $siteId: SiteID!
    $cursor: String
    $first: Int!
    $search: String!
  ) {
    campaigns(
      siteId: $siteId
      after: $cursor
      first: $first
      sort: "name"
      search: $search
    ) {
      edges {
        node {
          device
          id
          name
          description
          startedAt
          stoppedAt
          audience
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
