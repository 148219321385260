import { Text } from "@/includes";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HOME_OVERVIEW_QUERIES_ENUMS } from "./enums";
import Evolution from "./components/Evolution";
import Overview from "./components/Overview";

const HomePerformance = () => {
  const { t }: i18translateType = useTranslation();
  const [overviewSelected, setOverviewSelected] =
    useState<HOME_OVERVIEW_QUERIES_ENUMS>(HOME_OVERVIEW_QUERIES_ENUMS.VISITORS);
  const dates = {
    startDate: dayjs().subtract(32, "days").format("YYYY-MM-DD"),
    endDate: dayjs().subtract(2, "days").format("YYYY-MM-DD"),
    previousStartDate: dayjs().subtract(62, "days").format("YYYY-MM-DD"),
    previousEndDate: dayjs().subtract(32, "days").format("YYYY-MM-DD"),
  };
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Text variant="subtitle1">{t("performance_overview")}</Text>
        <Tooltip title={t("performance_overview_tooltip")}>
          <Box component="div">
            <HelpOutline sx={{ fill: "#BEBBC9" }} />
          </Box>
        </Tooltip>
      </Stack>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Overview
              dates={dates}
              overviewSelected={overviewSelected}
              setOverviewSelected={setOverviewSelected}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Evolution dates={dates} overviewSelected={overviewSelected} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default HomePerformance;
