import { useHasProperty } from "@/hooks";
import { RootState } from "@/services/redux/store";
import { IHasPages } from "@/utils/types/component";
import { useSelector } from "react-redux";

const HasSKUReportWrapper: React.FC<IHasPages> = ({ children }) => {
  const { hasSkuReport } = useHasProperty();
  const devMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );

  if (hasSkuReport || devMode) return <>{children}</>;

  return <></>;
};

export default HasSKUReportWrapper;
