type PrimitiveValue = string | number | boolean | null;

/**
 * Utility class for common comparison operations.
 */
export class ComparisonUtils {
  /**
   * Checks if the given value is not null or undefined.
   *
   * @param value - The value to check.
   * @returns `true` if the value is not null or undefined, `false` otherwise.
   */
  isNotNull = (value: Dic<any> | PrimitiveValue) => !!value;

  /**
   * Checks if two values are different.
   *
   * @param a - The first value.
   * @param b - The second value.
   * @returns `true` if the values are different, `false` otherwise.
   */
  areDifferent = (a: PrimitiveValue) => (b: PrimitiveValue) => b !== a;

  /**
   * Checks if two values are equal.
   *
   * @param a - The first value.
   * @param b - The second value.
   * @returns `true` if the values are equal, `false` otherwise.
   */
  areEquals = (a: PrimitiveValue) => (b: PrimitiveValue) => b === a;

  /**
   * Gets a value from a dictionary and compares it to a constant value.
   *
   * @param prop - The property key in the dictionary.
   * @param valueToCompare - The constant value to compare against.
   * @returns A function that takes a dictionary and performs the comparison.
   */
  getValueFromDicAndCompareToConstant =
    (prop: string, valueToCompare: PrimitiveValue) =>
    (obj: Dic<any>): boolean =>
      obj[prop] === valueToCompare;
}
