import { gql } from "@apollo/client";

export const GET_RULE = gql`
  query collection($id: CollectionID) {
    collection(collectionId: $id) {
      id
      name
      description
      settings
      isBuiltin
    }
  }
`;

export const GET_EXPERIENCE = gql`
  query experience($id: ExperienceID!) {
    experience(experienceId: $id) {
      id
      uuid
      name
      includedCategories
      excludedCategories
      settings
      analyticsName
      deployedAt
      collection {
        id
        uuid
        name
        description
        settings
      }
    }
  }
`;
