import { IIcon } from "./types";

const SearchIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3534 0.817916C9.12719 0.909548 7.98584 1.21269 6.88122 1.74009C4.93599 2.66882 3.30193 4.30288 2.3732 6.24811C0.860315 9.41678 1.18021 13.1399 3.20967 15.9843C4.55778 17.8737 6.54179 19.2138 8.79337 19.756C9.92137 20.0276 11.2133 20.09 12.3814 19.9293C14.0527 19.6993 15.6869 18.9963 17.0254 17.9314L17.2054 17.7882L19.8574 20.4345C22.7545 23.3254 22.5791 23.166 22.8988 23.1994C23.4298 23.2549 23.894 22.7837 23.8316 22.2525C23.7953 21.9439 23.9435 22.1063 21.0671 19.2243L18.4203 16.5723L18.5282 16.4403C18.9513 15.9226 19.4187 15.1808 19.7149 14.5571C20.3859 13.1437 20.6795 11.7522 20.6417 10.1645C20.6048 8.61482 20.2359 7.20592 19.5185 5.8744C18.1404 3.31684 15.7386 1.53957 12.8973 0.97502C12.0996 0.816524 11.1537 0.758108 10.3534 0.817916ZM10.3774 2.53704C10.318 2.54167 10.1452 2.56192 9.99337 2.58206C7.96628 2.85108 6.13129 3.89642 4.84232 5.51642C3.97621 6.60496 3.41358 7.92895 3.21157 9.35383C3.15985 9.7187 3.13979 10.7147 3.17612 11.1146C3.41574 13.7519 4.94977 16.0825 7.28116 17.3515C9.8625 18.7565 13.0372 18.5979 15.4774 16.9422C17.0766 15.857 18.2359 14.1946 18.7061 12.3123C18.8657 11.6732 18.9292 11.1291 18.9288 10.4043C18.9283 9.65448 18.8638 9.12362 18.6914 8.45073C18.3675 7.18704 17.7444 6.04012 16.8516 5.06426C15.5609 3.65361 13.7853 2.75889 11.8737 2.55604C11.5729 2.52412 10.688 2.51289 10.3774 2.53704Z"
        fill={color}
      />
    </svg>
  );
};

SearchIcon.defaultProps = {
  color: "transparent",
};

export default SearchIcon;
