import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector as useXstateSelector } from "@xstate/react";
import { useRunCollection, useSetState } from "@hooks";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import DraggableProductList from "@/components/_Organisms/DraggableProductList";
import { Box, Stack, HideShowBlock } from "@includes";
import { useTheme } from "@mui/material";
import { useConfigureMerchandising } from "../../hooks/useConfigureMerchandising";
import { IRun } from "./types";
import { VISIBLE_FIELDS } from "./enums";
import MerchandisingCategorySelector from "../MerchandisingCategorySelector";

const categoriesToSaveSelector = (state: any) => state.context.categoriesToSave;
const currentPushedProductsSelector = (state: any) =>
  state.context.currentPushedProducts;

const Run: React.FC<IRun> = ({
  settings,
  includedCategories,
  excludedCategories,
  gettingCategories,
  allCategories,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const experienceServices = useConfigureMerchandising();

  const categoriesToSave = useXstateSelector(
    experienceServices.experienceService,
    categoriesToSaveSelector
  );

  const currentPushedProducts = useXstateSelector(
    experienceServices.experienceService,
    currentPushedProductsSelector
  );

  const { send } = experienceServices.experienceService;

  const setCurrentPushedProducts = (pushedProducts: Dic<any>) =>
    send({
      type: "SET_CURRENT_PUSHED_PRODUCTS",
      data: { currentPushedProducts: pushedProducts },
    });

  const siteId = useSelector((state: RootState) => state.site.siteId);

  const {
    runningCollection,
    collectionResult: products,
    handleRunPage,
  } = useRunCollection(siteId);

  const [category, setCategory] = useSetState({ page: {}, id: "" });

  const { page } = category;

  const setCategoriesToSave = (categoriesToSave: Array<Dic<any>>) =>
    send({ type: "SET_CATEGORIES_TO_SAVE", data: { categoriesToSave } });

  const handleUpdatePushedProductForCategory = (pushedProducts: Dic<any>) => {
    setCurrentPushedProducts({
      ...currentPushedProducts,
      [category.id]: { ...pushedProducts },
    });
    if (
      JSON.stringify(page?.settings?.pushed_products) !==
      JSON.stringify(pushedProducts)
    ) {
      setCategoriesToSave([
        ...categoriesToSave,
        { id: `${category.id}`, pageId: page?.id, title: category.title },
      ]);
    } else
      setCategoriesToSave(
        categoriesToSave.filter((cat: Dic<any>) => cat.id !== category.id)
      );

    handleRunPage(category.id, {
      ...settings,
      pushedProducts,
    });
  };

  useEffect(() => {
    if (category.id)
      handleRunPage(category.id, {
        ...settings,
        pushedProducts: { ...currentPushedProducts[category.id] },
      });
  }, [category.id, settings]);

  const unpin = (productId: ID) => {
    const pushedProductForCategory = currentPushedProducts[category.id];

    const pushedProducts = pushedProductForCategory
      ? { ...pushedProductForCategory }
      : {};
    const keyToDelete = Object.keys(pushedProducts).find(
      (key: string) => pushedProducts[key] === productId
    );
    if (keyToDelete) delete pushedProducts[keyToDelete];
    handleUpdatePushedProductForCategory(pushedProducts);
  };

  const pushProductTo = (
    pId: string,
    index: number,
    newPushedProducts: Dic<string> | null = null
  ): any => {
    const pushedProductForCategory =
      newPushedProducts ?? currentPushedProducts[category.id];

    const pushedProducts = pushedProductForCategory
      ? { ...pushedProductForCategory }
      : {};
    for (const key of Object.keys(pushedProducts)) {
      if (pushedProducts[key] === pId) {
        delete pushedProducts[key];
      }
    }
    if (pushedProducts[index] && pushedProducts[index] !== pId) {
      const currentlyPushed = pushedProducts[index];
      pushedProducts[index] = pId;
      return pushProductTo(currentlyPushed, index + 1, pushedProducts);
    } else {
      pushedProducts[index] = pId;
    }
    handleUpdatePushedProductForCategory(pushedProducts);
  };

  const pin = (pId: ID, toIndex: number) => {
    const pushedProductForCategory = currentPushedProducts[category.id];

    const pushedProducts = pushedProductForCategory
      ? { ...pushedProductForCategory }
      : {};
    pushedProducts[toIndex] = pId;
    handleUpdatePushedProductForCategory(pushedProducts);
  };

  return (
    <HideShowBlock
      title={t("result_and_manipulation")}
      initialState
      subtitle={t("preview_merchandising_description")
        .split(".")
        .map((text: string) => (
          <>
            {text}
            <br />
          </>
        ))}
      slot={
        <Stack sx={{ width: "100%" }} alignItems="end">
          <Stack alignItems="center" direction="row" spacing={1}>
            <MerchandisingCategorySelector
              setCategory={setCategory}
              setCurrentPushedProducts={setCurrentPushedProducts}
              categories={includedCategories ?? []}
              excludedCategories={excludedCategories ?? []}
              allCategories={allCategories}
              loading={gettingCategories}
            />
          </Stack>
        </Stack>
      }
    >
      <Box id="preview-block">
        <Stack spacing={2}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <DraggableProductList
              categoryId={category.id}
              list="run result"
              visibleFields={VISIBLE_FIELDS}
              products={products ?? []}
              isLoading={runningCollection || !category.id}
              unpin={unpin}
              pushProduct={pushProductTo}
              pin={pin}
            />
          </Stack>
        </Stack>
      </Box>
    </HideShowBlock>
  );
};

export default Run;
