import { RootState } from "@store";
import { datesUtils } from "@utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import usePolledLazyQuery from "../usePolledLazyQuery";
import { GET_LAST_SUCCEEDED_SYNC, GET_LAST_SYNC } from "./requests";
import { isEmpty } from "lodash";

/**
 * Custom React hook to get the last synchronization time for the catalog.
 *
 * @returns Object containing the last synchronization time and a boolean indicating if synchronization has been performed.
 */
const useLastSync = () => {
  const { t }: i18translateType = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  // Custom hook to fetch data using a polled lazy query
  const {
    data: lastSucceededSync,
    getDatas: getLastSucceededSync,
    refetch: refetchLastSucceededSync,
  } = usePolledLazyQuery(GET_LAST_SUCCEEDED_SYNC, { siteId }, 60000);

  // Last time the catalog has been sync
  const lastSync: string = lastSucceededSync?.jobs?.edges[0]?.node?.endedAt
    ? `${t("last_refresh")} ${datesUtils.howManyTimeSince(
        lastSucceededSync?.jobs?.edges[0]?.node?.endedAt,
        t
      )} ${t("ago")}`
    : "";

  const {
    data: lastSyncStatus,
    getDatas: getLastSyncStatus,
    refetch: refetchLastSyncStatus,
  } = usePolledLazyQuery(GET_LAST_SYNC, { siteId }, 60000);

  const lastStatus: string = lastSyncStatus?.jobs?.edges[0]?.node?.status ?? "";

  const handleRefetch = () => {
    refetchLastSucceededSync();
    refetchLastSyncStatus();
  };

  useEffect(() => {
    if (siteId) {
      getLastSucceededSync();
      getLastSyncStatus();
    }
  }, [siteId]);

  // Return the last synchronization time and a boolean indicating if synchronization has been performed
  return { lastSync, lastStatus, handleRefetch };
};

export default useLastSync;
