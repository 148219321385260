import { styled } from "@mui/system";
import { Box } from "@mui/material";

const Layer1Box = styled(Box)(({ theme }: any) => ({
  padding: 12,
  borderRadius: 6,
  background: theme.customPalette.others.layer_1,
  border: "1px solid",
  borderColor: theme.customPalette.others.background,
}));

export default Layer1Box;
