import { Box, Stack, useTheme } from "@mui/material";
import { Button, Text } from "../../../includes";
import { useHistory, useParams } from "react-router";

const Footer = () => {
  const history = useHistory();

  const { siteId, accountId } = useParams<UrlParams>();
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: 1,
        position: "relative",
        left: 0,
        right: 0,
        bottom: -2,
        zIndex: 999,
        maxWidth: "100%",
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box component="img" src={`/${theme.palette.mode}/logoPotions.svg`} />
        <Stack direction="row">
          <Button
            variant="text"
            id="seeCGUBtn"
            onClick={() =>
              history.push(`/${accountId}/${siteId}/settings/about/cgu`)
            }
          >
            CGU
          </Button>
          <Button
            variant="text"
            id="seeCGUBtn"
            onClick={() =>
              history.push(
                `/${accountId}/${siteId}/settings/about/privacy_policy`
              )
            }
          >
            Privacy
          </Button>
          <Button
            variant="text"
            id="contactUsBtn"
            // onClick={() => window.open("mailto:contact@get-potions.com")}
            onClick={() =>
              history.push(`/${accountId}/${siteId}/settings/about/help`)
            }
          >
            Contact us
          </Button>
        </Stack>
        <Text textType="secondary">
          © {new Date().getFullYear().toString()} Potions Lab
        </Text>
      </Stack>
    </Box>
  );
};

export default Footer;
