import { gql } from "@apollo/client";
import { client } from "@/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import useSetState from "../useSetState";

const GET_ALL_VARIABLES = gql`
  query variables($siteId: SiteID!, $first: Int!) {
    variables(first: $first, siteId: $siteId) {
      totalCount
      edges {
        node {
          id
          name
          type
        }
      }
    }
  }
`;

/**
 * Custom React hook for managing site variables.
 *
 * @returns An object containing information about site variables.
 */
const useOldVariables = () => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [totalCount, setTotalCount] = useState<number>(10);
  const [status, setStatus] = useState<"" | "loading" | "error">("");
  const [siteVariables, setSiteVariables] = useSetState({});

  /**
   * Helper function to retrieve all site variables.
   *
   * @param first - The number of variables to fetch.
   */
  const getAllSiteVariables = (first = 10) => {
    setStatus("loading");
    client
      .query({
        query: GET_ALL_VARIABLES,
        variables: { siteId: parseInt(siteId), first },
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setTotalCount(res.data.variables.totalCount + 5);
        if (first < res.data.variables.totalCount + 5) {
          getAllSiteVariables(res.data.variables.totalCount + 5);
        } else {
          setSiteVariables(
            res.data.variables.edges
              .map((edge: Dic<any>) => ({ ...edge.node }))
              .reduce(
                (curr: Dic<string>, next: Dic<string>) => ({
                  ...curr,
                  [next.name]: next.type,
                }),
                {}
              )
          );
          setStatus("");
        }
      })
      .catch(() => setStatus("error"));
  };

  /**
   * Helper function to get a dictionary of available variables.
   *
   * @param variables - An array of variable names.
   * @param allVariableDic - A dictionary containing all site variables.
   * @returns A dictionary containing available variables.
   */
  const getAvailableVariablesDic = (
    variables: Array<string>,
    allVariableDic: Dic<string>
  ) => {
    return variables?.reduce((curr: Dic<string>, next: string) => {
      return { ...curr, [next]: allVariableDic[next] };
    }, {});
  };

  useEffect(() => {
    getAllSiteVariables(totalCount);
  }, [siteId]);

  return {
    allSiteVariableDic: siteVariables,
    allSiteVariables: Object.keys(siteVariables),
    status,
    getAvailableVariablesDic,
    gettingVariables: status === "loading",
    errorGettingVariables: status === "error",
  };
};

export default useOldVariables;
