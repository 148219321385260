import {
  GET_USER_INFOS,
  SET_PROFILE_IMAGE,
  SET_ROLE,
  SET_USER_INFOS,
  USER_SET_LANGUAGE,
} from "./action-types";

export function getUserInfos(): any {
  return {
    type: GET_USER_INFOS,
    payload: {},
  };
}

export function setUserInfos(
  email: string,
  isSuperuser: boolean,
  picture: string,
  id: ID,
  companyUsers: Array<any>
): any {
  return {
    type: SET_USER_INFOS,
    payload: { email, isSuperuser, picture, id, companyUsers },
  };
}

export function setRole(role: string): any {
  return {
    type: SET_ROLE,
    payload: { role },
  };
}

export function setUserLanguage(langue: string): any {
  return {
    type: USER_SET_LANGUAGE,
    payload: { langue },
  };
}

export function setUserProfileImage(profileImage: string) {
  return {
    type: SET_PROFILE_IMAGE,
    payload: { profilePicture: profileImage },
  };
}
