import { useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * Custom React hook for integrating Pendo analytics.
 *
 * @returns {void}
 */
const usePendo = () => {
  const email = useSelector((state) => state.login.user.email);
  const id = useSelector((state) => state.login.user.id);

  /**
   * Effect to initialize Pendo analytics when user ID is valid.
   */
  useEffect(() => {
    try {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];

          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);

          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";

          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
      })(process.env.REACT_APP_PENDO_API_KEY);

      if (
        (!!id &&
          ["11"]?.includes(id?.toString()) &&
          process.env.REACT_APP_ENV === "prod") ||
        (!!id &&
          ["3", "36", "14"]?.includes(id?.toString()) &&
          process.env.REACT_APP_ENV === "dev")
      ) {
        window.pendo.initialize({
          visitor: {
            id, // Required if user is logged in, default creates anonymous ID
            email, // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            email,
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [id]);
};

export default usePendo;
