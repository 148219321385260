import { useTheme } from "@mui/material";
import { Box } from "@includes";
import { IDateGridBox } from "./types";

const DataGridBox: React.FC<IDateGridBox> = ({
  children,
  cursorOnHover,
  noFooter,
  maxHeight,
  minHeight,
  customStyle,
}) => {
  const theme = useTheme();

  return (
    <Box
      className={theme.customPalette.mode}
      sx={{
        outline: "1px solid",
        outlineColor: theme.customPalette.others.background,
        width: "100%",
        overflow: "hidden",
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
        "& .MuiDataGrid.MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-main": {
          background: theme.customPalette.others.layer_1,
          overflowY: "auto",
          maxHeight: maxHeight ?? "unset",
        },
        "& .MuiDataGrid-row": {
          background: "transparent",
          transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
          "&:hover": {
            background: cursorOnHover
              ? theme.customPalette.others.background
              : "transparent !important",
            cursor: cursorOnHover ? "pointer" : "initial",
          },
        },

        "& .MuiDataGrid-footerContainer": {
          justifyContent: "center",
          display: noFooter ? "none" : "block",
          border: "none",
          paddingTop: 3,
          backgroundColor: theme.customPalette.others.layer_1,
          "& .MuiButtonBase-root": {
            color: theme.customPalette.grey.white,
            "&.Mui-disabled": {
              color: theme.customPalette.others.background,
            },
          },
        },

        "& .MuiDataGrid-columnHeadersInner": {
          background: theme.customPalette.others.layer_1,
        },
        "& .MuiDataGrid-columnHeader": {
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          background: theme.customPalette.others.layer_1,
          "&:hover": {
            background: theme.customPalette.others.background,
          },
        },
        "& .MuiDataGrid-cellContent": {
          color: theme.customPalette.grey.default,
          width: "100%",
          overflow: "hidden",
        },

        "& .MuiDataGrid-cell": {
          outline: "none !important",
        },
        "& .MuiDataGrid-virtualScroller": {
          borderBottomLeftRadius: 2,
          borderBottomRightRadius: 2,
          minHeight,
          overflow: "auto !important",
          background: theme.customPalette.others.layer_1,
          opacity: 1,
        },

        "& .MuiDataGrid-root": {
          border: "none",
        },

        ...customStyle,
      }}
    >
      {children}
    </Box>
  );
};

DataGridBox.defaultProps = {
  maxHeight: undefined,
  minHeight: undefined,
  customStyle: {},
};

export default DataGridBox;
