import { Box, LinearProgress, Stack, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@/includes";
import { IGetStarted } from "./types";

const GetStarted: React.FC<IGetStarted> = ({ step }) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const progress = (step / 2) * 100;
  return (
    <Box
      sx={{
        background: theme.customPalette.bluePurple.lighter,
        borderRadius: 2,
        p: 2,
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Stack spacing={2} sx={{ height: "100%" }} justifyContent="space-between">
        <Stack spacing={1}>
          <Box
            sx={{
              background: theme.customPalette.bluePurple.dark,
              height: 22,
              width: 22,
              borderRadius: 2,
              p: 1,
            }}
            component="img"
            src={`/${theme.customPalette.mode}/getStarted/icon.svg`}
          />
          <Text variant="subtitle1">{t("get_started")}</Text>
          <Text>{t("get_started_description")}</Text>
        </Stack>
        <Stack>
          <Stack justifyContent="space-between" direction="row">
            <Text>
              {progress.toString()} % {t("completed")}
            </Text>
            <Text>
              {step.toString()}/2 {t("steps")}
            </Text>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              "& .MuiLinearProgress-bar1Determinate": {
                backgroundColor: theme.customPalette.bluePurple.dark,
              },
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default GetStarted;
