import { useHasProperty } from "@hooks";
import { IIsSelfService } from "./types";

const IsSelfServiceWrapper: React.FC<IIsSelfService> = ({ children }) => {
  const { isSelfService } = useHasProperty();

  if (isSelfService) {
    return <>{children}</>;
  }

  return <></>;
};

export default IsSelfServiceWrapper;
