import { gql } from "@apollo/client";

export const GET_OVERVIEW = gql`
  query Overview(
    $siteId: SiteID!
    $devices: [String!]!
    $startDate: String!
    $endDate: String!
    $segmentExposedVisitors: String!
    $segmentUsers: String!
  ) {
    allVisitors: monitor(
      siteId: $siteId
      segment: "all;all;all;all"
      devices: $devices
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    exposedVisitors: monitor(
      siteId: $siteId
      segment: $segmentExposedVisitors
      devices: $devices
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    users: monitor(
      siteId: $siteId
      segment: $segmentUsers
      devices: $devices
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
