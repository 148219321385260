import { gql } from "@apollo/client";

export const SEARCH_COLLECTION = gql`
  query collections($siteId: SiteID, $search: String) {
    collections(siteId: $siteId, search: $search, first: 1000) {
      edges {
        node {
          id
          name
          settings
          lastUpdate
          initial
        }
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query collection($id: CollectionID) {
    collection(collectionId: $id) {
      id
      name
      settings
      lastUpdate
      initial
    }
  }
`;
