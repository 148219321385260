import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import { SET_DEVELOPER_MODE, LOGOUT } from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  isEnabled: potionsLocalStorage.developerMode
    ? potionsLocalStorage.developerMode === "true"
    : false,
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SET_DEVELOPER_MODE:
        draft.isEnabled = action.payload.isEnabled;
        potionsLocalStorage.set(
          "developerMode",
          action.payload.isEnabled.toString()
        );
        break;

      case LOGOUT:
        draft.isEnabled = false;
        potionsLocalStorage.set("developerMode", "false");

        break;
      default:
        break;
    }
  });
}
