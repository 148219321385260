import { useHasProperty, useViewport } from "@/hooks";
import { client } from "@/services/graphql/apolloConfig";
import { navigatorUtils } from "@/utils";
import { Box, Stack } from "@includes";
import { useTheme } from "@mui/material";
import { RootState } from "@store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import DeveloperModeWrapper from "../../../DeveloperModeWrapper";
import MobileWrapper from "../../../MobileWrapper";
import { HEIGHT_TOP_BAR } from "../../enums";
import Icon from "../Icon";
import { GET_EXPERIENCES } from "./requests";
import { SideMenuProps } from "./types";

const SideMenu = ({}: SideMenuProps) => {
  const open: any = useSelector((state: RootState) => state.drawer.open);

  const location = useLocation();
  const params = useParams<UrlParams>();
  const { siteId, accountId }: any = params;
  const history = useHistory();

  const theme = useTheme();

  const { t }: i18translateType = useTranslation();

  const [experiences, setExperiences] = useState<Array<any>>([]);
  const [totalExperiences, setTotalExperiences] = useState<number>(10);

  const getExperiences = () => {
    return client
      .query({
        query: GET_EXPERIENCES,
        variables: {
          siteId: parseInt(siteId),
          first: totalExperiences,
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        setExperiences(
          res.data.experiences.edges.map((edge: Dic<any>) => edge.node)
        );
        setTotalExperiences(res.data.experiences.totalCount);
      });
  };

  useEffect(() => {
    if (experiences.length < totalExperiences) {
      getExperiences();
    }
  }, [totalExperiences]);

  const { isMobile } = useViewport();
  return (
    <Box
      sx={{
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.customPalette.others.layer_1,
        overflow: "visible",
        height: isMobile ? 1 : `calc(100% - ${HEIGHT_TOP_BAR})`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          justifyContent: isMobile ? "start" : "center",
          alignItems: "center",
          padding: isMobile ? "16px" : "0px",
          pt: 2,
        }}
      >
        <Stack
          sx={{
            height: "100%",
            maxWidth: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            flex: isMobile ? 1 : "auto",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              WebkitAppearance: "none",
              display: "none",
            },
          }}
          justifyContent={isMobile ? "unset" : "space-between"}
        >
          <Stack
            spacing={isMobile ? "8px" : "12px"}
            sx={{ width: "100%" }}
            justifyContent="center"
            alignItems={isMobile ? "baseline" : "center"}
          >
            <MobileWrapper>
              <Box
                sx={{ height: 20, padding: 1, cursor: "pointer" }}
                component="img"
                src={`/${theme.customPalette.mode}/top_logo.svg`}
              />
            </MobileWrapper>

            <Stack
              spacing={isMobile ? "8px" : "4px"}
              sx={{ width: "100%" }}
              justifyContent="center"
              alignItems={isMobile ? "baseline" : "center"}
            >
              <DeveloperModeWrapper>
                <Icon
                  tooltipInfos={{
                    title: t("accounts"),
                    description: t("manage_all_accounts"),
                    tag: "",
                    position: "bottom",
                  }}
                  onClick={navigatorUtils.goTo(
                    `/${accountId}/${siteId}/super_admin/sites`,
                    history
                  )}
                  iconName="super_admin"
                  selected={location.pathname.includes("super_admin")}
                />
              </DeveloperModeWrapper>
              <Icon
                tooltipInfos={{
                  title: t("settings"),
                  description: t("settings_description"),
                  tag: "",
                  position: "bottom",
                }}
                onClick={navigatorUtils.goTo(
                  `/${accountId}/${siteId}/settings/account`,
                  history
                )}
                iconName="settings"
                selected={location.pathname.includes("settings")}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SideMenu;
