import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateRangePicker as MUIDateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  Box,
  InputAdornment,
  Stack,
  useTheme,
  Skeleton,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDateRangePicker } from "./types";
import { StyledTextField } from "./components/StyledTextField";
import { getPaperStyle } from "./helpers";

const DateRangePicker: React.FC<IDateRangePicker> = ({
  dateRange,
  onChange,
  minDate,
  maxDate,
  loading,
  disabled,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const paperStyle = React.useMemo(() => getPaperStyle(theme), [theme]);

  const handleDateChange = (newValue: Array<any>) => {
    let [newStartDate, newEndDate] = [newValue[0], newValue[1]];
    if (dayjs(newEndDate).isBefore(dayjs(newStartDate)))
      [newStartDate, newEndDate] = [newValue[1], newValue[0]];
    else {
      if (!!onChange) {
        onChange([dayjs(newStartDate), dayjs(newEndDate)]);
      }
    }
  };

  if (loading)
    return (
      <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
        <Skeleton
          width="50%"
          height={50}
          animation="wave"
          variant="rounded"
          sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        />
        <Skeleton
          width="50%"
          height={50}
          animation="wave"
          variant="rounded"
          sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        />
      </Stack>
    );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: t("start_date"), end: t("end_date") }}
    >
      <MUIDateRangePicker
        PaperProps={{
          sx: { ...paperStyle },
        }}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat="DD/MM/YYYY"
        value={dateRange}
        onChange={handleDateChange}
        renderInput={(startProps: any, endProps: any) => (
          <Fade in>
            <div>
              <Stack direction="row" spacing={2}>
                <StyledTextField
                  name="start date"
                  variant="outlined"
                  value={dateRange[0]}
                  sx={{ minWidth: 160 }}
                  {...startProps}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          src={`/${theme.customPalette.mode}/report/dateRange.svg`}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  name="end date"
                  variant="outlined"
                  value={dateRange[1]}
                  sx={{ minWidth: 160 }}
                  {...endProps}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          component="img"
                          src={`/${theme.customPalette.mode}/report/dateRange.svg`}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </div>
          </Fade>
        )}
      />
    </LocalizationProvider>
  );
};

DateRangePicker.defaultProps = {
  loading: false,
  disabled: false,
};

export default DateRangePicker;
