import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { formatUtils } from "@/utils";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Stack } from "@includes";
import { Skeleton, useTheme } from "@mui/material";
import { FetchResult } from "apollo-link";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HOME_OVERVIEW_QUERIES_ENUMS } from "../../enums";
import OverviewVisualization from "../OverviewVisualization";
import { formatDataToHomeOverview } from "./formatters";
import { GET_HOME_OVERVIEW } from "./requests";
import { HomeOverviewProps, ResultHomeOverviewDataType } from "./types";

const Overview = ({
  dates,
  overviewSelected,
  setOverviewSelected,
}: HomeOverviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { startDate, endDate, previousStartDate, previousEndDate } = dates;
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [loading, setLoading] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<ResultHomeOverviewDataType>();
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const getData = () => {
    setLoading(StatusEnum.LOADING);
    client
      .query({
        query: GET_HOME_OVERVIEW,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          previousStartDate: previousStartDate,
          previousEndDate: previousEndDate,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: Dic<any>) => {
        setData(formatDataToHomeOverview(res?.data));
        setLoading(StatusEnum.NORMAL);
      })
      .catch(() => setLoading(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(previousStartDate) &&
      !isEmpty(previousEndDate)
    )
      getData();
  }, [startDate, endDate, previousStartDate, previousEndDate]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        sx={{ height: "100%", gap: theme.spacing(3) }}
        justifyContent="space-between"
      >
        {loading === StatusEnum.LOADING ||
        (loading !== StatusEnum.ERROR && isEmpty(data)) ? (
          <>
            <Skeleton
              width="100%"
              height={110}
              animation="wave"
              variant="rounded"
              sx={{
                bgcolor: (theme) => theme.customPalette.others.layer_2,
              }}
            />
            <Skeleton
              width="100%"
              height={110}
              animation="wave"
              variant="rounded"
              sx={{
                bgcolor: (theme) => theme.customPalette.others.layer_2,
              }}
            />
            <Skeleton
              width="100%"
              height={110}
              animation="wave"
              variant="rounded"
              sx={{
                bgcolor: (theme) => theme.customPalette.others.layer_2,
              }}
            />
          </>
        ) : (
          <>
            <OverviewVisualization
              newValue={data?.visitors?.newValue ?? 0}
              oldValue={data?.visitors?.oldValue ?? 0}
              overviewSelected={overviewSelected}
              setOverviewSelected={setOverviewSelected}
              overviewKey={HOME_OVERVIEW_QUERIES_ENUMS.VISITORS}
              loading={loading}
              currency={currency}
            />
            <OverviewVisualization
              newValue={data?.transactions?.newValue ?? 0}
              oldValue={data?.transactions?.oldValue ?? 0}
              overviewSelected={overviewSelected}
              setOverviewSelected={setOverviewSelected}
              overviewKey={HOME_OVERVIEW_QUERIES_ENUMS.TRANSACTIONS}
              loading={loading}
              currency={currency}
            />
            <OverviewVisualization
              newValue={data?.revenues?.newValue ?? 0}
              oldValue={data?.revenues?.oldValue ?? 0}
              format={formatUtils.formatIntegerCurrency}
              overviewSelected={overviewSelected}
              setOverviewSelected={setOverviewSelected}
              overviewKey={HOME_OVERVIEW_QUERIES_ENUMS.REVENUES}
              loading={loading}
              currency={currency}
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default Overview;
