import { useViewport } from "@/hooks";
import { Box, Button, Layer1Box, Stack, Text } from "@/includes";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IStep } from "./types";

const Step: React.FC<IStep> = ({
  title,
  description,
  btnText,
  imgLink,
  step,
  icon,
  onClick,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { isMobile } = useViewport();
  return (
    <Layer1Box sx={{ height: "100%", boxSizing: "border-box" }}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%" }}
        justifyContent="space-between"
      >
        <Stack
          spacing={1}
          justifyContent="space-between"
          sx={{ width: isMobile ? "100%" : "55%" }}
        >
          <Stack spacing={1}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Box
                sx={{
                  border: `1px solid ${theme.customPalette.others.background}`,
                  p: "8px",
                  borderRadius: 2,
                }}
                component="img"
                src={`/${theme.customPalette.mode}/getStarted/${icon}.svg`}
              />
              <Text
                textType="secondary"
                variant="body1"
                customStyle={{ textTransform: "uppercase" }}
              >
                {t("step")} {step.toString()}
              </Text>
            </Stack>
            <Text variant="subtitle1">{title}</Text>
            <Text>{description}</Text>
          </Stack>
          <Box>
            <Button onClick={onClick} id="connect">
              {btnText}
            </Button>
          </Box>
        </Stack>
        {!isMobile && (
          <Box
            sx={{ width: "45%" }}
            component="img"
            src={`/${theme.customPalette.mode}/getStarted/${imgLink}.png`}
          />
        )}
      </Stack>
    </Layer1Box>
  );
};

export default Step;
