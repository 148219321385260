import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  LOGGING_IN,
  LOGGED_IN,
  LOGOUT,
  REGISTERING,
  REGISTERING_INVITE,
  LOGGING_IN_INVITE,
  GET_ACCOUNT_AND_SITE_ID,
} from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  loading: "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<string>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case GET_ACCOUNT_AND_SITE_ID:
        draft.loading = "login";
        break;
      case REGISTERING:
        draft.loading = "register";
        break;
      case REGISTERING_INVITE:
        draft.loading = "register";
        break;
      case LOGGING_IN:
        draft.loading = "login";
        break;
      case LOGGING_IN_INVITE:
        draft.loading = "login";
        break;
      case LOGGED_IN:
        draft.loading = "";
        break;
      case LOGOUT:
        potionsLocalStorage.clear();
        draft.loading = "";
        break;
      default:
        break;
    }
  });
}
