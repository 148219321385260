import {
  EXPERIENCES_MAP,
  getNoRowsTexts,
  getLoadingTexts,
  getEventName,
  FILTERED_PRODUCT_PROPS,
  VERSION,
} from "./constants";

import { isAnEmail } from "./helpers/regex";

import { getMemoKeys } from "./helpers/keys/getMemoKeys";
import { getOrderedKey } from "./helpers/keys/getOrderedKey";
import { ArrayUtils } from "./helpers/array/ArrayUtils";
import { RulesUtils } from "./helpers/collections/RulesUtils";
import { DatesUtils } from "./helpers/dates/DatesUtils";
import { FilterUtils } from "./helpers/filters/FilterUtils";
import { ExperiencesUtils } from "./helpers/experiences/ExperiencesUtils";
import { NavigatorUtils } from "./helpers/nagigator/NavigatorUtils";
import { ObjectUtils } from "./helpers/objects/ObjectUtils";
import { ApolloUtils } from "./helpers/graphql/ApolloUtils";
import { ProductUtils } from "./helpers/products/ProductUtils";
import { StringUtils } from "./helpers/string/StringUtils";
import { FormatUtils } from "./helpers/format/FormatUtils";
import { ComparisonUtils } from "./helpers/comparisons/ComparisonsUtils";

const arrayUtils = new ArrayUtils();
const rulesUtils = new RulesUtils();
const datesUtils = new DatesUtils();
const filterUtils = new FilterUtils();
const experiencesUtils = new ExperiencesUtils();
const graphqlUtils = new ApolloUtils();
const navigatorUtils = new NavigatorUtils();
const objectUtils = new ObjectUtils();
const productUtils = new ProductUtils();
const stringUtils = new StringUtils();
const formatUtils = new FormatUtils();
const comparisonUtils = new ComparisonUtils();

export {
  comparisonUtils,
  VERSION,
  formatUtils,
  stringUtils,
  productUtils,
  objectUtils,
  navigatorUtils,
  getOrderedKey,
  graphqlUtils,
  experiencesUtils,
  filterUtils,
  datesUtils,
  rulesUtils,
  arrayUtils,
  EXPERIENCES_MAP,
  getNoRowsTexts,
  getLoadingTexts,
  getEventName,
  FILTERED_PRODUCT_PROPS,
  isAnEmail,
  getMemoKeys,
};
