import { useTranslation } from "react-i18next";
import { useSelector } from "@xstate/react";
import { AutoComplete, SelectPaper, StyledTextField } from "@includes";
import { useReport } from "../../hooks/useReport";

const deviceSelector = (state: any) => state.context.device;

const isOnMobileSelector = (state: any) => state.context.onMobile;
const isOnDesktopSelector = (state: any) => state.context.onDesktop;

const DeviceSelector = () => {
  const { t }: i18translateType = useTranslation();
  const reportServices = useReport();

  const device = useSelector(reportServices.reportService, deviceSelector);
  const onMobile = useSelector(
    reportServices.reportService,
    isOnMobileSelector
  );
  const onDesktop = useSelector(
    reportServices.reportService,
    isOnDesktopSelector
  );

  const { send } = reportServices.reportService;

  const handleChange = (newDevice: string) => {
    send({ type: "CHANGE_DEVICE", data: { device: newDevice } });
  };

  const options = [
    { label: t("desktop"), value: "desktop", disabled: !onDesktop },
    { label: t("mobile"), value: "mobile", disabled: !onMobile },
    {
      label: t("mobile_and_desktop"),
      value: "mobileDesktop",
      disabled: !onMobile || !onDesktop,
    },
  ];

  return (
    <AutoComplete
      PaperComponent={SelectPaper}
      value={options.find((d: Dic<any>) => d.value === device)}
      onChange={(e: React.SyntheticEvent, value: any) =>
        handleChange(value.value)
      }
      disablePortal
      disableClearable
      id="device-selector"
      options={options?.filter((o: Dic<any>) => !o.disabled)}
      sx={{ width: 280 }}
      renderInput={(params: any) => (
        <StyledTextField {...params} label={t("device")} name={t("device")} />
      )}
    />
  );
};

export default DeviceSelector;
