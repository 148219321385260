import { closeDrawer } from "@/services/redux/actions";
import { Box, Text } from "@includes";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import DesktopWrapper from "../../../DesktopWrapper";
import MobileWrapper from "../../../MobileWrapper";
import { IIcon } from "./types";
import { fontSize } from "@mui/system";

const Icon: React.FC<IIcon> = ({
  iconName,
  selected,
  onClick,
  tooltipInfos,
  openDesktopNavbar,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { title } = tooltipInfos;

  const handleClick = (event: React.SyntheticEvent) => {
    dispatch(closeDrawer());
    onClick(event);
  };

  const [hover, setHover] = useState<boolean>(false);

  return (
    <>
      <DesktopWrapper>
        <Box
          data-tooltip-id={`tooltip-menu-${title}`}
          onClick={onClick}
          sx={{
            cursor: "pointer",
            background: selected
              ? theme.customPalette.bluePurple.slighty
              : "unset",
            p: 1,
            borderRadius: 1,
            "&:hover": {
              "& .MuiTypography-root": {
                color: theme.customPalette.bluePurple.dark,
              },
            },
            transition: "background 0.2s ease-in-out",
            display: "flex",
            alignItems: "center",
            justifyContent: openDesktopNavbar ? "flex-start" : "center",
            width: "calc(100% - 32px)",
            height: "24px",
            mx: 1,
          }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: 0,
            }}
          >
            {selected || hover ? (
              <Box
                sx={{
                  width: "100%",
                  position: "absolute",
                  zIndex: 2,
                  translate: "-50% -50%",
                  top: "50%",
                  left: "50%",
                }}
                component="img"
                src={`/${theme.customPalette.mode}/sideMenu/selected_${iconName}.svg`}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  position: "absolute",
                  zIndex: 2,
                  translate: "-50% -50%",
                  top: "50%",
                  left: "50%",
                }}
                component="img"
                src={`/${theme.customPalette.mode}/sideMenu/${iconName}.svg`}
              />
            )}
          </Box>
          {openDesktopNavbar && (
            <Text
              noWrap
              customStyle={{
                ml: 1,
                color: selected && theme.customPalette.bluePurple.dark,
                fontSize: "inherit",
              }}
            >
              {title}
            </Text>
          )}
        </Box>
      </DesktopWrapper>
      <MobileWrapper>
        <Box onClick={handleClick} sx={{ width: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              background: selected
                ? theme.customPalette.others.background
                : "transparent",
              padding: "4px 0 4px 4px",
              width: "95%",
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
              }}
              component="img"
              src={`/${theme.customPalette.mode}/sideMenu/${
                selected ? "selected_" : ""
              }${iconName}.svg`}
            />
            <Text>{title}</Text>
          </Stack>
        </Box>
      </MobileWrapper>
      {
        <ReactTooltip
          opacity={!openDesktopNavbar ? 1 : 0}
          offset={20}
          place="right"
          id={`tooltip-menu-${title}`}
          style={{
            backgroundColor: theme.customPalette.others.layer_1,
            padding: "5px",
            borderRadius: "4px",
            marginTop: 0,
          }}
          afterShow={() => {
            setHover(true);
          }}
          afterHide={() => setHover(false)}
        >
          <Text customStyle={{ fontWeight: "bold" }}>
            {!openDesktopNavbar && title}
          </Text>
        </ReactTooltip>
      }
    </>
  );
};

export default Icon;
