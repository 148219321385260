import { useTheme, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box, Text, Stack, Button } from "@includes";

interface IError {
  retry?: boolean;
  handleRetry?: () => void;
  loading?: boolean;
  errorMessage?: string;
}

const Error: React.FC<IError> = ({
  retry,
  handleRetry,
  loading,
  errorMessage,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  return (
    <Fade in>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/status/error.svg`}
          />
          <Text
            variant="body1"
            customStyle={{ color: theme.customPalette.grey.default }}
          >
            {errorMessage || t("default_error")}
          </Text>
          {!!retry && (
            <Button
              id="retry"
              onClick={() => !!handleRetry && handleRetry()}
              loading={!!loading}
            >
              {t("retry")}
            </Button>
          )}
        </Stack>
      </Box>
    </Fade>
  );
};

Error.defaultProps = {
  retry: false,
  handleRetry: () => null,
  loading: false,
  errorMessage: undefined,
};

export default Error;
