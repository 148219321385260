import { gql } from "@apollo/client";

export const GET_EXPERIENCE = gql`
  query experience($id: ExperienceID!) {
    experience(experienceId: $id) {
      name
      deployedAt
      onDesktop
      onMobile
      type
    }
  }
`;
