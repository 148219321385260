import { useTranslation } from "react-i18next";
import { AutoComplete, SelectPaper, StyledTextField } from "@includes";
import { useMemo } from "react";
import { IDeviceSelector } from "./types";
import { DEFAULT_OPTIONS } from "./enums";
import { getTraduction } from "@/utils/helpers/traductions";

const DeviceSelector: React.FC<IDeviceSelector> = ({
  device,
  handleChange,
  customStyle,
  availableDevices,
}) => {
  const { t }: i18translateType = useTranslation();

  const getOptions = () => {
    if (availableDevices) {
      return availableDevices.map((device: string) => ({
        value: device.trim(),
        label: getTraduction(t, device.trim()),
      }));
    }
    return DEFAULT_OPTIONS.map((device: string) => ({
      value: device,
      label: getTraduction(t, device),
    }));
  };

  const options = useMemo(getOptions, [availableDevices]);

  return (
    <AutoComplete
      PaperComponent={SelectPaper}
      value={
        options?.find((d: Dic<any>) => d.value === device) ?? {
          value: device,
          label: getTraduction(t, device),
        }
      }
      onChange={(e: React.SyntheticEvent, value: any) =>
        handleChange(value.value)
      }
      disableClearable
      id="device-selector"
      options={options?.filter((o: Dic<any>) => !o.disabled)}
      sx={{ width: 280, ...customStyle }}
      renderInput={(params: any) => (
        <StyledTextField {...params} label={t("device")} name={t("device")} />
      )}
    />
  );
};

DeviceSelector.defaultProps = {
  customStyle: {},
  availableDevices: undefined,
};
export default DeviceSelector;
