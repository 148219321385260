import { createTheme } from "@mui/material/styles";
// When using TypeScript 4.x and above
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";

const theme = {
  palette: {
    mode: "light",
    overrides: {
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "red",
          },
        },
      },
    },
  },

  customPalette: {
    mode: "light",
    error: {
      main: "#B02F25",
      complementary: "#FFFAFA",
    },
    warning: {
      main: "#E5A400",
      complementary: "#FFF6E0",
    },
    info: {
      main: "#A18CDE",
      complementary: "#FCFBFE",
    },
    success: {
      main: "#00806C",
      complementary: "#DBFAF6",
    },
    others: {
      background: "#EDEDF3",
      layer_1: "#FFFFFF",
      layer_2: "#E0E0E0",
    },

    bluePurple: {
      white: "#FCFBFE",
      slighty: "#EEEBFA",
      lighter: "#E2DCF4",
      light: "#A18CDE",
      main: "#3100BF",
      dark: "#1A018D",
      darker: "#000155",
    },
    red: {
      lighter: "#FFFAFA",
      light: "#FC7369",
      salmon: "#FF5757",
      main: "#B02F25",
      dark: "#7F261F",
      darker: "#310502",
    },
    grey: {
      disabled: "#9494A3",
      light: "#5E5E6E",
      default: "#2A2A3C",
      dark: "#272727",
      white: "#EDEDF3",
      lighter: "#E0E0EB",
      borderHome: "#D8D8E2",
      infoBubble: "#393939",
    },
    white: {
      main: "#FCFCFD",
    },
    black: {
      main: "#000000",
    },
    cream: {
      lighter: "#F1F1F1",
      light: "#E6E6E6",
    },
    mustard: {
      lighter: "#FFF6E0",
      light: "#FFECBD",
      main: "#E5A400",
      dark: "#705000",
      darker: "#4D3700",
    },
    green: {
      white: "#F5FFFE",
      lighter: "#DBFAF6",
      light: "#C7F4EE",
      main: "#00806C",
      dark: "#006154",
      darker: "#00332B",
    },
    lightBlue: {
      white: "#00332B",
      lighter: "#D7EBF7",
      light: "#88C6E5",
      main: "#55AEDA",
      dark: "#3B7997",
      darker: "#274F64",
    },
    citrus: {
      lighter: "#F3FFB2",
      light: "#F3FFB2",
      main: "#9AB804",
      dark: "#556600",
      darker: "#354000",
    },
    pink: {
      white: "#FCEDF5",
      light: "#EB89BA",
      main: "#E76EAA",
      dark: "#E14794",
      darker: "#6D1240",
      notifications: "#F96584",
    },
    common: {
      white: "#FFF",
    },
  },
  typography: {
    fontFamily: "Lato",
    h1: {
      fontFamily: "Lato",
      fontSize: "1.75rem !important",
    },
    h2: { fontFamily: "Lato", fontSize: "1.5rem !important" },
    h3: { fontFamily: "Lato", fontSize: "1rem !important" },
    h4: { fontFamily: "Lato", textDecoration: "line-through" },
    h5: {
      fontFamily: "Lato",
      textDecoration: "line-through",
    },
    h6: {
      fontFamily: "Lato",
      textDecoration: "line-through",
    },

    subtitle1: {
      fontFamily: "Lato",
      fontSize: "0.9375 !important",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    subtitle2: {
      fontFamily: "Lato",
      textDecoration: "line-through",
    },
    body1: {
      fontFamily: "Lato",
      fontSize: "0.875rem !important",
    },
    body2: {
      fontFamily: "Lato",
      fontSize: "0.75rem !important",
    },
    caption: {
      fontFamily: "Lato",
      fontSize: "0.625rem !important",
    },
    button: {
      fontFamily: "Lato",
      fontWeight: "bold",
      fontSize: "0.875rem !important",
    },
  },
} as const;

export type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/styles/createTheme" {
  interface Theme extends CustomTheme {
    customPalette: any;
  }
  interface ThemeOptions extends CustomTheme {
    customPalette?: any;
  }
}

export default createTheme(theme);
