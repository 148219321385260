import { useDebounce } from "@/hooks";
import { AutoComplete, SelectPaper, StyledTextField } from "@/includes";
import { RootState } from "@/services/redux/store";
import { graphqlUtils } from "@/utils";
import { Skeleton, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ALL_EXPERIENCE, FIRST } from "./enums";
import { GET_EXPERIENCES } from "./requests";
import { IExperienceSelector } from "./types";

const ExperienceSelector: React.FC<IExperienceSelector> = ({
  experience,
  handleChange,
  initialExperience,
  loading,
  type,
  noSelector,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [first, setFirst] = useState<number>(FIRST);
  const [searching, setSearching] = useState(false);
  const [experiences, setExperiences] = useState<Array<Dic<any>>>([]);

  const fetch = async (endCursor: string) =>
    await graphqlUtils.fetchWithoutCache(
      GET_EXPERIENCES,
      {
        search,
        siteId: parseInt(siteId),
        first,
        cursor: endCursor,
        type,
      },
      "no-cache"
    );
  const isNotHidden = (experience: Dic<any>) =>
    !experience?.settings?.interface?.hidden || siteId === "185";

  const filterExperiences = (experiences: Array<Dic<any>>) =>
    experiences.filter(isNotHidden);
  const handleSearch = async (search: String, endCursor = "", init = false) => {
    setSearching(true);
    let res = await fetch(endCursor);
    const totalCount = res?.data?.experiences?.totalCount ?? 0;

    if (res.data?.experiences?.edges) {
      if (!!search && totalCount < res.data.experiences.edges.length) {
        setFirst(totalCount);
        res = await fetch(endCursor);
      }
      setSearching(false);

      if (init)
        setExperiences([
          ...filterExperiences(
            res?.data?.experiences?.edges?.map((item: any) => ({
              ...item.node,
            }))
          ),
          ALL_EXPERIENCE(t),
        ]);
      else
        setExperiences([
          ...filterExperiences([
            ...experiences,
            ...res.data.experiences.edges.map((item: any) => ({
              ...item.node,
            })),
          ]),
          ALL_EXPERIENCE(t),
        ]);
    }
  };

  useEffect(() => {
    if (siteId && !searching) {
      handleSearch("");
    }
  }, [siteId]);

  const [search, setSearch] = useState("");

  const handleSearchChange = (newSearch: string) => setSearch(newSearch);

  useDebounce(
    () => {
      handleSearch(search, "", true);
    },
    [search],
    600
  );

  useEffect(() => {
    if (!experience?.id && experiences?.length > 0) {
      !!initialExperience
        ? handleChange(
            experiences.find((o: Dic<any>) => o?.id == initialExperience) ??
              experiences[0]
          )
        : handleChange(experiences[0]);
    }
  }, [experiences]);

  if (loading)
    return (
      <Skeleton
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        variant="rounded"
        animation="wave"
        width="100%"
        height={50}
      />
    );

  if (noSelector) {
    return null;
  }

  return (
    <AutoComplete
      value={experience}
      onChange={(e: any, value: Dic<any>) => handleChange(value)}
      filterOptions={(x) => x}
      sx={{ width: "100%", zIndex: 1 }}
      disableClearable
      loading={searching}
      getOptionLabel={(option: any) => option.name ?? ""}
      options={[...experiences]}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("experience")}
          name={t("experience")}
          onChange={(e: any) => handleSearchChange(e.target.value)}
          value={search}
        />
      )}
      PaperComponent={SelectPaper}
    />
  );
};

ExperienceSelector.defaultProps = {
  loading: false,
  type: undefined,
};

export default ExperienceSelector;
