import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AutomaticSelectProps } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const AutomaticSelect = ({
  label,
  value,
  handleChange,
  options,
}: AutomaticSelectProps) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={handleChange}>
        {options.map((option: any) => (
          <MenuItem value={option}>{getTraduction(t, option)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default AutomaticSelect;
