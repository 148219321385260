import useRole from "@/hooks/useRole";
import { RootState } from "@/services/redux/store";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { useSelector } from "react-redux";
import { IHasAdminRoleWrapper } from "./types";

const HasAdminRoleWrapper: React.FC<IHasAdminRoleWrapper> = ({ children }) => {
  const { role, isSuperUser } = useRole();

  if (!isSuperUser && role !== ROLE_ENUM.ADMINISTRATOR) {
    return <></>;
  }

  return <>{children}</>;
};

export default HasAdminRoleWrapper;
