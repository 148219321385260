import { ADD_RUN, REMOVE_RUN } from "./action-types";

// eslint-disable-next-line import/prefer-default-export
export function addRun(run: Dic<any>) {
  return {
    type: ADD_RUN,
    payload: { run },
  };
}

export function removeRun(runId: string) {
  return {
    type: REMOVE_RUN,
    payload: { runId },
  };
}
