import { CREATE_ACCOUNT } from "./action-types";

export function userCreateAccount(accountId: string, accountName: string): any {
  return {
    type: CREATE_ACCOUNT,
    payload: {
      accountId,
      accountName,
    },
  };
}
