import { SUMMARY_HEADERS } from "@/utils/enums/graphql";
import { ResponseAbTestOverviewDataType } from "./types";

export const formatDataToAbTestOverview = (
  data: ResponseAbTestOverviewDataType
) => {
  const result = {
    revenuePerUserA: 0,
    revenuePerUserB: 0,
    totalVisitors: 0,
  };
  data?.data?.ATest?.headers.forEach((header: SUMMARY_HEADERS, index) => {
    if (header === SUMMARY_HEADERS.REVENUE_PER_USER) {
      result.revenuePerUserA =
        data?.data?.ATest?.rows[0][index].floatValue ?? 0;
      result.revenuePerUserB =
        data?.data?.BTest?.rows[0][index].floatValue ?? 0;
    }
    if (header === SUMMARY_HEADERS.VISITORS) {
      result.totalVisitors =
        (data?.data?.ATest?.rows[0][index].floatValue ?? 0) +
        (data?.data?.BTest?.rows[0][index].floatValue ?? 0);
    }
  });
  return result;
};
