import React from "react";
import Step from "../Step";
import { Box } from "@mui/material";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { ISteps } from "./types";
import { getSteps } from "./helpers";

const Steps: React.FC<ISteps> = ({ step }) => {
  const { t }: i18translateType = useTranslation();
  const history = useHistory();

  const steps = getSteps(history, t);

  const currentStepIndex = step;

  if (!steps[currentStepIndex]) return null;

  const { title, description, btnText, imgLink, icon, onClick } =
    steps[currentStepIndex];

  return (
    <Box sx={{ height: "100%", boxSizing: "border-box" }}>
      <Step
        step={currentStepIndex + 1}
        imgLink={imgLink}
        key={title}
        title={title}
        description={description}
        btnText={btnText}
        icon={icon}
        onClick={onClick}
      />
    </Box>
  );
};

export default Steps;
