import React, { useEffect, useMemo } from "react";
import { Stack } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Text, StyledTextField, HideShowBlock } from "@includes";
import dayjs, { Dayjs } from "dayjs";
import { filterUtils } from "@utils";
import { ITemporaryProducts } from "./types";
import { getPaperStyle } from "./helpers";

const TemporaryProducts: React.FC<ITemporaryProducts> = ({
  disabled,
  handleChangeDate,
  filters,
  removeDates,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const paperStyle = useMemo(() => getPaperStyle(theme), [theme]);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);

  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(null));

  const [formattedFilters, setFormattedFilters] = React.useState<
    Array<Dic<any>>
  >([]);

  useEffect(() => {
    if (filters) {
      setFormattedFilters([
        ...filters.map((filter: any) => filterUtils.formatFilter(filter)),
      ]);
    }
  }, [filters]);

  useEffect(() => {
    setStartDate(
      dayjs(
        formattedFilters.find((filter: any) => filter.type === "startDate")
          ?.value ?? null
      )
    );
    setEndDate(
      dayjs(
        formattedFilters.find((filter: any) => filter.type === "endDate")
          ?.value ?? null
      )
    );
  }, [formattedFilters]);

  const handleUpdateDate = (type: string, newValue: Dayjs | null) => {
    let newFilters = formattedFilters;
    const filterToUpdate = newFilters.find(
      (filter: any) => filter.type === type
    ) ?? {
      type,
      format: "%Y-%m-%d %H:%M",
      value: dayjs(newValue).format("YYYY-MM-DD H:mm"),
    };

    newFilters = [
      ...newFilters.filter((filter: any) => filter.type !== type),
      { ...filterToUpdate, value: dayjs(newValue).format("YYYY-MM-DD H:mm") },
    ];

    handleChangeDate(newFilters);
  };

  const disableBlock = () => {
    removeDates();
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <HideShowBlock
      isCheckbox
      disableBlock={disableBlock}
      title={t("temporary_products")}
      subtitle={t("temporary_products_definition")}
      initialState={formattedFilters?.length > 0}
    >
      <Stack spacing={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3} direction="row">
            <DateTimePicker
              disabled={disabled}
              PaperProps={{ sx: { ...paperStyle } }}
              disablePast
              label={t("start_date")}
              inputFormat="DD/MM/YYYY H:mm"
              value={startDate}
              maxDate={endDate ?? null}
              onChange={(newValue: any) =>
                handleUpdateDate("startDate", newValue)
              }
              renderInput={(params: any) => (
                <StyledTextField
                  {...params}
                  error={false}
                  disabled={disabled}
                  name="start date"
                />
              )}
            />
            <DateTimePicker
              disabled={disabled}
              PaperProps={{ sx: { ...paperStyle } }}
              disablePast
              label={t("end_date")}
              inputFormat="DD/MM/YYYY H:mm"
              value={endDate}
              minDate={startDate ?? dayjs()}
              onChange={(newValue: any) =>
                handleUpdateDate("endDate", newValue)
              }
              renderInput={(params: any) => (
                <StyledTextField
                  {...params}
                  error={false}
                  disabled={disabled}
                  name="end date"
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Stack>
    </HideShowBlock>
  );
};

TemporaryProducts.defaultProps = {
  disabled: false,
};

export default TemporaryProducts;
