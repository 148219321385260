import { useHasProperty } from "@/hooks";
import { IHasPages } from "@/utils/types/component";

const HasCatalogWrapper: React.FC<IHasPages> = ({ children }) => {
  const { hasCatalog } = useHasProperty();

  if (!hasCatalog) {
    return <></>;
  }

  return <>{children}</>;
};

export default HasCatalogWrapper;
