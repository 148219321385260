import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { Paper, useTheme } from "@mui/material";
import { useSelector } from "@xstate/react";
import { useEffect, useMemo, useState } from "react";
import {
  Stack,
  Text,
  Loader,
  Box,
  AutoComplete,
  TextField,
  SelectPaper,
} from "@includes";
import { useReport } from "../../hooks/useReport";
import { formatUtils, getEventName } from "@utils";
import { useIsMounted } from "@hooks";
import { useTranslation } from "react-i18next";

const CustomTooltip = ({ active, payload, label, unit }: any) => {
  const theme = useTheme();
  if (active && payload) {
    return (
      <Stack
        sx={{
          padding: "6px 26px 6px 6px",
          background: theme.customPalette.others.layer_1,

          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          borderRadius: 2,
        }}
        spacing={2}
        justifyContent="start"
        alignItems="start"
      >
        <Text variant="body2">{label}</Text>

        <Text
          variant="body2"
          customStyle={{ color: theme.customPalette.bluePurple.dark }}
        >
          {unit === "percent"
            ? formatUtils.formatToPercent(payload[0]?.value)
            : payload[0]?.value}
        </Text>
      </Stack>
    );
  }
  return null;
};

const eventEvolutionsSelector = (state: any) => state.context.eventEvolutions;
const typeSelector = (state: any) => state.context.type;

const Evolution = () => {
  const { t }: i18translateType = useTranslation();
  const isMounted = useIsMounted();

  const theme = useTheme();
  const reportServices = useReport();

  const [key, setKey] = useState<string>("");
  const [keys, setKeys] = useState<Array<string>>([]);

  const eventEvolutions = useSelector(
    reportServices.reportService,
    eventEvolutionsSelector
  );

  const type = useSelector(reportServices.reportService, typeSelector);

  useEffect(() => {
    if (eventEvolutions && isMounted) {
      setKeys(
        Object.keys(eventEvolutions)?.filter(
          (_key: string) => _key !== "__typename" && eventEvolutions[_key]
        )
      );
    }
  }, [eventEvolutions]);

  useEffect(() => {
    if (keys?.length > 0 && isMounted) {
      setKey(keys[0]);
    }
  }, [keys]);

  const unit = useMemo(() => {
    if (key.includes("Percent")) return "percent";
    return "number";
  }, [key]);

  const evolutionData = useMemo(() => {
    if (!key || !eventEvolutions[key]) return [];
    return Object.keys(eventEvolutions[key])?.map((_key: string) => ({
      date: _key,
      value: eventEvolutions[key][_key],
    }));
  }, [key, keys]);

  if (!key) {
    return <Loader />;
  }

  return (
    <Stack
      spacing={3}
      sx={{
        position: "relative",
        p: 2,
        borderRadius: 2,
        "& .recharts-responsive-container ": {
          borderRadius: 5,
          background: theme.customPalette.others.layer_2,
          border: `1px solid ${theme.customPalette.others.background}`,
        },
        background: theme.customPalette.others.layer_1,
      }}
    >
      <Text variant="subtitle1">{t("evolution")}</Text>
      <Stack sx={{ width: "100%" }} alignItems="flex-end">
        <AutoComplete
          id="kpi-selector"
          autoHighlight
          PaperComponent={SelectPaper}
          onChange={(e: React.SyntheticEvent, value: any) => {
            setKey(value);
          }}
          value={key}
          inputValue={getEventName(type, t)?.[key]}
          noOptionsText={t("no_data")}
          loadingText={`${t("loading")}...`}
          disableClearable
          options={keys}
          getOptionLabel={(option: any) =>
            getEventName(type, t)?.[option] ?? ""
          }
          sx={{ width: 350 }}
          renderInput={(params: any) => (
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/others/ellipse.svg`}
              />
              <TextField {...params} label="" variant="standard" />
            </Stack>
          )}
        />
      </Stack>
      {evolutionData?.length > 0 ? (
        <Box
          sx={{
            "& .recharts-cartesian-axis-tick": {
              fontSize: "0.8rem",
              fontFamily: "Poppins",
              fill: "red",
            },
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <AreaChart
              data={evolutionData}
              margin={{
                top: 10,
                left: 0,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="80%" stopColor="#133A71" />
                  <stop offset="20%" stopColor="#133A71" />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                tickLine={false}
                stroke={theme.customPalette.grey.default}
              />
              <YAxis
                stroke={theme.customPalette.grey.default}
                dataKey="value"
                tickLine={false}
                tickFormatter={(v: any) => {
                  if (unit === "percent") {
                    return formatUtils.formatToPercent(v);
                  }
                  return v;
                }}
              />
              <Area
                type="monotone"
                dataKey="value"
                stroke={theme.customPalette.bluePurple.dark}
                fillOpacity={1}
                fill="url(#colorUv)"
                strokeWidth={3}
                dot={false}
              />
              <Tooltip
                wrapperStyle={{ outline: "none" }}
                animationDuration={200}
                content={<CustomTooltip unit={unit} />}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default Evolution;
