import { useEffect, useRef } from "react";

function useDidMountEffect(
  func: () => void,
  dependencies: Array<any> | undefined
) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, dependencies);
}

export default useDidMountEffect;
