import { EndIDFieldType } from "../entityFields/enums";
import { getDefaultValueFromType } from "../entityFields/helpers";
import { FieldType } from "../entityFields/types";

/**
 * Utility class for formatting various types of data.
 */
export class FormatUtils {
  /**
   * Format a price as a string with euro symbol.
   *
   * @param price - The price to format.
   * @param currency - Symbol of currency (default "€")
   * @returns The formatted price string.
   */
  formatPrice = (price: string | undefined, currency: string = "€"): string => {
    if (!price) return "-";
    return `${parseFloat(price)
      .toFixed(2)
      .toString()
      .replace(".", ",")} ${currency}`;
  };

  /**
   * Format a decimal number as a percentage string.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string.
   */
  formatToPercent = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "0 %";
    return `${(x * 100)?.toFixed(accuracy)?.toString()?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a percentage string with sign.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string.
   */
  formatToPercentWithSign = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "0 %";
    const sign = x >= 0 ? "+" : "-";
    return `${sign} ${Math.abs(x * 100)
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a percentage string with a sign + or -.
   *
   * @param x - The decimal number to format as a percentage.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @returns The formatted percentage string with a sign.
   */
  formatToPercentWithSymbol = (x = 0.0, accuracy = 1): string => {
    if (!x || typeof x === "string") return "+ 0 %";
    const sign = x >= 0 ? "+" : "-";
    return `${sign} ${(Math.abs(x) * 100)
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")} %`;
  };

  /**
   * Format a decimal number as a multiplied string.
   *
   * @param x - The decimal number to format as multiplied.
   * @param accuracy - The number of decimal places to round to (default is 1).
   * @param signPosition - The position of the sign ("before" or "after").
   * @returns The formatted multiplied string.
   */
  formatMultiply = (x = 0.0, accuracy = 1, signPosition = "before"): string => {
    if (!x || typeof x === "string") return "0";
    return ` ${signPosition === "before" ? "x " : ""}${x
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",")}${signPosition === "after" ? "x" : ""}`;
  };

  /**
   * Format a number as a currency string.
   *
   * @param x - The number to format as currency.
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @param currency - The currency symbol (default is "€").
   * @returns The formatted currency string.
   */
  formatCurrency = (
    x: number | string = 0.0,
    accuracy = 2,
    currency = "€"
  ): string => {
    let res = "0";
    if (!x || typeof x === "string") return res;
    const result = x?.toFixed(accuracy)?.toString()?.replace(".", ",");
    if (currency === "€") {
      `${result?.replace(/\B(?=(\d{3})+(?!\d))/g, "   ")} ${currency}`;
    }
    return `${result} ${currency}`;
  };

  /**
   * Format a number as a currency string.
   *
   * @param x - The number to format as currency.
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @param currency - The currency symbol (default is "€").
   * @returns The formatted currency string.
   */
  formatCurrencyWithSign = (
    x: number | string = 0.0,
    accuracy = 2,
    currency = "€"
  ): string => {
    let res = "0";
    if (!x || typeof x === "string") return res;
    const sign = x >= 0 ? "+" : "-";
    const result = Math.abs(x)
      ?.toFixed(accuracy)
      ?.toString()
      ?.replace(".", ",");
    if (currency === "€") {
      return `${sign} ${result?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        "   "
      )} ${currency}`;
    }

    return `${sign} ${result} ${currency}`;
  };

  /**
   * Convert a float to an integer.
   *
   * @param value - The float value to convert.
   * @returns The formatted integer string.
   */
  floatToInt = (value = 0.0): string => {
    if (!value || typeof value === "string") return "0";
    if (value < 0) {
      return Math.ceil(value).toString();
    }
    return Math.floor(value).toString();
  };

  /**
   * Format an integer as a string with thousand separators.
   *
   * @param value - The integer value to format.
   * @returns The formatted integer string.
   */
  formatInt = (value = 0): string => {
    if (!value || typeof value === "string") return "0";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "   ");
  };

  /**
   * Format an integer as a string with thousand separators with sign.
   *
   * @param value - The integer value to format.
   * @returns The formatted integer string.
   */
  formatIntWithSign = (value = 0): string => {
    if (!value || typeof value === "string") return "0";
    const sign = value >= 0 ? "+" : "-";
    return `${sign} ${Math.abs(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, "   ")}`;
  };

  /**
   * Format an integer as a currency string.
   *
   * @param value - The integer value to format.
   * @param currency - The currency symbol (default is "€").
   * @returns The formatted currency string.
   */
  formatIntegerCurrency = (value = 0.0, currency = "€"): string => {
    if (!value || typeof value === "string") return "0";
    const x = this.floatToInt(value)
      .toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, "   ");
    return `${x} ${currency}`;
  };

  /**
   * Format an integer as a currency string with sign.
   *
   * @param value - The integer value to format.
   * @param currency - The currency symbol (default is "€").
   * @returns The formatted currency string.
   */
  formatIntegerCurrencyWithSign = (value = 0.0, currency = "€"): string => {
    if (!value || typeof value === "string") return "0";
    const sign = value >= 0 ? "+" : "-";
    const x = this.floatToInt(Math.abs(value))
      .toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, "   ");
    return `${sign} ${x} ${currency}`;
  };

  /**
   * Format a float as a string with a specified number of decimal places.
   *
   * @param value - The float value to format.
   * @param accuracy - The number of decimal places to round to (default is 2).
   * @returns The formatted float string.
   */
  formatFloat = (value = 0.0, accuracy = 2): string => {
    if (typeof value !== "number") return "0";
    return `${value?.toFixed(accuracy)?.toString()?.replace(".", ",")}`;
  };

  /**
   * Format a result of graphql introspection as an array for input fields.
   *
   * @param result - The result of graphql introspection.
   * @returns The formatted array.
   */
  formatGraphQLInputFieldsIntrospection = (data: any): Array<FieldType> =>
    data?.__type?.inputFields?.map((field: any) => {
      return {
        name: field.name,
        type: field.type?.name ?? field.type?.ofType?.name,
      };
    }) ?? [];

  /**
   * Format a result of graphql introspection as an array for fields.
   *
   * @param result - The result of graphql introspection.
   * @returns The formatted array.
   */
  formatGraphQLFieldsIntrospection = (data: any): Array<FieldType> =>
    data?.__type?.fields?.map((field: any) => {
      return {
        name: field.name,
        type: field.type?.name ?? field.type?.ofType?.name,
      };
    }) ?? [];

  /**
   * Format a object of array of fields.
   *
   * @param fields - Array of fields.
   * @returns The object.
   */
  formatFieldsArray = (fields: Array<FieldType>): any => {
    const newObject: any = {};
    fields.forEach((field: FieldType) => {
      newObject[field.name] = field.type.endsWith(EndIDFieldType)
        ? null
        : getDefaultValueFromType(field.type);
    });
    return newObject;
  };

  /**
   * Format a string in kebab case.
   *
   * @param text - The string to format.
   * @returns The string in kebab case.
   */
  formatStringInKebabCase = (text: string): string => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1_$2")
      .replace(/[\s_]+/g, "_")
      .toLowerCase();
  };
}
