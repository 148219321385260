import { Box, Error, Layer1Box } from "@/includes";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Fade, Stack, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EvolutionGraph from "../EvolutionGraph";
import Header from "../EvolutionHeader";
import Loading from "../EvolutionLoading";
import { EVOLUTION_METRICS_ENUM, labelOfEvolutionMetric } from "./enums";
import { formatDataForGraph } from "./formatters";
import { GET_EVOLUTION_DATA } from "./requests";
import { IEvolution, ResponseEvolutionDataType } from "./types";

const Evolution: React.FC<IEvolution> = ({
  filters,
  experienceName,
  compare,
}) => {
  const {
    startDate,
    endDate,
    previousStartDate,
    previousEndDate,
    experience,
    devices,
  } = filters;
  const [evolutionMetric, setEvolutionMetric] =
    useState<EVOLUTION_METRICS_ENUM>(EVOLUTION_METRICS_ENUM.DISPLAYS_BY_DAY);
  const theme = useTheme();

  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<object[]>([]);

  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_EVOLUTION_DATA,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          previousStartDate: dayjs(previousStartDate).format("YYYY-MM-DD"),
          previousEndDate: dayjs(previousEndDate).format("YYYY-MM-DD"),
          segment: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };all`,
          devices,
          query: evolutionMetric,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseEvolutionDataType) => {
        setData(
          formatDataForGraph(res?.data, labelOfEvolutionMetric[evolutionMetric])
        );
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(evolutionMetric) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices) &&
      !isEmpty(experience)
    )
      getData();
  }, [evolutionMetric, startDate, endDate, devices, experience]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Fade in>
        <Layer1Box
          sx={{
            maxWidth: "100%",
            minHeight: theme.spacing(59),
          }}
        >
          <Stack
            spacing={1}
            sx={{
              opacity: 1,
              transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
              position: "relative",
            }}
          >
            <Header
              filters={filters}
              experienceName={experienceName}
              compare={compare}
              evolutionMetric={evolutionMetric}
              setEvolutionMetric={setEvolutionMetric}
            />

            {status !== StatusEnum.NORMAL || isEmpty(data) ? (
              status === StatusEnum.ERROR ? (
                <Error />
              ) : (
                <Loading />
              )
            ) : (
              <EvolutionGraph
                evolutionMetric={evolutionMetric}
                data={data}
                compare={compare}
              />
            )}
          </Stack>
        </Layer1Box>
      </Fade>
    </Box>
  );
};

export default Evolution;
