interface ISort {
  color?: string;
}

const Sort: React.FC<ISort> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1022_22230"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1022_22230)">
        <path
          d="M20.0134 5.19999L20.8634 5.54999C21.3801 5.76666 21.7259 6.14166 21.9009 6.67499C22.0759 7.20833 22.0468 7.73333 21.8134 8.24999L20.0134 12.15L20.0134 5.19999ZM16.0134 2.99999C16.5634 2.99999 17.0343 3.19583 17.4259 3.58749C17.8176 3.97916 18.0134 4.44999 18.0134 4.99999L18.0134 11L15.3634 3.64999C15.3134 3.53333 15.2634 3.42083 15.2134 3.31249C15.1634 3.20416 15.0968 3.09999 15.0134 2.99999L16.0134 2.99999ZM10.8634 3.09999C11.3968 2.89999 11.9134 2.92499 12.4134 3.17499C12.9134 3.42499 13.2634 3.81666 13.4634 4.34999L17.9134 16.55C18.1134 17.0833 18.0968 17.6042 17.8634 18.1125C17.6301 18.6208 17.2468 18.9667 16.7134 19.15L9.16343 21.9C8.63009 22.1 8.11343 22.075 7.61343 21.825C7.11343 21.575 6.76343 21.1833 6.56343 20.65L2.11343 8.44999C1.91343 7.91666 1.93009 7.39583 2.16343 6.88749C2.39676 6.37916 2.78009 6.03333 3.31343 5.84999L10.8634 3.09999ZM11.5634 4.99999L4.01343 7.74999L8.46343 20L16.0134 17.25L11.5634 4.99999Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

Sort.defaultProps = {
  color: "transparent",
};

export default Sort;
