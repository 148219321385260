/* eslint-disable no-param-reassign */
import { PersonIcon } from "@/assets/icons";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { Avatar as MUIAvatar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { IAvatar } from "./types";

const Avatar: React.FC<IAvatar> = ({ email, profilePicture, role }) => {
  const theme = useTheme();

  return (
    <MUIAvatar
      src={`data:image/png;base64,${profilePicture}`}
      sx={{
        width: 32,
        height: 32,
        background: theme.customPalette.bluePurple.slighty,
      }}
    >
      <PersonIcon color={theme.customPalette.bluePurple.dark} />
    </MUIAvatar>
  );
};

Avatar.defaultProps = {
  profilePicture: "broken",
  role: ROLE_ENUM.ADMINISTRATOR,
};

export default Avatar;
