import { useEffect, useState } from "react";

/**
 * Custom React hook to change scroll position on scroll.
 *
 * @param ref - React reference to the target element.
 * @returns An object containing width, top, position, and other styles.
 */
const useChangeScrollPositionOnscroll = (ref: any) => {
  const [position, setPosition] = useState<string>("relative");
  const [top, setTop] = useState<string>("unset");

  const [width, setWidth] = useState(0);

  // Event handler for scroll
  const handleScroll = () => {
    const element = document.querySelector("#container") as HTMLElement;
    if (document.body.offsetHeight > 950)
      if (window.scrollY < 70) {
        setTop("unset");
        setPosition("relative");
      } else if (element) {
        const distanceToTop =
          element.getBoundingClientRect().top - element.offsetHeight;
        if (distanceToTop < 20) {
          setTop("6vh");
          setPosition("fixed");
        }
      }
  };

  // Effect to set initial width and attach scroll event listener
  useEffect(() => {
    if (ref.current && width === 0) {
      setWidth((ref.current as HTMLElement).offsetWidth);
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [top, ref]);

  const others = {
    zIndex: 999,
    backdropFilter: "blur(10px)",
  };

  return { width, top, position, others };
};

export default useChangeScrollPositionOnscroll;
