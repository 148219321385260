import {
  Error,
  IntegerInput,
  StyledTextField,
  Text,
  Tooltip,
} from "@/includes";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { formatUtils } from "@/utils";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Alert, Box, Grid, Skeleton, Stack, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BusinessContributionVizualisation from "../BusinessContributionVizualisation";
import { GET_SKU_REPORT } from "./requests";
import { BusinessContributionProps } from "./types";
import { usePersistedState } from "@/hooks";

const BusinessContribution = ({
  filters,
  filtersCompare,
  compare,
}: BusinessContributionProps) => {
  const { t } = useTranslation();
  const { startDate, endDate, devices, experience } = filters;
  const {
    startDate: startDateCompare,
    endDate: endDateCompare,
    devices: devicesCompare,
    experience: experienceCompare,
  } = filtersCompare;
  const theme = useTheme();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [minRevenue, setMinRevenue] = useState(-1);
  const [maxRevenue, setMaxRevenue] = useState(-1);
  const [minRevenueCompare, setMinRevenueCompare] = useState(-1);
  const [maxRevenueCompare, setMaxRevenueCompare] = useState(-1);
  const [minTransactions, setMinTransactions] = useState(-1);
  const [maxTransactions, setMaxTransactions] = useState(-1);
  const [minTransactionsCompare, setMinTransactionsCompare] = useState(-1);
  const [maxTransactionsCompare, setMaxTransactionsCompare] = useState(-1);
  const [consentRate, setConsentRate] = usePersistedState({
    key: `consent-rate-${siteId}`,
    initialValue: 60,
  });

  const getSkuReport = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_SKU_REPORT,
        variables: {
          siteId,
          devices: devices,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          experiences:
            experience?.analyticsName !== "all"
              ? [
                  `${experience.type?.toLocaleLowerCase()}_${
                    experience.analyticsName
                  }`,
                ]
              : [],
        },
      })
      .then((res: Dic<any>) => {
        setMinRevenue(res.data.itemMonitorSummary?.generatedRevenue24h);
        setMaxRevenue(res.data.itemMonitorSummary?.generatedRevenue);
        setMinTransactions(
          res.data.itemMonitorSummary?.generatedTransactions24h
        );
        setMaxTransactions(res.data.itemMonitorSummary?.generatedTransactions);
        setStatus(StatusEnum.NORMAL);
      });
  };

  const getSkuReportCompare = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_SKU_REPORT,
        variables: {
          siteId,
          devices: devicesCompare,
          startDate: dayjs(startDateCompare).format("YYYY-MM-DD"),
          endDate: dayjs(endDateCompare).format("YYYY-MM-DD"),
          experiences: [
            `${experienceCompare.type?.toLocaleLowerCase()}_${
              experienceCompare.analyticsName
            }`,
          ],
        },
      })
      .then((res: Dic<any>) => {
        setMinRevenueCompare(res.data.itemMonitorSummary?.generatedRevenue24h);
        setMaxRevenueCompare(res.data.itemMonitorSummary?.generatedRevenue);
        setMinTransactionsCompare(
          res.data.itemMonitorSummary?.generatedTransactions24h
        );
        setMaxTransactionsCompare(
          res.data.itemMonitorSummary?.generatedTransactions
        );
        setStatus(StatusEnum.NORMAL);
      });
  };

  useEffect(() => {
    if (
      !isEmpty(experience) &&
      !isEmpty(devices) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate)
    ) {
      getSkuReport();
    }
  }, [experience, devices, startDate, endDate]);

  useEffect(() => {
    if (
      compare &&
      !isEmpty(experienceCompare) &&
      !isEmpty(devicesCompare) &&
      !isEmpty(startDateCompare) &&
      !isEmpty(endDateCompare)
    ) {
      getSkuReportCompare();
    }
  }, [
    compare,
    experienceCompare,
    devicesCompare,
    startDateCompare,
    endDateCompare,
  ]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Text variant="h2">{t("business_contribution_title")}</Text>
        <Tooltip
          title={`${t("data_period_tooltip")} ${startDate} ${t(
            "to"
          )} ${endDate}`}
        >
          <HelpOutline sx={{ fill: "#BEBBC9" }} />
        </Tooltip>
      </Stack>
      <Alert
        severity="warning"
        sx={{
          backgroundColor: "transparent",
          p: 0,
          color: theme.customPalette.grey.default,
        }}
      >
        {t("business_contribution_help")}
      </Alert>
      <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
        <Text variant="body2">{t("consent_rate_text")}</Text>
        <Box>
          <Text variant="body2" customStyle={{ mb: 0.5 }}>
            {t("rate")}:
          </Text>
          <IntegerInput
            value={consentRate}
            setValue={setConsentRate}
            min={1}
            max={100}
          />
        </Box>
      </Stack>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container spacing={2}>
          {minRevenue === -1 ||
          maxRevenue === -1 ||
          status === StatusEnum.LOADING ||
          status === StatusEnum.ERROR ? (
            <Grid item xs={12} md={6}>
              {status === StatusEnum.ERROR ? (
                <Error />
              ) : (
                <Skeleton
                  sx={{ bgcolor: theme.customPalette.others.layer_2 }}
                  height={110}
                  variant="rounded"
                  animation="wave"
                />
              )}
            </Grid>
          ) : (
            <BusinessContributionVizualisation
              label={t("revenue_contribution")}
              compare={compare}
              minValue={formatUtils.formatIntegerCurrency(
                Math.round(minRevenue / (consentRate / 100)),
                currency
              )}
              maxValue={formatUtils.formatIntegerCurrency(
                Math.round(maxRevenue / (consentRate / 100)),
                currency
              )}
              minCompareValue={formatUtils.formatIntegerCurrency(
                Math.round(minRevenueCompare / (consentRate / 100)),
                currency
              )}
              maxCompareValue={formatUtils.formatIntegerCurrency(
                Math.round(maxRevenueCompare / (consentRate / 100)),
                currency
              )}
            />
          )}
          {minTransactions === -1 ||
          maxTransactions === -1 ||
          status === StatusEnum.LOADING ||
          status === StatusEnum.ERROR ? (
            <Grid item xs={12} md={6}>
              {status === StatusEnum.ERROR ? (
                <Error />
              ) : (
                <Skeleton
                  sx={{ bgcolor: theme.customPalette.others.layer_2 }}
                  height={110}
                  variant="rounded"
                  animation="wave"
                />
              )}
            </Grid>
          ) : (
            <BusinessContributionVizualisation
              label={t("transactions_contribution")}
              compare={compare}
              minValue={formatUtils.formatInt(
                Math.round(minTransactions / (consentRate / 100))
              )}
              maxValue={formatUtils.formatInt(
                Math.round(maxTransactions / (consentRate / 100))
              )}
              minCompareValue={formatUtils.formatInt(
                Math.round(minTransactionsCompare / (consentRate / 100))
              )}
              maxCompareValue={formatUtils.formatInt(
                Math.round(maxTransactionsCompare / (consentRate / 100))
              )}
            />
          )}
        </Grid>
      </Box>
    </Stack>
  );
};

export default BusinessContribution;
