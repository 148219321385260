import { useEffect } from "react";

export function useBodyStyle(style: Dic<any>) {
  useEffect(() => {
    for (let key in style) {
      window.document.body.style[key as any] = style[key];
    }
    return () => {
      for (let key in style) {
        window.document.body.style[key as any] = "";
      }
    };
  }, [style]);
}
