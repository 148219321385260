import { Button, Text } from "@/includes";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridPaginatedVisibleSortedGridRowIdsSelector,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { HeaderProps } from "./types";

const Header = ({
  experienceName,
  startDate,
  endDate,
  apiRef,
}: HeaderProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options);

  const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) => {
    const hasDelta = apiRef.current.getAllRowIds().find((id: any) => {
      return id.includes("delta");
    });
    if (hasDelta)
      return gridPaginatedVisibleSortedGridRowIdsSelector(apiRef).slice(0, 2);
    return gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
  };
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ maxWidth: `calc(100% - ${theme.spacing(20)})` }}
      >
        <Tooltip
          title={`${t("key_indicators_over_the_period")} ${experienceName}`}
        >
          <Box sx={{ maxWidth: "100%" }}>
            <Text variant="h2" noWrap>
              {`${t("key_indicators_over_the_period")} ${experienceName}`}
            </Text>
          </Box>
        </Tooltip>
        {!!startDate && !!endDate && (
          <Tooltip
            title={`${t("data_period_tooltip")} ${startDate} ${t(
              "to"
            )} ${endDate}`}
          >
            <HelpOutlineIcon sx={{ fill: "#BEBBC9" }} />
          </Tooltip>
        )}
      </Stack>
      <Button
        startIcon={
          <GetAppIcon sx={{ fill: theme.customPalette.grey.default }} />
        }
        btnVariant="outline"
        id="export"
        onClick={() =>
          handleExport({ getRowsToExport: getRowsFromCurrentPage })
        }
      >
        {t("export")}
      </Button>
    </Stack>
  );
};

export default Header;
