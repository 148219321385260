import { Stack, useTheme } from "@mui/material";
import { useLocation } from "react-router";
import { Text, Box } from "@/includes";
import { useTranslation } from "react-i18next";
import { getData } from "./helpers";

const RightSide = () => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const { title, imageSource } = getData(location.pathname, t);
  return (
    <Box
      sx={{
        background: theme.customPalette.others.layer_2,
        height: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Stack sx={{ p: 5, pr: 0 }} spacing={8}>
        <Text variant="h1">{title}</Text>
        <Box
          component="img"
          src={`/${theme.customPalette.mode}/auth/${imageSource}`}
        />
      </Stack>
    </Box>
  );
};

export default RightSide;
