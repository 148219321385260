import SuperAdmin from "@/assets/icons/SuperAdmin";
import { RootState } from "@/services/redux/store";
import { navigatorUtils } from "@/utils";
import { MenuItem, Text } from "@includes";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomMenuItemProps } from "./types";
import { useState } from "react";
import { useHistory } from "react-router";
import { getTraduction } from "@/utils/helpers/traductions";

const CustomMenuItem = ({ Icon, label, url }: CustomMenuItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [hover, setHover] = useState<boolean>(false);
  const history = useHistory();
  return (
    <MenuItem
      key={label}
      sx={{
        cursor: "pointer",
        "&:hover": {
          "& .MuiTypography-root": {
            color: theme.customPalette.bluePurple.dark,
          },
          backgroundColor: "inherit",
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={navigatorUtils.goTo(`/${accountId}/${siteId}/${url}`, history)}
    >
      <Icon
        color={
          hover
            ? theme.customPalette.bluePurple.dark
            : theme.customPalette.grey.light
        }
      />
      <Text type="error" customStyle={{ ml: 1 }}>
        {getTraduction(t, label)}
      </Text>
    </MenuItem>
  );
};

export default CustomMenuItem;
