import { gql } from "@apollo/client";

export const GET_CATALOG_COLUMN = gql`
  query catalogColumn($catalogId: CatalogID!, $columnName: String!) {
    catalogColumn(catalogId: $catalogId, columnName: $columnName) {
      value
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories($catalogId: CatalogID!) {
    categories(catalogId: $catalogId, first: 1000, sort: "title") {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
