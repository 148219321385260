import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import useGraphQlLazyQuery from "./useGraphqlLazyQuery";

const GET_CMS = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      id
      defaultCmsIntegrationId
    }
  }
`;

export const useCMS = () => {
  const siteId: ID = useSelector((state: RootState) => state.site.siteId);
  const [CMS, setCMS] = useState<string | null>(null);

  const { getDatas, data } = useGraphQlLazyQuery(GET_CMS, { siteId });

  useEffect(() => {
    if (siteId) getDatas();
  }, [siteId]);

  useEffect(() => {
    if (data) setCMS(data?.site?.defaultCmsIntegrationId ?? null);
  }, [data]);

  return { CMS };
};
