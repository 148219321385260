interface ISuperAdmin {
  color: string;
}

const SuperAdmin: React.FC<ISuperAdmin> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g mask="url(#mask0_808_386)">
      <path
        d="M17 17C17.4167 17 17.7708 16.8542 18.0625 16.5625C18.3542 16.2708 18.5 15.9167 18.5 15.5C18.5 15.0833 18.3542 14.7292 18.0625 14.4375C17.7708 14.1458 17.4167 14 17 14C16.5833 14 16.2292 14.1458 15.9375 14.4375C15.6458 14.7292 15.5 15.0833 15.5 15.5C15.5 15.9167 15.6458 16.2708 15.9375 16.5625C16.2292 16.8542 16.5833 17 17 17ZM17 20C17.5167 20 17.9917 19.8792 18.425 19.6375C18.8583 19.3958 19.2083 19.075 19.475 18.675C19.1083 18.4583 18.7167 18.2917 18.3 18.175C17.8833 18.0583 17.45 18 17 18C16.55 18 16.1167 18.0583 15.7 18.175C15.2833 18.2917 14.8917 18.4583 14.525 18.675C14.7917 19.075 15.1417 19.3958 15.575 19.6375C16.0083 19.8792 16.4833 20 17 20ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V10.675C19.6833 10.5417 19.3583 10.4208 19.025 10.3125C18.6917 10.2042 18.35 10.125 18 10.075V6.4L12 4.15L6 6.4V11.1C6 11.8833 6.10417 12.6667 6.3125 13.45C6.52083 14.2333 6.8125 14.9792 7.1875 15.6875C7.5625 16.3958 8.01667 17.05 8.55 17.65C9.08333 18.25 9.675 18.75 10.325 19.15C10.5083 19.6833 10.75 20.1917 11.05 20.675C11.35 21.1583 11.6917 21.5917 12.075 21.975C12.0583 21.975 12.0458 21.9792 12.0375 21.9875C12.0292 21.9958 12.0167 22 12 22ZM17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3833 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3833 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22Z"
        fill={color}
      />
    </g>
  </svg>
);

export default SuperAdmin;
