import NewSlider from "@/components/_Templates/NewSlider";
import { Box } from "@mui/material";
import LoadingOverlay from "../LoadingOverlay";
import NoRowsOverlay from "../NoRowsOverlay";
import { ISlide } from "./types";

const Slide: React.FC<ISlide> = ({ isLoading, products, list }) => {
  if (isLoading) {
    return <LoadingOverlay list={list} />;
  }
  if (products?.length === 0) return <NoRowsOverlay list={list} />;
  return (
    <Box>
      <NewSlider products={products} />
    </Box>
  );
};

export default Slide;
