import { useState } from "react";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";

/**
 * Custom React hook for interacting with local storage.
 *
 * @param key - The key to use for storing and retrieving the value from local storage.
 * @param initialValue - The initial value to use if there is no value stored in local storage.
 * @returns A tuple containing the stored value and a function to set the value in local storage.
 */
function useLocalStorage(key: string, initialValue: any) {
  // State to hold the stored value
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = potionsLocalStorage.get(key);
      return item || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  /**
   * Function to set the value in local storage.
   * @param value - The value to be stored.
   */
  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (typeof window !== "undefined" && !key.includes("undefined")) {
        potionsLocalStorage.set(key, valueToStore);
      }
    } catch (error) {}
  };
  return [storedValue, setValue];
}

export default useLocalStorage;
