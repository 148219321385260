import { gql } from "@apollo/client";

export const GET_SKU_REPORT = gql`
  query ItemMonitor(
    $siteId: SiteID!
    $devices: [String!]!
    $startDate: String!
    $endDate: String!
    $experiences: [String!]
  ) {
    itemMonitorSummary(
      siteId: $siteId
      devices: $devices
      startDate: $startDate
      endDate: $endDate
      experiences: $experiences
      itemIds: []
    ) {
      generatedRevenue
      generatedRevenue24h
      generatedTransactions
      generatedTransactions24h
      generatedQuantity
      generatedQuantity24h
    }
  }
`;
