import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HideShowBlock, Text } from "@/includes";
import CategorySelector from "../CategorySelector";
import { IRun } from "./types";

const Run: React.FC<IRun> = ({
  children,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { t }: i18translateType = useTranslation();
  return (
    <HideShowBlock initialState title={t("result_and_manipulation")}>
      <Stack alignItems="end">
        <CategorySelector
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        {children}
      </Stack>
    </HideShowBlock>
  );
};

export default Run;
