import { SUMMARY_HEADERS } from "@/utils/enums/graphql";
import { CALCULATE_BY_ALL } from "../KeyIndicators/enums";
import { OverviewDataType } from "./types";

export const formatDataToOverview = (data: OverviewDataType) => {
  const ratio: any = {};
  data.users.headers.forEach((header: SUMMARY_HEADERS | any, index: number) => {
    const usersValue = data.users.rows[0][index]?.floatValue;

    if (
      CALCULATE_BY_ALL.includes(header) ||
      (!Object.values(SUMMARY_HEADERS).includes(header) && header != "segment")
    ) {
      const allVisitorsValue = data.allVisitors.rows[0][index]?.floatValue;
      ratio[header] = allVisitorsValue
        ? (usersValue ?? 0) / allVisitorsValue
        : 0;
    } else {
      const exposedVisitorsValue =
        data.exposedVisitors.rows[0][index]?.floatValue;
      ratio[header] = exposedVisitorsValue
        ? (usersValue ?? 0) / exposedVisitorsValue
        : 0;
    }
  });
  return ratio;
};
