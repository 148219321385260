import { DownloadIcon } from "@/assets/icons";
import { Button } from "@includes";
import { useTranslation } from "react-i18next";
import { IDownloadZipButton } from "./types";

const DownloadZipButton: React.FC<IDownloadZipButton> = ({ url }) => {
  const { t }: i18translateType = useTranslation();
  return (
    <Button
      onClick={() => window.open(url)}
      id="downloadZipBtn"
      startIcon={<DownloadIcon />}
      btnVariant="info"
    >
      {t("download")}
    </Button>
  );
};

export default DownloadZipButton;
