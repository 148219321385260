import Settings from "@/assets/icons/Settings";
import SuperAdmin from "@/assets/icons/SuperAdmin";
import DeveloperModeWrapper from "@/components/_Templates/DeveloperModeWrapper";
import useRole from "@/hooks/useRole";
import { userLogout } from "@actions";
import { MenuItem, Popover, Stack, Text } from "@includes";
import { Divider, useTheme } from "@mui/material";
import { RootState } from "@store";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CustomMenuItem from "../CustomMenuItem";
import { IAvatarPopover } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const AvatarPopover: React.FC<IAvatarPopover> = ({ anchorEl, handleClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { role } = useRole();

  const { t }: i18translateType = useTranslation();
  const open = Boolean(anchorEl);
  const user = useSelector((state: RootState) => state.login.user);
  const { email } = user;

  const logout = () => dispatch(userLogout());

  return (
    <Popover
      id="avatar-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          background: theme.customPalette.others.layer_1,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
        },
      }}
    >
      <MenuItem
        disableRipple
        key="email"
        sx={{
          cursor: "inherit",
          color: theme.customPalette.grey.default,
          "&:hover": {
            backgroundColor: "transparent",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Text>{`${t("welcome")}, ${email}`}</Text>
        <Stack direction="row" alignItems="center">
          <Text>{t("you_are")}</Text>
          <Text
            customStyle={{
              color: theme.customPalette.bluePurple.light,
              ml: 0.5,
            }}
          >
            {getTraduction(t, role)}
          </Text>
        </Stack>
      </MenuItem>
      <Divider />
      <DeveloperModeWrapper>
        <CustomMenuItem
          Icon={SuperAdmin}
          label="manage_all_accounts"
          url="super_admin/sites"
        />
      </DeveloperModeWrapper>
      <CustomMenuItem Icon={Settings} label="settings" url="settings/account" />

      <Divider />
      <MenuItem
        key="logout"
        sx={{
          cursor: "pointer",
          "&:hover": {
            "& .MuiTypography-root": {
              color: theme.customPalette.bluePurple.dark,
            },
            backgroundColor: "inherit",
          },
        }}
        onClick={logout}
      >
        <Text type="error">{t("logout")}</Text>
      </MenuItem>
    </Popover>
  );
};

export default AvatarPopover;
