import { Layer1Box, Text, Tooltip } from "@/includes";
import { formatUtils } from "@/utils";
import { Box, Grid, Stack, useTheme } from "@mui/material";
import { BusinessContributionVizualisationProps } from "./types";
import { useTranslation } from "react-i18next";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const BusinessContributionVizualisation = ({
  compare,
  minValue,
  maxValue,
  minCompareValue,
  maxCompareValue,
  label,
}: BusinessContributionVizualisationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Grid item xs={12} md={6}>
      <Box sx={{ width: 1, height: 1 }}>
        <Layer1Box
          sx={{
            transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
            position: "relative",
            height: "calc(100% - 24px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
            <Text variant="body1">{label}</Text>
            <Tooltip title={t("business_contribution_tooltip")}>
              <InfoOutlined sx={{ color: theme.customPalette.grey.default }} />
            </Tooltip>
          </Stack>

          <Stack
            direction="row"
            justifyContent={compare ? "flex-start" : "space-between"}
            alignItems={compare ? "baseline" : "center"}
            sx={{ mt: 1 }}
          >
            <Text variant="h3">{`${minValue} - ${maxValue}`}</Text>
            {compare && (
              <Text variant="body1" customStyle={{ mx: 1 }}>
                vs
              </Text>
            )}
            {compare && (
              <Text variant="h3">
                {`${minCompareValue} - ${maxCompareValue}`}
              </Text>
            )}
          </Stack>
        </Layer1Box>
      </Box>
    </Grid>
  );
};

export default BusinessContributionVizualisation;
