import { useCMS, useHasProperty } from "@/hooks";
import { RootState } from "@/services/redux/store";
import { gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { client } from "@/index";
import { GET_EXPERIENCES } from "./requests";

const useStep = () => {
  const { CMS } = useCMS();
  const { hasCatalog, hasEmail } = useHasProperty();
  const [hasOrganiseProductLists, setHasOrganiseProductLists] = useState(false);

  const hasCustomCatalog = hasCatalog && !CMS;

  const noGettingStarted = hasCustomCatalog;

  const siteId = useSelector((state: RootState) => state.site.siteId);

  useEffect(() => {
    client
      .query({
        query: GET_EXPERIENCES,
        variables: { siteId: parseInt(siteId) },
      })
      .then((res: any) => {
        setHasOrganiseProductLists(res.data.experiences.totalCount > 0);
      });
  }, [siteId]);

  const getStep = () => {
    if (!CMS) return 0;
    if (!hasOrganiseProductLists) return 1;
    return 0;
  };

  const step = useMemo(() => {
    return getStep();
  }, [hasEmail, hasOrganiseProductLists, CMS]);

  return { hasCustomCatalog, noGettingStarted, hasCms: CMS, step };
};

export default useStep;
