import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { formatUtils } from "@/utils";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Grid, Stack, Text } from "@includes";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Card from "../OverviewCard";
import Loading from "../OverviewLoading";
import { formatDataToOverview } from "./formatters";
import { GET_OVERVIEW } from "./requests";
import { OverviewProps, OverviewType, ResponseOverviewDataType } from "./types";

const Overview = ({ filters, filtersCompare, compare }: OverviewProps) => {
  const { t } = useTranslation();
  const { startDate, endDate, experience, devices } = filters;
  const {
    startDate: startDateCompare,
    endDate: endDateCompare,
    experience: experienceCompare,
    devices: devicesCompare,
  } = filtersCompare;
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [loading, setLoading] = useState<StatusType>(StatusEnum.LOADING);
  const [loadingCompare, setLoadingCompare] = useState<StatusType>(
    StatusEnum.NORMAL
  );
  const [data, setData] = useState<OverviewType>();
  const [dataCompare, setDataCompare] = useState<OverviewType>();

  const getData = () => {
    setLoading(StatusEnum.LOADING);
    client
      .query({
        query: GET_OVERVIEW,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          segmentExposedVisitors: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };all`,
          segmentUsers: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };convert`,
          devices: devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseOverviewDataType) => {
        setData(formatDataToOverview(res?.data));
        setLoading(StatusEnum.NORMAL);
      })
      .catch(() => setLoading(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(experience) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices)
    ) {
      getData();
    }
  }, [startDate, endDate, devices, experience]);

  const getDataCompare = () => {
    setLoadingCompare(StatusEnum.LOADING);
    client
      .query({
        query: GET_OVERVIEW,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDateCompare).format("YYYY-MM-DD"),
          endDate: dayjs(endDateCompare).format("YYYY-MM-DD"),
          segmentExposedVisitors: `potions;${experienceCompare.type?.toLowerCase()};${
            experienceCompare.analyticsName
          };all`,
          segmentUsers: `potions;${experienceCompare.type?.toLowerCase()};${
            experienceCompare.analyticsName
          };convert`,
          devices: devicesCompare,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseOverviewDataType) => {
        setDataCompare(formatDataToOverview(res?.data));
        setLoadingCompare(StatusEnum.NORMAL);
      })
      .catch(() => setLoadingCompare(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      compare &&
      !isEmpty(experienceCompare) &&
      !isEmpty(startDateCompare) &&
      !isEmpty(endDateCompare) &&
      !isEmpty(devicesCompare)
    ) {
      getDataCompare();
    }
  }, [startDateCompare, endDateCompare, devicesCompare, experienceCompare]);
  return (
    <Stack spacing={1} sx={{ width: "100%" }}>
      <Text variant="h2">{t("overview")}</Text>
      <Box
        sx={{
          "& .looker-components-reset": {
            background: "transparent !important",
            height: "100%",
          },
          height: "100%",
          width: "100%",
        }}
      >
        {loading === StatusEnum.LOADING ||
        loadingCompare === StatusEnum.LOADING ? (
          <Loading />
        ) : (
          <Grid container spacing={2}>
            <Grid key={"users_over_exposed"} item md={4} xs={12}>
              <Card
                label={t("users_over_exposed")}
                tooltip={t("user_rate_tooltip")}
                icon="analytics/experience/userRate.svg"
                value={formatUtils.formatToPercent(data?.visitors ?? 0)}
                compareValue={formatUtils.formatToPercent(
                  dataCompare?.visitors ?? 0
                )}
                compare={compare}
                loading={loading}
                loadingCompare={loadingCompare}
              />
            </Grid>
            <Grid key={"revenues_from_users"} item md={4} xs={12}>
              <Card
                label={t("revenues_from_users")}
                tooltip={t("revenues_from_exposed_tooltip")}
                icon="analytics/experience/revenueGeneratedByUser.svg"
                value={formatUtils.formatToPercent(data?.revenues ?? 0)}
                compareValue={formatUtils.formatToPercent(
                  dataCompare?.revenues ?? 0
                )}
                compare={compare}
                loading={loading}
                loadingCompare={loadingCompare}
              />
            </Grid>
            <Grid
              key={"revenues_per_users_over_revenues_per_exposed"}
              item
              md={4}
              xs={12}
            >
              <Card
                label={t("revenues_per_users_over_revenues_per_exposed")}
                tooltip={t("impact_ca_tooltip")}
                icon="analytics/experience/timesMoreSpent.svg"
                value={formatUtils.formatMultiply(
                  data?.revenue_per_user ?? 0,
                  2
                )}
                compareValue={formatUtils.formatMultiply(
                  dataCompare?.revenue_per_user ?? 0,
                  2
                )}
                compare={compare}
                loading={loading}
                loadingCompare={loadingCompare}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Stack>
  );
};

export default Overview;
