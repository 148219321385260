import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { IType } from "./types";
import { getStyle } from "./helpers";

const Tag: React.FC<IType> = ({ children, tagVariant, customStyle }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      noWrap
      sx={{
        ...getStyle(tagVariant)(theme),
        width: "fit-content",
        borderRadius: 2,
        margin: "auto !important",
        padding: "3px 14px",
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

Tag.defaultProps = {
  customStyle: {},
};

export default Tag;
