import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import { CHANGE_SITE, LOGOUT, SET_ANALYTICS } from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  type: potionsLocalStorage.get("analyticsType") ?? null,
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SET_ANALYTICS:
        draft.type = action.payload.type;
        potionsLocalStorage.set("analyticsType", action.payload.type);
        break;

      case CHANGE_SITE:
        potionsLocalStorage.remove("analyticsType");
        draft.type = null;
        break;

      case LOGOUT:
        draft.type = null;
        potionsLocalStorage.remove("analyticsType");

        break;
      default:
        break;
    }
  });
}
