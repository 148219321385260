import { RootState } from "@/services/redux/store";
import { gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { client } from "@/index";
import { useEffect, useState } from "react";
import { resourcesGenerated, sendNotification } from "@/services/redux/actions";
import { useTranslation } from "react-i18next";

const GENERATE_RESOURCES = gql`
  mutation generateResources($siteId: SiteID!) {
    generateResources(siteId: $siteId) {
      id
    }
  }
`;

const GET_PIPELINE_STATUS = gql`
  query resourcesRun($siteId: SiteID!, $runId: ID!) {
    resourcesRun(siteId: $siteId, runId: $runId) {
      status
    }
  }
`;

const PULL_INTERVAL = 3000;

/**
 * Custom React hook to generate resources and check the pipeline status.
 * Uses Apollo Client for GraphQL queries and mutations.
 *
 * @returns An object with the `generateResources` function and `loading` state.
 */
const useGenerateResources = () => {
  const { t }: i18translateType = useTranslation();

  // Redux state
  const shouldGenerateResources: boolean = useSelector(
    (state: RootState) => state.site.shouldGenerateResources
  );
  const siteId: ID = useSelector((state: RootState) => state.site.siteId);

  // Local state
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const stopLoading = () => setLoading(false);

  // Function to check the pipeline status
  const checkPipelineStatus = (runId: string) => {
    client
      .query({
        query: GET_PIPELINE_STATUS,
        variables: { runId, siteId },
        fetchPolicy: "network-only",
      })
      .then((res: any) => {
        setTimeout(() => {
          const status = res.data.resourcesRun.status;

          if (status.toLowerCase() === "running") checkPipelineStatus(runId);
          else {
            dispatch(resourcesGenerated());
            sendNotification(
              "resources_generated",
              t("resources_generated"),
              t("resources_generated_description"),
              "success"
            );
            setLoading(false);
          }
        }, PULL_INTERVAL);
      })
      .catch(stopLoading);
  };

  const generateResources = () => {
    setLoading(true);
    client
      .mutate({ mutation: GENERATE_RESOURCES, variables: { siteId } })
      .then((res: any) => {
        checkPipelineStatus(res.data.generateResources.id);
      })
      .catch(stopLoading);
  };

  // Effect to generate resources when shouldGenerateResources is true
  useEffect(() => {
    if (shouldGenerateResources) {
      dispatch(
        sendNotification(
          "generate_resources",
          t("generating_resources_description"),
          t("generating_resources_tooltip"),
          "info"
        )
      );
      generateResources();
    }
  }, [shouldGenerateResources]);

  return { generateResources, loading };
};

export default useGenerateResources;
