import { HomeOverviewDataType } from "./types";

export const formatDataToHomeOverview = (data: HomeOverviewDataType) => {
  const result: any = {};
  data?.currentPeriod?.headers.forEach((header: string, index: number) => {
    const newValue = data.currentPeriod.rows[0][index]?.floatValue;
    const oldValue = data.previousPeriod?.rows[0][index]?.floatValue;
    result[header] = {
      newValue,
      oldValue,
    };
  });
  return result;
};
