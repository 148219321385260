import { useEffect } from "react";
import { useParams } from "react-router";
import { gql } from "@apollo/client/core";
import dayjs from "dayjs";
import { useSelector as useXStateSelector } from "@xstate/react";
import { useTheme } from "@mui/material";
import { useReport } from "../../hooks/useReport";

import { Stack, Text, Box, Button, Loader } from "@includes";
import { useGraphQlQuery } from "@hooks";
import { experiencesUtils } from "@utils";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { useTranslation } from "react-i18next";
import { GET_EXPERIENCE } from "./types";
import { getDefaultDevice } from "./helpers";
import DeviceSelector from "../DeviceSelector";
import DateSelector from "../DateSelector";
import Synthesis from "../Synthesis";
import KeyIndicators from "../KeyIndicators";
import Evolution from "../Evolution";
import Engagement from "../Engagement";

const startDateSelector = (state: any) => state.context.startDate;
const endDateSelector = (state: any) => state.context.endDate;
const loadingSelector = (state: any) => state.context.loading;
const errorSelector = (state: any) => state.context.error;
const isReadySelector = (state: any) => state.context.isReady;
const deviceSelector = (state: any) => state.context.device;

const Main = () => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const { experienceId } = useParams<UrlParams>();

  const analyticsType = useSelector((state: RootState) => state.analytics.type);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const { data, loading: isGettinExperience } = useGraphQlQuery(
    GET_EXPERIENCE,
    {
      id: experienceId && parseInt(experienceId),
    }
  );

  const isConnect = data?.experience?.solution === "CONNECT";

  const dateMep = data?.experience?.deployedAt;

  const reportServices = useReport();

  const { send } = reportServices.reportService;

  const loading = useXStateSelector(
    reportServices.reportService,
    loadingSelector
  );
  const error = useXStateSelector(reportServices.reportService, errorSelector);
  const isReady = useXStateSelector(
    reportServices.reportService,
    isReadySelector
  );
  const device = useXStateSelector(
    reportServices.reportService,
    deviceSelector
  );

  const startDate = useXStateSelector(
    reportServices.reportService,
    startDateSelector
  );

  const endDate = useXStateSelector(
    reportServices.reportService,
    endDateSelector
  );

  useEffect(() => {
    if (dateMep && data && !startDate) {
      if (
        dayjs(dateMep).isBefore(
          dayjs().subtract(
            experiencesUtils.getMaximumRange(analyticsType),
            "days"
          )
        )
      ) {
        send({
          type: "initialize",
          data: {
            startDate: dayjs()
              .subtract(experiencesUtils.getDefaultRange(analyticsType), "days")
              .format("YYYY-MM-DD"),
            endDate: dayjs().subtract(2, "days").format("YYYY-MM-DD"),
            experienceId,
            onMobile: data?.experience?.onMobile,
            onDesktop: data?.experience?.onDesktop,
            device: getDefaultDevice(data?.experience),
            type: data?.experience?.type,
            name: data?.experience?.name,
          },
        });
      } else {
        send({
          type: "initialize",
          data: {
            startDate: dayjs(dateMep).format("YYYY-MM-DD"),
            endDate: dayjs().subtract(2, "days").format("YYYY-MM-DD"),
            experienceId,
            onMobile: data?.experience?.onMobile,
            onDesktop: data?.experience?.onDesktop,
            device: getDefaultDevice(data?.experience),
            type: data?.experience?.type,
            name: data?.experience?.name,
          },
        });
      }
    }
  }, [dateMep, data]);

  useEffect(() => {
    if (isReady && !loading && startDate && endDate && experienceId && device) {
      send("FETCH");
    }
  }, [isReady, startDate, endDate, experienceId, device]);

  if (isGettinExperience || loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={3} justifyContent="center" alignItems="center">
          <Text
            variant="body1"
            customStyle={{ color: theme.customPalette.error.main }}
          >
            {t("default_error")}
          </Text>
          <Button
            loading={loading}
            id="tryAgainBtn"
            btnVariant="error"
            onClick={() => {
              if (startDate && endDate && experienceId && device) {
                send("FETCH");
              }
            }}
          >
            {t("try_again")}
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Stack
      spacing={2}
      sx={{ position: "relative", boxSizing: "borderBox", width: "100%" }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ width: "100%" }}
        id="parameter-selectors-container"
      >
        <DeviceSelector />
        <DateSelector
          dateMep={
            dateMep
              ? dayjs(dateMep)
              : dayjs().subtract(
                  experiencesUtils.getDefaultRange(analyticsType),
                  "days"
                )
          }
        />
      </Stack>
      {loading || !isReady ? (
        <Loader />
      ) : (
        <>
          <Synthesis
            experienceName={data?.experience?.name}
            isConnect={isConnect}
            currency={currency}
          />
          <KeyIndicators
            experienceName={data?.experience?.name}
            currency={currency}
          />
          <Evolution />

          <Engagement />
        </>
      )}
    </Stack>
  );
};

export default Main;
