import { client } from "@/index";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { userChangeSite } from "@/services/redux/actions";
import { RootState } from "@/services/redux/store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ACCOUNT_AND_SITE } from "./requests";
import { IHasPages } from "@/utils/types/component";
import { useLocation } from "react-router";

const RedirectionWrapper: React.FC<IHasPages> = ({ children }) => {
  const dispatch = useDispatch();
  const storedSiteId = useSelector((state: RootState) => state.site.siteId);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const location = useLocation();

  const [first, accountId, siteId, ...others] = location.pathname.split("/");

  const changeSite = () => {
    client
      .query({
        query: GET_ACCOUNT_AND_SITE,
        variables: { siteId: parseInt(siteId) },
      })
      .then((res) => {
        const account = res?.data?.site?.company;
        const site = res?.data?.site;
        potionsLocalStorage.set("accountId", account?.id);
        potionsLocalStorage.set("siteId", siteId);
        potionsLocalStorage.set("accountName", account?.name);
        potionsLocalStorage.set("siteName", site?.name);
        potionsLocalStorage.set(
          "hasAlgorithmBuilder",
          site?.hasAlgorithmBuidler
        );

        dispatch(
          userChangeSite(
            siteId,
            site?.name,
            account?.id,
            account?.name,
            account?.theme,
            site?.hasAlgorithmBuilder
          )
        );
        window.location.href = `${window.location.origin}${window.location.pathname}`;
      });
  };

  useEffect(() => {
    if (isLoggedIn && !!siteId && siteId !== storedSiteId) {
      changeSite();
    }
  }, [siteId, isLoggedIn]);

  return <>{children}</>;
};

export default RedirectionWrapper;
