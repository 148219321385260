import { comparisonUtils } from "@/utils";

export /**
 * Function to format values for running an email collection.
 *
 * @param {Dic<string>} value - Values to format.
 * @returns {Dic<any>} - Formatted values.
 */
const formatValue = (value: Dic<string>): Dic<any> => {
  const res: Dic<any> = {};
  Object.keys(value).forEach((key: string) => {
    if (
      [
        "viewed_items",
        "viewing_item",
        "cart_items",
        "adding_to_cart_item",
        "product_ids",
      ].includes(key)
    ) {
      res[key] =
        value[key]
          ?.split(" ")
          ?.join("")
          ?.split(",")
          ?.filter(comparisonUtils.isNotNull) ?? [];
    } else if (["licence", "brand", "univers"].includes(key)) {
      res[key] = value[key];
    } else {
      res[key] =
        value[key]?.split(" ")?.join("")?.split(",")?.[1] ??
        value[key]?.split(" ")?.join("")?.split(",")?.[0] ??
        "";
    }
  });
  return res;
};
