import { Box, BoxProps, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { IGrowingInput } from "./types";

// Input with width depending on text length
const GrowingInput: React.FC<IGrowingInput> = ({
  value,
  onChange,
  customStyle,
  ...rest
}) => {
  const theme = useTheme();
  const [width, setWidth] = useState(2);

  const changeHandler = (evt: React.ChangeEvent<any>) => {
    onChange(evt.target.value);
  };

  useEffect(() => {
    setWidth(value.length + 2);
  }, [value]);

  return (
    <Box
      value={value}
      component="input"
      style={{
        width: width + "ch",
        minWidth: "10ch",
        background: "transparent",
        border: "none",
        outline: "none",
        color: theme.customPalette.grey.default,
        fontSize: "1.5rem",
        fontWeight: 700,
        fontFamily: "Poppins",
        ...customStyle,
      }}
      type="text"
      onChange={changeHandler}
      {...rest}
    />
  );
};

GrowingInput.defaultProps = {
  customStyle: {},
};

export default GrowingInput;
