import { gql } from "@apollo/client";
import { graphqlUtils } from "@/utils";
import { GET_CATEGORIES } from "./requests";

export const getCategories = async (
  params: any,
  init = false,
  categories: Array<any>,
  onSuccess: (data: Array<any>, cursor: string, hasNextPage: boolean) => void,
  onError: () => void
) => {
  const { catalogId, cursor, search } = params;
  const res = await graphqlUtils.fetchWithoutCache(
    GET_CATEGORIES,
    {
      catalogId,
      first: 1000,
      cursor: init ? "" : cursor,
      search,
    },
    "cache-first"
  );

  if (res?.data) {
    if (init) {
      onSuccess(
        [
          ...res?.data?.categories?.edges?.map((item: any) => ({
            ...item.node,
          })),
        ],
        res?.data?.pageInfo?.endCursor ?? "",
        res?.data?.pageInfo?.hasNextPage ?? false
      );
    } else {
      onSuccess(
        [
          ...categories,
          ...res?.data?.categories?.edges?.map((item: any) => ({
            ...item.node,
          })),
        ],
        res?.data?.pageInfo?.endCursor ?? "",
        res?.data?.pageInfo?.hasNextPage ?? false
      );
    }
  } else {
    onError();
  }
};
