import { IconButton } from "@mui/material";
import { createPortal } from "react-dom";
import { Modal, Box, ClickAwayListener, CloseIcon, Layer1Box } from "@includes";
import Overlay from "../Overlay";
import { IPopup } from "./types";

const Popup: React.FC<IPopup> = ({
  open,
  handleClose,
  children,
  customStyle,
  close,
}) => {
  return createPortal(
    <Modal open={open} onClose={handleClose} BackdropComponent={Overlay}>
      <div>
        <ClickAwayListener
          onClickAway={(event: any) => {
            if (event.target.localName === "body") {
              return;
            }
            handleClose();
          }}
        >
          <Layer1Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999999,
              maxHeight: "90vh",
              maxWidth: "75vw",
              overflowY: "auto",
              overflowX: "hidden",
              ...customStyle,
            }}
          >
            <Box sx={{ position: "relative" }}>
              {close && (
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", right: "0px", top: "-8px" }}
                >
                  <CloseIcon />
                </IconButton>
              )}

              {children}
            </Box>
          </Layer1Box>
        </ClickAwayListener>
      </div>
    </Modal>,
    document.body
  );
};

Popup.defaultProps = {
  customStyle: {},
  close: false,
};

export default Popup;
