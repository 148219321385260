interface IExperienceIcon {
  color?: string;
}

const ExperienceIcon: React.FC<IExperienceIcon> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="science">
        <mask
          id="mask0_803_20288"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#FAF9FA" />
        </mask>
        <g mask="url(#mask0_803_20288)">
          <path
            id="science_2"
            d="M5.00001 21C4.15001 21 3.54601 20.621 3.18801 19.863C2.82934 19.1043 2.91667 18.4 3.45001 17.75L9.00001 11V5H8.00001C7.71667 5 7.47901 4.904 7.28701 4.712C7.09567 4.52067 7.00001 4.28333 7.00001 4C7.00001 3.71667 7.09567 3.479 7.28701 3.287C7.47901 3.09567 7.71667 3 8.00001 3H16C16.2833 3 16.5207 3.09567 16.712 3.287C16.904 3.479 17 3.71667 17 4C17 4.28333 16.904 4.52067 16.712 4.712C16.5207 4.904 16.2833 5 16 5H15V11L20.55 17.75C21.0833 18.4 21.1707 19.1043 20.812 19.863C20.454 20.621 19.85 21 19 21H5.00001ZM5.00001 19H19L13 11.7V5H11V11.7L5.00001 19Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

ExperienceIcon.defaultProps = {
  color: "transparent",
};

export default ExperienceIcon;
