import { lazy } from "react";
import { useMaintenance } from "@hooks";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { IHasPages } from "@/utils/types/component";

const Maintenance = lazy(() => import("../../_Routes/Maintenance"));

const MaintenanceWrapper: React.FC<IHasPages> = ({ children }) => {
  const { isMaintenance } = useMaintenance();
  const isSuperUser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );

  if (isMaintenance && !isSuperUser) {
    return <Maintenance />;
  }

  return <>{children}</>;
};

export default MaintenanceWrapper;
