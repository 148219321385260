import { formatUtils } from "@/utils";
import { AB_TEST_EVOLUTION_METRICS_ENUM, yAxisUnit } from "../../enums";

export const formatDataFromEvolutionMetric = (
  value: number,
  evolutionMetric: AB_TEST_EVOLUTION_METRICS_ENUM,
  currency: string = "€"
) => {
  const type = yAxisUnit[evolutionMetric];
  if (type === "currency") {
    return formatUtils.formatCurrency(value, 2, currency);
  }
  if (type === "float") {
    return formatUtils.formatFloat(value);
  }
  return formatUtils.formatInt(value);
};
