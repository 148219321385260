import { useSelector } from "react-redux";
import { RootState } from "@store";
import { IHasPages } from "@/utils/types/component";

const SuperAdmin: React.FC<IHasPages> = ({ children }) => {
  const isSuperUser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );

  if (isSuperUser) {
    return <>{children}</>;
  }

  return <></>;
};

export default SuperAdmin;
