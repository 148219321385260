import { useViewport } from "@/hooks";
import { Text } from "@/includes";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import EvolutionMetricSelector from "../EvolutionMetricSelector";
import { HeaderProps } from "./types";

const EvolutionHeader = ({
  filters,
  evolutionMetric,
  setEvolutionMetric,
}: HeaderProps) => {
  const { startDate, endDate } = filters;
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack spacing={1} sx={{ width: "100%", mb: 2 }}>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        sx={{ width: "100%" }}
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ maxWidth: `calc(100% - ${theme.spacing(40)})` }}
        >
          <Tooltip title={`${t("evolution")} - ${filters.devices[0]}`}>
            <Box sx={{ maxWidth: "100%" }}>
              <Text variant="h2" noWrap>
                {`${t("evolution")} - ${filters.devices[0]}`}
              </Text>
            </Box>
          </Tooltip>
          {!!startDate && !!endDate && (
            <Tooltip
              title={`${t("data_period_tooltip")} ${startDate} ${t(
                "to"
              )} ${endDate}`}
            >
              <HelpOutlineIcon sx={{ fill: "#BEBBC9" }} />
            </Tooltip>
          )}
        </Stack>
        <EvolutionMetricSelector
          evolutionKey={evolutionMetric}
          handleChange={setEvolutionMetric}
        />
      </Stack>
    </Stack>
  );
};

export default EvolutionHeader;
