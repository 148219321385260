import { isEmpty } from "lodash";

export const removePreviousParameters = (
  parameters: Dic<any>,
  algoRef: string = ""
) => {
  const newParameters: Dic<any> = {};
  Object.entries(parameters)?.forEach((parameter) => {
    const algoRefs = parameter[1].algoRefs.filter(
      (algo: string) => algo !== algoRef
    );
    if (!isEmpty(algoRefs)) {
      newParameters[parameter[0]] = { ...parameter[1], algoRefs };
    }
  });
  return newParameters;
};

export const getNewParameters = (
  previousParameters: Dic<any>,
  algoParameters: Dic<any>
) => {
  const newParameters = { ...previousParameters };
  Object.entries(algoParameters).forEach((algoParam: Dic<any>) => {
    const prevParameter = newParameters?.[algoParam[0]] ?? null;
    if (!!prevParameter) {
      newParameters[algoParam[0]] = {
        type: prevParameter.type,
        algoRefs: [...prevParameter.algoRefs, ...algoParam[1].algoRefs],
      };
    } else {
      newParameters[algoParam[0]] = algoParam[1];
    }
  });
  return newParameters;
};
