import { useGenerateResources } from "@/hooks";
import { IHasPages } from "@/utils/types/component";

const GenerateResourcesWrapper: React.FC<IHasPages> = ({ children }) => {
  useGenerateResources();

  return <>{children}</>;
};

export default GenerateResourcesWrapper;
