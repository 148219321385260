import { gql } from "@apollo/client/core";

export const GET_TOKEN = gql`
  query APIToken($siteId: ID!) {
    APITokens(siteId: $siteId) {
      edges {
        node {
          token
        }
      }
    }
  }
`;
