import {
  AutoComplete,
  CatalogColumnData,
  SelectPaper,
  StyledTextField,
} from "@/includes";
import { RootState } from "@/services/redux/store";
import { comparisonUtils, filterUtils, rulesUtils } from "@/utils";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack } from "@mui/material";
import { isNil } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ManageFilter } from "../Filter/ManageFilter";
import OutputVariableSelector from "../OutputVariabelSelector";
import { IFilterClause } from "./types";
import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";
import { getTraduction } from "@/utils/helpers/traductions";

const FilterClause: React.FC<IFilterClause> = ({
  filter,
  handleChange,
  variables,
  requiredParameters,
  remove,
  filterType,
  canRemove,
}) => {
  const { t }: i18translateType = useTranslation();
  const manageFilter = new ManageFilter(filterType);
  const { entry, output, operator } = filter;
  const isProductIds =
    requiredParameters?.[entry.value] === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
    requiredParameters?.[entry.value] === VARIABLE_TYPE_ENUM.ITEM_ID; // -> most frequent key filter type
  const isCategoryId =
    requiredParameters?.[entry.value] === VARIABLE_TYPE_ENUM.CATEGORY_ID;

  const allOperations = rulesUtils.getOperators(t);

  const productFields = useSelector((state: RootState) =>
    state.catalog.catalogColumns?.filter((c: Dic<string>) => c?.type)
  );

  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );
  const entryParamType = !isProductIds
    ? requiredParameters?.[entry.value]
    : productFields.find((pf: Dic<string>) => pf.name === entry.key)?.type;

  const getOutputParamType = () => {
    if (!isProductIds && !isCategoryId)
      return requiredParameters?.[entry.value];
    return allOperations
      .find((_operator: Dic<any>) => _operator.name === operator.name)
      ?.types?.find(
        (type: Dic<any>) => type.entry === entryParamType?.toLowerCase()
      )?.output;
  };

  const outputParamType = getOutputParamType();

  const availableOperators = allOperations.filter(
    (o: Dic<any>) =>
      o.types.filter(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "entry",
          entryParamType?.toLowerCase()
        )
      )?.length > 0
  );

  const getNewOutput = (newParamType: string): Dic<any> => ({
    value: filterUtils.getDefaultValue(newParamType),
    type: "constant",
    key: "",
  });

  const handleChangeOperation = (newOperation: string) => {
    const newOutputParamType = allOperations
      .find(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "name",
          newOperation
        )
      )
      ?.types?.find(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "entry",
          entryParamType
        )
      )?.output;

    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        operator: { name: newOperation },
        output: { ...getNewOutput(newOutputParamType) },
      })
    );
  };

  const getEntryOnVariableTypeChange = (value: string) => {
    const newEntryVariableType = requiredParameters[value];
    if (
      newEntryVariableType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
      newEntryVariableType === VARIABLE_TYPE_ENUM.ITEM_ID
    )
      // most_frequent_key
      return {
        ...filter.entry,
        type: "most_frequent_key",
        value,
        key: filter.entry.key,
      };
    // params
    else
      return {
        ...filter.entry,
        type: "params",
        value,
        key: "",
      };
  };

  const handleChangeOutputValue = (value: string) => {
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        output: {
          ...filter.output,
          value,
        },
      })
    );
  };

  const handleChangeEntryValue = (value: string) => {
    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        entry: {
          ...getEntryOnVariableTypeChange(value),
        },
      })
    );
  };

  const handleChangeEntryKey = (key: string) => {
    const newEntryParamType = productFields.find(
      (pf: Dic<string>) => pf.name === key
    )?.type;
    const newOutputParamType = allOperations
      .find((_operator: Dic<any>) => _operator.name === operator.name)
      ?.types?.find(
        (type: Dic<any>) => type.entry === newEntryParamType
      )?.output;

    handleChange(
      manageFilter.handleTransformInterpretationIntoRule({
        ...filter,
        entry: {
          ...filter.entry,
          key,
        },
        operator: {},
        output: { ...getNewOutput(newOutputParamType) },
      })
    );
  };
  return (
    <Stack direction="row" alignItems="flex-end" spacing={1}>
      {isProductIds ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          <AutoComplete
            size="small"
            onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
              const { name, type } = value;
              handleChangeEntryKey(name);
            }}
            value={productFields.find(
              (p: Dic<string>, index: number) => p.name === entry.key
            )}
            options={productFields ?? []}
            disableClearable
            sx={{ width: 220, minWidth: 190 }}
            getOptionLabel={(option: Dic<string>) =>
              getTraduction(t, option.name)
            }
            PaperComponent={SelectPaper}
            renderInput={(params: any) => {
              return (
                <StyledTextField
                  {...params}
                  label={t("field")}
                  name={t("field")}
                />
              );
            }}
          />
          <OutputVariableSelector
            variables={variables}
            handleChangeOutputKey={handleChangeEntryValue}
            outputKey={entry.value}
          />
        </Stack>
      ) : (
        <OutputVariableSelector
          variables={variables}
          handleChangeOutputKey={handleChangeEntryValue}
          outputKey={entry.value}
        />
      )}
      <AutoComplete
        size="small"
        value={allOperations.find((o: Dic<any>) => o.name === operator.name)}
        onChange={(e: React.SyntheticEvent, value: any) =>
          handleChangeOperation(value.name)
        }
        sx={{ width: 200, minWidth: 170 }}
        disableClearable
        getOptionLabel={(option: Dic<any>) => option.description}
        options={availableOperators}
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label={t("operator")}
            name={t("operator")}
          />
        )}
        PaperComponent={SelectPaper}
      />
      {!isNil(output?.value) && (
        <CatalogColumnData
          outputParamType={outputParamType}
          entryParamType={entryParamType}
          handleChangeValue={handleChangeOutputValue}
          value={output.value}
          columnName={
            productFields.find((p: Dic<string>) => p.name === entry.key)?.name
          }
        />
      )}

      {canRemove && (
        <IconButton onClick={remove}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
};

FilterClause.defaultProps = {};

export default FilterClause;
