import { setUserLanguage } from "@actions";
import { AutoComplete, SelectPaper, StyledTextField } from "@includes";
import { RootState } from "@store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OPTIONS_LANGUAGES } from "./enums";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((state: RootState) => state.login.user.langue);

  const handleChange = (e: React.SyntheticEvent, value: any) => {
    dispatch(setUserLanguage(value.value));
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <AutoComplete
      PaperComponent={SelectPaper}
      options={OPTIONS_LANGUAGES}
      getOptionLabel={(option: Dic<string>) => option.name}
      value={OPTIONS_LANGUAGES.find((o: Dic<string>) => o.value === language)}
      onChange={handleChange}
      disablePortal
      disableClearable
      sx={{ width: 150 }}
      renderInput={(params: any) => (
        <StyledTextField {...params} label={t("langue")} name={t("langue")} />
      )}
    />
  );
};

export default LanguageSelector;
