export const getTraduction = (
  t: (text: string, options?: Dic<any>) => string,
  keyTrad: string = ""
) => {
  if (keyTrad.includes("_last_") && keyTrad.includes("_days")) {
    const splitKeyTrad = keyTrad.split("_");
    if (
      splitKeyTrad.length >= 4 &&
      splitKeyTrad[splitKeyTrad.length - 3] === "last" &&
      splitKeyTrad[splitKeyTrad.length - 1] === "days"
    ) {
      const name = splitKeyTrad
        .slice(0, splitKeyTrad.length - 3)
        .map((key: string) => t(key))
        .join(" ");
      return t("property_last_x_days", {
        property: `${name?.[0]?.toUpperCase()}${name?.slice(1).toLowerCase()} `,
        number: splitKeyTrad[splitKeyTrad.length - 2],
      });
    }
  }
  return t(keyTrad);
};
