import { client } from "@/services/graphql/apolloConfig";
import { Box, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { GET_LAST_SUCCEEDED_SYNC, GET_LAST_SYNC } from "./requests";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { useTranslation } from "react-i18next";
import { Button, Text } from "@/includes";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { getColorsByStatus, getTextByStatus } from "./helpers";
import { RefreshIcon } from "@/assets/icons";

const TagTooltip = () => {
  const [lastSync, setLastSync] = useState<Dic<any>>();
  const [lastSucceededSync, setLastSucceededSync] = useState<Dic<any>>();
  const [hover, setHover] = useState<boolean>(false);
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const history = useHistory();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { t } = useTranslation();
  const theme = useTheme();
  const getLastSucceededSync = () => {
    client
      .query({
        query: GET_LAST_SUCCEEDED_SYNC,
        variables: { siteId },
        fetchPolicy: "no-cache",
      })
      .then((res: Dic<any>) => {
        if (res.data?.jobs?.edges?.[0]?.node) {
          setLastSucceededSync(res.data.jobs.edges[0].node);
        }
      });
  };

  const getLastSync = () => {
    client
      .query({
        query: GET_LAST_SYNC,
        variables: { siteId },
        fetchPolicy: "no-cache",
      })
      .then((res: Dic<any>) => setLastSync(res?.data?.jobs?.edges[0]?.node));
  };

  const getData = () => {
    getLastSync();
    getLastSucceededSync();
  };

  const goToSyncs = () => {
    history.push(`/${accountId}/${siteId}/settings/syncs`);
  };

  return (
    <Box>
      <Box
        data-tooltip-id="tooltip-resources"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          border: `2px solid ${theme.customPalette.others.layer_2}`,
          px: 1,
          py: 0.5,
          borderRadius: 10,
          gap: 1,
          "& svg": {
            transition: "all 500ms",
          },
          "&:hover": {
            borderColor: theme.customPalette.bluePurple.dark,
            "& .MuiTypography-root": {
              color: theme.customPalette.bluePurple.dark,
            },
            "& svg": {
              fill: theme.customPalette.bluePurple.dark,
              transform: "rotate(90deg)",
            },
          },
        }}
        onClick={goToSyncs}
      >
        <RefreshIcon
          color={
            hover
              ? theme.customPalette.bluePurple.dark
              : theme.customPalette.grey.light
          }
        />
        <Text
          variant="body1"
          customStyle={{
            color: theme.customPalette.grey.light,
            fontWeight: "bold",
          }}
        >
          {t("resources")}
        </Text>
      </Box>
      <ReactTooltip
        id="tooltip-resources"
        afterShow={() => {
          setHover(true);
          getData();
        }}
        afterHide={() => setHover(false)}
        style={{
          backgroundColor: theme.customPalette.others.layer_1,
          padding: "20px",
          borderRadius: "4px",
        }}
        border={`3px solid ${theme.customPalette.bluePurple.dark}`}
      >
        <Box>
          {lastSync?.status && (
            <Text customStyle={{ color: getColorsByStatus(lastSync.status) }}>
              {t(getTextByStatus(lastSync.status))}
            </Text>
          )}
          <Stack direction="row" alignItems="center">
            <Text
              customStyle={{ color: theme.customPalette.success.main, mr: 0.5 }}
            >
              {`${t("latest_succeed_sync")}:`}
            </Text>
            <Text customStyle={{ color: theme.customPalette.grey.default }}>
              {lastSucceededSync?.endedAt
                ? dayjs(lastSucceededSync.endedAt).format("DD/MM/YYYY H:mm:ss")
                : "-"}
            </Text>
          </Stack>
        </Box>
      </ReactTooltip>
    </Box>
  );
};

export default TagTooltip;
