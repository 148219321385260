import { gql } from "@apollo/client";

export const GET_EXPERIENCES = gql`
  query getExperiences($siteId: SiteID, $first: Int!) {
    experiences(siteId: $siteId, first: $first, desc: false) {
      edges {
        node {
          type
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
      totalCount
    }
  }
`;
