import { AddIcon } from "@/assets/icons";
import { useViewport } from "@/hooks";
import {
  Box,
  Button,
  HideShowBlock,
  KeyboardArrowDownIcon,
  Text,
} from "@/includes";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Collapse, Divider, IconButton, Stack } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { useTranslation } from "react-i18next";
import ShortUniqueId from "short-unique-id";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import { ALGORITHM_PARTS_ENUMS, ALGORITHM_PARTS_LABEL } from "../../enums";
import useVariables from "@/hooks/useVariables";
import { Interpret } from "../Algorithm/Interpret";
import BlocksTab from "../BlocksTab";
import SubAlgorithm from "../SubAlgorithm";
import { nbItemsPerRow } from "./enums";
import { getNewParameters, removePreviousParameters } from "./helpers";
import { SubAlgorithmProps } from "./types";
import { isEmpty } from "lodash";

const SubAlgorithms = ({
  parameters,
  setParameters,
  availableVariables = [],
  algorithmIdInRecoAndMerch,
  algorithm = {},
  setAlgorithm,
  experienceType,
}: SubAlgorithmProps) => {
  const blocksSelector = (state: any) => state.context.blocks;
  const contextSelector = (state: any) => state.context;
  const { screenCategory } = useViewport();
  const { t }: i18translateType = useTranslation();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const configureAlgorithmServices = useConfigureAlgorithm();
  const { send } = configureAlgorithmServices.algorithmService;
  const interpreter = new Interpret();
  const blocks = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    blocksSelector
  );
  const context = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    contextSelector
  );

  const getAlgorithms = () => {
    try {
      return Object.values(blocks).map((block: any) => ({
        algorithm: block.defaultCase.algorithm ?? [""],
        reference: block.id,
        blockId: block.id,
        name: block.name,
        get id() {
          return this.algorithm[0];
        },
      }));
    } catch (e: any) {
      return [];
    }
  };

  const algorithms = getAlgorithms();
  const uid = new ShortUniqueId();
  const { allVariables } = useVariables();

  const handleChangeAlgorithms =
    (algoRef: string) => (newAlgorithmId: Array<string>, newAlgo: Dic<any>) => {
      send({
        type: "SET_DEFAULT_CASE",
        data: {
          blockId: algoRef,
          defaultCase: {
            ...blocks[algoRef].defaultCase,
            algorithm: newAlgorithmId,
          },
        },
      });

      if (!!setAlgorithm) {
        const algoSettings = {
          ...context,
          blocks: {
            ...context.blocks,
            [algoRef]: {
              ...context.blocks[algoRef],
              defaultCase: {
                ...blocks[algoRef].defaultCase,
                algorithm: newAlgorithmId,
              },
            },
          },
        };
        setAlgorithm({
          ...algorithm,
          settings: {
            ...interpreter.transformObjectIntoConditionalRule(algoSettings)
              ?.settings,
          },
        });
      }
      if (!!parameters && !!setParameters) {
        const removePreviousParametersConst = removePreviousParameters(
          parameters,
          algoRef
        );

        setParameters(
          getNewParameters(
            removePreviousParametersConst,
            interpreter.transformNewAlgoWithParameters(newAlgo, algoRef)
          )
        );
      }
    };

  const handleChangeAlgorithmName =
    (algoRef: string) => (newAlgorithmName: string) => {
      send({
        type: "SET_BLOCK_NAME",
        data: {
          blockId: algoRef,
          name: newAlgorithmName,
        },
      });
    };

  const addAlgorithm = () => {
    const uuid = new ShortUniqueId();
    send({
      type: "ADD_BLOCK",
      data: {
        blockId: uuid(),
      },
    });
  };

  const handleRemoveAlgorithm =
    (algoRef: string) => (blockId: string) => () => {
      send({ type: "REMOVE_BLOCK", data: { blockId } });
      if (!!parameters && !!setParameters) {
        const removePreviousParametersConst = removePreviousParameters(
          parameters,
          algoRef
        );
        setParameters(getNewParameters(removePreviousParametersConst, {}));
      }
    };

  const boxesPerRow = nbItemsPerRow[screenCategory];
  const nbRows = Math.ceil(algorithms.length / boxesPerRow);

  const onChange = (
    _sourceId: any,
    sourceIndex: number,
    targetIndex: number,
    _targetId: any
  ) => {
    const nextState = swap(algorithms, sourceIndex, targetIndex);
    const newBlocks = nextState.reduce(
      (current: Dic<any>, next: Dic<any>) => ({
        ...current,
        [next.blockId]: blocks[next.blockId],
      }),
      {}
    );
    send({ type: "SET_BLOCKS", data: { newBlocks } });
  };

  return (
    <HideShowBlock title={t("products_groups")} initialState>
      <Stack spacing={1}>
        <Text>{t("select_products_groups")}</Text>
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="algorithms"
            boxesPerRow={boxesPerRow}
            rowHeight={160}
            style={{ height: 160 * nbRows }}
            onError={(e: any) => console.error(e)}
          >
            {algorithms?.map((algorithm: Dic<string>, index: number) => (
              <GridItem key={algorithm.reference} style={{ width: "100%" }}>
                <div
                  style={{
                    width: `${90 / boxesPerRow}%`,
                    height: "100%",
                    cursor: "grab",
                  }}
                >
                  <SubAlgorithm
                    position={index + 1}
                    handleChangeAlgorithmName={handleChangeAlgorithmName(
                      algorithm.reference
                    )}
                    name={algorithm.name}
                    handleChangeAlgorithms={handleChangeAlgorithms(
                      algorithm.reference
                    )}
                    algorithm={algorithm}
                    handleRemoveAlgorithm={handleRemoveAlgorithm(
                      algorithm.reference
                    )}
                    availableVariables={availableVariables}
                    algorithmIdInRecoAndMerch={algorithmIdInRecoAndMerch}
                    experienceType={experienceType}
                  />
                </div>
              </GridItem>
            ))}
          </GridDropZone>
          <Box>
            <Button
              customStyle={{ padding: "5px 7px" }}
              btnVariant="outline"
              startIcon={<AddIcon />}
              onClick={addAlgorithm}
            >
              {t("add_products_group")}
            </Button>
          </Box>
        </GridContextProvider>
      </Stack>
      <Box sx={{ mt: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Text>{t(ALGORITHM_PARTS_LABEL.ADVANCE_SETTINGS)}</Text>
            <IconButton
              onClick={() => setShowAdvanced((prevState) => !prevState)}
            >
              {showAdvanced ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRight />
              )}
            </IconButton>
          </Stack>
          <Divider sx={{ width: "calc(100% - 116px)" }} />
        </Stack>
        <Collapse in={showAdvanced}>
          <Box>
            <BlocksTab
              tab={ALGORITHM_PARTS_ENUMS.ADVANCE_SETTINGS}
              experienceType={experienceType}
            />
          </Box>
        </Collapse>
      </Box>
    </HideShowBlock>
  );
};

export default SubAlgorithms;
