import { useTranslation } from "react-i18next";
import { ManageFilters } from "./ManageFilters";
import { rulesUtils } from "@/utils";

const useFilters = (
  parameters: Array<string>,
  filterType: "exception" | "filter" = "filter"
) => {
  const { t }: i18translateType = useTranslation();
  const allOperations = rulesUtils.getOperators(t);

  const interpret = (filters: Dic<any>) => {
    const manageFilters = new ManageFilters(
      allOperations,
      parameters,
      filterType
    );
    manageFilters.interpret(filters);
    return manageFilters.get();
  };

  return { interpret };
};

export default useFilters;
