export const getSteps = (history: Dic<any>, t: (text: string) => string) => {
  return [
    {
      title: t("cms_integration"),
      description: t("cms_integration_description"),
      btnText: t("connect"),
      imgLink: "cms_integration",
      icon: "cms_icon",
      onClick: () => history.push("settings/integrations"),
    },
    {
      title: t("merchandising"),
      description: t("merchandising_page_description"),
      btnText: t("create"),
      imgLink: "merchandising",
      icon: "merchandising_icon",
      onClick: () => history.push("merchandising/create"),
    },
  ];
};
