import { RootState } from "@/services/redux/store";
import { useSelector } from "react-redux";
import { Box, Stack } from "../../../includes";
import LazyLoading from "../../Wrappers/components/LazyLoading";
import DesktopWrapper from "../DesktopWrapper";
import MobileWrapper from "../MobileWrapper";
import { INoMenuWrapper } from "./types";

function NoMenuWrapper({ wrappedComponent }: INoMenuWrapper) {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  return (
    <>
      <DesktopWrapper>
        <Stack
          sx={{
            width: "100%",
            minHeight: "100vh",
            maxWidth: "100%",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Stack
              sx={{
                width: "100vw",
                position: "relative",
                margin: "32px",
                overflowX: "hidden",
                flex: 1,
                minHeight: "calc(100vh - 64px)",
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  position: "relative",
                  margin: "0 auto auto auto",
                  height: "100%",
                }}
                justifyContent="center"
              >
                <Box
                  sx={{
                    width: "100%",
                    boxSizing: "border-box",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <LazyLoading component={wrappedComponent} />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </DesktopWrapper>

      <MobileWrapper>
        <Stack
          sx={{
            position: "relative",
            minHeight: "100vh",
            overflowX: "hidden",
            padding: "8px 16px",
          }}
        >
          <Box
            sx={{
              padding: 0,
              margin: 0,
              maxWidth: "100%",
              overflowX: "hidden",
            }}
          >
            <LazyLoading component={wrappedComponent} />
          </Box>
        </Stack>
      </MobileWrapper>
    </>
  );
}

export default NoMenuWrapper;
