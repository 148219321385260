import { gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import useGraphQlLazyQuery from "./useGraphqlLazyQuery";
import { setHasAnalytics, setHasPages } from "@actions";
import {
  setDefaultAnalyticsIntegrationId,
  setDefaultCmsIntegrationId,
  setDefaultEmailingIntegrationId,
  setHasAbTest,
  setHasSkuReport,
  setHasCatalog,
  setHasConditionalRules,
  setHasCustomPlan,
  setHasReport,
  userChangeTheme,
  setHasAlgorithmBuilder,
} from "@/services/redux/actions/site-actions";
import { useHasExperiences, useIsMounted } from "@hooks";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";

const GET_HAS_PAGES = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      id
      showPages
      hasMonitor
      hasAbtest
      hasSkuReport
      hasCatalog
      hasAlgorithmBuilder
      defaultEmailingIntegrationId
      hasCustomPlan
      hasConditionalRules
      defaultCmsIntegrationId
      defaultAnalyticsIntegrationId
      company {
        theme
      }
    }
  }
`;

const GET_ACTIVE_MULTIPLY_EXPERIENCES = gql`
  query getExperiences($siteId: SiteID) {
    experiences(
      siteId: $siteId
      searchType: "MULTIPLY"
      filters: "is_monitorable = true AND deployed_at != null"
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

/**
 * Custom React hook to check various properties related to a site.
 *
 * @returns Object containing various properties of the site.
 */
const useHasProperty = () => {
  const isMounted = useIsMounted();
  const { hasExperiences } = useHasExperiences();
  const dispatch = useDispatch();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const hasPages: boolean = useSelector(
    (state: RootState) => state.site.hasPages
  );
  const hasAnalytics: boolean = useSelector(
    (state: RootState) => state.site.hasAnalytics
  );
  const hasAbtest: boolean = useSelector(
    (state: RootState) => state.site.hasAbtest
  );
  const hasSkuReport: boolean = useSelector(
    (state: RootState) => state.site.hasSkuReport
  );
  const hasCatalog: boolean = useSelector(
    (state: RootState) => state.site.hasCatalog
  );
  const hasConditionalRules: boolean = useSelector(
    (state: RootState) => state.site.hasConditionalRules
  );
  const isSuperUser: boolean = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const hasMember: boolean =
    isSuperUser || !["561", "504"].includes((siteId ?? "")?.toString());

  const defaultEmailingIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultEmailingIntegrationId
  );
  const defaultCmsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultCmsIntegrationId
  );
  const defaultAnalyticsIntegrationId: ID = useSelector(
    (state: RootState) => state.site.defaultAnalyticsIntegrationId
  );
  const hasCustomPlan: boolean = useSelector(
    (state: RootState) => state.site.hasCustomPlan
  );
  const isSelfService: boolean = !hasCustomPlan;

  const hasReport: boolean = useSelector(
    (state: RootState) => state.site.hasReport
  );

  const isNewLab = false;

  const {
    getDatas,
    data,
    loading: gettingHasPages,
  } = useGraphQlLazyQuery(GET_HAS_PAGES, { siteId }, "no-cache");

  // Custom hook to fetch data using GraphQL queries
  const {
    getDatas: getActiveExperiences,
    data: activeExperiences,
    loading: gettingExperiences,
  } = useGraphQlLazyQuery(
    GET_ACTIVE_MULTIPLY_EXPERIENCES,
    { siteId: parseInt(siteId) },
    "cache-first"
  );

  useEffect(() => {
    if (siteId && isMounted) {
      getDatas();
      getActiveExperiences();
    }
  }, [siteId]);

  // Effect to update Redux store based on fetched data
  useEffect(() => {
    if (data && isMounted) {
      dispatch(
        setDefaultEmailingIntegrationId(
          data?.site?.defaultEmailingIntegrationId
        )
      );
      dispatch(setHasPages(!!data?.site?.showPages));
      dispatch(
        setHasAnalytics(
          siteId > 300 && siteId < 400 ? false : !!data?.site?.hasMonitor
        )
      );
      dispatch(setHasAbTest(!!data?.site?.hasAbtest));
      dispatch(setHasSkuReport(!!data?.site?.hasSkuReport));
      dispatch(setHasCatalog(!!data?.site?.hasCatalog));
      dispatch(setHasCustomPlan(!!data?.site?.hasCustomPlan));
      dispatch(
        setHasReport(
          siteId > 300 && siteId < 400 ? false : !!data?.site?.hasMonitor
        )
      );
      dispatch(setHasConditionalRules(!!data?.site?.hasConditionalRules));
      dispatch(
        setDefaultAnalyticsIntegrationId(
          data?.site?.defaultAnalyticsIntegrationId
        )
      );
      dispatch(setDefaultCmsIntegrationId(data?.site?.defaultCmsIntegrationId));
      dispatch(userChangeTheme(data?.site?.company?.theme));
      dispatch(setHasAlgorithmBuilder(!!data?.site?.hasAlgorithmBuilder));
    }
  }, [data]);

  const refreshProperties = () => getDatas();

  return {
    refreshProperties,
    hasPages,
    hasAnalytics: hasAnalytics && hasExperiences,
    hasAbtest,
    hasSkuReport,
    hasCatalog,
    hasReport,
    defaultEmailingIntegrationId,
    defaultAnalyticsIntegrationId,
    defaultCmsIntegrationId,
    hasCmsTools: !!defaultCmsIntegrationId,
    hasAnalyticsTools: !!defaultAnalyticsIntegrationId,
    loading: gettingExperiences || gettingHasPages,
    hasEmail: !!defaultEmailingIntegrationId,
    isNewLab,
    isOldLab: !isNewLab,
    hasCustomPlan,
    isSelfService,
    hasConditionalRules,
    hasMember,
  };
};

export default useHasProperty;
