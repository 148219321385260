import { Box, Text } from "@/includes";
import { formatUtils } from "@/utils";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { labelOfAbTestEvolutionMetric, yAxisUnit } from "../../enums";
import CustomTooltip from "../EvolutionTooltip";
import { EvolutionGraphProps } from "./types";

const EvolutionGraph = ({
  data,
  evolutionMetric,
  currency = "€",
}: EvolutionGraphProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: theme.customPalette.others.layer_1,
        width: "100%",
        "& .recharts-legend-item-text": {
          color: theme.customPalette.grey.default,
        },
        "& .recharts-cartesian-grid-horizontal line": {
          stroke: theme.customPalette.others.background,
        },
      }}
    >
      <ResponsiveContainer height={400} width="100%">
        <ComposedChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            fill="transparent"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            stroke={theme.customPalette.grey.default}
            axisLine={false}
            tickFormatter={(v) => {
              return dayjs(v).format("DD.MM");
            }}
          />

          <YAxis
            stroke={theme.customPalette.grey.default}
            axisLine={false}
            tickFormatter={(v) => {
              if (yAxisUnit[evolutionMetric] === "currency")
                return formatUtils.formatCurrency(v, 2, currency);
              return v;
            }}
          />
          <Area
            dot={data.length <= 45}
            strokeWidth={3}
            type="monotone"
            dataKey={"aTest"}
            stroke={theme.customPalette.bluePurple.light}
            fill={theme.customPalette.bluePurple.slighty}
          />
          <Area
            dot={data.length <= 45}
            strokeWidth={3}
            type="monotone"
            dataKey={"bTest"}
            stroke={theme.customPalette.bluePurple.dark}
            fill={theme.customPalette.bluePurple.lighter}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
            animationDuration={200}
            content={<CustomTooltip />}
          />
          <Legend
            content={
              <>
                <Box component="ul">
                  <Box
                    component="li"
                    sx={{
                      "&::marker": {
                        color: theme.customPalette.bluePurple.light,
                        fontSize: "1.5em",
                      },
                    }}
                  >
                    <Text variant="body2">{`A - ${t(
                      labelOfAbTestEvolutionMetric[evolutionMetric]
                    )}`}</Text>
                  </Box>
                </Box>

                <Box component="ul">
                  <Box
                    component="li"
                    sx={{
                      "&::marker": {
                        color: theme.customPalette.bluePurple.dark,
                        fontSize: "1.5em",
                      },
                    }}
                  >
                    <Text variant="body2">{`B - ${t(
                      labelOfAbTestEvolutionMetric[evolutionMetric]
                    )}`}</Text>
                  </Box>
                </Box>
              </>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EvolutionGraph;
