import { Box, Grid } from "@mui/material";
import { IGridView } from "./types";

// grid with fixed nb items per row and total items
const GridView: React.FC<IGridView> = ({
  items,
  itemsPerRow,
  nbItemToShow,
  child,
}) => {
  return (
    <Grid container sx={{ padding: 1, height: "fit-content" }} spacing={1}>
      {items?.slice(0, nbItemToShow)?.map((p: Dic<any>) => (
        <Grid item md={12 / itemsPerRow} key={p?.id} sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            {child(p)}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridView;
