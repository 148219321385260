import { gql } from "@apollo/client";

export const GET_HOME_EVOLUTION = gql`
  query Evolution(
    $siteId: SiteID!
    $startDate: String!
    $endDate: String!
    $query: QueryByName!
  ) {
    monitor(
      siteId: $siteId
      segment: "potions;all;all;convert"
      devices: ["mobile", "desktop"]
      query: $query
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
