import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { RootState } from "@store";

const useSendAnalytics = () => {
  const user = useSelector((state: RootState) => state.login.user);

  const { email } = user;

  useEffect(() => {
    if (email && !email?.includes("@get-potions.com")) ReactGA.send("pageview");
  }, [user, email]);
};

export default useSendAnalytics;
