import { formatUtils } from "@/utils";

export const calculateTendance = (data: Array<number>) => {
  if (data[0] === 0 || !data[0])
    return { value: "", isPositive: false, hide: true };
  const res = (data[1] - data[0]) / data[0];
  const sign = res >= 0 ? "+" : "-";

  return {
    value: `${sign} ${formatUtils.formatToPercent(Math.abs(res))}`,
    isPositive: sign === "+",
    hide: Math.abs(res * 100) >= 1000,
  };
};
