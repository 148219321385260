import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * Custom React hook for integrating Net Promoter Score (NPS) with Satismeter.
 *
 * @returns {void}
 */
const useNPS = () => {
  const siteId = useSelector((state) => state.site.siteId);
  const email = useSelector((state) => state.login.user.email);
  const userId = useSelector((state) => state.login.user.id);
  const loggedIn = useSelector((state) => state.login.isLoggedIn);

  /**
   * Effect to initialize Satismeter integration when site ID, email, and login status are valid.
   */
  useEffect(() => {
    if (
      siteId &&
      !isEmpty(email) &&
      !email.includes("@get-potions.com") && // Exclude specific emails from Satismeter
      process.env.REACT_APP_ENV === "prod" && // Ensure the app is in production environment
      loggedIn
    ) {
      (function () {
        window.satismeter =
          window.satismeter ||
          function () {
            (window.satismeter.q = window.satismeter.q || []).push(arguments);
          };
        window.satismeter.l = 1 * new Date();
        const script = document.createElement("script");
        const parent = document.getElementsByTagName("script")[0].parentNode;
        script.async = 1;
        script.src = "https://app.satismeter.com/js";
        parent.appendChild(script);
      })();

      // Send information to Satismeter
      window.satismeter({
        writeKey: "dNaqbWU1ivyozsGk",
        userId,
        traits: {
          email,
        },
      });
    }
  }, [siteId, loggedIn]);
};

export default useNPS;
