interface ICopyIcon {
  color?: string;
}

const CopyIcon: React.FC<ICopyIcon> = ({ color }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3927_15163"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.74292" width="16" height="16" fill="#FAF9FA" />
      </mask>
      <g mask="url(#mask0_3927_15163)">
        <path
          d="M3.33333 15.4096C2.96667 15.4096 2.65267 15.2792 2.39133 15.0183C2.13044 14.757 2 14.443 2 14.0763V4.74296H3.33333V14.0763H10.6667V15.4096H3.33333ZM6 12.743C5.63333 12.743 5.31956 12.6125 5.05867 12.3516C4.79733 12.0903 4.66667 11.7763 4.66667 11.4096V3.40963C4.66667 3.04296 4.79733 2.72896 5.05867 2.46763C5.31956 2.20674 5.63333 2.07629 6 2.07629H12C12.3667 2.07629 12.6807 2.20674 12.942 2.46763C13.2029 2.72896 13.3333 3.04296 13.3333 3.40963V11.4096C13.3333 11.7763 13.2029 12.0903 12.942 12.3516C12.6807 12.6125 12.3667 12.743 12 12.743H6ZM6 11.4096H12V3.40963H6V11.4096Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CopyIcon.defaultProps = {
  color: "transparent",
};

export default CopyIcon;
