import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import { CLOSE_DRAWER, OPEN_DRAWER } from "../actions/action-types";

export const initialState = {
  open: false,
};

export default function drawerReducer(
  state = initialState,
  action: PayloadAction<Dic<string>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case OPEN_DRAWER:
        draft.open = true;
        break;
      case CLOSE_DRAWER:
        draft.open = false;
        break;
      default:
        break;
    }
  });
}
