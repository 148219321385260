import { Box, Stack } from "@mui/material";
import DesktopWrapper from "../DesktopWrapper";
import LazyLoading from "../../Wrappers/components/LazyLoading";
import { ITopBarWrapper } from "./types";

const TopBarWrapper: React.FC<ITopBarWrapper> = ({
  wrappedComponent,
  components,
}) => {
  const { TopBar } = components;

  return (
    <Stack
      sx={{
        width: "100%",
        position: "relative",
        margin: "0 auto auto auto",
      }}
      justifyContent="center"
    >
      <TopBar />

      <DesktopWrapper>
        <div>
          <Box
            sx={{
              width: "100%",
              paddingTop: 0,
              paddingBottom: 6,
              boxSizing: "border-box",
            }}
          >
            <LazyLoading component={wrappedComponent} />
          </Box>
        </div>
      </DesktopWrapper>
    </Stack>
  );
};

export default TopBarWrapper;
