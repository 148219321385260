import { useDebounce } from "@/hooks";
import { AutoComplete, StyledTextField, SelectPaper } from "@/includes";
import { RootState } from "@/services/redux/store";
import { graphqlUtils } from "@/utils";
import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Skeleton, useTheme } from "@mui/material";
import { IAbTestSelector } from "./types";
import { GET_ABTESTS } from "./requests";
import { isEmpty } from "lodash";

let FIRST = 50;

const AbTestSelector: React.FC<IAbTestSelector> = ({
  abTest,
  handleChange,
  loading,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const [searching, setSearching] = useState(false);

  const [cursor, setCursor] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [abTests, setAbTests] = useState<Array<Dic<any>>>([]);

  const fetch = async (endCursor: string) => {
    return await graphqlUtils.fetchWithoutCache(
      GET_ABTESTS,
      { search, siteId, first: FIRST, cursor: endCursor },
      "no-cache"
    );
  };

  const handleSearch = async (search: String, endCursor = "", init = false) => {
    setSearching(true);
    let res = await fetch(endCursor);
    const totalCount = res?.data?.campaigns?.totalCount ?? 0;

    if (res.data?.campaigns?.edges) {
      if (!!search && totalCount < res.data.campaigns.edges.length) {
        FIRST = totalCount;
        res = await fetch(endCursor);
      }
      setSearching(false);
      if (init)
        setAbTests(
          res?.data?.campaigns?.edges?.map((item: any) => ({
            ...item.node,
          }))
        );
      else
        setAbTests([
          ...abTests,
          ...res.data.campaigns.edges.map((item: any) => ({
            ...item.node,
          })),
        ]);
      setCursor(res?.data?.campaigns?.pageInfo?.endCursor ?? "");
      setHasNextPage(res?.data?.campaigns?.pageInfo?.hasNextPage ?? false);
    }
  };

  useEffect(() => {
    if (siteId && !searching) {
      handleSearch("");
    }
  }, [siteId]);

  const [search, setSearch] = useState("");

  const handleSearchChange = (newSearch: string) => setSearch(newSearch);

  useDebounce(
    () => {
      handleSearch(search, "", true);
    },
    [search],
    600
  );

  useEffect(() => {
    if (!abTest?.id && abTests?.length > 0) {
      handleChange(abTests[0]);
    }
  }, [abTests]);

  if (loading)
    return (
      <Skeleton
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        variant="rounded"
        animation="wave"
        width="100%"
        height={50}
      />
    );

  return (
    <AutoComplete
      value={abTest}
      onChange={(e: any, value: Dic<any>) => handleChange(value)}
      filterOptions={(x) => x}
      sx={{ width: "100%" }}
      disableClearable
      loading={searching}
      getOptionLabel={(option: any) =>
        !isEmpty(option.description) ? option.description : option.name ?? ""
      }
      options={abTests}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("abtest")}
          name={t("abtest")}
          onChange={(e: any) => handleSearchChange(e.target.value)}
          value={search}
        />
      )}
      PaperComponent={SelectPaper}
    />
  );
};

AbTestSelector.defaultProps = {
  loading: false,
};

export default AbTestSelector;
