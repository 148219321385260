interface IRemove {
  color?: string;
}

const Remove: React.FC<IRemove> = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12.1667L8 9.16667L11 12.1667L12.1667 11L9.16667 8L12.1667 5L11 3.83333L8 6.83333L5 3.83333L3.83333 5L6.83333 8L3.83333 11L5 12.1667ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V2.16667H2.16667V13.8333Z"
      fill={color}
    />
  </svg>
);

Remove.defaultProps = {
  color: "transparent",
};

export default Remove;
