export const getColor = (textType: string | undefined) => (theme: any) => {
  switch (textType) {
    case "primary":
      return { color: theme.customPalette.grey.default };
    case "secondary":
      return { color: theme.customPalette.grey.light };
    case "disabled":
      return { color: theme.customPalette.others.background };
    case "error":
      return { color: theme.customPalette.error.main };
    case "success":
      return { color: theme.customPalette.success.main };
    case "info":
      return { color: theme.customPalette.info.main };
    default:
      return { color: theme.customPalette.grey.default };
  }
};
