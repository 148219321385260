import Experience from "@/components/_Organisms/ReportExperience";
import HasEditingRoleWrapper from "@/components/_Templates/HasEditingRoleWrapper";
import useRole from "@/hooks/useRole";
import { client } from "@/index";
import { RootState } from "@/services/redux/store";
import { experiencesUtils } from "@/utils";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { usePersistedState, useSetState } from "@hooks";
import { Loader, StyledTabPanel, SwitchIcon } from "@includes";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Report from "./components/Report";
import { getColorVariant } from "./helpers";
import { GET_EXPERIENCE } from "./requests";
import { IExperienceTabs } from "./types";

const ExperienceTabs: React.FC<IExperienceTabs> = ({ slots }) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const hasBuilder = useSelector(
    (state: RootState) => state.site.hasAlgorithmBuilder
  );
  const [experience, setExperience] = useSetState({
    id: "",
    name: "",
    settings: {},
    deployedAt: null,
    type: "",
  });

  const { deployedAt, isMonitorable, type } = experience;

  const [showNewReport, setShowNewReport] = React.useState(false);

  const hasAnalytics = useSelector(
    (state: RootState) => state.site.hasAnalytics
  );

  const { experienceId } = useParams<UrlParams>();

  const [status, setStatus] = React.useState("loading");

  const [canSeeReportTab, setCanSeeReportTab] = React.useState(false);
  const [canSeeConfigureTab, setCanSeeConfigureTab] = React.useState(false);
  const getExperience = () => {
    if (experienceId === "create") {
      setCanSeeConfigureTab(true);
      setValue("Configure");
      setStatus("");
    } else {
      setStatus("loading");
      client
        .query({
          query: GET_EXPERIENCE,
          variables: { experienceId: experienceId && parseInt(experienceId) },
        })
        .then((res: any) => {
          const exp = res.data.experience;
          setExperience(exp);
          setCanSeeReportTab(
            experiencesUtils.hasReport(exp.type, exp.isMonitorable)
          );
          setCanSeeConfigureTab(
            res.data.experience.isConfigurable &&
              experiencesUtils.hasConfigure(exp.type)
          );
          setStatus("");
        })
        .catch(() => setStatus("error"));
    }
  };

  React.useEffect(() => {
    getExperience();
  }, []);

  const [value, setValue] = usePersistedState({
    key: "ExperienceTab",
    initialValue: "Configure",
  });

  React.useEffect(() => {
    if (status !== "loading") {
      if (!canSeeReportTab && value === "Report") {
        setValue("Configure");
      }
      if (!canSeeConfigureTab && value === "Configure") {
        setValue("Report");
      }
    }
  }, [canSeeConfigureTab, canSeeReportTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (hasAnalytics && !!experienceId && value === "Report" && !!deployedAt) {
      setShowNewReport(true);
    }
  }, [hasAnalytics, value, deployedAt]);
  const { isSuperUser, role } = useRole();
  if (status === "loading") return <Loader />;

  return (
    <Stack
      sx={{
        width: "100%",
        typography: "body1",
        "& .MuiButtonBase-root.MuiTab-root": {
          minHeight: "30px",
          height: "fit-content",
          fontSize: "1rem",
          alignItems: "center",
          padding: "4px 16px 4px 0",
          textTransform: "capitalize",
        },
        "& .MuiTabs-flexContainer": {
          alignItems: "end",
          height: "100%",
        },
      }}
      spacing={1}
    >
      {!!slots && slots.header && value === "Configure" && (
        <Box sx={{ paddingTop: 1 }}>{slots.header}</Box>
      )}
      {!!slots && slots.report_header && value === "Report" && (
        <Box sx={{ paddingTop: 1 }}>{slots.report_header}</Box>
      )}

      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            margin: "0 !important",
          }}
        >
          <TabList
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.customPalette.bluePurple.dark,
              },
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiButtonBase-root": {
                alignItems: "end",
              },
            }}
          >
            <Tab
              id="report-tab"
              sx={{
                color: `${
                  theme.customPalette.grey[
                    getColorVariant("Report", value, !canSeeReportTab)
                  ]
                } !important`,
                gap: "10px",
                display:
                  !canSeeReportTab || !deployedAt ? "none" : "inline-flex",
              }}
              label={t("report")}
              value="Report"
              disabled={!canSeeReportTab}
              icon={
                <SwitchIcon
                  icon="report"
                  disabled={!canSeeReportTab || value !== "Report"}
                />
              }
              iconPosition="start"
            />

            {!hasBuilder &&
              (isSuperUser || role === ROLE_ENUM.ADMINISTRATOR) &&
              !!slots &&
              !!slots.configure && (
                <Tab
                  id="configure-tab"
                  sx={{
                    color: `${
                      theme.customPalette.grey[
                        getColorVariant("Configure", value, !canSeeConfigureTab)
                      ]
                    } !important`,
                    gap: "10px",
                    display: !canSeeConfigureTab ? "none" : "inline-flex",
                  }}
                  label={t("configure")}
                  value="Configure"
                  disabled={!canSeeConfigureTab}
                  icon={
                    <SwitchIcon
                      icon="configure"
                      disabled={value !== "Configure" || !canSeeConfigureTab}
                    />
                  }
                  iconPosition="start"
                />
              )}
          </TabList>
        </Box>
        <StyledTabPanel
          value="Report"
          sx={{ display: !canSeeReportTab ? "none" : "block" }}
        >
          {showNewReport ? (
            <Stack sx={{ mt: 2 }}>
              <Experience />
            </Stack>
          ) : (
            <Report />
          )}
        </StyledTabPanel>
        {!hasBuilder && (
          <HasEditingRoleWrapper>
            {!!slots && !!slots.configure && (
              <StyledTabPanel
                sx={{ display: !canSeeConfigureTab ? "none" : "block" }}
                value="Configure"
              >
                {slots.configure}
              </StyledTabPanel>
            )}
          </HasEditingRoleWrapper>
        )}
      </TabContext>
    </Stack>
  );
};

ExperienceTabs.defaultProps = {
  slots: undefined,
};

export default ExperienceTabs;
