import { Tooltip, useTheme } from "@mui/material";
import { Box, Stack, Tag, Text, Button, Grid, ButtonLink } from "@includes";
import { DesktopWrapper } from "../../Wrappers";
import { useViewport } from "@/hooks";
import { useTranslation } from "react-i18next";
import { ISelectableBox } from "./types";
import { mapTagToName, mapTagToVariant } from "./enums";

const SelectableBox: React.FC<ISelectableBox> = ({
  imageSource,
  title,
  subtitle,
  imageBackground,
  isSelected,
  handleChangeSelectedBox,
  customStyle,
  tag,
  link,
  disabled,
  tooltip,
}) => {
  const { screenWidth, isMobile } = useViewport();
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  return (
    <Grid
      container
      sx={{
        border: disabled ? "none" : `1px solid`,
        borderColor: isSelected
          ? theme.customPalette.bluePurple.dark
          : theme.customPalette.bluePurple.lighter,
        transition: "border-color 0.3s ease-in-out",
        padding: "14px",
        borderRadius: 3,
        background: theme.customPalette.others.layer_2,
        cursor: disabled ? "initial" : "pointer",
        ...customStyle,
        height: "100%",
        boxSizing: "border-box",
      }}
      onClick={() => !disabled && handleChangeSelectedBox()}
    >
      <Grid item md={2} sm={2} xs={2}>
        <Tooltip title={tooltip}>
          <Box
            component="img"
            src={imageSource}
            sx={{
              background: imageBackground,
              padding: screenWidth < 1200 ? "4px" : 1,
              clipPath: "circle(50%)",
            }}
          />
        </Tooltip>
      </Grid>
      <Grid item md={8} sm={8} xs={8}>
        <>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Text
                textType={disabled ? "disabled" : "primary"}
                customStyle={{ maxWidth: tag ? "70%" : "100%" }}
              >
                {title}
              </Text>
              {tag && (
                <Tag tagVariant={mapTagToVariant[tag]}>{mapTagToName[tag]}</Tag>
              )}
            </Stack>
            <Text textType={disabled ? "disabled" : "secondary"}>
              {subtitle}
            </Text>
            <DesktopWrapper>
              <Stack alignItems="end" sx={{ width: "100%" }}>
                {!!link && (
                  <ButtonLink
                    customStyle={{ fontSize: 12, whiteSpace: "nowrap" }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      window.open(link);
                    }}
                  >
                    {t("learn_more")}
                  </ButtonLink>
                )}
              </Stack>
            </DesktopWrapper>
          </Stack>
        </>
      </Grid>
      <Grid item md={2} sm={2} xs={2}>
        <Stack justifyContent="center" alignItems="center">
          <Box
            sx={{
              height: 35,
              width: 35,
              opacity: isSelected ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
            component="img"
            src={`/${theme.customPalette.mode}/status/checkCircle.svg`}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

SelectableBox.defaultProps = {
  customStyle: {},
  tag: undefined,
  disabled: false,
  link: "",
  tooltip: undefined,
};

export default SelectableBox;
