import { Box, useTheme } from "@mui/material";
import { ISwitchIcon } from "./types";

// switch between 2 icons in public/theme/icons folder
const SwitchIcon: React.FC<ISwitchIcon> = ({ icon, disabled }) => {
  const theme = useTheme();
  return (
    <>
      {disabled ? (
        <Box
          component="img"
          src={`/${theme.customPalette.mode}/icons/disabled_${icon}.svg`}
        />
      ) : (
        <Box
          component="img"
          src={`/${theme.customPalette.mode}/icons/${icon}.svg`}
        />
      )}
    </>
  );
};

export default SwitchIcon;
