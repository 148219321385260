import { Paper, useTheme } from "@mui/material";

// Paper for the Select and Autocomplete components
const SelectPaper = (props: any) => {
  const theme = useTheme();
  return (
    <Paper
      {...props}
      sx={{
        background: theme.customPalette.others.layer_2,
        border: "1px solid",
        borderColor: theme.customPalette.others.background,
        maxWidth: 500,
      }}
    />
  );
};

export default SelectPaper;
