import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { ILoader } from "./types";
import { getCenteredStyle } from "./helpers";

const Loader: React.FC<ILoader> = ({ centered, isTable, customStyle }) => {
  const addStyle = centered ? getCenteredStyle(!!isTable) : {};

  return (
    <Box sx={{ display: "flex", ...addStyle, ...customStyle }}>
      <CircularProgress />
    </Box>
  );
};

Loader.defaultProps = {
  isTable: false,
  centered: true,
  customStyle: {},
};

export default Loader;
