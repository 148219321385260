import { useTranslation } from "react-i18next";
import { useSelector as useXStateSelector } from "@xstate/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  useTheme,
} from "@mui/material";
import { IntegerInput, Stack, Text } from "@/includes";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { RuleSizeProps } from "./types";

const settingsSelector = (state: any) => state.context.settings;

const OptionSize = ({
  type,
  title,
  tooltip,
  minValue,
  property,
  defaultValue,
  checkedValue,
}: RuleSizeProps) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const configureAlgorithmServices = useConfigureAlgorithm();

  const { send } = configureAlgorithmServices.algorithmService;

  const settings = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    settingsSelector
  );

  const size = settings?.[property] ?? defaultValue;

  const setSize = (newSize: number) =>
    send({ type: type, data: { size: newSize } });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (size >= 0) setSize(-1);
    else setSize(checkedValue);
  };

  return (
    <Stack direction="row" alignItems="center">
      <FormControlLabel
        control={<Checkbox checked={size >= 0} onChange={handleChange} />}
        label={
          <Stack spacing={1} alignItems="center" direction="row">
            <Text>{title}</Text>
            <Tooltip title={tooltip}>
              <HelpOutline sx={{ color: theme.customPalette.grey.default }} />
            </Tooltip>
          </Stack>
        }
      />
      {size >= 0 && (
        <Box sx={{ height: "fit-content" }}>
          <IntegerInput value={size} setValue={setSize} min={minValue} />
        </Box>
      )}
    </Stack>
  );
};

export default OptionSize;
