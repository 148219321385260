import { gql } from "@apollo/client";

export const GET_HOME_OVERVIEW = gql`
  query HomeOverview(
    $siteId: SiteID!
    $startDate: String!
    $endDate: String!
    $previousStartDate: String!
    $previousEndDate: String!
  ) {
    currentPeriod: monitor(
      siteId: $siteId
      segment: "potions;all;all;convert"
      devices: ["mobile", "desktop"]
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    previousPeriod: monitor(
      siteId: $siteId
      segment: "potions;all;all;convert"
      devices: ["mobile", "desktop"]
      query: SUMMARY
      startDate: $previousStartDate
      endDate: $previousEndDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
