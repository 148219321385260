import { StyledTextField } from "@includes";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Chip, Skeleton, Stack, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import { useTranslation } from "react-i18next";
import SelectPaper from "../../_Templates/SelectPaper";
import { IMultipleDeviceSelector } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Selector for devices (desktop, mobile)
const MultipleDeviceSelector: React.FC<IMultipleDeviceSelector> = ({
  devices,
  setDevices,
  availableDevices,
  loading,
}) => {
  const theme = useTheme();

  const { t }: i18translateType = useTranslation();

  const options: Array<any> = availableDevices.map((device: string) => ({
    value: device.trim(),
    title: getTraduction(t, device.trim()),
  }));

  const handleDelete = (index: number) => (e: React.SyntheticEvent) => {
    setDevices(devices.filter((d: string, i: number) => i !== index));
  };

  if (loading)
    return (
      <Skeleton
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        variant="rounded"
        height={50}
        width="100%"
        animation="wave"
      />
    );

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        width: "100%",
        "& .MuiSvgIcon-root": {
          cursor: "pointer",
        },
      }}
    >
      <Autocomplete
        disableClearable
        popupIcon={<KeyboardArrowDownIcon />}
        sx={{
          "& .MuiChip-root": {
            background: theme.customPalette.bluePurple.lighter,
            color: theme.customPalette.bluePurple.dark,
            border: "none",
          },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option?.title ?? ""}
              {...getTagProps({ index })}
              deleteIcon={
                <CloseIcon sx={{ fill: theme.customPalette.grey.default }} />
              }
              onDelete={handleDelete(index)}
            />
          ))
        }
        multiple
        limitTags={2}
        PaperComponent={SelectPaper}
        value={devices.map((device: any) =>
          options.find((o: any) => o.value === device)
        )}
        onChange={(event: any, newValue: any) => {
          setDevices(newValue.map((new_value: Dic<string>) => new_value.value));
        }}
        id="checkboxes-devices"
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option?.title ?? ""}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.title ?? ""}
            </li>
          );
        }}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={t("devices")}
            placeholder={`${t("add_devices")}...`}
          />
        )}
      />
    </Stack>
  );
};

MultipleDeviceSelector.defaultProps = {
  loading: false,
};

export default MultipleDeviceSelector;
