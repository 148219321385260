import { Text } from "@/includes";
import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EvolutionLegendProps } from "./types";

const Legend = ({ label, compare }: EvolutionLegendProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Box component="ul">
        <Box
          component="li"
          sx={{
            "&::marker": {
              color: theme.customPalette.bluePurple.light,
              fontSize: "1.5em",
            },
          }}
        >
          <Text variant="body2">{label}</Text>
        </Box>
      </Box>
      {!compare && (
        <Box component="ul" sx={{ listStyle: "none", position: "relative" }}>
          <Box
            component="li"
            sx={{
              "&::before": {
                content: '"--"',
                position: "absolute",
                left: 0,
                top: "-25%",
                color: theme.customPalette.bluePurple.light,
                fontSize: "1.5em",
                marginLeft: "18px",
              },
            }}
          >
            <Text variant="body2">{`${label} - ${t("previous_period")}`}</Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Legend;
