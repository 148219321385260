import useRole from "@/hooks/useRole";
import { RootState } from "@/services/redux/store";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { useSelector } from "react-redux";
import { IHasEditingRoleWrapper } from "./types";

const HasEditingRoleWrapper: React.FC<IHasEditingRoleWrapper> = ({
  children,
}) => {
  const { role, isSuperUser } = useRole();

  if (!isSuperUser && role === ROLE_ENUM.READER) {
    return <></>;
  }

  return <>{children}</>;
};

export default HasEditingRoleWrapper;
