import { Box, Collapse, IconButton, useTheme } from "@mui/material";
import { useState } from "react";
import { Stack, Button, Text, JsonInput } from "@includes";
import { useTranslation } from "react-i18next";
import { IJsonCodeBlock } from "./types";

const JsonCodeBlock: React.FC<IJsonCodeBlock> = ({
  validate,
  cancel,
  value,
  onChange,
  canValidate,
  handleDelete,
  disabled,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(value === "" ? true : false);

  return (
    <Collapse
      in={open}
      collapsedSize={45}
      easing={{
        exit: "cubic-bezier(0, 1.5, .8, 1)",
      }}
    >
      <Box
        sx={{
          background: canValidate
            ? theme.customPalette.others.layer_1
            : theme.customPalette.others.layer_2,
          p: 1,
          borderRadius: 3,
          position: "relative",
          transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
        }}
      >
        {open ? (
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <JsonInput value={value} onChange={onChange} />

            <Stack sx={{ height: "100%" }} justifyContent="space-between">
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  height: 30,
                }}
              >
                {!!handleDelete && (
                  <Button
                    onClick={handleDelete}
                    disabled={disabled}
                    customStyle={{ height: 30 }}
                    btnVariant="error"
                    id="deleteFilterBtn"
                  >
                    {t("delete")}
                  </Button>
                )}
                <Button id="close" onClick={() => setOpen(false)}>
                  {t("close")}
                </Button>
              </Stack>
              <Stack
                spacing={1}
                alignItems="center"
                direction="row"
                sx={{ position: "absolute", bottom: 13, right: 13 }}
              >
                {canValidate && !disabled ? (
                  <Button
                    id="validateCustomFilter"
                    onClick={() => validate(value)}
                  >
                    {t("validate")}
                  </Button>
                ) : (
                  <IconButton
                    disabled
                    sx={{
                      background: `${theme.customPalette.success.complementary} !important`,
                      borderRadius: 3,
                      border: "1px solid",
                      borderColor: theme.customPalette.success.main,
                    }}
                  >
                    <Box
                      component="img"
                      src={`/${theme.customPalette.mode}/status/checkSuccess.svg`}
                    />
                  </IconButton>
                )}
                <Button
                  disabled={!canValidate || disabled}
                  onClick={cancel}
                  id="cancelCustomFilter"
                  btnVariant="outline"
                >
                  {t("reset")}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack
            justifyContent="space-between"
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
          >
            <Text>{t("custom_filter")}</Text>
            <Stack direction="row" spacing={1} alignItems="center">
              {!!handleDelete && (
                <Button
                  onClick={handleDelete}
                  disabled={disabled}
                  customStyle={{ height: 30 }}
                  btnVariant="error"
                  id="deleteFilterBtn"
                >
                  {t("delete")}
                </Button>
              )}
              <Button
                id="open"
                onClick={() => setOpen(true)}
                customStyle={{ height: 30 }}
              >
                {t("open")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Collapse>
  );
};

JsonCodeBlock.defaultProps = {
  canValidate: true,
  handleDelete: undefined,
};

export default JsonCodeBlock;
