import { gql } from "@apollo/client";

export const GET_LAST_SUCCEEDED_SYNC = gql`
  query Jobs($siteId: SiteID!) {
    jobs(
      siteId: $siteId
      filters: "status = SUCCEEDED"
      sort: "ended_at desc"
      first: 1
    ) {
      edges {
        node {
          endedAt
        }
      }
    }
  }
`;

export const GET_LAST_SYNC = gql`
  query Jobs($siteId: SiteID!) {
    jobs(siteId: $siteId, sort: "created_at desc", first: 1) {
      edges {
        node {
          status
        }
      }
    }
  }
`;
