import { THEME_OPTIONS_ENUM } from "./enums";

export const getPlurialEntityType = (type: string) => {
  if (type === "company") {
    return "companies";
  }
  return `${type}s`;
};

export const getOptionsByField = (fieldType: string) => {
  if (fieldType.toLocaleLowerCase() == "theme") {
    return Object.values(THEME_OPTIONS_ENUM);
  }
  return [];
};
