import { useTranslation } from "react-i18next";
import { Stack, Text } from "../../../includes";
import { useTheme } from "@mui/material";

const PrivacyPolicies = () => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  return (
    <Stack sx={{ paddingLeft: 4, mt: 2 }}>
      <Text variant="h3">{t("PrivacyPolicy 61")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 1")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 2")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 3")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 4")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 5")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 6")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 7")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 8")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 9")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 10")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 11")}</Text>
      <br />
      {t("PrivacyPolicy 12")}
      <Text variant="body2">{t("PrivacyPolicy 13")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 14")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 15")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 16")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 17")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 18")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 19")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 20")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 21")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 22")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 23")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 24")}</Text>
      <br />
      <ul>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 25")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 26")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 27")}
        </li>
      </ul>
      <Text variant="body2">{t("PrivacyPolicy 28")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 29")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 30")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 31")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 32")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 33")}</Text>
      <br />
      <ul>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 34")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 35")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 36")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 37")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 38")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 39")}
        </li>
      </ul>
      <Text variant="h3">{t("PrivacyPolicy 40")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 41")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 42")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 43")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 44")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 45")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 46")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 47")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 48")}</Text>
      <br />
      <Text variant="h3">{t("PrivacyPolicy 49")}</Text>
      <br />
      <Text variant="body2">{t("PrivacyPolicy 50")}</Text>
      <br />
      <ul>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 51")}
        </li>
        <li style={{ color: theme.customPalette.grey.default }}>
          {t("PrivacyPolicy 52")}
        </li>
      </ul>
      <Text variant="h3">{t("PrivacyPolicy 53")}</Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 62")}</span>
        {t("PrivacyPolicy 54")}
      </Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 63")}</span>
        {t("PrivacyPolicy 55")}
      </Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 64")}</span>
        {t("PrivacyPolicy 56")}
      </Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 65")}</span>
        {t("PrivacyPolicy 57")}
      </Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 66")}</span>
        {t("PrivacyPolicy 58")}
      </Text>
      <br />
      <Text variant="body2">
        <span>{t("PrivacyPolicy 67")}</span>
        {t("PrivacyPolicy 59")}
      </Text>
      <br />
    </Stack>
  );
};

export default PrivacyPolicies;
