import { Box, InputAdornment, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import StyledTextField from "../../_Atoms/StyledTextField";
import Text from "../../_Atoms/Text";
import { IHostnameTextField } from "./types";

const HostnameTextField: React.FC<IHostnameTextField> = ({
  handleChangeHostname,
  hostname,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeHostname(
      `https://${e.target.value?.replaceAll("https://", "")}`
    );
  };

  return (
    <StyledTextField
      sx={{
        width: "100%",
        "& .MuiInputBase-root": {
          paddingLeft: 0,
        },
      }}
      label={t("domain")}
      name="Domain"
      type="text"
      value={hostname.replace("https://", "")}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box
              sx={{
                background: theme.customPalette.others.background,
                padding: "16px 8px 16px 16px",
              }}
            >
              <Text>https://</Text>
            </Box>
          </InputAdornment>
        ),
      }}
      inputProps={{ maxLength: 50 }}
    />
  );
};

export default HostnameTextField;
