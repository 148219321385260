export function insertUrlParam(key: string, value: string) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
}

export const getColorVariant = (
  text: string,
  value: string,
  disabled = false
) => {
  if (value === text) return "primary";
  return "secondary";
};
