import dayjs from "dayjs";
import { Box, Grid, Skeleton, Stack, useTheme } from "@mui/material";
import AbTestSelector from "../AbTestSelector";
import { DateRangePicker, DeviceSelector } from "@/includes";
import { IHeader } from "./types";

const Header: React.FC<IHeader> = ({
  boxRef,
  abTest,
  setAbTest,
  dateRange,
  device,
  setDevice,
}) => {
  const theme = useTheme();
  const availableDevices = abTest?.device?.split(",") ?? [];

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        id="container"
        sx={{
          backdropFilter: "blur(10px)",
          zIndex: 999,
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} sm={12} xs={12}>
            <AbTestSelector
              abTest={abTest}
              handleChange={setAbTest}
              loading={false}
            />
          </Grid>
          <Grid item xl={4} lg={5} sm={12} xs={12}>
            <DateRangePicker
              disabled
              loading={false}
              dateRange={dateRange ?? []}
              maxDate={
                abTest?.stoppedAt
                  ? dayjs(abTest?.stoppedAt)
                  : dayjs().subtract(2, "days")
              }
              minDate={abTest?.startedAt}
            />
          </Grid>
          <Grid item xl={4} lg={3} sm={12} xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {false ? (
                <Skeleton
                  sx={{ bgcolor: theme.customPalette.others.layer_2 }}
                  variant="rounded"
                  height={50}
                  width="100%"
                  animation="wave"
                />
              ) : (
                <DeviceSelector
                  device={device}
                  handleChange={setDevice}
                  availableDevices={availableDevices}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Header;
