export const getColors =
  (btnVariant: string | undefined, disabled: boolean) => (theme: any) => {
    if (disabled)
      return {
        border: `1px solid ${theme.customPalette.others.background}`,
        background: theme.customPalette.others.layer_1,
        color: theme.customPalette.others.background,
        "& path": {
          fill: `${theme.customPalette.others.background} !important`,
        },
      };

    let backgroundColor = "transparent";
    let color = theme.customPalette.grey.default;
    let hoverColor = theme.customPalette.grey.default;
    let hoverBgColor = "transparent";
    let borderColor = "transparent";
    let hoverBorderColor = "transparent";

    switch (btnVariant) {
      case "outline":
        hoverBgColor = theme.customPalette.bluePurple.dark;
        borderColor = theme.customPalette.bluePurple.dark;
        hoverBorderColor = theme.customPalette.bluePurple.dark;
        color = theme.customPalette.bluePurple.dark;
        hoverColor = theme.customPalette.bluePurple.lighter;
        break;
      case "primary":
        backgroundColor = theme.customPalette.bluePurple.dark;
        hoverBgColor = theme.customPalette.bluePurple.darker;
        hoverColor = theme.customPalette.bluePurple.lighter;
        color = theme.customPalette.bluePurple.lighter;
        break;
      case "error":
        backgroundColor = theme.customPalette.error.main;
        hoverBgColor = theme.customPalette.error.complementary;
        hoverColor = theme.customPalette.error.main;
        color = theme.customPalette.error.complementary;
        break;

      case "error_outline":
        hoverBgColor = theme.customPalette.error.complementary;
        hoverColor = theme.customPalette.error.main;
        color = theme.customPalette.error.main;
        borderColor = color;
        hoverBorderColor = color;
        break;

      case "warning":
        backgroundColor = theme.customPalette.warning.main;
        hoverBgColor = theme.customPalette.warning.complementary;
        hoverColor = theme.customPalette.warning.main;
        color = theme.customPalette.warning.complementary;
        break;
      case "success":
        backgroundColor = theme.customPalette.success.main;
        hoverBgColor = theme.customPalette.success.complementary;
        hoverColor = theme.customPalette.success.main;
        color = theme.customPalette.success.complementary;
        break;
      case "info":
        backgroundColor = theme.customPalette.info.main;
        hoverBgColor = theme.customPalette.info.complementary;
        hoverColor = theme.customPalette.info.main;
        color = theme.customPalette.info.complementary;
        break;

      default:
        backgroundColor = theme.customPalette.bluePurple.dark;
        hoverBgColor = theme.customPalette.bluePurple.darker;
        hoverColor = theme.customPalette.bluePurple.lighter;
        color = theme.customPalette.bluePurple.lighter;
        break;
    }

    return {
      border: `1px solid`,
      borderColor: btnVariant?.includes("outline")
        ? borderColor
        : backgroundColor,
      backgroundColor,
      color,
      "& path": {
        fill: `${color} !important`,
      },
      "&:hover": {
        color: hoverColor,
        background: hoverBgColor,
        "& path": {
          fill: `${hoverColor} !important`,
        },
        borderColor: btnVariant?.includes("outline")
          ? hoverBorderColor
          : backgroundColor,
      },
    };
  };

export const getStartIcon = (isHover: boolean, disabled: boolean) => {
  if (disabled) return "disabled";
  if (isHover) return "startHover";
  return "start";
};

export const getStyle = (variant: string | undefined) => {
  if (variant === "text") return { textTransform: "unset" };
  return {};
};
