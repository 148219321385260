import {
  Box,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import AlgorithmSelector from "../AlgorithmSelector";
import { CloseIcon, StyledTextField, Text } from "@/includes";
import { useThrottle } from "@/hooks";
import { ISubAlgorithm } from "./types";

const SubAlgorithm: React.FC<ISubAlgorithm> = ({
  algorithm,
  handleRemoveAlgorithm,
  handleChangeAlgorithms,
  name,
  position,
  handleChangeAlgorithmName,
  availableVariables = [],
  algorithmIdInRecoAndMerch,
  experienceType,
}) => {
  const theme = useTheme();
  const { id, reference } = algorithm;
  const { t }: i18translateType = useTranslation();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleChangeAlgorithm = (
    _: React.ChangeEvent<any> | null,
    newAlgorithm: Dic<any>
  ) => {
    handleChangeAlgorithms(
      [newAlgorithm.uuid ?? newAlgorithm.id],
      newAlgorithm
    );
  };

  const [currentAlgoName, setCurrentAlgoName] = useState(name);
  const handleChangeAlgoName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCurrentAlgoName(e.target.value);

  const throtteledValue = useThrottle(currentAlgoName);
  useEffect(() => {
    handleChangeAlgorithmName(throtteledValue);
  }, [throtteledValue]);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        border: "1px solid",
        borderColor: theme.customPalette.others.background,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledTextField
            placeholder={`${t("products_group")} ${position}...`}
            size="small"
            sx={{ width: "90%" }}
            multiline
            maxRows={2}
            minRows={2}
            value={currentAlgoName}
            variant="standard"
            onChange={handleChangeAlgoName}
          />
          <Fade in={isHovered}>
            <Tooltip title={t("remove_algorithm")}>
              <IconButton onClick={handleRemoveAlgorithm(reference)}>
                <CloseIcon color={theme.customPalette.grey.default} />
              </IconButton>
            </Tooltip>
          </Fade>
        </Stack>
        <AlgorithmSelector
          selectedAlgorithm={id}
          handleChangeAlgorithm={handleChangeAlgorithm}
          availableVariables={availableVariables}
          algorithmIdInRecoAndMerch={algorithmIdInRecoAndMerch}
          experienceType={experienceType}
        />
      </Stack>
    </Box>
  );
};

export default SubAlgorithm;
