import { isEmpty, isNil } from "lodash";

export const getRequest = (
  experienceUuid: ID,
  siteId: ID,
  paramsCanFill: Dic<any>,
  tokenApi: string,
  params: Dic<any>
) => {
  const paramsFilled = { ...paramsCanFill, ...params };
  return `curl 'https://client.experiences.get-potions.com/v1/${siteId}/experience/${experienceUuid}'\\
      ${
        !isEmpty(tokenApi) || !isNil(tokenApi)
          ? `-H 'authorization: ${tokenApi}'\\
      -H 'content-type: application/json'\\
      --request POST\\
      --data '${JSON.stringify(paramsFilled)}' `
          : `-H \'content-type: application/json'\\
            --request POST\\
            --data '${JSON.stringify(paramsFilled)}'`
      }`;
};

export const getJsRequest = (
  experienceUuid: ID,
  siteId: ID,
  paramsCanFill: Dic<any>,
  tokenApi: string,
  params: Dic<any>
) => {
  const paramsFilled = { ...paramsCanFill, ...params };
  return `
  const query = JSON.stringify(
      ${JSON.stringify(paramsFilled)}
  ) \ 

  const res = await fetch(
      "https://client.experiences.get-potions.com/v1/${siteId}/experience/${experienceUuid}",
      {
          method: "POST",
          headers: {
              ${
                !isEmpty(tokenApi) || !isNil(tokenApi)
                  ? `    Authorization: Bearer \"${tokenApi}\",
                  "Content-type": "application/json"`
                  : '"Content-type": "application/json"'
              } 
          },
          body: query
      }
  )
  const data = await res.json();`;
};

export const getPythonRequest = (
  experienceUuid: ID,
  siteId: ID,
  paramsCanFill: Dic<any>,
  tokenApi: string,
  params: Dic<any>
) => {
  const paramsFilled = { ...paramsCanFill, ...params };
  return `
  import requests
  import json
  
  query = ${JSON.stringify(paramsFilled)}

  res = requests.post(
      'https://client.experiences.get-potions.com/v1/${siteId}/experience/${experienceUuid}',
      ${
        !isEmpty(tokenApi) || !isNil(tokenApi)
          ? `headers={'Authorization': f'Bearer ${tokenApi}'},
      json=query`
          : "json=query"
      } 
  )
  res.raise_for_status()
  data = res.json()`;
};
