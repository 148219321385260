import { useHasProperty, useViewport } from "@/hooks";
import { Layer1Box, Slider, Stack, Text } from "@/includes";
import { navigatorUtils } from "@/utils";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import Icon from "./components/Icon";
import { allExceptionsChecked, getTips } from "./helpers";

const Tips: React.FC = () => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { hasCustomPlan, hasAnalytics, hasCatalog, hasMember } =
    useHasProperty();
  const { isMobile } = useViewport();
  const history = useHistory();
  const { accountId, siteId } = useParams<UrlParams>();

  const tips = getTips(t, theme).filter((tip: Dic<any>) =>
    allExceptionsChecked(tip.exceptions, {
      hasCustomPlan,
      hasAnalytics,
      hasCatalog,
      hasMember,
    })
  );

  // forced bellow
  const [maxProductPerRow, setMaxProductPerRow] = useState<number>(3);

  const totalItems = tips.length;

  return (
    <Stack spacing={2}>
      <Text variant="subtitle1">{t("tips_and_tricks")}</Text>
      <Slider
        totalItems={totalItems}
        maxProductPerRow={4}
        setMaxProductPerRow={setMaxProductPerRow}
      >
        {tips.map((tip: Dic<any>) => (
          <Layer1Box
            onClick={navigatorUtils.goTo(
              `/${accountId}/${siteId}/${tip.redirectTo}`,
              history
            )}
            key={tip.title}
            sx={{
              width: isMobile ? "200px" : `calc((100% - 140px)/4)`,
              minWidth: isMobile ? "200px" : `calc((100% - 140px)/4)`,
              transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
              cursor: "pointer",
              "&: hover": {
                background: theme.customPalette.others.background,
              },
            }}
          >
            <Stack spacing={1}>
              <Icon
                backgroundColor={tip.backgroundColor}
                borderColor={tip.borderColor}
              >
                {tip.icon}
              </Icon>
              <Text>{tip.title}</Text>
              <Text variant="body2" textType="secondary">
                {tip.description}
              </Text>
            </Stack>
          </Layer1Box>
        ))}
      </Slider>
    </Stack>
  );
};

export default Tips;
