import { client } from "@/index";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_CATALOG_COLUMN, GET_CATEGORIES } from "./requests";
import { CategoryType } from "./types";

/**
 * Custom React hook for fetching data from a catalog column.
 *
 * @param columnName - The name of the catalog column to fetch.
 * @returns An object containing information about the catalog column data.
 */
const useCatalogColumnData = (
  columnName: string,
  entryParamType?: string | null
) => {
  const [values, setValues] = useState<Array<any>>([]);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);

  /**
   * Helper function to fetch data from the catalog column.
   */
  const getCatalogColumn = () => {
    setStatus(StatusEnum.LOADING);
    if (
      columnName === "category_id" ||
      entryParamType === "category_id" ||
      columnName === "CATEGORY_ID" ||
      entryParamType === "CATEGORY_ID"
    ) {
      client
        .query({
          query: GET_CATEGORIES,
          variables: { catalogId },
        })
        .then((res) => {
          setValues(
            res.data.categories?.edges.map(
              (elem: CategoryType) => `${elem.node.title} - ${elem.node.id}`
            )
          );
          setStatus(StatusEnum.NORMAL);
        })
        .catch(() => setStatus(StatusEnum.ERROR));
    } else {
      client
        .query({
          query: GET_CATALOG_COLUMN,
          variables: { catalogId, columnName },
        })
        .then((res) => {
          setValues(res.data.catalogColumn.value);
          setStatus(StatusEnum.NORMAL);
        })
        .catch(() => setStatus(StatusEnum.ERROR));
    }
  };

  // Effect to fetch catalog column data when columnName or catalogId changes
  useEffect(() => {
    if (!!columnName && !!catalogId) getCatalogColumn();
  }, [columnName, catalogId]);

  return { status, values, loading: status === StatusEnum.LOADING };
};

export default useCatalogColumnData;
