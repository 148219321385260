import {
  useLazyQuery,
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/react-hooks";
import { useEffect } from "react";
import _ from "lodash";

// Initialize an AbortController to allow canceling of the query
const abortController = new AbortController();

/**
 * Custom React hook for making a lazy GraphQL query with polling support.
 *
 * @param {DocumentNode | TypedDocumentNode<any, OperationVariables>} Query - The GraphQL query document.
 * @param {Dic<any>} variables - The variables to be used in the query.
 * @param {number} pollInterval - The time interval (in milliseconds) between each poll.
 * @returns {{ getDatas: () => void, loading: boolean, data: any, error: import('@apollo/client').ApolloError | undefined, fetchMore: import('@apollo/client').FetchMoreFn<any, Record<string, any>, Record<string, any>>, stopPolling: () => void }} - An object containing functions and data related to the GraphQL query.
 */
const usePolledLazyQuery = (
  Query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  variables: Dic<any>,
  pollInterval: number
) => {
  const [getDatas, { loading, data, error, fetchMore, stopPolling, refetch }] =
    useLazyQuery(Query, {
      variables,
      pollInterval,
      fetchPolicy: "network-only",
      errorPolicy: "all",
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    });

  useEffect(() => {
    return () => stopPolling(); // Stop polling when the component unmounts
  }, []);

  return {
    getDatas,
    loading: loading && !data,
    data,
    error,
    fetchMore,
    stopPolling,
    refetch,
  };
};

export default usePolledLazyQuery;
