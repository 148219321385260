import { experiencesUtils } from "@utils";

export const canRedirect = (item: any, type: string) => {
  return (
    type === "rule" ||
    (type === "experience" &&
      ((experiencesUtils.hasReport(item.type, item.isMonitorable) &&
        item.deployedAt) ||
        experiencesUtils.hasConfigure(item.type)))
  );
};
