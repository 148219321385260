import { Box, Stack, useTheme } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useConfigureAlgorithm } from "@/hooks/useConfigureAlgorithm";
import { Text } from "@/includes";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Filters from "../Filters";
import useOldVariables from "@/hooks/useOldVariables/useOldVariables";

const settingsSelector = (state: any) => state.context.settings;
const variablesSelector = (state: any) => state.context.variables;

// Filters applied to promote and pin products
const PromoteAndPinFilters = () => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const configureAlgorithmServices = useConfigureAlgorithm();

  const { send } = configureAlgorithmServices.algorithmService;

  const settings = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    settingsSelector
  );

  const promoteAndPinFilters = settings?.promoted_and_pin_items_filters ?? [];

  const variables = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    variablesSelector
  );

  const { getAvailableVariablesDic, allSiteVariableDic } = useOldVariables();

  const requiredParameters = useMemo(
    () => getAvailableVariablesDic(variables, allSiteVariableDic),
    [JSON.stringify(variables), allSiteVariableDic]
  );

  const appliedFilters =
    promoteAndPinFilters?.[0]?.filter?.[0]?.[
      Object.keys(promoteAndPinFilters?.[0]?.filter?.[0])?.[0]
    ];

  const noFilters = !(appliedFilters?.length > 0);

  const updateFilters = (
    newFilters: Array<Dic<any>>,
    logicalOperator: string
  ) => {
    if (newFilters.length === 0)
      send({ type: "SET_PROMOTE_AND_PIN_FILTERS", data: { filters: [] } });
    else
      send({
        type: "SET_PROMOTE_AND_PIN_FILTERS",
        data: {
          filters: [
            { filter: [{ [logicalOperator ?? "and_all"]: [...newFilters] }] },
          ],
        },
      });
  };

  return (
    <Stack alignItems="start" sx={{ width: "100%" }}>
      <Stack
        spacing={1}
        sx={{
          p: 1,
          maxWidth: noFilters ? 600 : "100%",
          borderRadius: 2,
        }}
      >
        {noFilters && (
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <InfoOutlined sx={{ fill: theme.customPalette.grey.light }} />
            <Text textType="secondary">{t("no_promote_and_pin_filter")}</Text>
          </Stack>
        )}

        <Filters
          filters={promoteAndPinFilters}
          variables={variables}
          filterType="filter"
          updateFilters={updateFilters}
          requiredParameters={requiredParameters}
        />
      </Stack>
    </Stack>
  );
};

export default PromoteAndPinFilters;
