import { gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useSelector as useXstateSelector } from "@xstate/react";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import { stringUtils, graphqlUtils, rulesUtils } from "@utils";
import {
  SelectPaper,
  StyledTextField,
  Stack,
  GraphQLSelector,
  HideShowBlock,
} from "@includes";
import { useDebounce, useGraphQlLazyQuery } from "@hooks";
import { useSharedErrors } from "../../hooks/useError";
import { ICollectionSelector } from "./types";
import { GET_COLLECTION, SEARCH_COLLECTION } from "./requests";
import { getTraduction } from "@/utils/helpers/traductions";
import { useConfigureMerchandising } from "../../hooks/useConfigureMerchandising";

const collectionSelector = (state: any) => state.context.collection;

const CollectionSelector: React.FC<ICollectionSelector> = ({ applyFilter }) => {
  const { t }: i18translateType = useTranslation();
  const { siteId } = useParams<UrlParams>();

  const experienceServices = useConfigureMerchandising();

  const selectedCollection = useXstateSelector(
    experienceServices.experienceService,
    collectionSelector
  );

  const { send } = experienceServices.experienceService;

  const selectedCollectionId = selectedCollection?.id;

  const requiredParameter = selectedCollection?.settings?.required_parameter;

  const setSelectedCollection = (collection: Dic<any>) => {
    send({ type: "SET_COLLECTION", data: { collection } });
  };

  const {
    data,
    loading: loadingCollection,
    getDatas,
  } = useGraphQlLazyQuery(
    GET_COLLECTION,
    { id: selectedCollectionId },
    "cache-first"
  );

  useEffect(() => {
    if (selectedCollectionId) getDatas();
  }, [selectedCollectionId]);

  const { errorCollections, removeCollectionsError } = useSharedErrors();

  const [collections, setCollections] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = async (hint: string) => {
    setLoading(true);
    const res = await graphqlUtils.fetchWithoutCache(SEARCH_COLLECTION, {
      search: hint,
      siteId,
    });

    setLoading(false);
    if (res?.data)
      setCollections([
        ...res?.data?.collections?.edges?.map((item: any) => ({
          ...item.node,
          type: rulesUtils.getCollectionType(item),
        })),
      ]);
  };

  useEffect(() => {
    if (siteId && isOpen) {
      handleSearch("");
    }
  }, [siteId, isOpen]);

  useEffect(() => {
    if (data) {
      if (
        !collections.find(
          (collection: Dic<any>) => collection.id === data.collection.id
        )
      ) {
        setCollections([...collections, data.collection]);
      }
      setSearch(t(data.collection.name));
      setSelectedCollection(data.collection);
    }
  }, [data]);

  useDebounce(
    () => {
      if (isOpen) {
        handleSearch(search);
      }
    },
    [search],
    600
  );

  const collectionsToShow = useMemo(() => {
    if (applyFilter)
      return collections?.filter(
        (c: Dic<any>) =>
          c?.settings?.required_parameter === requiredParameter ||
          c?.settings?.product_ids
      );
    return collections;
  }, [collections]);

  return (
    <HideShowBlock
      title={t("rule_to_apply")}
      initialState
      subtitle={t("rules_description")}
    >
      <Stack
        sx={{
          "& .MuiAutocomplete-endAdornment": {
            top: "unset !important",
          },
          width: "min(100%, 450px)",
        }}
      >
        <Stack>
          <GraphQLSelector
            id="collection-selector"
            setIsOpen={setIsOpen}
            filterOptions={(x: any) => x}
            loading={loading || loadingCollection}
            options={collectionsToShow}
            renderInput={(params: any) => (
              <StyledTextField
                {...params}
                label={stringUtils.capitalizeFirstLetter(t("rule"))}
                name={stringUtils.capitalizeFirstLetter(t("rule"))}
                onChange={(e: any) => setSearch(e?.target?.value)}
                value={search}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            onClose={() => {
              setSearch(getTraduction(t, selectedCollection?.name));
              setIsOpen(false);
            }}
            disableClearable
            getOptionLabel={(option: any) =>
              getTraduction(t, option.name ?? "")
            }
            value={selectedCollection}
            inputValue={search}
            onChange={(e: React.SyntheticEvent, value: any) => {
              if (value) removeCollectionsError();
              setSelectedCollection(value);
              setSearch(getTraduction(t, value.name));
            }}
            PaperComponent={SelectPaper}
          />
          {errorCollections && (
            <FormHelperText error>{t("select_rule")}</FormHelperText>
          )}
        </Stack>
      </Stack>
    </HideShowBlock>
  );
};

CollectionSelector.defaultProps = {
  applyFilter: true,
};

export default CollectionSelector;
