import { gql } from "@apollo/client";

export const GET_ALGORITHMS = gql`
  query collections($siteId: SiteID, $filters: String) {
    collections(
      siteId: $siteId
      first: 1000
      searchType: "algorithm"
      filters: $filters
    ) {
      edges {
        node {
          id
          uuid
          name
          settings
          description
          variables {
            variableId
            algorithmId
            inputParameters
          }
        }
      }
    }
  }
`;
