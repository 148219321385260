import { Stack } from "@mui/material";
import LoadingOverlay from "../LoadingOverlay";
import NoRowsOverlay from "../NoRowsOverlay";
import ProductCard from "../ProductCard";
import GridView from "../GridView";
import { PreviewViewSettings } from "@includes";
import { IList } from "./types";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";

const getProduct = (productPerRow: number, currency: string = "€") => {
  return (product: Dic<any>) => {
    return (
      <ProductCard
        product={product}
        productPerRow={productPerRow}
        currency={currency}
      />
    );
  };
};

const grid = (
  products: Array<Dic<any>>,
  nbProductToShow: number,
  currency: string = "€"
) => {
  return (
    <GridView
      itemsPerRow={4}
      items={products}
      nbItemToShow={nbProductToShow}
      child={getProduct(4, currency)}
    />
  );
};

const List: React.FC<IList> = ({
  isLoading,
  products,
  nbProductToShow,
  productsPerRow,
  setNbProductToShow,
  setProductsPerRow,
  list,
}) => {
  const currency = useSelector((state: RootState) => state.catalog.currency);
  if (isLoading) {
    return <LoadingOverlay list={list} />;
  }

  if (products?.length === 0) return <NoRowsOverlay list={list} />;

  return (
    <>
      <Stack direction="row" justifyContent="end" alignItems="center" mt={2}>
        <PreviewViewSettings />
      </Stack>
      {grid(products, nbProductToShow, currency)}
    </>
  );
};

export default List;
