import { Backdrop } from "@mui/material";

const Overlay = (props: React.PropsWithChildren<any>) => {
  return (
    <Backdrop
      open
      sx={{
        transition: "none !important",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "grid",
        overflow: "auto",
        position: "fixed",
      }}
    >
      {props.children && <>{...props.children}</>}
    </Backdrop>
  );
};

export default Overlay;
