/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from "@apollo/client/core";
import dayjs from "dayjs";
import _ from "lodash";
import { createContext, useContext } from "react";
import { assign, DoneInvokeEvent, ActorRefFrom, createMachine } from "xstate";
import { useInterpret } from "@xstate/react";
import { client } from "@/index";

type Event = DoneInvokeEvent<any>;

const GET_REPORT = gql`
  query monitor(
    $experienceId: ExperienceID!
    $startDate: String!
    $endDate: String!
    $device: String!
  ) {
    monitor(
      experienceId: $experienceId
      startDate: $startDate
      endDate: $endDate
      device: $device
    ) {
      allUsersIndicators {
        averageBasket
        conversionRate
        revenuePerUser
        transactionPerUser
        transactionRevenue
        transactions
        users
      }
      eventCounts {
        seen
        used
        used0
        used1
      }
      eventEvolutions {
        seen
        used
        used0Percent
        used1Percent
        usedPercent
      }
      usersIndicators {
        seen {
          averageBasket
          conversionRate
          revenuePerUser
          transactionPerUser
          transactionRevenue
          transactions
          users
        }
        seenBuyers {
          averageBasket
          conversionRate
          revenuePerUser
          transactionPerUser
          transactionRevenue
          transactions
          users
        }
        used {
          averageBasket
          conversionRate
          revenuePerUser
          transactionPerUser
          transactionRevenue
          transactions
          users
        }
        usedBuyers {
          averageBasket
          conversionRate
          revenuePerUser
          transactionPerUser
          transactionRevenue
          transactions
          users
        }
      }
    }
  }
`;

const fetchApi: any = (
  experienceId: string,
  startDate: string,
  endDate: string,
  device: string
) =>
  client
    .query({
      query: GET_REPORT,
      variables: {
        experienceId,
        startDate,
        endDate,
        device,
      },
      fetchPolicy: "cache-first",
    })
    .then((res: any) => {
      if (res?.data) {
        const {
          allUsersIndicators,
          eventCounts,
          eventEvolutions,
          usersIndicators,
        } = res?.data.monitor;

        return {
          type: "update",
          data: {
            allUsersIndicators,
            eventCounts,
            eventEvolutions,
            usersIndicators,
          },
        };
      }
      return {
        type: "ERROR",
        data: {},
      };
    })
    .catch((error: any) => {
      return {
        type: "ERROR",
        data: {},
      };
    });

const getReport =
  (experienceId: string, startDate: string, endDate: string, device: string) =>
  (callback: any, onReceive: any) =>
    fetchApi(experienceId, startDate, endDate, device).then((res: any) => {
      callback(res);
    });

const reportMachine = createMachine<any, Event>({
  id: "reportMachine",
  initial: "idle",
  context: {
    allUsersIndicators: null,
    eventCounts: null,
    eventEvolutions: null,
    usersIndicators: null,
    error: null,
    loading: false,
    startDate: null,
    endDate: null,
    device: "desktop",
    experienceId: null,
    isReady: false,
    onDesktop: false,
    onMobile: false,
    name: "UNTITLED",
    type: "",
  },
  on: {
    initialize: {
      actions: assign((context, event) => ({
        error: null,
        allUsersIndicators: null,
        eventCounts: null,
        eventEvolutions: null,
        usersIndicators: null,
        startDate: event.data.startDate ?? null,
        endDate: event.data.endDate ?? dayjs().subtract(2, "days"),
        experienceId: event.data.experienceId,
        isReady: true,
        device: event.data.device,
        onDesktop: event.data.onDesktop,
        onMobile: event.data.onMobile,
        type: event?.data?.type ?? "",
        // loading: true,
      })),
    },
    update: {
      actions: assign((context, event) => ({
        allUsersIndicators: event.data.allUsersIndicators,
        eventCounts: event.data.eventCounts,
        eventEvolutions: event.data.eventEvolutions,
        usersIndicators: event.data.usersIndicators,
        loading: false,
        error: false,
      })),
    },
    CHANGE_DATE_RANGE: {
      actions: assign((context, event) => ({
        error: null,
        allUsersIndicators: null,
        eventCounts: null,
        eventEvolutions: null,
        usersIndicators: null,
        startDate: event.data.startDate ?? context.startDate,
        endDate: event.data.endDate ?? context.endDate,
        loading: true,
      })),
      target: ["loading"],
    },
    CHANGE_DEVICE: {
      actions: assign((context, event) => ({
        device: event.data.device,
        error: false,
        loading: true,
      })),
      target: ["loading"],
    },
    FETCH: {
      actions: assign((context, event) => ({
        loading: true,
        error: false,
      })),
      target: ["loading"],
    },
    ERROR: {
      actions: assign((context, event) => ({
        error: true,
        allUsersIndicators: null,
        eventCounts: null,
        eventEvolutions: null,
        usersIndicators: null,
        loading: false,
      })),
    },
  },
  states: {
    idle: {
      on: {
        FETCH: "loading",
      },
    },
    loading: {
      invoke: {
        id: "getReport",
        src: (context, event) =>
          getReport(
            context.experienceId,
            context.startDate,
            context.endDate,
            context.device
          ),
      },
    },
    success: {},
    failure: {
      on: {
        RETRY: "loading",
      },
    },
  },
});

interface ReportContextType {
  reportService: ActorRefFrom<typeof reportMachine>;
}

export const ReportContext = createContext({} as ReportContextType);

export default function ReportProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const reportService = useInterpret(reportMachine);

  return (
    <ReportContext.Provider value={{ reportService }}>
      {children}
    </ReportContext.Provider>
  );
}

export function useReport() {
  return useContext(ReportContext);
}
