export enum HOME_OVERVIEW_QUERIES_ENUMS {
  VISITORS = "VISITORS_BY_DAY",
  TRANSACTIONS = "TRANSACTIONS_BY_DAY",
  REVENUES = "REVENUES_BY_DAY",
}

export enum HOME_OVERVIEW_LABEL_ENUMS {
  VISITORS_BY_DAY = "all_experience_users",
  TRANSACTIONS_BY_DAY = "transactions_generated_by_all_experiences_users",
  REVENUES_BY_DAY = "revenue_generated_by_all_experiences_users",
}

export enum HOME_OVERVIEW_LEGEND_ENUMS {
  VISITORS_BY_DAY = "users",
  TRANSACTIONS_BY_DAY = "transactions",
  REVENUES_BY_DAY = "revenue",
}
