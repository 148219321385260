import { formatUtils } from "@/utils";
import { FieldType } from "@/utils/helpers/entityFields/types";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import HostnameTextField from "../HostnameTextField";
import { StyledTextField } from "@includes";
import { AutomaticFormProps } from "./types";
import {
  CommonFieldTypes,
  EndIDFieldType,
  EndIdFieldName,
} from "@/utils/helpers/entityFields/enums";
import AutomaticAutocomplete from "./components/AutomaticAutocomplete";
import { getOptionsByField, getPlurialEntityType } from "./helpers";
import AutomaticSelect from "./components/AutomaticSelect";
import { getTraduction } from "@/utils/helpers/traductions";

const AutomaticForm = ({
  fields,
  entity,
  setEntity,
  entityId,
}: AutomaticFormProps) => {
  const { t } = useTranslation();
  const handleChange =
    (name: string, forceType?: null | CommonFieldTypes) => (x: any) => {
      switch (typeof x) {
        case "string":
          setEntity({ ...entity, [name]: x });
          break;
        case "boolean":
          setEntity({ ...entity, [name]: x });
          break;
        default:
          switch (forceType) {
            case CommonFieldTypes.BOOLEAN:
              setEntity({ ...entity, [name]: x.target.checked });
              break;
            case CommonFieldTypes.INT:
              setEntity({ ...entity, [name]: parseInt(x.target.value) });
              break;
            default:
              setEntity({ ...entity, [name]: x.target.value });
              break;
          }
          break;
      }
    };

  const getInputByType = (field: FieldType) => {
    if (field.type.endsWith(EndIDFieldType)) {
      const singularType = field.type.split(EndIDFieldType)[0].toLowerCase();
      return (
        <AutomaticAutocomplete
          key={field.name}
          entity={entity}
          setEntity={setEntity}
          value={field.name}
          label={getTraduction(
            t,
            formatUtils.formatStringInKebabCase(
              field.name.split(EndIdFieldName)[0]
            )
          )}
          type={getPlurialEntityType(singularType)}
          entityId={entityId}
        />
      );
    }
    switch (field.type) {
      case CommonFieldTypes.STRING:
        return (
          <StyledTextField
            key={field.name}
            value={entity[field.name]}
            label={getTraduction(
              t,
              formatUtils.formatStringInKebabCase(field.name)
            )}
            onChange={handleChange(field.name)}
          />
        );
      case CommonFieldTypes.HOSTNAME:
        return (
          <HostnameTextField
            key={field.name}
            hostname={entity[field.name] ?? ""}
            handleChangeHostname={handleChange(field.name)}
          />
        );
      case CommonFieldTypes.BOOLEAN:
        return (
          <FormControlLabel
            key={field.name}
            sx={{
              color: "grey.default",
              width: "fit-content",
              flex: 0.5,
            }}
            control={
              <Checkbox
                checked={entity[field.name]}
                onChange={handleChange(field.name, CommonFieldTypes.BOOLEAN)}
              />
            }
            label={getTraduction(
              t,
              formatUtils.formatStringInKebabCase(field.name)
            )}
          />
        );
      case CommonFieldTypes.INT:
        return (
          <StyledTextField
            key={field.name}
            type="number"
            value={entity[field.name]}
            label={getTraduction(
              t,
              formatUtils.formatStringInKebabCase(field.name)
            )}
            onChange={handleChange(field.name, CommonFieldTypes.INT)}
          />
        );
      default:
        return (
          <AutomaticSelect
            key={field.name}
            value={entity[field.name]}
            label={getTraduction(t, field.name)}
            handleChange={handleChange(field.name)}
            options={getOptionsByField(field.type)}
          />
        );
    }
  };
  return (
    <Stack spacing={2}>
      {fields.map((field: FieldType) => getInputByType(field))}
    </Stack>
  );
};

export default AutomaticForm;
