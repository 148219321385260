import React from "react";
import { useTheme } from "@mui/material/styles";
import { Link as MuiLink, SxProps } from "@mui/material";
import { useHistory } from "react-router";
import { navigatorUtils } from "@/utils";
import { ILink } from "./types";

const Link: React.FC<ILink> = ({ variant, href, children, customStyle }) => {
  const theme = useTheme();

  const history = useHistory();

  return (
    <MuiLink
      data-testid="linkTestId"
      href={href}
      variant={variant}
      underline="hover"
      sx={{
        color: theme.customPalette.bluePurple.dark,
        fontFamily: "poppins",
        ...customStyle,
      }}
      onClick={(e) => {
        e.preventDefault();
        navigatorUtils.goTo(href, history)(e);
      }}
    >
      {children}
    </MuiLink>
  );
};

Link.defaultProps = {
  customStyle: {},
  variant: "body2",
};

export default Link;
