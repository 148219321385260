import { EditIcon } from "@/assets/icons";
import { StyledTextField, Text, Tooltip, CategorySelector } from "@includes";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import { rulesUtils } from "@utils";
import { ParamSelectorType } from "./types";

const ParamSelector = ({
  requiredParameter,
  param,
  setIsOpen,
  setCategoryId,
  setAdditionnalParam,
  additionnalParam,
}: ParamSelectorType) => {
  const theme = useTheme();
  if (
    ["category", "product_ids", "product_id", "viewed_product_ids"].includes(
      rulesUtils.getExecutionParameter(requiredParameter) ?? ""
    )
  ) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "relative",
          background: theme.customPalette.others.layer_2,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          padding: "5px 16px",
          borderRadius: 2,
          width: "fit-content",
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box
            component="img"
            src={param.imgLink}
            sx={{ height: 33, borderRadius: 2 }}
          />
          <Tooltip title={param.title}>
            <div>
              <Text noWrap customStyle={{ maxWidth: 120 }}>
                {param.title}
              </Text>
            </div>
          </Tooltip>
        </Stack>
        <IconButton onClick={() => setIsOpen(true)}>
          <EditIcon color={theme.customPalette.grey.default} />
        </IconButton>
        {param?.absoluteLink && (
          <IconButton onClick={() => window.open(param.absoluteLink)}>
            <OpenInNewIcon />
          </IconButton>
        )}
      </Stack>
    );
  } else if (
    rulesUtils.getExecutionParameter(requiredParameter) === "category_id"
  ) {
    return (
      <Box
        sx={{
          position: "relative",
        }}
      >
        <CategorySelector setCategoryId={setCategoryId} />
      </Box>
    );
  } else if (!!requiredParameter) {
    return (
      <StyledTextField
        label={requiredParameter}
        value={additionnalParam[requiredParameter]}
        onChange={(e: any) =>
          setAdditionnalParam({
            ...additionnalParam,
            [requiredParameter]: e.target.value,
          })
        }
      />
    );
  }
  return null;
};

export default ParamSelector;
