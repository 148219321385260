interface IJoinIcon {
  color?: string;
}

const JoinIcon: React.FC<IJoinIcon> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1296_8062"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#FAF9FA" />
      </mask>
      <g mask="url(#mask0_1296_8062)">
        <path
          d="M12 21V19H19V5H12V3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H12ZM10 17L8.625 15.55L11.175 13H3V11H11.175L8.625 8.45L10 7L15 12L10 17Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

JoinIcon.defaultProps = {
  color: "transparent",
};

export default JoinIcon;
