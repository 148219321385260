import { useSetState } from "@/hooks";
import { Box, Stack, Text } from "@includes";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import Evolution from "./components/Evolution";
import Header from "./components/Header";
import KeyIndicators from "./components/KeyIndicators";
import Overview from "./components/Overview";
import { getEndDate, hasData } from "./helpers";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";

const ReportABTest = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [abTest, setAbTest] = useSetState(null);
  const [device, setDevice] = useState<Device>(
    abTest?.device?.split(",")?.[0] ?? "desktop"
  );
  const startDate: string =
    abTest?.startedAt && dayjs(abTest.startedAt)?.format("YYYY-MM-DD");
  const endDate: string = getEndDate(abTest);

  const hasDataForABTest = hasData(abTest);
  const ref = useRef(null);

  const audience = abTest?.audience ?? { A: "Control", B: "Test" };

  const filters = {
    devices: [device],
    startDate,
    endDate,
    abExperimentId: abTest?.id,
  };

  return (
    <Box>
      <Stack spacing={3} mt={1} ref={ref}>
        <Header
          setAbTest={setAbTest}
          abTest={abTest}
          boxRef={ref}
          device={device}
          setDevice={setDevice}
          dateRange={[startDate, endDate]}
        />
        {hasDataForABTest ? (
          <Box sx={{ gap: 2 }}>
            <Overview filters={filters} audience={audience} abTest={abTest} />
            <Evolution filters={filters} />
            <KeyIndicators filters={{ ...filters }} audience={audience} />
          </Box>
        ) : (
          <Box sx={{ gap: 2, display: "flex", justifyContent: "center" }}>
            <Text customStyle={{ color: theme.customPalette.grey.default }}>
              {t("data_after_48_hours")}
            </Text>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default ReportABTest;
