import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { client } from "@/index";
import ResultExample from "../ResultExample";
import SavePage from "../SavePage";
import { GET_CATEGORIES } from "./requests";

const RunAndSave = () => {
  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);

  const [allCategories, setAllCategories] = useState<Array<any>>([]);

  const [gettingCategories, setGettingCategories] = useState(false);

  const getCategories = (): Promise<string> => {
    setGettingCategories(true);
    return new Promise((resolve) => {
      client
        .query({
          query: GET_CATEGORIES,
          variables: { catalogId, first: 10000 },
          fetchPolicy: "network-only",
        })
        .then((res) => {
          setAllCategories([
            ...(res.data?.categories?.edges?.map((item: any) => ({
              ...item.node,
              page:
                item.node.pages.edges?.[item.node.pages.edges.length - 1]
                  ?.node ?? {},
            })) ?? []),
          ]);
          resolve("success");
        })
        .catch(() => resolve("error"));
    });
  };

  useEffect(() => {
    if (catalogId) getCategories().then(() => setGettingCategories(false));
  }, [catalogId]);

  return (
    <>
      <ResultExample
        allCategories={allCategories}
        gettingCategories={gettingCategories}
      />
      <SavePage refreshCategories={getCategories} />
    </>
  );
};

export default RunAndSave;
