import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Tooltip, useTheme } from "@mui/material";
import { Box, Stack, Tag, Text } from "@/includes";
import { RootState } from "@/services/redux/store";
import { formatUtils } from "@/utils";
import { getTraduction } from "@/utils/helpers/traductions";

/**
 * Custom React hook for handling catalog columns and additional columns.
 *
 * @param columns - Array of initial grid columns.
 * @returns An object containing the filtered and additional columns.
 */
const useCatalogColumns = (
  columns: Array<GridColDef>,
  currency: string = "€"
) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );

  // Add additional columns based on client's catalog
  const additionalColumns = useMemo(
    () =>
      catalogColumns
        .filter(
          (cCol: Dic<any>) =>
            !columns.find((c: Dic<any>) => c.headerName === t(cCol.name)) &&
            cCol.type !== "array" &&
            ![
              "available",
              "Stock per size",
              "img_link",
              "link",
              "absolute_link",
              "categories",
              "id",
              "stroke_price",
              "price",
            ].includes(cCol.name)
        )
        .map((cCols: Dic<any>) => ({
          disableColumnMenu: true,
          field: cCols.name,
          headerName: getTraduction(t, cCols.name),
          width: 170,
          align: "right",
          sortable: false,
          renderHeader: () => (
            <Text variant="body2" textType="secondary" isTableHeader>
              {getTraduction(t, cCols.name)}
            </Text>
          ),
          renderCell: (params: any) => {
            const value = params.row[cCols.name];
            if (typeof value === "boolean")
              return (
                <Text
                  variant="body2"
                  noWrap
                  customStyle={{ textTransform: "capitalize" }}
                >
                  {value ? t("yes") : t("no")}
                </Text>
              );
            if (typeof value === "object")
              return (
                <Text variant="body2">{JSON.stringify(value, null, 2)}</Text>
              );
            if (JSON.stringify(value)?.toLowerCase()?.includes("jpg"))
              return <Box sx={{ height: "90%" }} component="img" src={value} />;
            if (cCols.name === "potential_turnover")
              return (
                <Text variant="body2" noWrap>
                  {formatUtils.formatCurrency(value)}
                </Text>
              );
            return (
              <Tooltip title={value?.toString()}>
                <Box>
                  <Text
                    variant="body2"
                    noWrap
                    customStyle={{ textTransform: "capitalize" }}
                  >
                    {value?.toString()}
                  </Text>
                </Box>
              </Tooltip>
            );
          },
        })),
    [catalogColumns]
  );
  // Filter columns based on criteria
  const cols = [
    ...columns.filter((c: GridColDef) => c.field !== "actions"),
    {
      disableColumnMenu: true,
      field: "isDeleted",
      headerName: t("is_deleted"),
      width: 120,
      align: "right",
      sortable: false,
      renderHeader: () => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("is_deleted")}
          </Text>
          <Tooltip title={t("is_deleted_tooltip")}>
            <HelpOutline color={theme.customPalette.grey.light} />
          </Tooltip>
        </Stack>
      ),
      renderCell: (params: any) => {
        return (
          <>
            {params.row.isDeleted ? (
              <Tag
                customStyle={{ textTransform: "capitalize" }}
                tagVariant="error"
              >
                {t("yes")}
              </Tag>
            ) : (
              <Tag
                customStyle={{ textTransform: "capitalize" }}
                tagVariant="success"
              >
                {t("no")}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      field: "priceBeforeDiscount",
      headerName: t("priceBeforeDiscount"),
      width: 160,
      sortable: false,
      align: "left",
      renderCell: (params: GridRowParams) => (
        <Text variant="body2">
          {formatUtils.formatPrice(params.row.priceBeforeDiscount, currency)}
        </Text>
      ),
      headerAlign: "left",
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/products/price.svg`}
          />
          <Text variant="body2" textType="secondary" isTableHeader>
            {t("priceBeforeDiscount")}
          </Text>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) => params.row.priceBeforeDiscount,
    },
    ...additionalColumns,
    columns.find((c: Dic<any>) => c.field === "actions"),
  ];

  return { cols };
};

export default useCatalogColumns;
