import { Error, Layer1Box } from "@/includes";
import { Stack } from "@mui/system";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { isEmpty, isNil } from "lodash";
import { useTranslation } from "react-i18next";
import Header from "../KeyIndicatorsHeader";
import Loading from "../KeyIndicatorsLoading";
import Table from "../KeyIndicatorsTable";
import { ExperienceKeyIndicatorsProps } from "./types";
import { StatusEnum } from "@/utils/helpers/status/enums";

const ExperienceKeyIndicators = ({
  rows,
  experienceName,
  startDate,
  endDate,
  loading,
  experienceId,
}: ExperienceKeyIndicatorsProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  return (
    <Layer1Box
      sx={{
        opacity: 1,
        transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
        position: "relative",
        "& .MuiTablePagination-displayedRows": { display: "none" },
      }}
    >
      <Stack spacing={1}>
        <Header
          experienceName={experienceName}
          startDate={startDate}
          endDate={endDate}
          apiRef={apiRef}
        />
        {isEmpty(rows) ||
        isNil(experienceId) ||
        loading !== StatusEnum.NORMAL ? (
          loading === StatusEnum.ERROR ? (
            <Error />
          ) : (
            <Loading />
          )
        ) : (
          <Table experienceId={experienceId} rows={rows} apiRef={apiRef} />
        )}
      </Stack>
    </Layer1Box>
  );
};

export default ExperienceKeyIndicators;
