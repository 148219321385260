import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";

export const DEFAULT_PARAM_VALUE: Dic<any> = {
  NUMBER: 10,
  STRING: "",
  BOOLEAN: true,
};

export const SIDE_MODAL_NAME: Dic<"search_product" | "search_rule"> = {
  ARRAY_ITEM_ID: "search_product",
  ITEM_ID: "search_product",
};
