import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";

export const getValue = (allParams: any, parameterType: VARIABLE_TYPE_ENUM) => {
  if (
    parameterType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
    parameterType === VARIABLE_TYPE_ENUM.ITEM_ID
  ) {
    if (allParams[parameterType]?.length > 1) {
      return `${
        allParams[parameterType][0]?.title ?? allParams[parameterType][0]?.name
      } ...`;
    }
    if (allParams[parameterType]?.length === 1) {
      return (
        allParams[parameterType][0]?.title ?? allParams[parameterType][0]?.name
      );
    }
  }
  return (
    allParams[parameterType]?.title ??
    allParams[parameterType]?.name ??
    allParams[parameterType]
  );
};
