import { useCallback } from "react";
import useDidMountEffect from "./useDidMountEffect";

/**
 * Custom React hook for debouncing effects.
 *
 * @param effect - The function to be debounced.
 * @param dependencies - The dependencies for the debounced effect.
 * @param delay - The delay in milliseconds for the debounce.
 */
export default function useDebounce(
  effect: (...params: any) => void,
  dependencies: Array<any>,
  delay: number
) {
  // Memoized callback
  const callback = useCallback(effect, dependencies);

  useDidMountEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
