import { AB_TEST_EVOLUTION_METRICS_ENUM } from "../../enums";

export const getOptions = (
  t: (
    key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
    options?: any
  ) => string
) => [
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.REVENUES_PER_VISITOR,
    label: t("revenues_per_visitor"),
  },
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.REVENUES_PER_TRANSACTIONS,
    label: t("revenues_per_transaction"),
  },
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.TRANSACTIONS_PER_VISITOR,
    label: t("transactions_per_visitor"),
  },
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.EXPOSED,
    label: t("cumulated_exposed"),
  },
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.REVENUES,
    label: t("cumulated_revenues"),
  },
  {
    value: AB_TEST_EVOLUTION_METRICS_ENUM.TRANSACTIONS,
    label: t("cumulated_transactions"),
  },
];
