/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext } from "react";

import { useInterpret } from "@xstate/react";
import ShortUniqueId from "short-unique-id";
import { ActorRefFrom, assign, createMachine } from "xstate";
import { areEquals } from "./helpers";
import { Event } from "./types";

const AlgorithmMachine = createMachine<any, Event>({
  id: "AlgorithmesMachine",
  context: {
    pins: {},
    id: null,
    uuid: null,
    name: "NEW_ALGORITHM",
    description: "",
    options: [],
    settings: {
      size: -1,
      fields: "all",
      filters: [], //global filters, applied to all blocks
    },
    blocks: {},
    serverState: {},
    requireToSave: false,
    variables: [],
  },
  on: {
    INITIALIZE_BLOCK: {
      actions: assign((context, event) => {
        const uid = new ShortUniqueId();
        const id = uid();
        return {
          ...context,
          blocks: {
            [id]: {
              id,
              name: "",
              size: -1,
              defaultCase: {
                algorithm: [""],
              },
              exceptions: [],
            },
          },
        };
      }),
    },
    SET_RULE_SIZE: {
      actions: assign((context, event) => ({
        ...context,
        settings: {
          ...context.settings,
          size: event.data.size,
        },
      })),
    },
    SET_MIN_RULE_SIZE: {
      actions: assign((context, event) => ({
        ...context,
        settings: {
          ...context.settings,
          min_size: event.data.size,
        },
      })),
    },
    SET_PINS: {
      actions: assign((context, event) => ({
        ...context,
        pins: event.data.pins,
        requireToSave: !areEquals(
          {
            ...context,
            pins: event.data.pins,
          },
          context.serverState
        ),
      })),
    },
    SET_GLOBAL_FILTERS: {
      actions: assign((context, event) => ({
        ...context,
        settings: {
          ...context.settings,
          filters: event.data.globalFilters,
        },
        requireToSave: !areEquals(
          {
            ...context,
            settings: {
              ...context.settings,
              filters: event.data.globalFilters,
            },
          },
          context.serverState
        ),
      })),
    },
    SET_PROMOTE_AND_PIN_FILTERS: {
      actions: assign((context, event) => ({
        ...context,
        settings: {
          ...context.settings,
          promoted_and_pin_items_filters: event.data.filters,
        },
        requireToSave: !areEquals(
          {
            ...context,
            settings: {
              ...context.settings,
              promoted_and_pin_items_filters: event.data.filters,
            },
          },
          context.serverState
        ),
      })),
    },
    SET_OPTIONS: {
      actions: assign((context, event) => ({
        ...context,
        options: event.data.options,
        requireToSave: !areEquals(
          {
            ...context,
            options: event.data.options,
          },
          context.serverState
        ),
      })),
    },
    SET_RULE: {
      actions: assign((context, event) => ({
        ...context,
        ...event.data.rule,
        serverState: {
          ...event.data.rule,
        },
        requireToSave: false,
      })),
    },
    SET_NAME: {
      actions: assign((context, event) => ({
        ...context,
        name: event.data.name,
        requireToSave: true,
      })),
    },
    SET_VARIABLES: {
      actions: assign((context, event) => ({
        ...context,
        variables: event.data.variables,
        required_input_variables: event.data.variablesTypes,
      })),
    },
    SET_DEFAULT_CASE: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            defaultCase: { ...event.data.defaultCase },
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                defaultCase: { ...event.data.defaultCase },
              },
            },
          },
          context.serverState
        ),
      })),
    },
    SET_BLOCK_NAME: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            name: event.data.name,
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                name: event.data.name,
              },
            },
          },
          context.serverState
        ),
      })),
    },
    ADD_BLOCK: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            name: "",
            size: -1,
            id: event.data.blockId,
            exceptions: [],
            defaultCase: {
              algorithm: [""],
            },
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                name: "",
                size: -1,
                id: event.data.blockId,
                exceptions: [],
                defaultCase: {
                  algorithm: [""],
                },
              },
            },
          },
          context.serverState
        ),
      })),
    },
    REMOVE_BLOCK: {
      actions: assign((context, event) => {
        const blocks = { ...context.blocks };
        delete blocks[event.data.blockId];
        return {
          ...context,
          blocks,
          requireToSave: !areEquals(
            {
              ...context,
              blocks,
            },
            context.serverState
          ),
        };
      }),
    },
    SET_BLOCKS: {
      actions: assign((context, event) => ({
        ...context,
        blocks: { ...event.data.newBlocks },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: { ...event.data.newBlocks },
          },
          context.serverState
        ),
      })),
    },
    SET_BLOCK_MAX_ITEMS: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            size: event.data.maxItems,
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                size: event.data.maxItems,
              },
            },
          },
          context.serverState
        ),
      })),
    },
    ADD_SHUFFLE: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            defaultCase: {
              ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
              shuffle: [],
            },
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                defaultCase: {
                  ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
                  shuffle: [],
                },
              },
            },
          },
          context.serverState
        ),
      })),
    },
    REMOVE_SHUFFLE: {
      actions: assign((context, event) => {
        const newBlock = { ...context.blocks[event.data.blockId] };
        const defaultCase = {
          ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
        };
        delete defaultCase.shuffle;
        return {
          ...context,
          blocks: {
            ...context.blocks,
            [event.data.blockId]: {
              ...newBlock,
              defaultCase,
            },
          },
          requireToSave: !areEquals(
            {
              ...context,
              blocks: {
                ...context.blocks,
                [event.data.blockId]: {
                  ...newBlock,
                  defaultCase,
                },
              },
            },
            context.serverState
          ),
        };
      }),
    },
    REMOVE_FILTER: {
      actions: assign((context, event) => {
        const newBlock = { ...context.blocks[event.data.blockId] };
        const defaultCase = {
          ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
        };
        delete defaultCase.filter;
        return {
          ...context,
          blocks: {
            ...context.blocks,
            [event.data.blockId]: {
              ...newBlock,
              defaultCase,
            },
          },
          requireToSave: !areEquals(
            {
              ...context,
              blocks: {
                ...context.blocks,
                [event.data.blockId]: {
                  ...newBlock,
                  defaultCase,
                },
              },
            },
            context.serverState
          ),
        };
      }),
    },
    SET_FILTER: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            defaultCase: {
              ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
              filter: event.data.filter,
            },
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                defaultCase: {
                  ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
                  filter: event.data.filter,
                },
              },
            },
          },
          context.serverState
        ),
      })),
    },
    SET_PROMOTE: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            defaultCase: {
              ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
              promote: [event.data.promote],
            },
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                defaultCase: {
                  ...(context.blocks[event.data.blockId]?.defaultCase ?? {}),
                  promote: [event.data.promote],
                },
              },
            },
          },
          context.serverState
        ),
      })),
    },
    // exceptions
    SET_EXCEPTIONS: {
      actions: assign((context, event) => ({
        ...context,
        blocks: {
          ...context.blocks,
          [event.data.blockId]: {
            ...context.blocks[event.data.blockId],
            exceptions: [...event.data.blockExceptions],
          },
        },
        requireToSave: !areEquals(
          {
            ...context,
            blocks: {
              ...context.blocks,
              [event.data.blockId]: {
                ...context.blocks[event.data.blockId],
                exceptions: [...event.data.blockExceptions],
              },
            },
          },
          context.serverState
        ),
      })),
    },
  },
  states: {},
});

interface AlgorithmContextType {
  algorithmService: ActorRefFrom<typeof AlgorithmMachine>;
}

export const AlgorithmContext = createContext({} as AlgorithmContextType);

export default function AlgorithmProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const algorithmService = useInterpret(AlgorithmMachine);

  return (
    <AlgorithmContext.Provider value={{ algorithmService }}>
      {children}
    </AlgorithmContext.Provider>
  );
}

export function useConfigureAlgorithm() {
  return useContext(AlgorithmContext);
}
