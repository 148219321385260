import { useDebounce, useThrottle } from "@hooks";
import {
  Checkbox,
  Error,
  HideShowBlock,
  Loader,
  StyledTextField,
} from "@includes";
import {
  Box,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Stack,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { RootState } from "@store";
import { comparisonUtils, graphqlUtils } from "@utils";
import { useSelector as useXstateSelector } from "@xstate/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSharedErrors } from "../../hooks/useError";
import { GET_CATEGORIES } from "./requests";
import { useConfigureMerchandising } from "../../hooks/useConfigureMerchandising";

const includedCategoriesSelector = (state: any) =>
  state.context.includedCategories;
const excludedCategoriesSelector = (state: any) =>
  state.context.excludedCategories;

const CategoriesSelector = () => {
  const theme = useTheme();
  const { siteId } = useParams<UrlParams>();

  const { t }: i18translateType = useTranslation();

  const experienceServices = useConfigureMerchandising();

  const { send } = experienceServices.experienceService;

  const includedCategories = useXstateSelector(
    experienceServices.experienceService,
    includedCategoriesSelector
  );

  const handleChangeIncludedCategories = (
    newIncludedCategories: Array<string>
  ) => {
    send({
      type: "SET_INCLUDED_CATEGORIES",
      data: { includedCategories: newIncludedCategories },
    });
  };

  const handleChangeExcludedCategories = (
    newExcludedCategories: Array<string>
  ) => {
    send({
      type: "SET_EXCLUDED_CATEGORIES",
      data: { excludedCategories: newExcludedCategories },
    });
  };

  const excludedCategories = useXstateSelector(
    experienceServices.experienceService,
    excludedCategoriesSelector
  );

  const [endCursor, setEndCursor] = useState("");
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const [categories, setCategories] = useState<Array<Dic<any>>>([]);
  const [isGettingCategories, setIsGettingCategories] = useState(false);
  const [errorGettingCategories, setErrorGettingCategories] = useState(false);

  const { errorCategories, removeCategoriesError } = useSharedErrors();

  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);

  const getCategories = async (query = "", cursor = "", init = false) => {
    setIsGettingCategories(true);
    setErrorGettingCategories(false);
    const res = await graphqlUtils.fetchWithoutCache(
      GET_CATEGORIES,
      {
        catalogId,
        first: 50,
        cursor,
        search: query,
      },
      "cache-first"
    );

    if (res.errors) {
      setErrorGettingCategories(true);
    } else {
      setEndCursor(res?.data.categories.pageInfo.endCursor);
      setHasNextPage(res?.data.categories.pageInfo.hasNextPage);
      if (init)
        setCategories([
          ...res?.data?.categories?.edges?.map((item: any) => ({
            ...item?.node,
          })),
        ]);
      else
        setCategories([
          ...categories,
          ...res?.data?.categories?.edges?.map((item: any) => ({
            ...item?.node,
          })),
        ]);
    }
    setIsGettingCategories(false);
  };

  useEffect(() => {
    if (siteId) getCategories();
  }, [siteId]);

  const fetchMore = () => {
    getCategories(search, endCursor);
  };

  const [search, setSearch] = useState("");

  const throttledValue = useThrottle(search);

  const handleChange = (e: any) => {
    setSearch(e?.target?.value);
  };

  useDebounce(
    () => {
      getCategories(throttledValue, "", true);
    },
    [throttledValue],
    600
  );

  const handleChangeSelection = (isSelected: boolean, id: string) => {
    removeCategoriesError();
    if (!!includedCategories?.find((c: string) => c === "all")) {
      if (isSelected) {
        handleChangeExcludedCategories(
          excludedCategories.filter(comparisonUtils.areDifferent(id))
        );
      } else {
        const newExcluded = [...(excludedCategories ?? []), id];
        handleChangeExcludedCategories(newExcluded);
        handleChangeIncludedCategories([
          ...categories
            .filter((cat: any) => !newExcluded.includes(cat.id))
            .map((cat: any) => cat.id),
        ]);
      }
    } else {
      if (isSelected) {
        handleChangeIncludedCategories(
          includedCategories.filter(comparisonUtils.areDifferent(id))
        );
      } else {
        handleChangeIncludedCategories([...(includedCategories ?? []), id]);
      }
    }
  };

  if (errorGettingCategories) return <Error />;

  return (
    <HideShowBlock
      initialState
      title={t("merchandising_categories")}
      subtitle={t("categories_merchandising")}
    >
      <Stack
        spacing={1}
        sx={{
          width: "min(100%, 700px)",
        }}
      >
        <StyledTextField
          value={search}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="img"
                  src={`/${theme.customPalette.mode}/actions/search.svg`}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <>{isGettingCategories ? <CircularProgress size={20} /> : null}</>
            ),
          }}
          label={t("categories")}
          name={t("categories")}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderColor: theme.customPalette.others.background,
              "&.Mui-focused fieldset": {
                borderColor: theme.customPalette.others.background,
              },
            },
          }}
        />
        <InfiniteScroll
          next={fetchMore}
          dataLength={categories.length}
          hasMore={hasNextPage && !isGettingCategories}
          loader={<Loader />}
          height={250}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <FormControlLabel
              label={t("all")}
              control={
                <Checkbox
                  indeterminate={
                    !!includedCategories?.find((c: string) => c === "all") &&
                    excludedCategories?.length > 0
                  }
                  sx={{ color: theme.customPalette.bluePurple.lighter }}
                  checked={
                    !!includedCategories?.find((c: string) => c === "all")
                  }
                  onChange={(e: any) => {
                    if (
                      !!includedCategories?.find((c: string) => c === "all")
                    ) {
                      handleChangeIncludedCategories([]);
                      handleChangeExcludedCategories([]);
                    } else {
                      handleChangeIncludedCategories(["all"]);
                      handleChangeExcludedCategories([]);
                    }
                  }}
                />
              }
              sx={{ color: theme.customPalette.grey.default, marginLeft: 0 }}
            />
          </Stack>
          {categories.map((category: Dic<string>) => (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              key={category.id}
            >
              <FormControlLabel
                label={`${category.title} - ${category.id.replace(
                  "gid://shopify/Collection/",
                  ""
                )}`}
                control={
                  <Checkbox
                    sx={{ color: theme.customPalette.bluePurple.lighter }}
                    onChange={() =>
                      handleChangeSelection(
                        !!excludedCategories?.find(
                          (c: any) => c.toString() === category.id.toString()
                        ) ||
                          !!includedCategories?.find(
                            (c: any) => c.toString() === category.id.toString()
                          ),
                        category.id.toString()
                      )
                    }
                    checked={
                      !!includedCategories?.find(
                        (c: any) => c.toString() === category.id.toString()
                      ) ||
                      (!!includedCategories?.find((c: string) => c === "all") &&
                        !excludedCategories?.find(
                          (c: any) => c.toString() === category.id.toString()
                        ))
                    }
                  />
                }
                sx={{
                  color: theme.customPalette.grey.default,
                  marginLeft: 0,
                }}
              />
            </Stack>
          ))}
        </InfiniteScroll>
        {errorCategories && (
          <FormHelperText error>{t("select_category")}</FormHelperText>
        )}
      </Stack>
    </HideShowBlock>
  );
};

export default CategoriesSelector;
