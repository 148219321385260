import { gql } from "@apollo/client";

export const UPDATE_PAGE = gql`
  mutation updatePageSettings($pageId: ID!, $page: PageInput!) {
    updatePage(pageId: $pageId, page: $page) {
      id
      runId
    }
  }
`;

export const CREATE_PAGE = gql`
  mutation createPage($experienceId: ExperienceID!, $page: PageInput!) {
    createPage(experienceId: $experienceId, page: $page) {
      id
      runId
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
  mutation updateExperience(
    $id: ExperienceID!
    $experience: ExperienceUpdateInput!
  ) {
    updateExperience(experienceId: $id, experience: $experience) {
      id
      runId
      name
    }
  }
`;

export const CREATE_EXPERIENCE = gql`
  mutation createExperience($siteId: SiteID!, $experience: ExperienceInput!) {
    createExperience(siteId: $siteId, experience: $experience) {
      id
      runId
      name
    }
  }
`;
