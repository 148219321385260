import { gql } from "@apollo/client";

export const RUN_COLLECTION = gql`
  query queryResourcesApi($id: SiteID!, $query: ResourcesAPIQuery!) {
    queryResourcesApi(siteId: $id, query: $query) {
      response
    }
  }
`;

export const RUN_PAGE = gql`
  query queryPageApi($id: SiteID!, $query: ResourcesAPIQuery!) {
    queryPageApi(siteId: $id, query: $query) {
      response
    }
  }
`;
