export class ManageFilter {
  private _filterType: "exception" | "filter" = "filter";

  constructor(filterType: "exception" | "filter") {
    this._filterType = filterType;
  }

  createMostFrequentKeyFilter(key: string, value: string): Dic<any> {
    return {
      most_frequent_key: [
        key,
        {
          get_details_parameter: [value],
        },
      ],
    };
  }

  createParamsFilter(key: string, value: string): Dic<any> {
    return {
      params: [value],
    };
  }

  createPropFilter(key: string, value: string): Dic<any> {
    return {
      prop: [key],
    };
  }

  createObject(object: Dic<any>) {
    const { type, key, value } = object;
    switch (type) {
      case "most_frequent_key":
        return this.createMostFrequentKeyFilter(key, value);
      case "params":
        return this.createParamsFilter(key, value);
      case "prop":
        return this.createPropFilter(key, value);
      default:
        return value;
    }
  }

  format(interpretation: Dic<any>): Dic<any> {
    // if isin, constant first,
    if (interpretation.entry.type === "constant") {
      return {
        [interpretation.operator.name]: [
          this.createObject(interpretation.entry),
          this.createObject(interpretation.output),
        ],
      };
    } else if (interpretation.output.type === "constant") {
      return {
        [interpretation.operator.name]: [
          this.createObject(interpretation.output),
          this.createObject(interpretation.entry),
        ],
      };
    } else if (interpretation.output.type === "prop") {
      return {
        [interpretation.operator.name]: [
          this.createObject(interpretation.entry),
          this.createObject(interpretation.output),
        ],
      };
    } else if (interpretation.entry.type === "prop") {
      return {
        [interpretation.operator.name]: [
          this.createObject(interpretation.output),
          this.createObject(interpretation.entry),
        ],
      };
    }

    return {
      [interpretation.operator.name]: [
        this.createObject(interpretation.entry),
        this.createObject(interpretation.output),
      ],
    };
  }

  handleTransformInterpretationIntoRule(interpretation: Dic<any>) {
    return this.format(interpretation);
  }
}
