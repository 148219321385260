import { ManageFilters } from "@/components/_Routes/ConfigureAlgorithm/hooks/useFilters/ManageFilters";
import { stringUtils } from "@/utils";
import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";

/**
 * Utility class for working with filters.
 */
export class FilterUtils {
  private defaultOperation: Dic<string> = {
    string: "isin",
    number: "eq",
    int: "eq",
    float: "eq",
    array: "anyisin",
    boolean: "eq",
  };
  private defaultValue: Dic<any> = {
    string: [],
    number: 10,
    int: 10,
    float: 10.5,
    array: [],
    boolean: true,
  };

  private defaultFilterValue: Dic<any> = {
    string: "",
    number: 10,
    int: 10,
    float: 10.5,
    array: [],
    boolean: true,
  };

  private defaultFilter: Dic<any> = {
    isin: [[], { prop: [] }],
  };

  /**
   * Get the text representation of a filter clause.
   *
   * @param clause - The filter clause to be interpreted.
   * @param t - A function for translating text.
   * @param variables - An array of variable names.
   * @param variableTypes - A dictionary mapping variable names to their types.
   * @returns The interpreted filter clause in a human-readable format.
   */
  getClauseText(
    clause: Dic<any>,
    t: (text: string) => string,
    variables: Array<string>,
    variableTypes: Dic<string>
  ): Dic<any> {
    if (
      (JSON.stringify(clause).includes("or_all") ||
        JSON.stringify(clause).includes("and_all")) &&
      clause[Object.keys(clause)[0]].length > 1
    )
      return {
        nbExceptions: clause[Object.keys(clause)[0]].length,
        description: `${clause[Object.keys(clause)[0]].length} ${t(
          "exceptions"
        )}`,
      };

    const operators = this.getOperations(t).filter(
      (operation: Dic<any>) => !operation.isInverse
    );

    const manageFilter = new ManageFilters(operators, variables, "exception");
    manageFilter.interpret(clause);
    const interpretableFilter = manageFilter.get();

    const operationPrefix = operators.find(
      (op: Dic<any>) => op.name === interpretableFilter.operator.name
    )?.prefix;

    let prefix = "";
    if (
      variableTypes[interpretableFilter.entry.value] !== "product_ids" &&
      variableTypes[interpretableFilter.entry.value] !==
        VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID &&
      variableTypes[interpretableFilter.entry.value] !==
        VARIABLE_TYPE_ENUM.ITEM_ID
    )
      prefix = t(interpretableFilter.entry.value);
    else {
      prefix = `${interpretableFilter.entry.key} (${t(
        interpretableFilter.entry.value
      )})`;
    }
    return {
      nbExceptions: 1,
      description: `${stringUtils.capitalizeFirstLetter(
        prefix
      )} ${operationPrefix} ${interpretableFilter.output.value}`,
    };
  }

  /**
   * Get the default filter value for a given type.
   *
   * @param type - The type for which to get the default filter value.
   * @returns The default filter value for the specified type.
   */
  getDefaultFilterValue(type: string): any {
    return this.defaultFilterValue[type];
  }

  /**
   * Get the default filter.
   *
   * @returns The default filter.
   */
  getDefaultFilter(): Dic<any> {
    return this.defaultFilter;
  }

  /**
   * Get the default value for a given type.
   *
   * @param type - The type for which to get the default value.
   * @returns The default value for the specified type.
   */
  getDefaultValue(type: string): any {
    return this.defaultValue[type] ?? "";
  }

  /**
   * Get the default operation for a given type.
   *
   * @param type - The type for which to get the default operation.
   * @returns The default operation for the specified type.
   */
  getDefaultOperation(type: string): string {
    return this.defaultOperation[type];
  }

  /**
   * Get the default conditional block for a given case.
   *
   * @param defaultCase - The default case.
   * @param variables - An array of variable names.
   * @param variablesType - A dictionary mapping variable names to their types.
   * @param defaultValue - The default value.
   * @returns The default conditional block.
   */
  getDefaultConditionalBlock = (
    defaultCase: Dic<any>,
    variables: Array<string>,
    variablesType: Dic<string>,
    defaultValue: any = ""
  ): Array<Dic<any>> => {
    const selectedVariable = variables[0];
    const variableType = variablesType[selectedVariable];
    if (
      variableType === "product_ids" ||
      variableType === VARIABLE_TYPE_ENUM.ARRAY_ITEM_ID ||
      variableType === VARIABLE_TYPE_ENUM.ITEM_ID
    )
      return [
        {
          isin: [
            defaultValue,
            {
              most_frequent_key: [
                "id",
                {
                  get_details_parameter: [selectedVariable],
                },
              ],
            },
          ],
        },
        { ...defaultCase[1] },
      ];
    if (variableType === "CATEGORY_ID") {
      return [
        {
          anyisin: [
            "",
            {
              params: [selectedVariable],
            },
          ],
        },
        { ...defaultCase[1] },
      ];
    }
    return [
      {
        isin: [
          "",
          {
            params: [selectedVariable],
          },
        ],
      },
      { ...defaultCase[1] },
    ];
  };

  /**
   * Get the date information from a filter rule.
   *
   * @param input - The input filter rule.
   * @returns The date information.
   */
  getDateFromFilterRule = (
    input: Dic<any>
  ): { format: string; value: any } | null => {
    try {
      const key = Object.keys(input)[0];
      const value = input[key][0].strptime;

      return { format: value[0], value: value[1] };
    } catch (e: any) {
      return null;
    }
  };

  /**
   * Format a filter.
   *
   * @param input - The input filter.
   * @returns The formatted filter.
   */
  formatFilter = (input: Dic<any>): Dic<string> => {
    let res: any = {};
    try {
      for (const operation of input.filter[0].pipe) {
        const key = Object.keys(operation)[0];
        if (key === "ge") {
          res = { startDate: operation[key] };
        }
        if (key === "le") {
          res = { endDate: operation[key] };
        }
      }
      return { type: Object.keys(res)[0], ...this.getDateFromFilterRule(res) };
    } catch (e: any) {
      return {};
    }
  };

  /**
   * Create a filter from formatted input.
   *
   * @param formattedInput - The formatted input.
   * @returns The created filter.
   */
  createFilter = (formattedInput: Dic<string>): Dic<any> => {
    const keys: Dic<string> = {
      startDate: "ge",
      endDate: "le",
    };
    const key = keys[formattedInput.type];
    return {
      filter: [
        {
          pipe: [
            { now: [] },
            {
              [key]: [
                { strptime: [formattedInput.format, formattedInput.value] },
              ],
            },
          ],
        },
      ],
    };
  };

  /**
   * Get date filters and other filters from a list of filters.
   *
   * @param filters - The list of filters.
   * @returns The date filters and other filters.
   */
  getDateFilters = (filters: any): { dateFilters: any[]; others: any[] } => {
    const dateFilters =
      filters?.filter((filter: any) =>
        JSON.stringify(filter)?.includes("strptime")
      ) ?? [];

    const others =
      filters?.filter(
        (filter: any) =>
          !dateFilters?.find(
            (f: any) => JSON.stringify(f) === JSON.stringify(filter)
          )
      ) ?? [];
    return { dateFilters, others };
  };

  // old filters
  /**
   * Get available filter operations.
   *
   * @param t - A function for translating text.
   * @returns An array of available filter operations.
   */
  getOperations = (t: (text: string) => string): Array<Dic<any>> => {
    return [
      {
        name: "isin",
        description: t("included_in"),
        types: [{ entry: "string", output: "array" }],
        isInverse: false,
        prefix: ":",
      },
      {
        name: "not_isin",
        description: t("not_included_in"),
        isInverse: true,
        types: [{ entry: "string", output: "array" }],
      },
      {
        name: "lt",
        description: t("lower_than"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
        prefix: "<",
      },
      {
        name: "eq",
        description: t("equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
          { entry: "boolean", output: "boolean" },
        ],
        isInverse: false,
        prefix: ":",
      },
      {
        name: "ne",
        description: t("not_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
          { entry: "boolean", output: "boolean" },
        ],
        isInverse: false,
        prefix: `: ${t("not")}`,
      },
      {
        name: "gt",
        description: t("greater_than"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
        prefix: ">",
      },
      {
        name: "ge",
        description: t("greater_than_or_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
        prefix: "≥",
      },
      {
        name: "le",
        description: t("lower_than_or_equal_to"),
        types: [
          { entry: "number", output: "number" },
          { entry: "float", output: "float" },
        ],
        isInverse: false,
        prefix: "≤",
      },
      {
        name: "anyisin",
        description: t("anyisin"),
        types: [{ entry: "array", output: "array" }],
        isInverse: false,
        prefix: t("in"),
      },
      {
        name: "not_anyisin",
        description: t("not_anyisin"),
        isInverse: false,
        types: [{ entry: "array", output: "array" }],
        prefix: t("not_in"),
      },

      {
        name: "contains",
        description: t("contains"),
        isInverse: false,
        types: [{ entry: "string", output: "string" }],
        prefix: ":",
      },

      {
        name: "contains",
        description: t("does_not_contains"),
        isInverse: true,
        types: [{ entry: "string", output: "string" }],
      },
      {
        name: "not_contains",
        description: t("does_not_contains"),
        isInverse: false,
        types: [{ entry: "string", output: "string" }],
        prefix: `: ${t("not")}`,
      },
    ];
  };
}
