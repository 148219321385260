import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import { client } from "../../../index";
import { setAnalytics } from "@actions";
import { GET_ANALYTICS } from "./requests";
import { IHasPages } from "@/utils/types/component";

const AnalyticsWrapper: React.FC<IHasPages> = ({ children }) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      client
        .query({ query: GET_ANALYTICS, variables: { id: siteId } })
        .then((res: any) => {
          if (res.data) {
            const analytics = res?.data?.site?.analyticsTools?.edges?.find(
              (item: any) => item.node.name.includes("Main")
            )?.node;
            dispatch(setAnalytics(analytics?.settings?.type ?? "ua"));
          }
        });
    }
  }, [siteId]);

  return <>{children}</>;
};

export default AnalyticsWrapper;
