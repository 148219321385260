/* eslint-disable no-case-declarations */
import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  LOGGING_IN,
  LOGGED_IN,
  LOGOUT,
  SET_USER_INFOS,
  ERROR_LOGIN,
  SET_PROFILE_IMAGE,
  USER_MUST_VERIFY_EMAIL,
  USER_SET_LANGUAGE,
  LOGGING_IN_WITH_GOOGLE,
  EMAIL_VERIFIED,
  SET_ROLE,
} from "../actions/action-types";
import { navigatorUtils } from "@utils";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";

export const initialState = {
  isLoggedIn: potionsLocalStorage.get("isLoggedIn") ?? false,
  user: potionsLocalStorage.get("user") ?? {
    profilePicture: "",
    email: "",
    langue: navigatorUtils.getNavigatorLanguage() ?? "en",
    id: "",
  },
  isSuperUser: false,
  error: {
    errorMessage: "",
    errorLocation: "",
    isError: false,
  },
  role: "reader",
  mustCheckEmail: false,
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case LOGGING_IN_WITH_GOOGLE:
        draft.user.email = action.payload.email;
        break;
      case USER_MUST_VERIFY_EMAIL:
        draft.mustCheckEmail = true;
        break;
      case EMAIL_VERIFIED:
        draft.mustCheckEmail = false;
        break;
      case LOGGING_IN:
        draft.error = initialState.error;
        break;
      case SET_PROFILE_IMAGE:
        const newStateUser = {
          ...state.user,
          profilePicture: action.payload.profilePicture,
        };
        draft.user = { ...newStateUser };
        potionsLocalStorage.set("user", newStateUser);
        break;
      case LOGGED_IN:
        draft.isLoggedIn = true;
        draft.user = action.payload.user;
        break;
      case LOGOUT:
        potionsLocalStorage.clear();
        draft.user = {};
        draft.isSuperUser = false;
        window.location.href = "/login";
        break;
      case SET_ROLE:
        draft.role = action.payload.role;
        break;
      case SET_USER_INFOS:
        const newUser = {
          ...state.user,
          email: action.payload.email,
          profilePicture: action.payload.picture,
          id: action.payload.id,
          companyUsers: action.payload.companyUsers,
        };
        draft.user = { ...newUser };
        potionsLocalStorage.set("user", newUser);
        draft.isSuperUser = action.payload.isSuperuser;
        break;
      case USER_SET_LANGUAGE:
        const currentUser = {
          ...state.user,
          langue: action.payload.langue,
        };
        draft.user = {
          ...currentUser,
        };
        potionsLocalStorage.set("user", currentUser);
        break;
      case ERROR_LOGIN:
        draft.error = {
          isError: true,
          errorMessage: action.payload.errorMessage,
          errorLocation: action.payload.errorLocation,
        };
        break;
      default:
        break;
    }
  });
}
