import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
  WatchQueryFetchPolicy,
} from "@apollo/react-hooks";

const useGraphQlLazyQuery = (
  Query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  variables: Dic<any>,
  fetchPolicy: WatchQueryFetchPolicy | undefined = "network-only"
) => {
  const [getDatas, { loading, data, error, fetchMore, refetch }] = useLazyQuery(
    Query,
    {
      variables,
      fetchPolicy,
      errorPolicy: "all",
    }
  );

  return { getDatas, loading, data, error, fetchMore, refetch };
};

export default useGraphQlLazyQuery;
