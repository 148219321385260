import { isEmpty, uniq, uniqueId } from "lodash";
import ShortUniqueId from "short-unique-id";
import { VariableType } from "@/utils/types/variables";
import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";

class TransformConditionalRuleIntoInterpretableObject {
  private mapOperationName: Dic<any> = {
    filter: {
      name: "filter",
      get: (operation: Dic<any>) => [operation],
    },
    apply_recommendation: {
      name: "algorithm",
      get: (operation: Dic<any>) => operation.apply_recommendation,
    },
    promote: {
      name: "promote",
      get: (operation: Dic<any>) => operation.promote,
    },
    shuffle_on_block_size: {
      get: (operation: Dic<any>) => operation.shuffle_on_block_size,
      name: "shuffle",
    },
  };

  getPinsFromSwitch = (cases: Dic<any>) => {
    let pins: Dic<Dic<string>> = {};

    const getCategoryIdAndPins = (switchPipe: Array<Dic<any>>) => {
      if (typeof switchPipe[0] == "boolean") {
        // Default case
        return;
      }
      const [categoryRule, operations] = switchPipe;
      // get the categoryId from the rule
      const category = categoryRule[Object.keys(categoryRule)[0]].find(
        (rule: any) => typeof rule !== "object"
      );

      // transform the list of pins [[1, "1234"],...] into an object {position: productId,...}
      const pinsDic = operations.pipe
        .find((operation: Dic<any>) => operation.pins)
        ?.pins?.[0]?.reduce(
          (a: Dic<string>, b: Array<any>) => ({
            ...a,
            ...Object.fromEntries([b]),
          }),
          {}
        );

      pins = { ...pins, [category]: { ...pinsDic } };
    };

    cases.map(getCategoryIdAndPins);
    return pins;
  };

  getOperations = (pipe: Array<Dic<any>>) =>
    pipe.reduce((current: Dic<any>, operation: Dic<any>) => {
      const key = Object.keys(operation)[0];
      return {
        ...current,
        [this.mapOperationName[key].name]:
          this.mapOperationName[key].get(operation),
      };
    }, {});

  getDefaultCase = (rule: Dic<any>) => {
    const defaultCase = rule.find((r: Array<any>) => typeof r[0] === "boolean");
    if (defaultCase) return this.getOperations(defaultCase[1].pipe);
  };

  getExceptions = (rule: Dic<any>) => {
    const exceptions = rule.filter(
      (r: Array<any>) => typeof r[0] !== "boolean"
    );
    const uid = new ShortUniqueId();

    return exceptions.map((exception: Dic<any>) => ({
      ...this.getOperations(exception[1].pipe),
      clause: exception[0],
      id: uid(),
    }));
  };

  getSettings = (settings: Dic<any>) => {
    const innerSettings = settings.settings ?? {};
    let allPins = {};
    if (innerSettings.rules) {
      const pins = innerSettings?.rules?.switch;
      if (pins) allPins = this.getPinsFromSwitch(pins);
    }

    return {
      pins: { ...allPins },
      innerSettings: {
        size: window.location.href.includes("merchandising") ? -1 : 12,
        ...innerSettings,
        fields: "all",
      },
    };
  };

  transformConditionalRuleIntoInterpretableObject = (rule: Dic<any>) => {
    const { id, uuid, name, description, settings, experiences } = rule;
    const { blocks, options, required_input_variables } = settings;
    const uid = new ShortUniqueId();
    const newBlocks = blocks.reduce((current: Dic<any>, block: Dic<any>) => {
      const id = block?.id ?? uid();
      const rule = block.rule.switch;

      return {
        ...current,
        [id]: {
          id,
          name: block.name ?? "",
          size: block.max_items ?? -1,
          defaultCase: this.getDefaultCase(rule) ?? {},
          exceptions: this.getExceptions(rule),
        },
      };
    }, {});

    const { pins, innerSettings } = this.getSettings(settings);
    return {
      id,
      uuid,
      name,
      description,
      blocks: newBlocks,
      settings: innerSettings,
      pins,
      options,
      required_input_variables,
      experiences,
    };
  };

  transformRequiredInputParametersIntoParameters = (algorithm: Dic<any>) => {
    const parameters: Dic<any> = {};
    const algoReferences = algorithm?.settings?.algoReferences ?? [];
    const requiredInputVariables =
      algorithm?.settings?.required_input_variables ?? [];

    Object.entries(requiredInputVariables)?.forEach(
      (requiredInputVariable: any) => {
        parameters[requiredInputVariable[0]] = {
          type: requiredInputVariable[1],
          algoRefs: uniq(algoReferences[requiredInputVariable[0]]),
        };
      }
    );
    return parameters;
  };

  transformNewAlgoWithParameters = (algorithm: Dic<any>, algoRef: string) => {
    const parameters: Dic<any> = {};
    const requiredInputVariables =
      algorithm?.settings?.required_input_variables ?? [];

    Object.entries(requiredInputVariables)?.forEach(
      (requiredInputVariable: any) =>
        (parameters[requiredInputVariable[0]] = {
          type: requiredInputVariable[1],
          algoRefs: [algoRef],
        })
    );
    return parameters;
  };
}

class TransformObjectIntoConditionalRule {
  private mapOperationName: Dic<any> = {
    filter: {
      name: "filter",
      get: (operation: Dic<any>) => operation.filter[0],
    },
    algorithm: {
      name: "apply_recommendation",
      get: (operation: Dic<any>) => ({
        apply_recommendation: operation.algorithm,
      }),
    },
    promote: {
      name: "promote",
      get: (operation: Dic<any>) => ({
        promote: operation.promote,
      }),
    },
    shuffle: {
      name: "shuffle_on_block_size",
      get: (operation: Dic<any>) => ({
        shuffle_on_block_size: operation.shuffle,
      }),
    },
  };

  formatPins = (categoryPins: Dic<Dic<string>>) => {
    if (Object.keys(categoryPins).length === 0) return {};
    return {
      rules: {
        switch: [
          [true, { var: ["recommendations"] }],
          ...Object.keys(categoryPins).map((categoryId: string) => [
            {
              eq: [
                {
                  params: ["category_id"],
                },
                categoryId,
              ],
            },
            {
              pipe: [
                {
                  pins: [
                    [...Object.entries(categoryPins[categoryId])],
                    {
                      var: ["recommendations"],
                    },
                  ],
                },
              ],
            },
          ]),
        ],
      },
    };
  };

  sortOperations = (operations: Dic<any>) =>
    ["algorithm", "filter", "shuffle", "promote"]
      .filter((operation: string) =>
        Object.keys(operations).includes(operation)
      )
      .reduce(
        (result: Dic<any>, key: string) =>
          Object.assign({}, result, { [key]: operations[key] }),
        {}
      );

  getPipe(operations: Dic<any>) {
    return {
      pipe: Object.keys(this.sortOperations(operations))
        .filter((operation: string) => {
          if (operation === "algorithm") return !!operations.algorithm[0];
          if (operation === "promote")
            return operations.promote?.[0]?.length > 0;
          return operation !== "clause" && operation !== "id";
        })
        .map((operation: string) =>
          this.mapOperationName[operation].get(operations)
        ),
    };
  }

  getDefault(operations: Dic<any>) {
    return [true, this.getPipe(operations)];
  }

  getExceptions(operationsMap: Array<Dic<any>>) {
    if (!Array.isArray(operationsMap)) return [];
    return operationsMap.map((operations: Dic<any>) => [
      operations.clause,
      this.getPipe(operations),
    ]);
  }

  transformObjectIntoConditionalRule = (object: Dic<any>) => {
    const blocks = Object.values(object.blocks).map((block: any) => {
      return {
        id: block.id,
        name: block.name,
        max_items: block.size,
        rule: {
          switch: [
            this.getDefault(block.defaultCase),
            ...this.getExceptions(block.exceptions),
          ],
        },
      };
    });

    return {
      id: object.id,
      name: object.name,
      description: object.description,
      settings: {
        blocks,
        options: [...object.options],
        settings: {
          ...object.settings,
          ...this.formatPins(object.pins),
        },
        sub_keys: [...(object.settings.sub_keys ?? [])],

        required_input_variables: {
          ...object.required_input_variables,
        },
      },
      requireToSave: object.requireToSave,
    };
  };

  transformParametersIntoRequiredInputVariables = (
    parameters: Dic<{ type: string; algoRefs: Array<string> }>
  ) => {
    const requiredInputVariables: any = {};
    const algoReferences: Dic<any> = {};
    Object.keys(parameters)?.forEach((key: string) => {
      const inputParameter = parameters[key];
      requiredInputVariables[key] = inputParameter.type;
      algoReferences[key] = inputParameter.algoRefs;
    });
    return { requiredInputVariables, algoReferences };
  };
}

// not very beautiful typing :/
function Classes(bases: Array<any>): any {
  class Bases {
    constructor() {
      bases.forEach((base: any) => Object.assign(this, new base()));
    }
  }
  bases.forEach((base: any) => {
    Object.getOwnPropertyNames(base.prototype)
      .filter((prop: string) => prop != "constructor")
      .forEach(
        (prop: string) =>
          ((Bases.prototype as any)[prop] = base.prototype[prop])
      );
  });
  return Bases;
}

export class Interpret extends Classes([
  TransformConditionalRuleIntoInterpretableObject,
  TransformObjectIntoConditionalRule,
]) {
  constructor() {
    super();
  }
}
