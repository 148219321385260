import { IHasPages } from "@/utils/types/component";
import { useViewport } from "@hooks";

const MobileWrapper: React.FC<IHasPages> = ({ children }) => {
  const { isMobile } = useViewport();

  if (isMobile) return <>{children}</>;

  return <></>;
};

export default MobileWrapper;
