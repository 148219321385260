import React, { useEffect, useState } from "react";
import { useDebounce } from "@hooks";

const useConfigureLogic = (
  visibilityModel: any,
  setVisibilityModel: (visibilityModel: any) => void
) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeVisibilityModel = (key: string, value: boolean) => {
    const model = { ...visibilityModel };
    model[key] = !value;
    setVisibilityModel(model);
  };

  const handleHideAll = () => {
    const model = { ...visibilityModel };
    Object.keys(model).forEach((key: string) => {
      if (key !== "actions") model[key] = false;
    });
    setVisibilityModel(model);
  };

  const handleShowAll = () => {
    const model = { ...visibilityModel };
    Object.keys(model).forEach((key: string) => {
      model[key] = true;
    });
    setVisibilityModel(model);
  };

  const [search, setSearch] = useState("");

  const [columns, setColumns] = useState<Array<Dic<any>>>([]);

  useDebounce(
    () => {
      if (!search) {
        setColumns(Object.entries(visibilityModel));
      } else {
        setColumns(
          Object.entries(visibilityModel)?.filter(
            ([key, value]: any) =>
              key?.toLowerCase()?.includes(search?.toLowerCase()) ||
              search?.toLowerCase()?.includes(key?.toLowerCase())
          )
        );
      }
    },
    [search],
    600
  );

  useEffect(() => {
    setColumns(Object.entries(visibilityModel));
  }, [visibilityModel]);

  const handleSearchChange = (e: any) => {
    setSearch(e?.target?.value);
  };

  return {
    anchorEl,
    handleSearchChange,
    open,
    handleClick,
    handleClose,
    search,
    columns,
    handleChangeVisibilityModel,
    handleHideAll,
    handleShowAll,
  };
};

export default useConfigureLogic;
