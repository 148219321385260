import { gql } from "@apollo/client";

export const GET_VARIABLES = gql`
  query Variables($first: Int!, $siteId: SiteID) {
    variables(first: $first, siteId: $siteId) {
      totalCount
      edges {
        node {
          id
          name
          siteId
          type
          config
        }
      }
    }
  }
`;
