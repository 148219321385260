import { IIcon } from "./types";

const Close: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79095 6.15643C7.1229 5.82448 7.66109 5.82448 7.99303 6.15643L12.6346 10.798L17.2762 6.15643C17.6081 5.82449 18.1463 5.82448 18.4783 6.15643C18.8102 6.48837 18.8102 7.02656 18.4783 7.35851L13.8367 12.0001L18.4783 16.6417C18.8102 16.9736 18.8102 17.5118 18.4783 17.8438C18.1463 18.1757 17.6081 18.1757 17.2762 17.8438L12.6346 13.2022L7.99303 17.8438C7.66108 18.1757 7.12289 18.1757 6.79095 17.8437C6.459 17.5118 6.45901 16.9736 6.79095 16.6417L11.4325 12.0001L6.79095 7.35851C6.45901 7.02657 6.45901 6.48837 6.79095 6.15643Z"
        fill={color}
      />
    </svg>
  );
};

Close.defaultProps = {
  color: "transparent",
};

export default Close;
