import {
  AutoComplete,
  Box,
  CategorySelector,
  FloatInput,
  IntegerInput,
  SelectPaper,
  StyledTextField,
  Text,
} from "@/includes";
import { IParameterValue } from "./types";
import { VARIABLE_TYPE_ENUM } from "@/utils/enums/variables";

const ParameterValue: React.FC<IParameterValue> = ({
  parameterType,
  handleChange,
  value,
  parameterName,
}) => {
  if (parameterType === VARIABLE_TYPE_ENUM.CATEGORY_ID)
    return <CategorySelector setCategoryId={handleChange} />;

  if (parameterType === VARIABLE_TYPE_ENUM.STRING) {
    return (
      <StyledTextField
        label={parameterName}
        sx={{ width: "100%", minWidth: 200 }}
        value={value}
        onChange={(e: React.ChangeEvent<any>) => handleChange(e.target.value)}
      />
    );
  }
  if (parameterType === VARIABLE_TYPE_ENUM.NUMBER) {
    return (
      <Box>
        <IntegerInput value={value} setValue={handleChange} />
      </Box>
    );
  }

  if (parameterType === VARIABLE_TYPE_ENUM.BOOLEAN)
    return (
      <AutoComplete
        options={[true, false]}
        sx={{ width: "100%" }}
        disableClearable
        value={value}
        getOptionLabel={(option: any) => option.toString()}
        renderInput={(params: any) => (
          <StyledTextField {...params} label={parameterName} name="boolean" />
        )}
        onChange={(e: React.SyntheticEvent, value: any) => {
          handleChange(value);
        }}
        PaperComponent={SelectPaper}
      />
    );

  return <></>;
};

export default ParameterValue;
