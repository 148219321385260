import React, { useState } from "react";
import { Tooltip as MuiTooltip } from "@mui/material";
import { ITooltip } from "./types";

const Tooltip: React.FC<ITooltip> = ({
  title,
  children,
  shouldShowTooltip,
  placement,
  customStyle,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <MuiTooltip
      data-testid="tooltipTestId"
      placement={placement}
      title={title}
      open={shouldShowTooltip && showTooltip}
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      sx={{ ...customStyle }}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.defaultProps = {
  shouldShowTooltip: true,
  placement: "bottom-start",
  customStyle: {},
};

export default Tooltip;
