import { ResponseAbTestKeyIndicatorsDataType } from "./types";

export const formatDataToTable = (
  data: ResponseAbTestKeyIndicatorsDataType,
  audience: any,
  t: (text: string) => void
) => {
  const aTest: any = {
    segment: `Audience A - (${audience.A})`,
  };
  const bTest: any = {
    segment: `Audience B - (${audience.B})`,
  };
  const delta: any = {
    segment: t("delta"),
  };
  const deltaPercent: any = {
    segment: t("delta_percent"),
  };
  data?.data.ATest.headers.forEach((header: string, index: number) => {
    const aValue = data.data?.ATest?.rows[0][index]?.floatValue ?? 0;
    const bValue = data.data?.BTest?.rows[0][index]?.floatValue ?? 0;
    const deltaValue = bValue - aValue;
    const deltaPercentValue = deltaValue / aValue;
    aTest[header] = aValue;
    bTest[header] = bValue;
    delta[header] = deltaValue;
    deltaPercent[header] = deltaPercentValue;
  });
  return [bTest, aTest, delta, deltaPercent];
};
