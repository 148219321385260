import { StyledTextField } from "@/includes";
import { useTheme } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { useTranslation } from "react-i18next";
import { DatePickerType } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const DatePicker = ({ label, entity, property, setEntity }: DatePickerType) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        PaperProps={{
          sx: {
            background: theme.customPalette.others.layer_1,
            border: "1px solid",
            borderColor: theme.customPalette.grey.white,
            "& .MuiPickersDay-root": {
              background: "transparent",
            },
            "& .MuiPickersDay-root:hover": {
              background: theme.customPalette.bluePurple.main,
            },
            "& .MuiPickersDay-root.Mui-disabled": {
              color: theme.customPalette.others.background,
            },
            "& .MuiPickersDay-root.Mui-selected": {
              background: theme.customPalette.bluePurple.main,
              color: theme.customPalette.grey.default,
            },
            "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
              background: theme.customPalette.others.layer_2,
            },
            "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
              color: theme.customPalette.grey.default,
            },
          },
        }}
        label={getTraduction(t, label)}
        inputFormat="DD/MM/YYYY"
        value={entity[property]}
        onChange={(newValue: any) =>
          setEntity({ ...entity, [property]: newValue })
        }
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            error={false}
            name={property}
            sx={{ mt: 3, width: 1 }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
