import { gql } from "@apollo/client";

export const GET_LABELS = gql`
  query Labels($siteId: SiteID!, $first: Int!) {
    labels(siteId: $siteId, first: $first) {
      totalCount
      edges {
        node {
          siteId
          id
          name
          backgroundColor
          textColor
        }
      }
    }
  }
`;

export const CREATE_LABEL = gql`
  mutation CreateLabel($siteId: SiteID!, $label: LabelInput!) {
    createLabel(siteId: $siteId, label: $label) {
      siteId
      id
      name
      backgroundColor
      textColor
    }
  }
`;

export const UPDATE_EXPERIENCE_LABELS = gql`
  mutation UpdateExperienceLabels(
    $experienceId: ExperienceID!
    $experience: ExperienceUpdateLabelsInput!
  ) {
    updateExperienceLabels(
      experienceId: $experienceId
      experience: $experience
    ) {
      id
    }
  }
`;
