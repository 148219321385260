import { Button, Checkbox, IconButton } from "@mui/material";
import { Box, Stack, Text, Popover, StyledTextField, AddIcon } from "@includes";
import useConfigureLogic from "./hooks/usedConfigureLogic";
import { useTranslation } from "react-i18next";
import { getTraduction } from "@/utils/helpers/traductions";

const Configure = ({ theme, visibilityModel, setVisibilityModel }: any) => {
  const { t }: i18translateType = useTranslation();
  const {
    anchorEl,
    open,
    handleClick,
    handleClose,
    search,
    handleSearchChange,
    columns,
    handleChangeVisibilityModel,
    handleShowAll,
    handleHideAll,
  } = useConfigureLogic(visibilityModel, setVisibilityModel);

  return (
    <Box>
      <Popover
        PaperProps={{
          style: {
            backgroundColor: "unset",
            background: theme.customPalette.others.layer_1,
            overflow: "hidden",
            border: `1px solid ${theme.customPalette.others.background}`,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 1, pt: 2 }}>
          <Text customStyle={{ mb: 2 }}>{t("chose_columns")}</Text>
          <StyledTextField
            label={t("search_property")}
            name={t("search_property")}
            onChange={handleSearchChange}
            value={search}
          />
          <Stack sx={{ maxHeight: 300, overflowY: "auto" }}>
            {columns?.map(([key, value]: any) => {
              if (key !== "actions")
                return (
                  <Box
                    key={key}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleChangeVisibilityModel(key, value)}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Checkbox checked={value} />
                      <Text>{getTraduction(t, key)}</Text>
                    </Stack>
                  </Box>
                );
            })}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            justifyContent="space-between"
          >
            <Button variant="text" onClick={handleHideAll}>
              {t("hide_all")}
            </Button>
            <Button variant="text" onClick={handleShowAll}>
              {t("show_all")}
            </Button>
          </Stack>
        </Stack>
      </Popover>
      <IconButton onClick={handleClick}>
        <AddIcon color={theme.customPalette.bluePurple.dark} />
      </IconButton>
    </Box>
  );
};

export default Configure;
