import { Box, Grid, Stack } from "@mui/material";
import DesktopWrapper from "../DesktopWrapper";
import LazyLoading from "../../Wrappers/components/LazyLoading";
import RightSide from "./components/RightSide";
import { IAuthWrapper } from "./types";

const AuthWrapper: React.FC<IAuthWrapper> = ({ wrappedComponent }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={5} sm={12} xs={12}>
          <Box
            sx={{ height: "100%", display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box sx={{ width: "80%", height: "85%" }}>
              <LazyLoading component={wrappedComponent} />
            </Box>
          </Box>
        </Grid>
        <DesktopWrapper>
          <Grid item md={7}>
            <RightSide />
          </Grid>
        </DesktopWrapper>
      </Grid>
    </Stack>
  );
};

export default AuthWrapper;
