import ConditionalRules from "@/components/_Organisms/Algorithm";
import Run from "../Run";
import { useSetState } from "@/hooks";
import { IRule, category } from "./types";

const Rule: React.FC<IRule> = ({
  ruleId,
  handleChangeExperience,
  rule,
  experience,
}) => {
  const [selectedCategory, setSelectedCategory] = useSetState({
    id: "",
    title: "",
  });

  // pass component as a slot with props so child and parent can communicate "outside" of the machine !
  const slot =
    (
      selectedCategory: category,
      setSelectedCategory: (category: category) => void
    ) =>
    (component: (...props: any) => JSX.Element) =>
      (
        <Run
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        >
          {component(selectedCategory)}
        </Run>
      );

  return (
    <ConditionalRules
      availableVariables={["category_id"]}
      handleChangeExperience={handleChangeExperience}
      ruleId={ruleId}
      experienceType="EMERCH"
      slot={slot(selectedCategory, setSelectedCategory)}
      slotProps={{ selectedCategory, setSelectedCategory }}
      rule={rule}
      experience={experience}
    />
  );
};

export default Rule;
