export const OPTIONS = [
  {
    value: "exclude_cart_items",
    exceptions: ["cart_items"],
    recommended: true,
  },
  {
    value: "exclude_viewing_items",
    exceptions: ["viewing_items"],
    recommended: true,
  },
  {
    value: "exclude_viewing_items",
    exceptions: ["viewing_items"],
    recommended: false,
  },
  {
    value: "exclude_viewing_item",
    exceptions: ["viewing_item"],
    recommended: false,
  },
];
