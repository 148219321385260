export enum EVOLUTION_METRICS_ENUM {
  DISPLAYS_BY_DAY = "DISPLAYS_BY_DAY",
  USAGES_BY_DAY = "USAGES_BY_DAY",
  CLOSURES_BY_DAY = "CLOSURES_BY_DAY",
  PERCENT_USAGES_BY_DAY = "PERCENT_USAGES_BY_DAY",
  PERCENT_CLOSURES_BY_DAY = "PERCENT_CLOSURES_BY_DAY",
}

export const labelOfEvolutionMetric = {
  DISPLAYS_BY_DAY: "number_of_displays",
  USAGES_BY_DAY: "number_of_usages",
  CLOSURES_BY_DAY: "number_of_closures",
  PERCENT_USAGES_BY_DAY: "utilization_rate",
  PERCENT_CLOSURES_BY_DAY: "share_closure",
};
