import { IIcon } from "./types";

const DuplicateIcon: React.FC<IIcon> = ({ color }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53378 1.02285C2.54635 1.16769 1.76114 1.88186 1.49076 2.88098L1.4388 3.07298L1.43217 10.453C1.42759 15.5432 1.43335 17.8708 1.45075 17.9549C1.51634 18.2721 1.75865 18.522 2.08039 18.6044C2.50454 18.7131 2.92886 18.4848 3.10121 18.0552C3.13951 17.9598 3.14304 17.4631 3.15425 10.585C3.16584 3.46235 3.16773 3.21417 3.21086 3.13298C3.2729 3.01615 3.43586 2.85098 3.55025 2.78899L3.64625 2.73698L11.0142 2.72498C17.8924 2.71377 18.389 2.71024 18.4845 2.67194C18.8027 2.54431 19.0188 2.27294 19.0563 1.954C19.1052 1.53782 18.8317 1.13855 18.4252 1.03267C18.3053 1.00142 17.3771 0.997338 10.9792 0.999906C6.95928 1.00151 3.60883 1.01183 3.53378 1.02285ZM7.42663 5.01767C6.42113 5.20034 5.61965 6.00871 5.44593 7.01539C5.41406 7.20002 5.41044 8.07921 5.41596 14.2932L5.42225 21.361L5.47521 21.5468C5.73312 22.4514 6.36943 23.0939 7.24625 23.335L7.48625 23.401H14.6142H21.7422L21.9462 23.3486C22.6064 23.1793 23.1213 22.8065 23.4746 22.2424C23.6072 22.0307 23.7079 21.7897 23.7779 21.517L23.8302 21.313V14.185V7.05698L23.7643 6.81698C23.5232 5.94016 22.8807 5.30385 21.9761 5.04595L21.7902 4.99298L14.6982 4.98899C8.81078 4.98571 7.57574 4.99058 7.42663 5.01767ZM7.73633 6.70907C7.49885 6.74959 7.29753 6.90772 7.18913 7.13891L7.12625 7.27298V14.197V21.121L7.18896 21.253C7.26038 21.4034 7.39399 21.5459 7.53425 21.6213L7.63025 21.673H14.6262H21.6222L21.7182 21.621C21.8326 21.559 21.9956 21.3938 22.0576 21.277C22.1008 21.1957 22.1022 20.9696 22.1022 14.197V7.20098L22.0506 7.10498C21.9751 6.96472 21.8326 6.83111 21.6822 6.75969L21.5502 6.69698L14.6982 6.69367C10.9296 6.69184 7.79678 6.69878 7.73633 6.70907Z"
        fill={color}
      />
    </svg>
  );
};

DuplicateIcon.defaultProps = {
  color: "transparent",
};

export default DuplicateIcon;
