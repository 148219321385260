import Tendency from "@/components/_Organisms/Tendency";
import { useViewport } from "@/hooks";
import { Text } from "@/includes";
import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const Tooltip = ({ active, payload, compare }: TooltipProps) => {
  const theme = useTheme();
  const isMobile = useViewport();
  const { t } = useTranslation();
  if (active && payload) {
    return (
      <Stack
        key={JSON.stringify(payload[0]?.payload?.evolutionMetricLabel)}
        sx={{
          padding: 1,
          background: theme.customPalette.others.layer_1,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          borderRadius: 2,
          minWidth: isMobile ? "unset" : 300,
        }}
        justifyContent="start"
        alignItems="start"
        spacing={1}
      >
        <Text variant="body2">
          {getTraduction(t, payload[0]?.payload?.evolutionMetricLabel)}
        </Text>
        <Stack
          sx={{
            borderLeft: `2px solid ${theme.customPalette.bluePurple.light}`,
            pl: 1,
          }}
        >
          <Text textType="secondary" variant="body3">
            {`${payload[0]?.payload?.date} ${
              !compare ? `VS ${payload[0]?.payload?.previousDate}` : ""
            }`}
          </Text>
          <Text variant="body2">
            {`${payload[0]?.payload?.current} ${getTraduction(
              t,
              `${payload[0]?.payload?.evolutionMetricLabel}_unit`
            )}`}
          </Text>
          {!compare && (
            <Box sx={{ width: "fit-content" }}>
              <Tendency
                newValue={payload[0]?.payload?.current}
                oldValue={payload[0]?.payload?.previous}
              />
            </Box>
          )}
        </Stack>
      </Stack>
    );
  }
  return <Box />;
};

export default Tooltip;
