import { SUMMARY_HEADERS } from "@/utils/enums/graphql";
import { KeyIndicatorsDataType } from "./types";
import { CALCULATE_BY_ALL } from "./enums";

export const formatDataToTable = (data: KeyIndicatorsDataType) => {
  const allVisitors: any = {
    segment: "all_visitors",
  };
  const exposedVisitors: any = {
    segment: "exposed_visitors",
  };
  const users: any = {
    segment: "users",
  };
  const ratio: any = {
    segment: "ratio",
  };
  data.allVisitors.headers.forEach(
    (header: SUMMARY_HEADERS | any, index: number) => {
      allVisitors[header] = data.allVisitors.rows[0][index]?.floatValue ?? 0;
    }
  );
  data.exposedVisitors.headers.forEach(
    (header: SUMMARY_HEADERS | any, index: number) => {
      exposedVisitors[header] =
        data.exposedVisitors.rows[0][index]?.floatValue ?? 0;
    }
  );
  data.users.headers.forEach((header: SUMMARY_HEADERS | any, index: number) => {
    const usersValue = data.users.rows[0][index]?.floatValue;
    users[header] = usersValue ?? 0;
    if (
      CALCULATE_BY_ALL.includes(header) ||
      (!Object.values(SUMMARY_HEADERS).includes(header) && header != "segment")
    ) {
      const allVisitorsValue = data.allVisitors.rows[0][index]?.floatValue;
      ratio[header] = allVisitorsValue
        ? (usersValue ?? 0) / allVisitorsValue
        : 0;
    } else {
      const exposedVisitorsValue =
        data.exposedVisitors.rows[0][index]?.floatValue;
      ratio[header] = exposedVisitorsValue
        ? (usersValue ?? 0) / exposedVisitorsValue
        : 0;
    }
  });
  return [allVisitors, exposedVisitors, users, ratio];
};
