import { Box, Text } from "@/includes";
import { formatUtils } from "@/utils";
import { getTraduction } from "@/utils/helpers/traductions";
import { Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

const useProductsColumns = (currency: string = "€") => {
  const getCatalogColumns = (
    columns: Array<any>,
    t: (
      key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
      options?: any
    ) => string
  ) => {
    return columns
      .filter(
        (cCol: Dic<any>) =>
          !columns.find(
            (c: Dic<any>) => c.headerName === getTraduction(t, cCol.name)
          ) &&
          ![
            "available",
            "Stock per size",
            "img_link",
            "link",
            "absolute_link",
            "categories",
            "id",
            "stroke_price",
            "price",
          ].includes(cCol.name)
      )
      .map((cCols: Dic<any>) => ({
        disableColumnMenu: true,
        field: cCols.name,
        headerName: getTraduction(t, cCols.name),
        width: 170,
        align: "right",
        sortable: false,
        renderHeader: () => (
          <Text variant="body2" textType="secondary" isTableHeader>
            {getTraduction(t, cCols.name)}
          </Text>
        ),
        renderCell: (params: any) => {
          const value = params.row[cCols.name];
          if (typeof value === "boolean")
            return (
              <Text
                variant="body2"
                noWrap
                customStyle={{ textTransform: "capitalize" }}
              >
                {value ? t("yes") : t("no")}
              </Text>
            );
          if (typeof value === "object")
            return (
              <Text variant="body2">{JSON.stringify(value, null, 2)}</Text>
            );
          if (JSON.stringify(value)?.toLowerCase()?.includes("jpg"))
            return <Box sx={{ height: "90%" }} component="img" src={value} />;
          if (cCols.name === "potential_turnover")
            return (
              <Text variant="body2" noWrap>
                {formatUtils.formatCurrency(value, 2, currency)}
              </Text>
            );
          return (
            <Tooltip title={value?.toString()}>
              <Box>
                <Text
                  variant="body2"
                  noWrap
                  customStyle={{ textTransform: "capitalize" }}
                >
                  {value?.toString()}
                </Text>
              </Box>
            </Tooltip>
          );
        },
      }));
  };

  const getInitialColumns = (columns: GridColDef[]) => {
    const initalColumns = [
      "id",
      "name",
      "price",
      "img_link",
      "revenues_last_30_days",
    ];
    const initialData: Dic<any> = {};
    columns.forEach((elem: GridColDef) => {
      initialData[elem.field] = initalColumns.includes(elem?.field);
    });
    return initialData;
  };

  const getAllColumns = (columns: GridColDef[]) => {
    const initialData: Dic<any> = {};
    columns.forEach((elem: GridColDef) => {
      initialData[elem.field] = true;
    });
    return initialData;
  };

  return { getCatalogColumns, getInitialColumns, getAllColumns };
};

export default useProductsColumns;
