import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../locales/en.json";
import translationFR from "../locales/fr.json";
import { navigatorUtils } from "../utils";
import { potionsLocalStorage } from "@localStorage";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng:
    potionsLocalStorage.langue ?? navigatorUtils.getNavigatorLanguage() ?? "en",
  keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18next;
