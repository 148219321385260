import { potionsLocalStorage } from "@localStorage";
import {
  GET_ACCOUNT_AND_SITE_ID,
  LOGGED_IN,
  LOGGING_IN,
  LOGOUT,
  REGISTERING,
  REGISTERING_INVITE,
  LOGGING_IN_INVITE,
  USER_MUST_VERIFY_EMAIL,
  LOGGING_IN_WITH_GOOGLE,
  EMAIL_VERIFIED,
} from "./action-types";

export function userLoginWithGoogle(googleDatas: IGoogleDatas): any {
  potionsLocalStorage.set("token", googleDatas.token);

  return {
    type: LOGGING_IN_WITH_GOOGLE,
    payload: {
      ...googleDatas,
    },
  };
}

export function userMustVerifyEmail(): any {
  return {
    type: USER_MUST_VERIFY_EMAIL,
    payload: {},
  };
}

export function userEmailVerified(): any {
  return {
    type: EMAIL_VERIFIED,
    payload: {},
  };
}

export function userSignUp(email: string, password: string): any {
  return {
    type: REGISTERING,
    payload: {
      data: {
        email: email,
        password: password,
      },
    },
  };
}

export function invitedUserSignUp(
  email: string,
  password: string,
  token: string | null
): any {
  return {
    type: REGISTERING_INVITE,
    payload: {
      data: {
        email: email,
        password: password,
      },
      token,
    },
  };
}

export function invitedUserLogin(
  email: string,
  password: string,
  token: string
): any {
  return {
    type: LOGGING_IN_INVITE,
    payload: {
      encodedForm: `grant_type=password&username=${email}&password=${password}`,
      token,
    },
  };
}

export function userLogin(email: string, password: string): any {
  return {
    type: LOGGING_IN,
    payload: {
      encodedForm: `grant_type=password&username=${email}&password=${password}`,
    },
  };
}

export function userLoggedIn(
  user: Dic<string>,
  accountId?: string | null,
  siteId?: string | null,
  accountTheme?: string,
  hasAlgorithmBuilder?: boolean,
  accountName?: string,
  siteName?: string,
  catalogId?: number,
  hasPages?: boolean,
  hasAnalytics?: boolean,
  hasAbtest?: boolean,
  hasSkuReport?: boolean,
  shouldGenerateResources = false
): any {
  potionsLocalStorage.set("isLoggedIn", true);
  return {
    type: LOGGED_IN,
    payload: {
      accountId,
      siteId,
      user,
      accountName,
      accountTheme,
      siteName,
      catalogId,
      hasPages,
      hasAbtest,
      hasAlgorithmBuilder,
      hasAnalytics,
      hasSkuReport,
      shouldGenerateResources,
    },
  };
}

export function userGetAccountAndSiteId(): any {
  return {
    type: GET_ACCOUNT_AND_SITE_ID,
    payload: {},
  };
}

export function userLogout(): any {
  return {
    type: LOGOUT,
    payload: {},
  };
}
