import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  CHANGE_SITE,
  LOGGED_IN,
  LOGGING_IN,
  LOGOUT,
  SET_CATALOG_ID,
  SET_CATALOG_COLUMNS,
  CHANGE_CURRENCY,
} from "../actions/action-types";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";

export const initialState = {
  catalogId: potionsLocalStorage.get("catalogId") ?? -1,
  catalogColumns: potionsLocalStorage.get("catalogColumns") ?? [],
  currency: "",
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case CHANGE_SITE:
        potionsLocalStorage.remove("catalogId");
        potionsLocalStorage.remove("catalogColumns");
        draft.catalogId = -1;
        draft.catalogColumns = [];
        break;
      case SET_CATALOG_ID:
        draft.catalogId = action.payload.catalogId;
        potionsLocalStorage.set("catalogId", action.payload.catalogId);
        break;
      case SET_CATALOG_COLUMNS:
        draft.catalogColumns = action.payload.catalogColumns;
        potionsLocalStorage.set(
          "catalogColumns",
          action.payload.catalogColumns
        );
        break;
      case LOGGED_IN:
        draft.catalogId = action.payload.catalogId;
        potionsLocalStorage.set("catalogId", action.payload.catalogId);
        break;
      case LOGOUT:
        draft.catalogId = null;
        draft.catalogColumns = [];
        potionsLocalStorage.remove("catalogColumns");
        potionsLocalStorage.remove("catalogId");
        break;
      case CHANGE_CURRENCY:
        draft.currency = action.payload.currency;
        break;
      default:
        break;
    }
  });
}
