interface ISave {
  color?: string;
}

const Save: React.FC<ISave> = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 3.83333V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H12.1667L15.5 3.83333ZM13.8333 4.54167L11.4583 2.16667H2.16667V13.8333H13.8333V4.54167ZM8 13C8.69444 13 9.28472 12.7569 9.77083 12.2708C10.2569 11.7847 10.5 11.1944 10.5 10.5C10.5 9.80556 10.2569 9.21528 9.77083 8.72917C9.28472 8.24306 8.69444 8 8 8C7.30556 8 6.71528 8.24306 6.22917 8.72917C5.74306 9.21528 5.5 9.80556 5.5 10.5C5.5 11.1944 5.74306 11.7847 6.22917 12.2708C6.71528 12.7569 7.30556 13 8 13ZM3 6.33333H10.5V3H3V6.33333Z"
        fill={color}
      />
    </svg>
  );
};

Save.defaultProps = {
  color: "transparent",
};

export default Save;
