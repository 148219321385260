import {
  AutoComplete,
  FloatInput,
  IntegerInput,
  MultipleSelect,
  SelectPaper,
  StyledTextField,
} from "@/includes";
import { IValueInput } from "./types";

const ValueInput: React.FC<IValueInput> = ({
  type,
  value,
  handleChangeValue,
}) => {
  if (type === "string") {
    return (
      <StyledTextField
        size="small"
        value={value}
        onChange={(e: any) => handleChangeValue(e?.target?.value)}
      />
    );
  }

  if (type === "boolean") {
    return (
      <AutoComplete
        size="small"
        options={[true, false]}
        sx={{ width: 150 }}
        disableClearable
        value={value}
        getOptionLabel={(option: any) => option.toString()}
        renderInput={(params: any) => (
          <StyledTextField {...params} label="" name="boolean" />
        )}
        onChange={(e: React.SyntheticEvent, value: any) => {
          handleChangeValue(value);
        }}
        PaperComponent={SelectPaper}
      />
    );
  }

  if (type === "float") {
    return <FloatInput value={value} setValue={handleChangeValue} />;
  }

  if (type === "number") {
    return <IntegerInput value={value} setValue={handleChangeValue} />;
  }

  if (type === "array") {
    return <MultipleSelect values={value} handleChange={handleChangeValue} />;
  }
  return <></>;
};

export default ValueInput;
