import { gql } from "@apollo/client";

export const GET_AB_TEST_KEY_INDICATORS = gql`
  query KeyIndicators(
    $siteId: SiteID!
    $devices: [String!]!
    $startDate: String!
    $endDate: String!
    $segmentA: String!
    $segmentB: String!
  ) {
    ATest: monitor(
      siteId: $siteId
      segment: $segmentA
      devices: $devices
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    BTest: monitor(
      siteId: $siteId
      segment: $segmentB
      devices: $devices
      query: SUMMARY
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
