interface IDropDownIcon {
  color?: string;
}

const DropDownIcon: React.FC<IDropDownIcon> = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1589_9662"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect
          y="16"
          width="16"
          height="16"
          transform="rotate(-90 0 16)"
          fill="#FAF9FA"
        />
      </mask>
      <g mask="url(#mask0_1589_9662)">
        <path
          d="M9.5999 7.99995L6.3999 11.2V4.79995L9.5999 7.99995Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

DropDownIcon.defaultProps = {
  color: "transparent",
};

export default DropDownIcon;
