import { client } from "@/index";
import { removeRun, sendNotification } from "@/services/redux/actions";
import { RootState } from "@/services/redux/store";
import { Notification } from "@includes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_PIPELINE_STATUS } from "./requests";
import { IHasPages } from "@/utils/types/component";

const FollowExperienceRunWrapper: React.FC<IHasPages> = ({ children }) => {
  const alert = useSelector((state: RootState) => state.notification);
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const { open } = alert;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const runs = useSelector((state: RootState) => state.runs.runs);

  const removeRunAndNotify = (run: Dic<any>) => {
    dispatch(removeRun(run.id));
    if (run.notify && run.alert) {
      const { name, title, text, severity } = run.alert;
      dispatch(sendNotification(name, title, text, severity));
    }
  };

  const removeRunAndSendError = (run: Dic<any>) => {
    dispatch(removeRun(run.id));
    const [name, title, text, severity] = ["", "Error", "Error error", "error"];
    dispatch(sendNotification(name, title, text, severity));
  };

  const checkPipelineStatus = (runId: string): Promise<string> => {
    return new Promise((resolve) => {
      client
        .query({
          query: GET_PIPELINE_STATUS,
          variables: { runId, siteId },
          fetchPolicy: "network-only",
        })
        .then((res: any) => {
          const status = res.data.resourcesRun.status;

          if (status.toLowerCase() === "running") {
            resolve("running");
          } else if (status.toLowerCase() === "failed") {
            resolve("error");
          } else {
            resolve("success");
          }
        });
    });
  };

  const checkStatus = async () => {
    setLoading(true);
    await Promise.all(
      runs.map((run: Dic<any>) => {
        checkPipelineStatus(run.id).then((status: string) => {
          if (status === "success") {
            removeRunAndNotify(run);
          } else if (status === "error") {
            removeRunAndSendError(run);
          }
        });
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        checkStatus();
      }, 3000);
    }
  }, [runs, loading]);

  return (
    <>
      {open && <Notification />}
      {children}
    </>
  );
};

export default FollowExperienceRunWrapper;
