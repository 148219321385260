import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ShortUniqueId from "short-unique-id";
import { Box, Button } from "@includes";
import useDraggableGridViewLogic from "../../hooks/useDraggableGridViewLogic";
import { IDraggableGridView } from "./types";

const DraggableGridView: React.FC<IDraggableGridView> = ({
  totalItems,
  itemsPerRow,
  child,
  items,
  nbItemToShow,
  pushProduct,
  setNbProductToShow,
}) => {
  const { t }: i18translateType = useTranslation();
  const length = totalItems < nbItemToShow ? totalItems : nbItemToShow;
  const nbRows = Math.ceil(length / itemsPerRow);

  const [itemsToShow, setItemsToShow] = useState<Array<any>>([]);

  useEffect(() => {
    setItemsToShow(items);
  }, [items]);

  const { rowHeight, onChange } = useDraggableGridViewLogic(
    pushProduct,
    itemsToShow,
    setItemsToShow
  );

  const toShow = useMemo(() => {
    return itemsToShow?.slice(0, nbItemToShow);
  }, [itemsToShow, nbItemToShow]);

  const handleSeeMore = () => {
    const newTotal =
      nbItemToShow + 30 < totalItems ? nbItemToShow + 30 : totalItems;
    setNbProductToShow(newTotal);
  };

  const uuid = new ShortUniqueId();

  return (
    <div style={{ marginTop: 6 }}>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={itemsPerRow}
          rowHeight={rowHeight}
          style={{ height: rowHeight * nbRows }}
          onError={(e: any) => console.error(e)}
        >
          {toShow?.map((p: Dic<any>, index: number) => {
            return (
              <GridItem key={p?.id ?? uuid()} style={{ width: "100%" }}>
                <div style={{ width: "100%", height: "100%" }}>
                  {child(p, index)}
                </div>
              </GridItem>
            );
          })}
        </GridDropZone>
      </GridContextProvider>
      {nbItemToShow < totalItems && (
        <Box sx={{ ml: 6 }}>
          <Button id="see-more" onClick={handleSeeMore}>
            {t("see_more")}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default DraggableGridView;
