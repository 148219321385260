import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "@store";
import { client } from "../index";

const GET_SITE = gql`
  query site($siteId: SiteID!) {
    site(siteId: $siteId) {
      isUnderMaintenance
      hostname
    }
  }
`;

/**
 * Custom React hook for checking maintenance status of a site.
 *
 * @returns An object containing the maintenance status of the site.
 */
const useMaintenance = () => {
  const location = useLocation();
  const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
  const siteId: ID = useSelector((state: RootState) => state.site.siteId);

  /**
   * Function to fetch maintenance status from the server.
   */
  const getMaintenance = () => {
    client
      .query({
        query: GET_SITE,
        variables: {
          siteId,
        },
      })
      .then((res: any) => {
        if (res?.data) {
          setIsMaintenance(!!res?.data?.site?.isUnderMaintenance);
        }
      });
  };

  useEffect(() => {
    if (siteId) getMaintenance();
  }, [location.pathname, siteId]);

  return { isMaintenance };
};

export default useMaintenance;
