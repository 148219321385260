export const getTypesOfAvailablesVariables = (
  variables: Dic<any>,
  availableVariables: Array<string>
) => {
  return Object.fromEntries(
    availableVariables?.map((availableVariable: string) => [
      availableVariable,
      variables.find(
        (variable: Dic<any>) => variable?.name === availableVariable
      )?.type ?? "",
    ])
  );
};
