import GridToolbar from "@/components/_Molecules/GridToolbar";
import { usePersistedState } from "@/hooks";
import { DataGrid, Error } from "@/includes";
import { RootState } from "@/services/redux/store";
import { Skeleton, useTheme } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { isEmpty, isNil } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import getColumns from "./columns";
import { getInitialColumns } from "./helpers";
import { TableProps } from "./types";
import { StatusEnum } from "@/utils/helpers/status/enums";

const KeyIndicatorsTable = ({
  rows,
  abExperimentId,
  apiRef,
  status,
}: TableProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const columns = getColumns(t, currency);
  const [columnVisibilityModel, setColumnVisibilityModel] = usePersistedState({
    key: `AnalyticsTableVisibilityModel-AbTest-keyIndicators-${siteId}-${abExperimentId}`,
    initialValue: {},
  });
  const [initialData, setInitialData] = useState<Dic<any>>({});

  useEffect(() => {
    if (!isEmpty(columns)) {
      setInitialData(getInitialColumns(columns));
    }
  }, []);

  useEffect(() => {
    if (
      isEmpty(columnVisibilityModel) &&
      !isEmpty(initialData) &&
      !isNil(abExperimentId)
    ) {
      setColumnVisibilityModel(initialData);
    } else if (!isEmpty(initialData) && !isNil(abExperimentId)) {
      setColumnVisibilityModel({ ...initialData, ...columnVisibilityModel });
    }
  }, [initialData]);

  if (isEmpty(columnVisibilityModel))
    return (
      <Skeleton
        sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        height={350}
        variant="rounded"
        animation="wave"
      />
    );
  if (status === StatusEnum.ERROR) {
    return <Error />;
  }
  return (
    <DataGrid
      apiRef={apiRef}
      paginationType="server"
      hideFooter
      sortingMode="server"
      columns={columns}
      rows={rows}
      componentsProps={{
        panel: {
          sx: {
            "& .MuiDataGrid-paper": {
              backgroundColor: "unset",
              background: theme.customPalette.others.layer_1,
            },
          },
        },
      }}
      components={{
        Toolbar: GridToolbar,
      }}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(model: GridColumnVisibilityModel) => {
        if (isEmpty(model)) {
          setColumnVisibilityModel(initialData);
        } else {
          setColumnVisibilityModel(model);
        }
      }}
      currentPage={0}
      pageSize={3}
    />
  );
};

export default KeyIndicatorsTable;
