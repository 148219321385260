export class ManageFilters {
  private initialState = {
    entry: {
      index: 0,
      type: "",
      key: "",
      value: "",
    },
    operator: {
      name: "",
      isInverse: false,
    },
    output: {
      index: 0,
      type: "",
      key: "",
      value: "",
    },
  };
  private _allOperator: Array<Dic<any>> = [];
  private _rules: Array<string> = ["most_frequent_key", "prop", "params"];
  private _variables: Array<string> = [];
  private _filterType: "exception" | "filter" = "filter";
  private _interpretableFilter: Dic<any> = {};

  constructor(
    operators: Array<Dic<any>>,
    variables: Array<string>,
    filterType: "exception" | "filter"
  ) {
    this._allOperator = operators;
    this._variables = variables;
    this._filterType = filterType;
    this._interpretableFilter = { ...this.initialState };
  }

  isArray(object: any) {
    return Array.isArray(object);
  }

  isObject(object: any) {
    return !Array.isArray(object) && typeof object === "object";
  }

  isNotObjectNorArray(object: any) {
    return !this.isArray(object) && !this.isObject(object);
  }

  isOperation(key: string) {
    return !!this._allOperator.find(
      (operator: Dic<any>) => operator.name === key
    );
  }

  isRule(key: string) {
    return !!this._rules.find((rule: string) => rule === key);
  }

  isVariable(key: string) {
    return !!this._variables.find((variable: string) => variable === key);
  }

  reformat() {
    let filter = { ...this._interpretableFilter };
    if (filter.entry.type === "params" && this._filterType === "filter") {
      filter = {
        ...filter,
        entry: {
          ...this._interpretableFilter.output,
        },
        output: {
          ...this._interpretableFilter.entry,
        },
      };
      this._interpretableFilter = {
        ...filter,
      };
    }
  }

  get() {
    this.reformat();
    return this._interpretableFilter;
  }

  getPosition() {
    return this._interpretableFilter.entry.type !== "" ? "output" : "entry";
  }

  getPropPosition() {
    if (this._interpretableFilter.entry.type !== "") {
      this._interpretableFilter = {
        ...this._interpretableFilter,
        output: { ...this._interpretableFilter.entry },
        entry: { ...this.initialState.entry },
      };
    }
    return "entry";
  }

  getPropData(rule: any) {
    this._interpretableFilter = {
      ...this._interpretableFilter,
      [this.getPropPosition()]: {
        index: 0,
        type: "prop",
        key: rule[0],
        value: "",
      },
    };
  }

  getParamsData(rule: any) {
    if (this._filterType === "filter")
      this._interpretableFilter = {
        ...this._interpretableFilter,
        output: {
          index: 0,
          type: "params",
          key: "",
          value: rule[0],
        },
      };
    else
      this._interpretableFilter = {
        ...this._interpretableFilter,
        entry: {
          index: 0,
          type: "params",
          key: "",
          value: rule[0],
        },
      };
  }

  mostFrequentKey(rule: any) {
    if (this._filterType === "filter")
      this._interpretableFilter = {
        ...this._interpretableFilter,
        output: {
          index: 0,
          type: "most_frequent_key",
          key: rule[0],
          value: rule[1].get_details_parameter[0],
        },
      };
    else
      this._interpretableFilter = {
        ...this._interpretableFilter,
        entry: {
          index: 0,
          type: "most_frequent_key",
          key: rule[0],
          value: rule[1].get_details_parameter[0],
        },
      };
  }

  interpretRule(rule: Array<any>) {
    const [ruleName, ruleOperation] = [...rule];
    switch (ruleName) {
      case "prop":
        this.getPropData(ruleOperation);
        break;
      case "params":
        this.getParamsData(ruleOperation);
        break;
      case "most_frequent_key":
        this.mostFrequentKey(ruleOperation);
        break;
    }
  }

  allItemsSameType(items: Array<any>) {
    if (items.length === 0) return true;
    const type = typeof items[0];
    if (type === "object") return false;
    let areSameType = true;
    items.forEach((item: any) => {
      if (typeof item !== type) areSameType = false;
    });
    return areSameType;
  }

  interpret(value: any, parent: any = null): any {
    if (this.isArray(value) && this.allItemsSameType(value)) {
      this._interpretableFilter = {
        ...this._interpretableFilter,
        output: {
          type: "constant",
          value,
          key: "",
        },
      };
    } else if (this.isArray(value) && !this.isRule(value[0])) {
      return value.forEach((filter: Dic<any>) => this.interpret(filter, value));
    } else if (this.isArray(value) && this.isRule(value[0])) {
      return this.interpretRule(value);
    } else if (this.isObject(value)) {
      const [key, v] = Object.entries(value);
      return this.interpret(key, v);
    } else if (this.isOperation(value)) {
      this._interpretableFilter = {
        ...this._interpretableFilter,
        operator: {
          name: value,
        },
      };
    } else {
      this._interpretableFilter = {
        ...this._interpretableFilter,
        output: {
          type: "constant",
          value,
          key: "",
        },
      };
    }
  }
}
