import { useMemo } from "react";
import { Box, Dialog as MUIDialog, useTheme } from "@mui/material";
import Overlay from "../Overlay";
import { IDialog } from "./types";

const Dialog: React.FC<IDialog> = ({
  children,
  open,
  onClose,
  PaperProps,
  ...rest
}) => {
  const theme = useTheme();

  const Backdrop = useMemo(
    () => () =>
      (
        <Box onClick={onClose}>
          <Overlay />
        </Box>
      ),
    []
  );

  return (
    <MUIDialog
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      PaperProps={{
        style: {
          background: theme.customPalette.grey.white,
          padding: 2,
          borderRadius: 5,
          border: "1px solid",
          borderColor: theme.customPalette.grey.white,
        },
        ...PaperProps,
      }}
      {...rest}
    >
      {children}
    </MUIDialog>
  );
};

Dialog.defaultProps = {
  PaperProps: {},
};

export default Dialog;
