import { RootState } from "@/services/redux/store";
import { useSelector } from "react-redux";
import usePersistedState from "./usePersistedState";

const useCatalogColumnsVisibilityModel = (cols: any[] = []) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const catalogColumns = useSelector(
    (state: RootState) => state.catalog.catalogColumns
  );
  const catalogColumnsFiltered = catalogColumns.filter(
    (elem: any) =>
      !["img_link", "image", "link", "price", "stroke_price"].includes(
        elem.name
      )
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = usePersistedState({
    key: `productsTableVisibilityModel-8 - ${siteId}`,
    initialValue: {
      id: false,
      image: true,
      title: true,
      "Last 30 days views": true,
      "Last 14 days views": false,
      "Last 7 days views": false,
      "Yesterday views": false,
      "Visibility score": true,
      "Visibility rank": false,
      "Last 30 days transactions": true,
      "Last 14 days transactions": false,
      "Last 7 days transactions": false,
      "Yesterday transactions": false,
      "Last 30 days revenue": true,
      "Last 14 days revenue": false,
      "Last 7 days revenue": false,
      "Yesterday revenue": false,
      "Popularity rank": false,
      "Popularity score": true,
      category: false,
      discountedPrice: true,
      salePrice: true,
      priceBeforeDiscount: true,
      Status: true,
      Availability: true,
      isDeleted: true,
      "Stock per size": false,
      ...catalogColumnsFiltered.reduce(
        (curr: Dic<boolean>, next: Dic<string>) => ({
          ...curr,
          [next.name]: true,
        }),
        {}
      ),
    },
  });
  return { columnVisibilityModel, setColumnVisibilityModel };
};

export default useCatalogColumnsVisibilityModel;
