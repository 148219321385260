import { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import useSetState from "./useSetState";
import { comparisonUtils } from "@/utils";
import { client } from "@/index";

const GET_RULES = gql`
  query collections($siteId: SiteID) {
    collections(siteId: $siteId, first: 1000, filters: "is_builtin = true") {
      edges {
        node {
          id
          uuid
          name
          settings
          description
        }
      }
    }
  }
`;

/**
 * Custom React hook for managing required parameters based on settings and rules.
 *
 * @param {Array<string>} allVariables - An array of all variables.
 * @param {Dic<any>} settings - Settings to determine required parameters.
 * @returns {{ requiredParameters: Array<string>, requiredParametersWithType: Dic<any> }} - An object containing required parameters and their types.
 */
const useRequiredParameters = (
  allVariables: Array<string>,
  settings: Dic<any>
) => {
  const siteId: ID = useSelector((state: RootState) => state.site.siteId);
  const [requiredParameters, setRequiredParameters] = useState<Array<string>>(
    []
  );
  const [requiredParametersWithType, setRequiredParametersWithType] =
    useSetState({});

  /**
   * Function to add filter variables to the required parameters.
   *
   * @returns {Array<string>} - An array of required parameters.
   */
  const addFilterVariables = () => {
    const copy = { ...settings };
    delete copy.required_parameters;
    if (_.isEmpty(copy)) return [];
    return [
      ...new Set([
        ...allVariables
          .filter(comparisonUtils.isNotNull)
          .filter((v: string) => JSON.stringify(copy).includes(v)),
      ]),
    ];
  };

  /**
   * Function to add rule variables to the required parameters.
   *
   * @param {Dic<any>} res - The result of the rules query.
   */
  const addRuleVariables = (res: Dic<any>) => {
    const rules = res.data.collections.edges.map((edge: Dic<any>) => ({
      ...edge.node,
    }));
    const usedRules = rules.filter((rule: Dic<any>) =>
      JSON.stringify(settings).includes(rule.uuid)
    );
    const usedRulesParameters = usedRules
      .map((rule: Dic<any>) => {
        const parameters = rule.settings.required_parameters ?? {};
        return [...Object.keys(parameters)];
      })
      .flat();
    const newRequiredParams = [
      ...new Set([...usedRulesParameters, ...addFilterVariables()]),
    ];
    setRequiredParameters(newRequiredParams);
    setRequiredParametersWithType(
      newRequiredParams
        .map((param: string) => ({
          variable: param,
          type: settings?.required_input_variables?.[param],
        }))
        .reduce(function (m: Dic<any>, v: Dic<any>) {
          m[v.variable] = v.type;
          return m;
        }, {})
    );
  };

  const getRules = () => {
    client
      .query({ query: GET_RULES, variables: { siteId } })
      .then(addRuleVariables);
  };

  useEffect(() => {
    if (siteId) getRules();
  }, [settings, siteId, JSON.stringify(allVariables)]);

  return { requiredParameters, requiredParametersWithType };
};

export default useRequiredParameters;
