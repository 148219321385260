import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import { SET_BREADCRUMB } from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  paths: potionsLocalStorage.get("breadcrumb")?.paths ?? [],
  names: potionsLocalStorage.get("breadcrumb")?.paths ?? [],
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<string>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SET_BREADCRUMB:
        draft.paths = action.payload.paths;
        draft.names = action.payload.paths;
        break;
      default:
        break;
    }
  });
}
