import dayjs, { Dayjs } from "dayjs";

export const getStartDate = (deployedAt: Dayjs) => {
  const startDate = dayjs(deployedAt).isBefore(dayjs().subtract(32, "days"))
    ? dayjs().subtract(32, "days").format("YYYY-MM-DD")
    : dayjs(deployedAt).format("YYYY-MM-DD");
  return startDate;
};

export const getEndDate = () =>
  dayjs().subtract(2, "days").add(1, "day").format("YYYY-MM-DD");

export const compareExperiences = (a: Dic<any>, b: Dic<any>) => {
  if (!a.isActive && !b.isActive) {
    return 0; // Les deux n'ont pas de date, pas de changement d'ordre
  }
  if (!a.isActive) {
    return 1; // a n'a pas de date, b passe avant
  }
  if (!b.isActive) {
    return -1; // b n'a pas de date, a passe avant
  }
  return 0;
};
