import React from "react";
import { useTheme } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import { IBtnLink } from "./types";

const ButtonLink: React.FC<IBtnLink> = ({ children, customStyle, ...rest }) => {
  const theme = useTheme();

  return (
    <MuiButton
      variant="text"
      disableRipple
      sx={{
        textTransform: "unset",
        borderRadius: 0,
        color: theme.customPalette.bluePurple.dark,
        padding: 0,
        paddingBottom: "1px",
        fontWeight: 400,
        fontSize: "0.775rem",
        "&:hover": {
          backgroundColor: "transparent",
          textDecoration: "underline",
        },

        ...customStyle,
      }}
      {...rest}
    >
      {children}
    </MuiButton>
  );
};

ButtonLink.defaultProps = {
  customStyle: {},
};

export default ButtonLink;
