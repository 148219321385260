import { Autocomplete } from "@mui/material";
import { IGraphQLSelector } from "./types";

const GraphQLSelector: React.FC<IGraphQLSelector> = ({
  options,
  setIsOpen,
  ...props
}) => {
  return (
    <Autocomplete
      options={options}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...props}
    />
  );
};

export default GraphQLSelector;
