import { gql } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store";
import { client } from "../../../index";
import { setCatalogColumns, setCatalogId } from "@actions";
import { arrayUtils } from "@utils";
import { GET_CATALOG_ID } from "./requests";
import { IHasPages } from "@/utils/types/component";

const CatalogWrapper: React.FC<IHasPages> = ({ children }) => {
  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId)
      client
        .query({
          query: GET_CATALOG_ID,
          variables: { id: siteId },
          fetchPolicy: "cache-first",
        })
        .then((res: any) => {
          dispatch(
            setCatalogId(
              parseInt(
                res?.data?.site?.catalogs?.edges?.[0]?.node?.id ?? "0",
                10
              )
            )
          );

          const columns =
            res?.data?.site?.catalogs?.edges?.[0]?.node?.settings
              ?.resources_catalog_columns;
          const types =
            res?.data?.site?.catalogs?.edges?.[0]?.node?.settings
              ?.resources_catalog_columns_types;
          const catalogColumns =
            columns?.map((column: string) => ({
              name: column,
              type: types?.[column],
            })) ?? [];
          dispatch(
            setCatalogColumns(
              [
                ...catalogColumns,
                { name: "id", type: "string" },
                { name: "Stock per size" },
              ].sort(arrayUtils.dynamicSort("name"))
            )
          );
        });
  }, [catalogId, siteId]);

  return <>{children}</>;
};

export default CatalogWrapper;
