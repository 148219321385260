import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Layer1Box, Stack } from "@includes";
import { Skeleton } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import KeyIndicatorsHeader from "../KeyIndicatorsHeader";
import KeyIndicatorsTable from "../KeyIndicatorsTable";
import { formatDataToTable } from "./formatters";
import { GET_AB_TEST_KEY_INDICATORS } from "./requests";
import { IKeyIndicators, ResponseAbTestKeyIndicatorsDataType } from "./types";

const KeyIndicators: React.FC<IKeyIndicators> = ({ filters, audience }) => {
  const { t }: i18translateType = useTranslation();
  const { startDate, endDate, abExperimentId, devices } = filters;
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<any[]>([]);
  const apiRef = useGridApiRef();
  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_AB_TEST_KEY_INDICATORS,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          segmentA: `potions;ab_experiment_${abExperimentId};A;all`,
          segmentB: `potions;ab_experiment_${abExperimentId};B;all`,
          devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseAbTestKeyIndicatorsDataType) => {
        setData(formatDataToTable(res, audience, t));
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(abExperimentId) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices)
    )
      getData();
  }, [startDate, endDate, devices, abExperimentId]);

  return (
    <Layer1Box
      sx={{
        maxWidth: "100%",
        mt: 3,
      }}
    >
      <Stack spacing={1} sx={{ width: "100%" }}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          {status === StatusEnum.LOADING ? (
            <Skeleton
              sx={{
                bgcolor: (theme) => theme.customPalette.others.layer_2,
              }}
              height={350}
              variant="rounded"
              animation="wave"
            />
          ) : (
            <>
              <KeyIndicatorsHeader apiRef={apiRef} filters={filters} />
              <KeyIndicatorsTable
                rows={data}
                abExperimentId={filters?.abExperimentId}
                apiRef={apiRef}
                status={status}
              />
            </>
          )}
        </Box>
      </Stack>
    </Layer1Box>
  );
};

export default KeyIndicators;
