import { useViewport } from "@/hooks";
import { closeDrawer } from "@actions";
import { Box, Drawer } from "@includes";
import { RootState } from "@store";
import { useDispatch, useSelector } from "react-redux";
import { drawerOpenWith, drawerWidth } from "../../enums";
import SideMenu from "../SideMenu";
import { NavbarProps } from "./types";

const Navbar = ({
  openDesktopNavbar = false,
  setOpenDesktopNavbar = () => {},
}: NavbarProps) => {
  const { isMobile } = useViewport();
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const open: any = useSelector((state: RootState) => state.drawer.open);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    dispatch(closeDrawer());
  };

  return (
    <Box
      component="nav"
      sx={{
        zIndex: isMobile ? 999999 : 1299,
        width: { sm: openDesktopNavbar ? drawerOpenWith : drawerWidth },
        flexShrink: { sm: 0 },
        height: "100%",
        transition: "all 200ms",
      }}
      id="side-menu"
      aria-label="mailbox folders"
    >
      {/* ---------- Mobile ------------ */}
      <Drawer
        id="drawer"
        container={container}
        variant="temporary"
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          zIndex: 9999999,
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "80vw",
          },
        }}
      >
        <SideMenu />
      </Drawer>
      {/* ---------- Desktop ------------ */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          height: 1,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: openDesktopNavbar ? drawerOpenWith : drawerWidth,
            top: "auto",
            overflow: "visible",
            transition: "all 200ms",
          },
        }}
        open
      >
        <SideMenu
          openDesktopNavbar={openDesktopNavbar}
          setOpenDesktopNavbar={setOpenDesktopNavbar}
        />
      </Drawer>
    </Box>
  );
};

export default Navbar;
