interface IPreviewIcon {
  color: string;
}

const PreviewIcon: React.FC<IPreviewIcon> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <mask
      id="mask0_1387_48181"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#FAF9FA" />
    </mask>
    <g mask="url(#mask0_1387_48181)">
      <path
        d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM5 19H19V7H5V19ZM12 17C10.6333 17 9.41267 16.629 8.338 15.887C7.26267 15.1457 6.48333 14.1833 6 13C6.48333 11.8167 7.26267 10.8543 8.338 10.113C9.41267 9.371 10.6333 9 12 9C13.3667 9 14.5877 9.371 15.663 10.113C16.7377 10.8543 17.5167 11.8167 18 13C17.5167 14.1833 16.7377 15.1457 15.663 15.887C14.5877 16.629 13.3667 17 12 17ZM12 15.5C12.9333 15.5 13.7833 15.279 14.55 14.837C15.3167 14.3957 15.9167 13.7833 16.35 13C15.9167 12.2167 15.3167 11.604 14.55 11.162C13.7833 10.7207 12.9333 10.5 12 10.5C11.0667 10.5 10.2167 10.7207 9.45 11.162C8.68333 11.604 8.08333 12.2167 7.65 13C8.08333 13.7833 8.68333 14.3957 9.45 14.837C10.2167 15.279 11.0667 15.5 12 15.5ZM12 14.5C12.4167 14.5 12.7707 14.354 13.062 14.062C13.354 13.7707 13.5 13.4167 13.5 13C13.5 12.5833 13.354 12.2293 13.062 11.938C12.7707 11.646 12.4167 11.5 12 11.5C11.5833 11.5 11.2293 11.646 10.938 11.938C10.646 12.2293 10.5 12.5833 10.5 13C10.5 13.4167 10.646 13.7707 10.938 14.062C11.2293 14.354 11.5833 14.5 12 14.5Z"
        fill={color}
      />
    </g>
  </svg>
);

export default PreviewIcon;
