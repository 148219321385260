import Close from "@/assets/icons/Close";
import { useDebounce, useViewport } from "@/hooks";
import { Button, Divider, StyledTextField, Text } from "@/includes";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { arrayUtils } from "@/utils";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CREATE_COLLECTION,
  CREATE_EXPERIENCE,
  GET_COMPANIES,
  GET_EXPERIENCE,
} from "./requests";
import { IDuplicateOnOtherSiteDialog } from "./types";

const DuplicateInOtherSiteDialog = ({
  open,
  handleClose,
  experienceId,
  experienceName,
}: IDuplicateOnOtherSiteDialog) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useViewport();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [siteSelected, setSiteSelected] = useState<Dic<any>>({});
  const [inputValue, setInputValue] = useState<string>("");
  const [accounts, setAccounts] = useState<Array<Dic<any>>>([]);
  const [options, setOptions] = useState<Array<Dic<any>>>([]);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(true);

  const handleSearch = (term: string) => {
    setLoadingOptions(true);
    client
      .query({
        query: GET_COMPANIES,
        variables: {
          search: term,
        },
      })
      .then((res) => {
        const accounts = res.data?.companies?.edges?.map(
          (item: Dic<Dic<string | any>>) => ({
            ...item.node,
            sites: item?.node?.sites?.edges?.map(
              (subItem: Dic<Dic<string>>) => subItem.node
            ),
          })
        );
        setAccounts(accounts);
        setOptions([
          ...accounts
            ?.map((c: any) =>
              c?.sites?.map((s: any) => ({
                ...s,
                accountId: c?.id,
                accountName: c?.name?.toLowerCase(),
                accountTheme: c?.theme,
              }))
            )
            ?.flat(),
        ]);
        setLoadingOptions(false);
      });
  };

  useEffect(() => {
    if (isEmpty(siteSelected) && !isEmpty(options)) setSiteSelected(options[0]);
  }, [options]);

  useEffect(() => {
    handleSearch("");
  }, []);

  useDebounce(
    () => {
      handleSearch(inputValue);
    },
    [inputValue],
    600
  );

  const onClose = () => {
    setSiteSelected({});
    handleClose();
  };

  const handleDuplicate = () => {
    client
      .query({
        query: GET_EXPERIENCE,
        variables: {
          experienceId,
        },
      })
      .then((res: Dic<any>) => {
        const experience = res?.data?.experience;
        const { __typename, ...collectionToCreate } = experience.collection;
        if (!!experience) {
          client
            .mutate({
              mutation: CREATE_COLLECTION,
              variables: {
                id: siteSelected?.id,
                collection: collectionToCreate,
              },
            })
            .then((res: Dic<any>) => {
              const { __typename, collection, ...experienceToCreate } =
                experience;
              const experienceInput = {
                ...experienceToCreate,
                collectionId: res?.data?.createCollection?.id,
              };
              client
                .mutate({
                  mutation: CREATE_EXPERIENCE,
                  variables: { siteId: siteSelected?.id, experienceInput },
                })
                .then(() => onClose());
            });
        }
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "min(100vw, 550px)",
          maxWidth: "min(100vw, 550px)",
        },
      }}
    >
      <Stack spacing={2} sx={{ padding: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Text variant="h3">
            {t("duplicate")} {experienceName}
          </Text>
          <IconButton onClick={onClose}>
            <Close color={theme.customPalette.grey.default} />
          </IconButton>
        </Stack>
        <DialogContent sx={{ padding: 0 }}>
          <Text customStyle={{ mb: 2 }}>
            {t("select_site_to_duplicate_experience")}
          </Text>
          <Autocomplete
            autoHighlight
            onChange={(e: React.SyntheticEvent, value: any) => {
              setSiteSelected(value);
            }}
            groupBy={(option: any) => option?.accountId}
            value={siteSelected}
            loading={loadingOptions}
            disableClearable
            id="site-duplicate-selector"
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={options.sort(arrayUtils.dynamicSort("name"))}
            getOptionLabel={(option: any) => option?.name ?? ""}
            renderInput={(params: any) => (
              <StyledTextField
                {...params}
                label={t("site")}
                InputProps={{
                  ...params.InputProps,
                  onMouseDown: (e) => {
                    e.stopPropagation(); // Empêche la propagation du clic
                  },
                  endAdornment: (
                    <>
                      {loadingOptions ? <CircularProgress size={12} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderGroup={(params) => {
              const account = accounts?.find(
                (c: any) => c?.id === params?.group
              );
              const shouldShowAccount =
                account?.sites?.length > 1 ||
                (account?.sites?.length === 1 &&
                  account?.sites?.[0]?.name !== account?.name);
              return (
                <Box key={params.key} p={1}>
                  {!shouldShowAccount ? (
                    <></>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        padding: "2px 0",
                      }}
                    >
                      <Box
                        sx={{ width: 16, height: 16 }}
                        component="img"
                        alt=""
                        src={`/${theme.palette.mode}/others/account.svg`}
                      />
                      <Text variant="body2">
                        {
                          accounts?.find((c: any) => c?.id === params?.group)
                            ?.name
                        }
                      </Text>
                    </Stack>
                  )}

                  <Box>{params.children}</Box>
                  <Divider />
                </Box>
              );
            }}
          />
        </DialogContent>
        <DialogActions sx={{ gap: 3 }}>
          <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
            spacing={1}
          >
            <Button
              btnVariant="outline"
              id="cancelBtn"
              onClick={onClose}
              customStyle={{ width: isMobile ? "100%" : "50%" }}
            >
              {t("cancel")}
            </Button>
            <Button
              customStyle={{ width: isMobile ? "100%" : "50%" }}
              onClick={handleDuplicate}
              id="duplicate"
            >
              {t("duplicate")}
            </Button>
          </Stack>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default DuplicateInOtherSiteDialog;
