import { AutoComplete, SelectPaper, StyledTextField } from "@/includes";
import { doMutation } from "@/utils/helpers/graphql/Mutation";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRequestForEntities } from "./requests";
import { AutomaticSelectProps } from "./types";
import { ENTITIES_TYPE_REQUEST_WITH_SITE_ID } from "./enums";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";

const AutomaticAutocomplete = ({
  entity,
  setEntity,
  value,
  label,
  type,
  entityId,
}: AutomaticSelectProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [collection, setCollection] = useState<Array<any>>([]);
  const handleChangeEntities = (res: Dic<any>) => {
    setCollection(
      res.data[type].edges.map((edge: Dic<any>) => ({
        ...edge.node,
      }))
    );
    setTotalCount(res.data[type].totalCount);
  };
  const getAllEntities = (first = 10) => {
    doMutation(
      null,
      getRequestForEntities(type),
      ENTITIES_TYPE_REQUEST_WITH_SITE_ID.includes(type)
        ? { siteId: entityId, first }
        : { first },
      setStatus,
      handleChangeEntities
    );
    setStatus(StatusEnum.LOADING);
  };
  useEffect(() => {
    getAllEntities();
  }, []);

  useEffect(() => {
    if (collection.length < totalCount) {
      getAllEntities(totalCount + 5);
    }
  }, [totalCount]);
  return (
    <AutoComplete
      loading={status === StatusEnum.LOADING}
      value={collection.find((elem) => elem.id === entity[value]) ?? null}
      sx={{ flex: 1, mb: 2 }}
      options={collection}
      getOptionLabel={(option: any) => option.name ?? option.type}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={label}
          name={value}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {status === StatusEnum.LOADING ? (
                  <CircularProgress size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(e: React.SyntheticEvent, valueAutocomplete: any | null) => {
        setEntity({ ...entity, [value]: valueAutocomplete?.id ?? null });
      }}
      PaperComponent={SelectPaper}
    />
  );
};

export default AutomaticAutocomplete;
