import { useEffect, useState } from "react";
import { fromEvent, debounce, timer } from "rxjs";
import { useTheme } from "@mui/material/styles";

const useViewport = () => {
  const theme = useTheme();
  const [width, setWidth] = useState(window.outerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const mediumWidth = theme.breakpoints.values.md;
  const [isMobile, setIsMobile] = useState(!(window.outerWidth >= mediumWidth));
  const [screenCategory, setScreenCategory] = useState<string>("xs");

  const changeCategory = () => {
    if (window.outerWidth > 600) {
      setScreenCategory("sm");
    }
    if (window.outerWidth > 900) {
      setScreenCategory("md");
    }
    if (window.outerWidth > 1200) {
      setScreenCategory("lg");
    }
    if (window.outerWidth > 1536) {
      setScreenCategory("xl");
    }
  };

  useEffect(() => {
    const resize$ = fromEvent(window, "resize")
      .pipe(debounce(() => timer(250)))
      .subscribe(() => {
        setTimeout(() => {
          setWidth(window.outerWidth);
          setHeight(window.innerHeight);
          setIsMobile(window.outerWidth < 900);
          changeCategory();
        }, 500);
      });

    const loaded$ = fromEvent(window, "DOMContentLoaded")
      .pipe(debounce(() => timer(250)))
      .subscribe(() => {
        setTimeout(() => {
          setWidth(window.outerWidth);
          setHeight(window.innerHeight);
          setIsMobile(window.outerWidth < 900);
          changeCategory();
        }, 500);
      });

    changeCategory();

    return () => {
      resize$.unsubscribe();
      loaded$.unsubscribe();
    };
  }, []);

  return { screenWidth: width, screenHeight: height, isMobile, screenCategory };
};

export default useViewport;
