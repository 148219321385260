import { Box, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterUtils, getMemoKeys } from "@/utils";
import { Button, Text } from "@includes";
import LogicalOperator from "../LogicalOperator";
import { AddIcon } from "@/assets/icons";
import { IFilters } from "./types";
import useFilters from "../../hooks/useFilters";
import FilterClause from "../FilterClause";
import Filter from "../Filter";

const Filters: React.FC<IFilters> = ({
  filters,
  variables,
  updateFilters,
  requiredParameters,
  filterType,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const applyedFilters =
    filterType === "filter"
      ? filters?.[0]?.filter?.[0] ?? {}
      : filters?.[0] ?? {};

  const logicalOperator: string = Object.keys(applyedFilters)[0]; // and_all OR or_all
  const oppositeOperator = logicalOperator === "and_all" ? "or_all" : "and_all";

  const { interpret } = useFilters(variables, filterType);

  const [interpretableFilters, setInterpretableFilters] = useState([]);
  useEffect(() => {
    if (logicalOperator)
      setInterpretableFilters(
        applyedFilters[logicalOperator].map((filter: Dic<any>) => {
          if (filter[oppositeOperator]) {
            return [...filter[oppositeOperator].map(interpret)];
          }
          return interpret(filter);
        })
      );
    else setInterpretableFilters([]);
  }, [filters]);

  const handleChange = (index: number) => (newFilter: Dic<any>) => {
    const newFilters = [...applyedFilters[logicalOperator]];
    newFilters[index] = newFilter;
    updateFilters(newFilters, logicalOperator);
  };

  const handleChangeInnerFilter =
    (innerFilterIndex: number, filterIndex: number) =>
    (newFilter: Dic<any>) => {
      const newFilters = [...applyedFilters[logicalOperator]];

      const currentInnerFilters =
        applyedFilters[logicalOperator][filterIndex][oppositeOperator];
      currentInnerFilters[innerFilterIndex] = newFilter;
      newFilters[filterIndex] = { [oppositeOperator]: currentInnerFilters };
      updateFilters(newFilters, logicalOperator);
    };

  const addFilter = () => {
    updateFilters(
      [
        ...(applyedFilters?.[logicalOperator] ?? []),
        filterUtils.getDefaultFilter(),
      ],
      logicalOperator
    );
  };

  const textBetweenInnerFilter = {
    or_all: t("or"),
    and_all: t("and"),
  };

  const handleChangeLogicalOperators = () => {
    updateFilters(
      JSON.parse(
        JSON.stringify([...applyedFilters[logicalOperator]]).replaceAll(
          oppositeOperator,
          logicalOperator
        )
      ),
      oppositeOperator
    );
  };

  const handleAddLogicalOperator = (index: number) => () => {
    const newFilters = [...applyedFilters[logicalOperator]];
    const currentFilter = newFilters[index];
    newFilters[index] = {
      [oppositeOperator]: [currentFilter, filterUtils.getDefaultFilter()],
    };
    updateFilters(newFilters, logicalOperator);
  };

  const handleRemove = (index: number) => () => {
    updateFilters(
      applyedFilters[logicalOperator].filter(
        (_: any, _index: number) => index !== _index
      ),
      logicalOperator
    );
  };

  const handleRemoveInnerFilter =
    (innerIndex: number, filterIndex: number) => () => {
      const newFilters = [...applyedFilters[logicalOperator]];
      const currentInnerFilters = newFilters[filterIndex][oppositeOperator];
      const filterToKeep = currentInnerFilters[(innerIndex + 1) % 2];
      newFilters[filterIndex] = filterToKeep;
      updateFilters(newFilters, logicalOperator);
    };

  return (
    <Stack sx={{ ml: 2 }}>
      <Stack spacing={2}>
        {interpretableFilters.map((interpretableFilter: any, index: number) => {
          const showLogicalOperator = index > 0;
          if (!Array.isArray(interpretableFilter))
            return (
              <Stack
                direction="row"
                spacing={1}
                key={`not-array-interpretable-filter-${index}-${
                  interpretableFilter?.operator?.name ?? "none"
                }`}
              >
                <Box
                  sx={{
                    visibility: showLogicalOperator ? "visible" : "hidden",
                  }}
                >
                  <LogicalOperator
                    logicalOperator={logicalOperator}
                    handleChangeLogicalOperators={handleChangeLogicalOperators}
                  />
                </Box>
                <Stack
                  spacing={1}
                  sx={{
                    borderLeft: `1px solid ${theme.customPalette.bluePurple.dark}`,
                    p: 1,
                  }}
                >
                  {filterType === "filter" ? (
                    <Filter
                      filter={interpretableFilter}
                      handleChange={handleChange(index)}
                      requiredParameters={requiredParameters}
                      variables={variables}
                      remove={handleRemove(index)}
                      filterType={filterType}
                    />
                  ) : (
                    <FilterClause
                      filter={interpretableFilter}
                      handleChange={handleChange(index)}
                      requiredParameters={requiredParameters}
                      variables={variables}
                      remove={handleRemove(index)}
                      filterType={filterType}
                      canRemove={interpretableFilters.length > 1}
                    />
                  )}
                  {filterType === "filter" && (
                    <Box>
                      <Button
                        startIcon={<AddIcon />}
                        customStyle={{ padding: "5px 7px" }}
                        onClick={handleAddLogicalOperator(index)}
                      >
                        {textBetweenInnerFilter[oppositeOperator]}
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Stack>
            );
          return (
            <Stack
              direction="row"
              spacing={1}
              key={`array-interpretable-filter-${index}`}
            >
              <Box
                sx={{
                  visibility: showLogicalOperator ? "visible" : "hidden",
                }}
              >
                <LogicalOperator
                  logicalOperator={logicalOperator}
                  handleChangeLogicalOperators={handleChangeLogicalOperators}
                />
              </Box>
              <Stack
                sx={{
                  borderLeft: `1px solid ${theme.customPalette.bluePurple.dark}`,
                  p: 1,
                }}
              >
                {interpretableFilter.map(
                  (innerFilter: Dic<any>, _index: number) => (
                    <Stack
                      direction="row"
                      alignItems="end"
                      spacing={1}
                      key={`inner-filter-${_index}`}
                    >
                      <Text
                        customStyle={{
                          visibility: _index > 0 ? "visible" : "hidden",
                          textTransform: "capitalize",
                        }}
                      >
                        {textBetweenInnerFilter[oppositeOperator]}
                      </Text>
                      <Filter
                        filter={innerFilter}
                        handleChange={handleChangeInnerFilter(_index, index)}
                        requiredParameters={requiredParameters}
                        variables={variables}
                        remove={handleRemoveInnerFilter(_index, index)}
                        filterType={filterType}
                      />
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          );
        })}
        <Box>
          <Button
            customStyle={{ padding: "5px 7px" }}
            onClick={addFilter}
            startIcon={<AddIcon />}
            btnVariant="outline"
          >
            {t(`add_${filterType}`)}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Filters;
