import { CircularProgress, InputAdornment, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import StyledTextField from "../../_Atoms/StyledTextField";
import { ISearchBar } from "./types";

const SearchBar: React.FC<ISearchBar> = ({
  text,
  handleChange,
  label,
  customStyle,
  loading,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <StyledTextField
      {...rest}
      value={text}
      label={label}
      name={label}
      onChange={(e: any) => handleChange(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box
              component="img"
              src={`/${theme.customPalette.mode}/actions/search.svg`}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <Box>{loading ? <CircularProgress size={20} /> : null}</Box>
        ),
      }}
      sx={{ ...customStyle }}
    />
  );
};

SearchBar.defaultProps = {
  customStyle: {},
  loading: false,
};

export default SearchBar;
