import Main from "./components/Main";
import ExperienceProvider from "./hooks/useExperienceAnalytics";

const Experience = () => {
  return (
    <ExperienceProvider>
      <Main />
    </ExperienceProvider>
  );
};

export default Experience;
