import {
  useLazyQuery,
  WatchQueryFetchPolicy,
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/react-hooks";
import { useEffect } from "react";

const useGraphQlQuery = (
  Query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  variables: Dic<any>,
  fetchPolicy: WatchQueryFetchPolicy | undefined = "cache-and-network"
) => {
  const [getDatas, { loading, data, error }] = useLazyQuery(Query, {
    variables,
    fetchPolicy,
    errorPolicy: "all",
  });

  useEffect(() => {
    getDatas();
  }, []);

  return { loading, data, error };
};

export default useGraphQlQuery;
