import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }: any) => ({
  "& .MuiInputBase-root": {
    backgroundColor: "transparent",
    "&:hover": {
      background: theme.customPalette.grey.lighter,
    },
    "&.Mui-disabled:hover": {
      background: "inherit",
    },
  },
  "& label.Mui-focused": {
    color: theme.customPalette.grey.default,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.customPalette.others.background,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.customPalette.grey.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.customPalette.bluePurple.light,
      borderWidth: 1,
    },
  },
}));
