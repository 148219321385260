import { Box, useTheme } from "@mui/material";

const TitleLine = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: 2,
        height: 3,
        width: "50%",
        background: theme.customPalette.bluePurple.light,
      }}
    />
  );
};

export default TitleLine;
