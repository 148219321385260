import { Text } from "@/includes";
import { formatUtils } from "@/utils";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip, Typography } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid";
import { formatColumn } from "./formatters";
import { COLUMN_DATA_TYPE_ENUM } from "./enums";
import { getTraduction } from "@/utils/helpers/traductions";

function getColumns(t: (text: string) => string, currency: string = "€"): any {
  return [
    {
      field: "segment",
      headerName: "",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {getTraduction(t, params.row.segment)}
          </Typography>
        );
      },
    },
    {
      field: "visitors",
      headerName: t("cumulated_exposed"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.visitors,
          t,
          COLUMN_DATA_TYPE_ENUM.INT,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.visitors,
              t,
              COLUMN_DATA_TYPE_ENUM.INT,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "revenues",
      headerName: t("revenues"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.revenues,
          t,
          COLUMN_DATA_TYPE_ENUM.CURRENCY,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.revenues,
              t,
              COLUMN_DATA_TYPE_ENUM.CURRENCY,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "transactions",
      headerName: t("transactions"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.transactions,
          t,
          COLUMN_DATA_TYPE_ENUM.INT,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.transactions,
              t,
              COLUMN_DATA_TYPE_ENUM.INT,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "revenue_per_user",
      headerName: t("revenue_per_exposed"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      renderHeader: (params: GridRowParams) => (
        <Stack direction="row" alignItems="center">
          {t("revenue_per_exposed")}
          <Tooltip title={`${t("revenues")} / ${t("cumulated_exposed")}`}>
            <HelpOutline sx={{ ml: 0.5 }} />
          </Tooltip>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.revenue_per_user,
          t,
          COLUMN_DATA_TYPE_ENUM.CURRENCY,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.revenue_per_user,
              t,
              COLUMN_DATA_TYPE_ENUM.CURRENCY,
              currency
            )}
          </Typography>
        );
      },
    },

    {
      field: "transaction_rate",
      headerName: t("conversion_rate"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      renderHeader: (params: GridRowParams) => (
        <Stack direction="row" alignItems="center">
          {t("conversion_rate")}
          <Tooltip title={`${t("transactions")} / ${t("cumulated_exposed")}`}>
            <HelpOutline sx={{ ml: 0.5 }} />
          </Tooltip>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.transaction_rate,
          t,
          COLUMN_DATA_TYPE_ENUM.PERCENT,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.transaction_rate,
              t,
              COLUMN_DATA_TYPE_ENUM.PERCENT,
              currency
            )}
          </Typography>
        );
      },
    },
    {
      field: "average_cart",
      headerName: t("revenues_per_transaction"),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      renderHeader: (params: GridRowParams) => (
        <Stack direction="row" alignItems="center">
          {t("revenues_per_transaction")}
          <Tooltip title={`${t("revenues")} / ${t("transactions")}`}>
            <HelpOutline sx={{ ml: 0.5 }} />
          </Tooltip>
        </Stack>
      ),
      valueGetter: (params: GridRowParams) =>
        formatColumn(
          params,
          params.row.average_cart,
          t,
          COLUMN_DATA_TYPE_ENUM.CURRENCY,
          currency
        ),
      renderCell: (params: GridRowParams) => {
        return (
          <Typography variant="body1">
            {formatColumn(
              params,
              params.row.average_cart,
              t,
              COLUMN_DATA_TYPE_ENUM.CURRENCY,
              currency
            )}
          </Typography>
        );
      },
    },
  ];
}

export default getColumns;
