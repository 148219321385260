import React, { useEffect, useState } from "react";
import { Box, Skeleton, Stack, alpha, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Tag, Text, Tooltip } from "@/includes";
import { IProductImage } from "./types";
import InventoryIcon from "@mui/icons-material/Inventory";
import InfoIcon from "@mui/icons-material/Info";

const ProductImage: React.FC<IProductImage> = ({
  src,
  inactive,
  available,
  ...rest
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [isCorrectImage, setIsCorrectImage] = useState<boolean>(false);

  const checkImageExists = (src: string) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsCorrectImage(true);
      setLoading(false);
    };
    img.onerror = () => {
      setIsCorrectImage(false);
      setLoading(false);
    };
  };

  const getImage = () => {
    fetch(src, {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.status == 404) {
          setIsCorrectImage(false);
        } else {
          setIsCorrectImage(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsCorrectImage(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (src !== "no-image") getImage();
    else setLoading(false);
  }, [src]);

  checkImageExists(src);

  if (loading) {
    return (
      <Skeleton
        width={100}
        height={150}
        animation="wave"
        variant="rounded"
        sx={{ bgcolor: theme.customPalette.bluePurple.light }}
      />
    );
  }

  return (
    <>
      {isCorrectImage ? (
        <Box
          sx={{
            borderRadius: 3,

            position: "relative",
          }}
        >
          <Box
            component="img"
            src={src}
            loading="lazy"
            sx={{
              width: "100%",
              minHeight: 150,
              maxHeight: 150,
              objectFit: "contain",
            }}
            {...rest}
          />
          {(inactive || !available) && (
            <Box
              sx={{
                backgroundColor: alpha(theme.customPalette.others.layer_2, 0.5),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              {inactive && (
                <Tag tagVariant="error" customStyle={{ alignItems: "center" }}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Tooltip title={t("inactive_product")}>
                      <InfoIcon sx={{ fontSize: "16px" }} />
                    </Tooltip>
                    <Text
                      customStyle={{ color: theme.customPalette.error.main }}
                    >
                      {t("inactive")}
                    </Text>
                  </Stack>
                </Tag>
              )}
              {!inactive && !available && (
                <Tag
                  tagVariant="warning"
                  customStyle={{ alignItems: "center" }}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <InventoryIcon sx={{ fontSize: "16px" }} />
                    <Text
                      customStyle={{ color: theme.customPalette.warning.main }}
                    >
                      {t("out_of_stock")}
                    </Text>
                  </Stack>
                </Tag>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            width: 1,
            height: 150,
            borderRadius: 3,
            background: theme.customPalette.bluePurple.white,
            display: "grid",
            placeItems: "center",
          }}
        >
          <Text>{t("no_image")}</Text>
        </Box>
      )}
    </>
  );
};

export default ProductImage;
