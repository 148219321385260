import {
  Box,
  Stack,
  useTheme,
  Grid,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { Text } from "@includes";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CopyIcon } from "@/assets/icons";
import { ICopyText } from "./types";

const CopyText: React.FC<ICopyText> = ({ text, noWrap, sx }) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const [isCopied, setIsCopied] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3500);
    });
  };

  return (
    <Grid
      container
      sx={{
        mt: 2,
        borderRadius: 3,
        height: 40,
        border: "2px solid",
        borderColor: theme.customPalette.others.background,
        overflow: "hidden",
        ...sx,
      }}
    >
      <Grid
        item
        xs={9}
        md={9}
        lg={8}
        sx={{ background: theme.customPalette.others.layer_2, height: "100%" }}
      >
        <ClickAwayListener onClickAway={() => setIsCopied(false)}>
          <Stack
            alignItems="start"
            justifyContent="center"
            sx={{
              paddingLeft: "8px",
              paddingRight: "8px",
              height: "100%",
              width: "100%",
              background: theme.customPalette.others.layer_2,
            }}
          >
            <Tooltip title={t("text_copied")} open={isCopied}>
              <div>
                <Text noWrap={!!noWrap} variant="body2">
                  {text}
                </Text>
              </div>
            </Tooltip>
          </Stack>
        </ClickAwayListener>
      </Grid>
      <Grid item xs={3} md={3} lg={4}>
        <Box
          sx={{
            height: "100%",
            background: theme.customPalette.others.layer_1,
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
            padding: "0 12px",
            justifyContent: "center",
            borderLeft: `1px solid ${theme.customPalette.others.background}`,
          }}
          onClick={copy}
        >
          <CopyIcon color={theme.customPalette.grey.default} />
          <Text customStyle={{ width: "fit-content" }}>{t("copy")}</Text>
        </Box>
      </Grid>
    </Grid>
  );
};

CopyText.defaultProps = {
  noWrap: false,
};

export default CopyText;
