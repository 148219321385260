import * as React from "react";
import { Typography as MuiTypography, SxProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IText } from "./types";
import { getColor } from "./helpers";

const Text: React.FC<IText> = ({
  type,
  children,
  customStyle,
  variant,
  centerText,
  noWrap,
  isTableHeader,
  textType,
}) => {
  const theme = useTheme();

  return (
    <MuiTypography
      data-testid="textTestId"
      noWrap={noWrap}
      variant={variant}
      sx={{
        textAlign: centerText ? "center" : "start",
        ...getColor(textType)(theme),
        ...customStyle,
      }}
    >
      {children}
    </MuiTypography>
  );
};

Text.defaultProps = {
  type: "primary",
  customStyle: {},
  centerText: false,
  noWrap: false,
  variant: "body1",
  isTableHeader: false,
  textType: "primary",
};

export default Text;
