export enum ALGORITHM_PARTS_ENUMS {
  FILTERS = "filters",
  PROMOTES = "promotes",
  EXCEPTIONS = "exceptions",
  ADVANCE_SETTINGS = "advancedSettings",
  VARIABLES = "variables",
}

export enum ALGORITHM_PARTS_LABEL {
  FILTERS = "advanced_filters",
  PROMOTES = "advanced_promote",
  EXCEPTIONS = "advanced_exceptions",
  ADVANCE_SETTINGS = "advanced",
  VARIABLES = "parameters",
}
