/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */

import { useEffect, useState } from "react";

/**
 * Custom React hook for loading external scripts dynamically.
 *
 * @param {string} src - Source URL of the script.
 * @returns {string} - The status of the script loading process.
 */
export default function useScript(src: string): string {
  // State to track the status of the script
  const [status, setStatus] = useState<string>(src ? "loading" : "idle");

  useEffect(() => {
    // If the source is not provided, set the status to idle
    if (!src) {
      setStatus("idle");
      return;
    }

    // Check if the script with the same source already exists
    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="${src}"]`
    );

    // If the script doesn't exist, create a new one
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");

      // Append the script to the body
      document.body.appendChild(script);

      // Function to set attribute based on the load or error event
      const setAttributeFromEvent = (event: Event) => {
        !!script &&
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          );
      };

      // Attach event listeners for load and error events
      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      // If the script exists, set the status from the data attribute
      setStatus(script.getAttribute("data-status") || "idle");
    }

    // Function to set state based on the load or error event
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };

    // Attach event listeners for load and error events
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]); // Dependency on the src parameter

  // Return the current status of the script
  return status;
}
