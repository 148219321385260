import { JsonInput as MantineJsonInput } from "@mantine/core";
import { Box, useTheme } from "@mui/material";
import { IJsonInput } from "./types";

// An input managing JSON (for preprocess, filters)
const JsonInput: React.FC<IJsonInput> = ({ value, onChange }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "60%",
        height: "100%",
        "& .mantine-JsonInput-label": {
          color: theme.customPalette.grey.default,
        },
        "& .mantine-JsonInput-error": {
          color: theme.customPalette.error.main,
        },
        "& .mantine-JsonInput-input": {
          background: "transparent",
          color: theme.customPalette.grey.default,
          border: "transparent !important",
        },
        "& textarea": {
          tabSize: 2,
        },
      }}
    >
      <MantineJsonInput
        value={value}
        onChange={onChange}
        area-label="Custom filter"
        placeholder="Your code here"
        // validationError="Invalid JSON"
        formatOnBlur
        autosize
        minRows={5}
        maxRows={25}
        onError={console.error}
        radius={3}
        onKeyDown={(event: any) => {
          // tab click = 4 spaces
          if (event.keyCode === 9) {
            event.preventDefault();
            const { selectionStart, selectionEnd } = event.target;
            // update the state
            onChange(
              value.substring(0, selectionStart) +
                "\t" +
                value.substring(selectionEnd)
            );
          }
        }}
      />
    </Box>
  );
};

export default JsonInput;
