import { Box, Grid } from "@mui/material";
import GetStarted from "./components/GetStarted";
import Steps from "./components/Steps";
import useStep from "./hooks/useSteps/useSteps";

const GettingStarted = () => {
  const { noGettingStarted, step } = useStep();

  if (noGettingStarted) return null;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <GetStarted step={step} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Steps step={step} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GettingStarted;
