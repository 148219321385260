import { useEffect, useState } from "react";
import { AutoComplete, SelectPaper, StyledTextField } from "@includes";
import CircularProgress from "@mui/material/CircularProgress";
import { useSetState } from "@hooks";
import { useTranslation } from "react-i18next";
import { IMerchandisingCategorySelector } from "./types";

const MerchandisingCategorySelector: React.FC<IMerchandisingCategorySelector> =
  ({
    setCategory,
    categories,
    excludedCategories,
    setCurrentPushedProducts,
    allCategories,
    loading,
  }) => {
    const { t }: i18translateType = useTranslation();

    const includedCategories = categories;

    const [selectedCategory, setSelectedCategory] = useSetState({
      title: "",
      id: "",
    });

    const [options, setOptions] = useState<Array<any>>([]);

    const [search, setSearch] = useState("");

    useEffect(() => {
      if (selectedCategory) {
        setCategory(selectedCategory);
        setSearch(
          `${selectedCategory.title} - ${selectedCategory.id.replace(
            "gid://shopify/Collection/",
            ""
          )}`
        );
      }
    }, [selectedCategory]);

    useEffect(() => {
      if (includedCategories?.length > 0) {
        setOptions([
          ...includedCategories?.map((i: string) =>
            allCategories?.find((c: Dic<any>) => c?.id === i)
          ),
        ]);
      }
    }, [includedCategories, allCategories]);

    useEffect(() => {
      if (excludedCategories?.length > 0) {
        setOptions([
          ...allCategories?.filter(
            (c: Dic<any>) =>
              !excludedCategories?.find((ec: string) => ec === c?.id)
          ),
        ]);
      }
    }, [excludedCategories, allCategories]);

    useEffect(() => {
      if (
        options.length > 0 &&
        (!selectedCategory.id ||
          !options.find((opt: Dic<any>) => opt.id === selectedCategory.id))
      ) {
        setSelectedCategory(options[0]);
      } else if (options.length > 0) {
        setCategory(
          allCategories.find((c: Dic<any>) => c?.id === selectedCategory.id)
        );
      }
    }, [options]);

    useEffect(() => {
      if (
        includedCategories?.length === 0 &&
        excludedCategories?.length === 0 &&
        allCategories?.length > 0
      ) {
        setOptions([...allCategories]);
      }
    }, [excludedCategories, includedCategories, allCategories]);

    useEffect(() => {
      const allPushedProducts = allCategories
        .map((category: Dic<any>) => ({
          [category.id]: category?.page?.settings?.pushed_products ?? undefined,
        }))
        .reduce(function (acc, x) {
          for (let key in x) acc[key] = x[key];
          return acc;
        }, {});
      setCurrentPushedProducts(allPushedProducts);
    }, [allCategories]);

    return (
      <AutoComplete
        sx={{ width: 350 }}
        disableClearable
        loading={loading}
        value={selectedCategory}
        inputValue={search}
        options={options}
        getOptionLabel={(option: any) =>
          `${option?.title} - ${option?.id?.replace(
            "gid://shopify/Collection/",
            ""
          )}`
        }
        onClose={() =>
          setSearch(
            `${selectedCategory?.title} - ${selectedCategory?.id?.replace(
              "gid://shopify/Collection/",
              ""
            )}`
          )
        }
        renderInput={(params: any) => (
          <StyledTextField
            {...params}
            label={t("category")}
            name={t("category")}
            value={search}
            onChange={(e: any) => setSearch(e?.target?.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onChange={(e: React.SyntheticEvent, value: any) => {
          setSelectedCategory(value);
        }}
        PaperComponent={SelectPaper}
      />
    );
  };

export default MerchandisingCategorySelector;
