import Main from "../Main/Main";
import ReportProvider from "../../hooks/useReport";
import { useSendAnalytics } from "@hooks";

const Report = () => {
  useSendAnalytics();

  return (
    <ReportProvider>
      <Main />
    </ReportProvider>
  );
};

export default Report;
