function getItem(key: string) {
  try {
    return JSON.parse(localStorage.getItem(key) ?? "");
  } catch (e: any) {
    return null;
  }
}

function setItem(key: string, value: any) {
  if (!value) return;
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e: any) {
    console.error(e);
  }
}

function removeItem(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (e: any) {
    console.error(e);
  }
}

function clearLocalStorage() {
  try {
    removeItem("user");
    removeItem("token");
    removeItem("refreshToken");
    removeItem("accountId");
    removeItem("siteId");
    removeItem("isLoggedIn");
  } catch (e: any) {
    console.error(e);
  }
}

export const potionsLocalStorage = {
  get: getItem,
  set: setItem,
  remove: removeItem,
  clear: clearLocalStorage,
  get version() {
    return this.get("version");
  },
  get developerMode() {
    return this.get("developerMode");
  },
  get langue() {
    return this.get("user")?.langue;
  },
  get email() {
    return this.get("user")?.email;
  },
  get accountId() {
    return this.get("accountId");
  },
  get siteId() {
    return this.get("siteId");
  },
  get accountName() {
    return this.get("accountName");
  },
  get accountTheme() {
    return this.get("accountTheme");
  },
  get siteName() {
    return this.get("siteName");
  },
  get refreshToken() {
    return this.get("refreshToken");
  },
  get token() {
    return this.get("token");
  },
};
