import { useSelector } from "@xstate/react";
import { useEffect, useState } from "react";
import { useReport } from "../../hooks/useReport";
import {
  Text,
  Stack,
  Box,
  Grid,
  Loader,
  Layer1Box,
  Layer2Box,
} from "@includes";
import { formatUtils, getEventName } from "@utils";
import { useTranslation } from "react-i18next";

const eventCountsSelector = (state: any) => state.context.eventCounts;
const errorSelector = (state: any) => state.context.error;
const typeSelector = (state: any) => state.context.type;

const Engagement = () => {
  const { t }: i18translateType = useTranslation();
  const reportServices = useReport();

  const eventCounts = useSelector(
    reportServices.reportService,
    eventCountsSelector
  );

  const error = useSelector(reportServices.reportService, errorSelector);

  const type = useSelector(reportServices.reportService, typeSelector);

  const [data, setData] = useState<Array<Dic<any>>>([]);

  useEffect(() => {
    if (eventCounts) {
      setData(
        Object.keys(eventCounts)
          ?.filter((_key: string) => _key !== "__typename" && eventCounts[_key])
          ?.map((filteredKey: string) => ({
            label: getEventName(type, t)?.[filteredKey],
            value: eventCounts?.[filteredKey],
          }))
      );
    }
  }, [eventCounts]);

  if (!error && !eventCounts) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Layer1Box>
      <Stack spacing={2}>
        <Text variant="subtitle1">{t("engagement_title")}</Text>
        <Box sx={{ position: "relative" }}>
          <Grid container spacing={2}>
            {data.map((_data: Dic<any>) => (
              <Grid key={_data.label} item md={3} sm={6} xs={12}>
                <Layer2Box sx={{ height: "100%", boxSizing: "border-box" }}>
                  <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="start"
                    sx={{ height: "100%" }}
                  >
                    <Text variant="body2">{_data.label}</Text>
                    <Text variant="h2">
                      {formatUtils.formatInt(_data.value)}
                    </Text>
                  </Stack>
                </Layer2Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Layer1Box>
  );
};

export default Engagement;
