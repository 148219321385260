import { useQuery } from "@apollo/client";
import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import { CREATE_LABEL, GET_LABELS, UPDATE_EXPERIENCE_LABELS } from "./requests";
import { useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { isEmpty } from "lodash";
import { EdgeType } from "@/utils/types/requests";
import { StyledTextField, Text } from "@/includes";
import { useEffect, useState } from "react";
import { LabelsAutocompleteProps } from "./types";
import { useTranslation } from "react-i18next";
import { client } from "@/services/graphql/apolloConfig";
import ShortUniqueId from "short-unique-id";
import { LabelType } from "../../types";

const LabelsAutocomplete = ({
  params,
  labels,
  refetchLabels,
}: LabelsAutocompleteProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const [value, setValue] = useState<Array<Dic<any>>>([]);
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const uid = new ShortUniqueId();
  const [labelsWithAddedValue, setLabelsWithAddedValue] = useState<
    Array<Dic<any>>
  >([]);

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    setInputValue(value);
    if (!isEmpty(value)) {
      setLabelsWithAddedValue([
        { id: "add", name: `${t("add")} ${value}`, basename: value },
        ...labels,
      ]);
    } else {
      setLabelsWithAddedValue(labels);
    }
  };

  useEffect(() => {
    if (!isEmpty(labels)) {
      setLabelsWithAddedValue(labels);
      if (!isEmpty(params?.row?.labels)) {
        setValue(
          labels.filter((label: Dic<any>) =>
            params.row.labels
              .map((rowLabel: { label: LabelType }) => rowLabel.label.id)
              .includes(label.id)
          )
        );
      }
    }
  }, [labels]);

  const handleChangeValue = (
    event: React.SyntheticEvent,
    value: Array<Dic<any>>
  ) => {
    const newLabel = value.find((label: Dic<any>) => label.id === "add");
    if (!isEmpty(newLabel)) {
      client
        .mutate({
          mutation: CREATE_LABEL,
          variables: {
            siteId,
            label: {
              name: newLabel.basename,
              textColor: "#000",
              backgroundColor: "#fff",
            },
          },
        })
        .then((res: Dic<any>) => {
          const newValues = [
            ...value.filter((label: Dic<any>) => label.id !== "add"),
            res?.data?.createLabel,
          ];
          setValue(newValues);
          client.mutate({
            mutation: UPDATE_EXPERIENCE_LABELS,
            variables: {
              experienceId: params?.row?.id,
              experience: {
                labels: newValues?.map((val: Dic<any>) => val.id),
              },
            },
          });
        });
    } else {
      setValue(value);
      client.mutate({
        mutation: UPDATE_EXPERIENCE_LABELS,
        variables: {
          experienceId: params?.row?.id,
          experience: {
            labels: value?.map((val: Dic<any>) => val.id),
          },
        },
      });
    }
  };
  return (
    <Autocomplete
      multiple
      sx={{ width: 1 }}
      limitTags={1}
      options={labelsWithAddedValue}
      value={value}
      onChange={handleChangeValue}
      renderInput={(params) => <StyledTextField {...params} />}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      getOptionLabel={(option: Dic<any>) => option?.name}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`${option.id}${uid()}`}>
            {option.name}
          </li>
        );
      }}
      isOptionEqualToValue={(option: Dic<any>, value: Dic<any>) =>
        option.id === value.id
      }
      disableClearable
      disableCloseOnSelect
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        return (
          <Stack direction="row" alignItems="center" sx={{ gap: 1 }}>
            <Chip
              label={value
                .slice(0, 1)
                .map((option, _) => option.name)
                .join(", ")}
              size="small"
            />
            <Text variant="body2">
              {numTags > 1 && ` +${numTags - 1} more`}
            </Text>
          </Stack>
        );
      }}
    />
  );
};

export default LabelsAutocomplete;
