import { usePersistedState } from "@/hooks";
import { RootState } from "@/services/redux/store";
import { useSelector } from "react-redux";
import { Box, Stack } from "../../../includes";
import LazyLoading from "../../Wrappers/components/LazyLoading";
import DesktopWrapper from "../DesktopWrapper";
import MobileWrapper from "../MobileWrapper";
import Navbar from "./components/Navbar";
import TopBar from "./components/TopBar";
import { drawerOpenWith, drawerWidth } from "./enums";
import { ISideMenuWrapper } from "./types";

function SideMenuWrapper({ wrappedComponent }: ISideMenuWrapper) {
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const [openDesktopNavbar, setOpenDesktopNavbar] = usePersistedState({
    key: `open_desktop_drawer_${siteId}`,
    initialValue: "false",
  });
  const openDesktopNavbarBoolean = openDesktopNavbar === "true";
  return (
    <>
      <DesktopWrapper>
        <Stack
          direction="row"
          sx={{
            position: "relative",
            maxWidth: "100vw",
            minHeight: "100vh",
          }}
        >
          <Stack
            sx={{
              width: "100%",
              minHeight: "100vh",
              maxWidth: "100%",
            }}
          >
            <TopBar />

            <Box sx={{ display: "flex" }}>
              <Navbar
                openDesktopNavbar={openDesktopNavbarBoolean}
                setOpenDesktopNavbar={setOpenDesktopNavbar}
              />

              <Stack
                sx={{
                  width: `calc(100vw - ${
                    openDesktopNavbar ? drawerOpenWith : drawerWidth
                  }px - 100px)`,
                  position: "relative",
                  margin: "32px auto 0 auto",
                  overflowX: "hidden",
                }}
              >
                <Stack
                  sx={{
                    width: "100%",
                    position: "relative",
                    margin: "0 auto auto auto",
                  }}
                  justifyContent="center"
                >
                  <Box
                    sx={{
                      width: "100%",
                      paddingTop: 0,
                      paddingBottom: 6,
                      boxSizing: "border-box",
                    }}
                  >
                    <LazyLoading component={wrappedComponent} />
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </DesktopWrapper>

      <MobileWrapper>
        <TopBar />
        <Stack
          sx={{
            position: "relative",
            minHeight: "100vh",
            overflowX: "hidden",
            padding: "8px 16px",
          }}
        >
          <Navbar />
          <Box
            sx={{
              padding: 0,
              margin: 0,
              maxWidth: "100%",
              overflowX: "hidden",
            }}
          >
            <LazyLoading component={wrappedComponent} />
          </Box>
        </Stack>
      </MobileWrapper>
    </>
  );
}

export default SideMenuWrapper;
