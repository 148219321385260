import { OPEN_DRAWER, CLOSE_DRAWER } from "./action-types";

// eslint-disable-next-line import/prefer-default-export
export function openDrawer() {
  return {
    type: OPEN_DRAWER,
  };
}

export function closeDrawer() {
  return {
    type: CLOSE_DRAWER,
  };
}
