import { client } from "@/index";
import { RootState } from "@/services/redux/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_ALGORITHMS } from "./requests";
import { isEmpty } from "lodash";

const useAlgorithms = (algorithmId = "") => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [rules, setRules] = useState<Array<Dic<any>>>([]);

  const get = (res: Dic<any>) => res.data.collections;

  const shape = (dicRes: Dic<any>) => {
    return dicRes.edges.map((edge: Dic<any>) => ({ ...edge.node }));
  };

  const onError = () => setRules([]);

  const getRules = () => {
    client
      .query({
        query: GET_ALGORITHMS,
        variables: {
          siteId,
          filters:
            isEmpty(algorithmId) || algorithmId === "create"
              ? ""
              : `id != ${algorithmId}`,
        },
        fetchPolicy: "no-cache",
      })
      .then(get)
      .then(shape)
      .then(setRules)
      .catch(onError);
  };

  useEffect(() => {
    if (siteId) getRules();
  }, [siteId]);

  return { allAlgorithms: rules };
};

export default useAlgorithms;
