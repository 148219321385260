import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box } from "@includes";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ExperienceKeyIndicators from "../ExperienceKeyIndicators";
import { formatDataToTable } from "./formatters";
import { GET_KEY_INDICATORS } from "./requests";
import { KeyIndicatorsProps, ResponseKeyIndicatorsDataType } from "./types";

const KeyIndicators = ({
  filters,
  experienceName,
  experienceId,
}: KeyIndicatorsProps) => {
  const { startDate, endDate, experience, devices } = filters;
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<any[]>([]);
  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_KEY_INDICATORS,
        variables: {
          siteId: parseInt(siteId),
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
          segmentExposedVisitors: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };all`,
          segmentUsers: `potions;${experience.type?.toLowerCase()};${
            experience.analyticsName
          };convert`,
          devices,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseKeyIndicatorsDataType) => {
        setData(formatDataToTable(res?.data));
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(experience) &&
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(devices)
    )
      getData();
  }, [startDate, endDate, devices, experience]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <ExperienceKeyIndicators
        rows={data}
        experienceName={experienceName}
        startDate={startDate}
        endDate={endDate}
        experienceId={experienceId}
        loading={status}
      />
    </Box>
  );
};

export default KeyIndicators;
