export const getData = (pathname: string, t: (text: string) => string) => {
  let data = {
    title: "",
    imageSource: "",
  };
  const page = pathname.split("/")?.[pathname.split("/").length - 1];
  switch (page) {
    case "create_account":
      data = {
        title: t("create_account_auth_rightside"),
        imageSource: "createSite.png",
      };
      break;
    case "signup":
      data = {
        title: t("signup_auth_rightside"),
        imageSource: "createSite.png",
      };
      break;
    default:
      data = {
        title: t("login_auth_rightside"),
        imageSource: "createSite.png",
      };
      break;
  }
  return data;
};
