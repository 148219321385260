import { gql } from "@apollo/client";

export const GET_ANALYTICS = gql`
  query analytics($id: SiteID!) {
    site(siteId: $id) {
      analyticsTools {
        edges {
          node {
            id
            name
            settings
          }
        }
      }
    }
  }
`;
