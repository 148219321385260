import { formatUtils } from "@/utils";
import { SUMMARY_HEADERS } from "@/utils/enums/graphql";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { isEmpty } from "lodash";

export const getInitialColumns = (columns: GridColDef[]) => {
  const initialData: Dic<any> = {};
  columns.forEach((elem: GridColDef) => {
    initialData[elem.field] = true;
  });
  return initialData;
};

export const getTooltipForColumn = (
  allVisitors: boolean,
  t: (text: string) => void
) => {
  let tooltip = "";
  if (allVisitors) {
    tooltip = `${t("users")} / ${t("all_visitors")}`;
  } else {
    tooltip = `${t("users")} / ${t("exposed_visitors")}`;
  }
  return (
    <Tooltip title={tooltip}>
      <div>
        <HelpOutline sx={{ ml: 1 }} />
      </div>
    </Tooltip>
  );
};

export const getAdditionalColumns = (
  rows: Array<any>,
  t: (text: string) => void
) => {
  const newColumns: Array<any> = [];
  if (!isEmpty(rows)) {
    Object.keys(rows[0]).forEach((header: any) => {
      if (
        !Object.values(SUMMARY_HEADERS).includes(header) &&
        header != "segment"
      ) {
        newColumns.push({
          field: header,
          headerName: header,
          flex: 1,
          disableColumnMenu: true,
          sortable: false,
          align: "left",
          valueGetter: (params: GridRowParams) => {
            if (params.row.segment === "ratio") {
              return formatUtils.formatToPercent(params.row[header]);
            }
            return formatUtils.formatInt(params.row[header]);
          },
          renderCell: (params: GridRowParams) => {
            if (params.row.segment === "ratio") {
              return (
                <Stack direction="row">
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.customPalette.grey.light,
                    }}
                  >
                    ({formatUtils.formatToPercent(params.row[header])})
                  </Typography>
                  {getTooltipForColumn(true, t)}
                </Stack>
              );
            }
            return (
              <Typography variant="body1">
                {formatUtils.formatInt(params.row[header])}
              </Typography>
            );
          },
        });
      }
    });
  }
  return newColumns;
};
