import ShortUniqueId from "short-unique-id";

/**
 * Gets or generates a memoized key based on the provided seed.
 *
 * @param seed - The seed value to generate the memoized key.
 * @returns The memoized key associated with the provided seed.
 */
export const getMemoKeys = (seed: string) => {
  const uuid = new ShortUniqueId();
  if (!window.memoKeys) window.memoKeys = {};
  if (!window.memoKeys[seed]) window.memoKeys[seed] = uuid();
  return window.memoKeys[seed];
};
