/**
 * Utility class for working with experiences.
 */
export class ExperiencesUtils {
  /**
   * Check if the experience has a monitor.
   *
   * @param experienceType - The type of the experience.
   * @param isMonitorable - A boolean defining if the experience has a report or not.
   * @returns True if the experience has a report, false otherwise.
   */
  hasReport = (
    experienceType: ExperienceType,
    isMonitorable = true
  ): boolean => {
    if (!isMonitorable) return false;
    let hasReport = true;
    if (experienceType === "CONNECT" || experienceType === "EMERCH") {
      hasReport = false;
    }
    return hasReport;
  };

  /**
   * Check if the experience is configurable.
   *
   * @param experienceType - The type of the experience.
   * @returns True if the experience is configurable, false otherwise.
   */
  hasConfigure = (experienceType: string): boolean => {
    let hasConfigure = false;
    if (["EMERCH", "MULTIPLY", "EMAILING"].includes(experienceType)) {
      hasConfigure = true;
    }
    return hasConfigure;
  };

  /**
   * Get the maximum date range that is possible to query based on the analytics type.
   *
   * @param analyticsType - The type of the analytics used (e.g., matomo).
   * @returns The maximum date range that is possible to query.
   */
  getMaximumRange = (analyticsType: AnalyticsType): number => {
    if (analyticsType === "matomo") {
      return 14;
    }
    return 89;
  };

  /**
   * Get the default date range used for querying based on the analytics type.
   *
   * @param analyticsType - The type of the analytics used (e.g., matomo).
   * @returns The default date range used for querying.
   */
  getDefaultRange = (analyticsType: AnalyticsType): number => {
    if (analyticsType === "matomo") {
      return 14;
    }
    return 30;
  };
}
