import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  CHANGE_SITE,
  LOGOUT,
  SET_PRODUCT_PROPERTIES,
} from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";

export const initialState = {
  properties: potionsLocalStorage.get(
    `productProperties - ^${potionsLocalStorage.siteId}`
  ) ?? ["id", "name", "price", "img_link"],
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case SET_PRODUCT_PROPERTIES:
        draft.properties = action.payload.properties;
        potionsLocalStorage.set(
          `productProperties - ^${potionsLocalStorage.siteId}`,
          action.payload.properties
        );
        break;

      case CHANGE_SITE:
        potionsLocalStorage.remove(
          `productProperties - ^${potionsLocalStorage.siteId}`
        );
        draft.properties = [];
        break;

      case LOGOUT:
        potionsLocalStorage.remove(
          `productProperties - ^${potionsLocalStorage.siteId}`
        );
        draft.properties = [];

        break;
      default:
        break;
    }
  });
}
