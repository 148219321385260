import produce, { Draft } from "immer";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ADD_RUN,
  CHANGE_SITE,
  LOGOUT,
  REMOVE_RUN,
} from "../actions/action-types";
import { potionsLocalStorage } from "@localStorage";
import { comparisonUtils } from "@/utils";

export const initialState = {
  runs: potionsLocalStorage.get("runs") ?? ([] as Array<Dic<any>>),
};

export default function todosReducer(
  state = initialState,
  action: PayloadAction<Dic<any>>
) {
  return produce(state, (draft: Draft<typeof initialState>) => {
    switch (action.type) {
      case ADD_RUN:
        const runs: Array<Dic<any>> = [
          ...draft.runs,
          action.payload.run,
        ].filter(comparisonUtils.isNotNull);
        draft.runs = runs;
        potionsLocalStorage.set("runs", runs);
        break;
      case REMOVE_RUN:
        const filteredRuns = [...draft.runs].filter(
          (run: Dic<any>) => !!run && run.id !== action.payload.runId
        );
        draft.runs = filteredRuns;
        potionsLocalStorage.set("runs", filteredRuns);

        break;
      case CHANGE_SITE:
        draft.runs = [];
        potionsLocalStorage.set("runs", []);
        break;
      case LOGOUT:
        draft.runs = [];
        potionsLocalStorage.set("runs", []);
        break;
      default:
        break;
    }
  });
}
