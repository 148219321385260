import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query companies($search: String) {
    companies(first: 1000) {
      edges {
        node {
          id
          name
          theme
          sites(search: $search) {
            edges {
              node {
                isUnderMaintenance
                id
                name
                hasAlgorithmBuilder
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_EXPERIENCE = gql`
  query Experience($experienceId: ExperienceID!) {
    experience(experienceId: $experienceId) {
      uuid
      name
      pageType
      analyticsName
      type
      deployedAt
      device
      locationId
      isConfigurable
      includedCategories
      excludedCategories
      settings
      collection {
        uuid
        name
        description
        settings
        deletedAt
      }
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation createCollection($id: SiteID!, $collection: CollectionInput!) {
    createCollection(collection: $collection, siteId: $id) {
      id
    }
  }
`;

export const CREATE_EXPERIENCE = gql`
  mutation createExperience(
    $siteId: SiteID!
    $experienceInput: ExperienceInput!
  ) {
    createExperience(siteId: $siteId, experience: $experienceInput) {
      id
    }
  }
`;
