interface IInvite {
  color?: string;
}

const Invite: React.FC<IInvite> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_1022_22244"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1022_22244)">
        <path
          d="M12.5 11.95C12.9833 11.4167 13.3543 10.8083 13.613 10.125C13.871 9.44167 14 8.73333 14 8C14 7.26667 13.871 6.55833 13.613 5.875C13.3543 5.19167 12.9833 4.58333 12.5 4.05C13.5 4.18333 14.3333 4.625 15 5.375C15.6667 6.125 16 7 16 8C16 9 15.6667 9.875 15 10.625C14.3333 11.375 13.5 11.8167 12.5 11.95ZM18 20V17C18 16.4 17.8667 15.8293 17.6 15.288C17.3333 14.746 16.9833 14.2667 16.55 13.85C17.4 14.15 18.1877 14.5373 18.913 15.012C19.6377 15.4873 20 16.15 20 17V20H18ZM20 13V11H18V9H20V7H22V9H24V11H22V13H20ZM8 12C6.9 12 5.95833 11.6083 5.175 10.825C4.39167 10.0417 4 9.1 4 8C4 6.9 4.39167 5.95833 5.175 5.175C5.95833 4.39167 6.9 4 8 4C9.1 4 10.0417 4.39167 10.825 5.175C11.6083 5.95833 12 6.9 12 8C12 9.1 11.6083 10.0417 10.825 10.825C10.0417 11.6083 9.1 12 8 12ZM0 20V17.2C0 16.6333 0.146 16.1123 0.438 15.637C0.729333 15.1623 1.11667 14.8 1.6 14.55C2.63333 14.0333 3.68333 13.6457 4.75 13.387C5.81667 13.129 6.9 13 8 13C9.1 13 10.1833 13.129 11.25 13.387C12.3167 13.6457 13.3667 14.0333 14.4 14.55C14.8833 14.8 15.2707 15.1623 15.562 15.637C15.854 16.1123 16 16.6333 16 17.2V20H0ZM8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.97933 9.413 6.588C9.021 6.196 8.55 6 8 6C7.45 6 6.97933 6.196 6.588 6.588C6.196 6.97933 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM2 18H14V17.2C14 17.0167 13.9543 16.85 13.863 16.7C13.771 16.55 13.65 16.4333 13.5 16.35C12.6 15.9 11.6917 15.5623 10.775 15.337C9.85833 15.1123 8.93333 15 8 15C7.06667 15 6.14167 15.1123 5.225 15.337C4.30833 15.5623 3.4 15.9 2.5 16.35C2.35 16.4333 2.22933 16.55 2.138 16.7C2.046 16.85 2 17.0167 2 17.2V18Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

Invite.defaultProps = {
  color: "transparent",
};

export default Invite;
