import { AutoComplete, SelectPaper, StyledTextField } from "@includes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { getOptions } from "./helpers";
import { IEvolutionMetricSelector } from "./types";

const EvolutionMetricSelector: React.FC<IEvolutionMetricSelector> = ({
  evolutionKey,
  handleChange,
}) => {
  const { t }: i18translateType = useTranslation();
  const options = getOptions(t);

  return (
    <AutoComplete
      popupIcon={<KeyboardArrowDownIcon />}
      PaperComponent={SelectPaper}
      value={options.find((d: Dic<any>) => d.value === evolutionKey)}
      onChange={(e: React.SyntheticEvent, value: any) =>
        handleChange(value.value)
      }
      disablePortal
      disableClearable
      id="evolution-metric-selector"
      options={options?.filter((o: Dic<any>) => !o.disabled)}
      sx={{ width: 280 }}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("Evolution metric")}
          name={t("Evolution metric")}
        />
      )}
    />
  );
};

export default EvolutionMetricSelector;
