import { useHasProperty } from "@hooks";
import { IHasCustomPlan } from "./types";

// /!\ custom plan users = users not in self service
const HasCustomPlanWrapper: React.FC<IHasCustomPlan> = ({ children }) => {
  const { hasCustomPlan } = useHasProperty();

  if (!hasCustomPlan) {
    return <></>;
  }

  return <>{children}</>;
};

export default HasCustomPlanWrapper;
