/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext } from "react";
import { gql } from "@apollo/client/core";
import _ from "lodash";
import { assign, DoneInvokeEvent, ActorRefFrom, createMachine } from "xstate";
import { useInterpret } from "@xstate/react";
import { client } from "@/index";
import dayjs from "dayjs";
import { GET_EXPERIENCE } from "./requests";
import { Event } from "./types";

const initialState = {
  variables: [],
  id: "",
  name: `Nouvelle expérience - ${dayjs(new Date()).format("DD-MM-YYYY")}`,
  locationId: null,
  deployedAt: null,
  settings: {},
  type: "MULTIPLY",
  collection: {},
  experienceInput: {
    collectionId: null,
    name: "UNTITLED",
    type: "MULTIPLY",
    settings: null,
    locationId: null,
  },
  error: false,
  isLoading: false,
  serverData: {
    collectionId: null,
    name: "UNTITLED",
    type: "MULTIPLY",
    settings: null,
    locationId: null,
  },
};

const getInitialCollectionParameter = (type: string) => {
  return "product_ids";
};

const getExperience = (expId: string | null, type: string) => {
  if (!expId || expId === "create") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          ...initialState,
          experienceInput: {
            name: "UNTITLED",
            type,
          },
          collection: {},
        });
      }, 100);
    });
  }
  return client
    .query({
      query: GET_EXPERIENCE,
      variables: { id: parseInt(expId, 10) },
      fetchPolicy: "no-cache",
    })
    .then(async (res: any) => {
      const { data } = res;

      if (data) {
        const { experience } = data;
        return {
          ...experience,
          experienceInput: {
            collectionId: experience.collection?.id ?? "",
            name: experience.name ?? "UNTITLED",
            type: "MULTIPLY",
            settings: experience.settings,
            locationId: experience.locationId,
          },
          serverData: {
            collectionId: experience.collection?.id ?? "",
            name: experience.name ?? "UNTITLED",
            type: "MULTIPLY",
            settings: experience.settings,
            locationId: experience.locationId,
          },
        };
      }
    });
};

const ExperienceMachine = createMachine<any, Event>({
  id: "ExperienceMachine",
  context: {
    ...initialState,
  },
  on: {
    SET_VARIABLES: {
      actions: assign((context, event) => ({
        ...context,
        variables: event.data.variables,
      })),
    },
    SET_LOCATION: {
      actions: assign((context, event) => {
        const newName = ["UNTITLED", event.data.locationName].includes(
          context.name
        )
          ? event.data.name
          : context.name;
        return {
          locationId: event.data.locationId,
          name: newName,
          experienceInput: {
            ...context.experienceInput,
            name: newName,
            locationId: event.data.locationId,
          },
        };
      }),
    },
    SET_EXPERIENCE_ID: {
      actions: assign((context, event) => ({
        id: event.data.id,
        isLoading: true,
      })),
      target: "initializeExperience",
    },
    REFRESH: {
      actions: assign((context, event) => ({
        id: event.data.id,
        isLoading: false,
      })),
      target: "initializeExperience",
    },
    SET_PAGE_TYPE: {
      actions: assign((context, event) => ({
        pageType: event.data.pageType ?? "",
        experienceInput: {
          ...context.experienceInput,
          pageType: event.data.pageType ?? "",
        },
      })),
    },
    CHANGE_NAME: {
      actions: assign((context, event) => ({
        name: event.data.name ?? "UNTITLED",
        experienceInput: {
          ...context.experienceInput,
          name: event.data.name ?? "UNTITLED",
        },
      })),
    },
    SET_FRONT: {
      actions: assign((context, event) => ({
        settings: {
          ...context.settings,
          tag: {
            ...event.data.tag,
          },
        },
        experienceInput: {
          ...context.experienceInput,
          settings: {
            tag: {
              ...event.data.tag,
            },
          },
        },
      })),
    },
    SET_COLLECTION: {
      actions: assign((context, event) => ({
        collection: event.data.collection,
        experienceInput: {
          ...context.experienceInput,
          collectionId: event.data.collection?.id,
        },
      })),
    },
  },
  states: {
    initializeExperience: {
      invoke: {
        src: (context, event) => getExperience(context.id, context.type),
        onDone: {
          actions: assign((context, event) => {
            return {
              ...context,
              ...event.data,
              isLoading: false,
              categoriesToSave: [],
            };
          }),
        },
      },
    },
  },
});

interface ExperienceContextType {
  experienceService: ActorRefFrom<typeof ExperienceMachine>;
}

export const ExperienceContext = createContext({} as ExperienceContextType);

export default function ExperienceProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const experienceService = useInterpret(ExperienceMachine);

  return (
    <ExperienceContext.Provider value={{ experienceService }}>
      {children}
    </ExperienceContext.Provider>
  );
}

export function useConfigureRecommendations() {
  return useContext(ExperienceContext);
}
