import { Skeleton, useTheme } from "@mui/material";

const Loading = () => {
  const theme = useTheme();
  return (
    <Skeleton
      height={400}
      variant="rounded"
      animation="wave"
      sx={{ bgcolor: theme.customPalette.others.layer_2 }}
    />
  );
};

export default Loading;
