import { HIDE_NOTIFICATION, SEND_NOTIFICATION } from "./action-types";

// eslint-disable-next-line import/prefer-default-export
export function sendNotification(
  name: string,
  title: string,
  text: string,
  severity: string
) {
  return {
    type: SEND_NOTIFICATION,
    payload: { name, title, text, severity },
  };
}

export function hideNotification() {
  return {
    type: HIDE_NOTIFICATION,
    payload: {},
  };
}
