import { RedirectIcon } from "@/assets/icons";
import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { ButtonLink, Stack, StyledTabPanel } from "@includes";
import { TabContext, TabList } from "@mui/lab";
import { Box, Tab, useTheme } from "@mui/material";
import { useSelector as useXstateSelector } from "@xstate/react";
import { useEffect, useState } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useConfigureRecommendations } from "../../../../_Routes/ConfigureRecommendations/hooks/useConfigureRecommendations";
import { TAB_API_ENUMS } from "./enums";
import { getJsRequest, getPythonRequest, getRequest } from "./helpers";
import { GET_TOKEN } from "./requests";
import { ApiProps } from "./types";

const experienceUuidSelector = (state: any) => state.context.uuid;
const variablesSelector = (state: any) => state.context.variables;

const Api = ({ params }: ApiProps) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const experienceServices = useConfigureRecommendations();
  const [tokenApi, setTokenApi] = useState<string>("");
  const [tabValue, setTabValue] = useState<TAB_API_ENUMS>(TAB_API_ENUMS.CURL);
  const uuid = useXstateSelector(
    experienceServices.experienceService,
    experienceUuidSelector
  );

  const variables = useXstateSelector(
    experienceServices.experienceService,
    variablesSelector
  );

  const paramsCanFill = variables.reduce(
    (acc: Dic<string>, variable: string) => {
      return { ...acc, [variable]: [] };
    },
    {}
  );

  const getToken = () => {
    client
      .query({
        query: GET_TOKEN,
        variables: { siteId },
        fetchPolicy: "no-cache",
      })
      .then((res: Dic<any>) =>
        setTokenApi(res?.data?.APITokens?.edges[0]?.node?.token)
      );
  };

  useEffect(() => {
    if (siteId) {
      getToken();
    }
  }, [siteId]);

  const handleChangeTab = (
    event: React.SyntheticEvent | null,
    newValue: TAB_API_ENUMS
  ) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack spacing={2}>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              margin: "0 !important",
            }}
          >
            <TabList
              scrollButtons
              allowScrollButtonsMobile
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.customPalette.bluePurple.dark,
                },
              }}
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              sx={{
                "& .MuiButtonBase-root": {
                  alignItems: "end",
                },
              }}
            >
              <Tab
                key={TAB_API_ENUMS.CURL}
                label="Curl"
                value={TAB_API_ENUMS.CURL}
                iconPosition="start"
              />
              <Tab
                key={TAB_API_ENUMS.JS}
                label="Javascript"
                value={TAB_API_ENUMS.JS}
                iconPosition="start"
              />
              <Tab
                key={TAB_API_ENUMS.PYTHON}
                label="Python"
                value={TAB_API_ENUMS.PYTHON}
                iconPosition="start"
              />
            </TabList>
          </Box>

          <StyledTabPanel value={TAB_API_ENUMS.CURL}>
            <CopyBlock
              language="shell"
              text={getRequest(uuid, siteId, paramsCanFill, tokenApi, params)}
              codeBlock
              theme={dracula}
            />
          </StyledTabPanel>
          <StyledTabPanel value={TAB_API_ENUMS.JS}>
            <CopyBlock
              language="javascript"
              text={getJsRequest(uuid, siteId, paramsCanFill, tokenApi, params)}
              codeBlock
              theme={dracula}
            />
          </StyledTabPanel>
          <StyledTabPanel value={TAB_API_ENUMS.PYTHON}>
            <CopyBlock
              language="python"
              text={getPythonRequest(
                uuid,
                siteId,
                paramsCanFill,
                tokenApi,
                params
              )}
              codeBlock
              theme={dracula}
            />
          </StyledTabPanel>
        </TabContext>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <ButtonLink
            onClick={() =>
              window.open("https://techdocs.get-potions.com/potions-data-api")
            }
            startIcon={
              <RedirectIcon color={theme.customPalette.grey.default} />
            }
            customStyle={{
              color: theme.customPalette.grey.default,
            }}
          >
            {t("potions_data_api_documentation")}
          </ButtonLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Api;
