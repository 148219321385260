import { IHasPages } from "@/utils/types/component";
import { useHasProperty } from "@hooks";

const HasAnalyticsOrABTestWrapper: React.FC<IHasPages> = ({ children }) => {
  const { hasAnalytics, hasAbtest } = useHasProperty();

  if (hasAnalytics || hasAbtest) {
    return <>{children}</>;
  }

  return <></>;
};

export default HasAnalyticsOrABTestWrapper;
