import { useState, useEffect } from "react";
import { gql } from "@apollo/client";
import { graphqlUtils } from "@/utils";
import { RootState } from "@/services/redux/store";
import { useSelector } from "react-redux";

const GET_EXPERIENCES = gql`
  query getExperiences($siteId: SiteID, $first: Int!) {
    experiences(
      siteId: $siteId
      sort: "name"
      desc: false
      filters: "is_monitorable = true AND deployed_at != null"
      first: $first
    ) {
      edges {
        node {
          isMonitorable
          id
          name
          deployedAt
          type
          onMobile
          onDesktop
          analyticsName
          settings
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

let FIRST = 50;

/**
 * Custom React hook to check if the site has any non-hidden experiences.
 *
 * @returns Object containing a boolean 'hasExperiences'.
 */
const useHasExperiences = () => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const [experiences, setExperiences] = useState<Array<Dic<any>>>([]);

  // Function to check if an experience is not hidden
  const isNotHidden = (experience: Dic<any>) =>
    !experience?.settings?.interface?.hidden;

  // Function to filter out hidden experiences
  const filterExperiences = (experiences: Array<Dic<any>>) =>
    experiences.filter(isNotHidden);

  // Function to fetch experiences from the GraphQL server
  const fetch = async () =>
    await graphqlUtils.fetchWithoutCache(
      GET_EXPERIENCES,
      { siteId: parseInt(siteId), first: FIRST },
      "no-cache"
    );

  // Function to get experiences and update the state
  const getExperiences = async () => {
    let res = await fetch();
    const totalCount = res?.data?.experiences?.totalCount ?? 0;

    if (res.data?.experiences?.edges) {
      if (totalCount < res.data.experiences.edges.length) {
        FIRST = totalCount;
        res = await fetch();
      }
      setExperiences(
        filterExperiences(
          res?.data?.experiences?.edges?.map((item: any) => ({
            ...item.node,
          }))
        )
      );
    }
  };

  useEffect(() => {
    if (siteId) getExperiences();
  }, [siteId]);

  return { hasExperiences: experiences.length > 0 };
};

export default useHasExperiences;
