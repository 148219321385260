import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  KeyboardArrowDownIcon,
  SelectPaper,
  StyledTextField,
} from "@/includes";
import { IOutputVariableSelector } from "./types";
import { getTraduction } from "@/utils/helpers/traductions";

const OutputVariableSelector: React.FC<IOutputVariableSelector> = ({
  outputKey,
  handleChangeOutputKey,
  variables,
}) => {
  const { t }: i18translateType = useTranslation();
  return (
    <AutoComplete
      size="small"
      popupIcon={<KeyboardArrowDownIcon />}
      PaperComponent={SelectPaper}
      value={outputKey}
      onChange={(event: any, newValue: any) => {
        handleChangeOutputKey(newValue);
      }}
      disableClearable
      options={variables}
      getOptionLabel={(option: string) => getTraduction(t, option)}
      style={{ width: "100%", minWidth: 100 }}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("variables")}
          placeholder={`${t("choose_variables")}...`}
        />
      )}
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
    />
  );
};

export default OutputVariableSelector;
