import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query getCategories($catalogId: CatalogID!, $first: Int!) {
    categories(catalogId: $catalogId, first: $first, sort: "title") {
      edges {
        node {
          id
          title
          pages {
            edges {
              node {
                id
                settings
                collectionId
                collection {
                  settings
                  id
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
