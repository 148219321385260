import { CloseIcon } from "@/assets/icons";
import { Box, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import * as React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Anchor, Child, ISideModal } from "./types";

const SearchProduct = React.lazy(
  () => import("./components/SearchProduct/index")
);
const SearchCollection = React.lazy(
  () => import("./components/SearchCollection")
);

const SideModal: React.FC<ISideModal> = ({
  position,
  isOpen,
  onClose,
  child,
  params,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const getChild = (child: Child, params: any) => {
    switch (child) {
      case "search_product":
        return (
          <SearchProduct
            selectedProducts={params?.selectedProducts ?? []}
            addProduct={params.addProduct}
            type={params.type ?? "add"}
            removeProduct={params?.removeProduct}
            categoryId={params?.param?.categoryId ?? ""}
            addProducts={params?.addProducts}
          />
        );
      case "search_rule":
        return (
          <SearchCollection
            selectedCollections={params?.selectedCollections}
            addCollection={params?.addCollection}
            removeCollection={params?.removeCollection}
            parameter={params?.parameter}
          />
        );

      default:
        return <></>;
    }
  };

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    if (!open) onClose();
    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    toggleDrawer(position, isOpen)({ type: "" });
  }, [isOpen]);

  return createPortal(
    <div>
      <Drawer
        ModalProps={{ keepMounted: true }}
        disableScrollLock
        anchor={position}
        open={state[position]}
        onClose={toggleDrawer(position, false)}
        sx={{ zIndex: 1500 }}
        PaperProps={{
          sx: {
            background: theme.customPalette.others.layer_1,
            width: `min(70vw, 575px)`,
          },
        }}
      >
        <Stack sx={{ width: "100%" }} alignItems="end">
          <Box>
            <Tooltip title={t("close_drawer")}>
              <IconButton onClick={onClose}>
                <CloseIcon color={theme.customPalette.grey.default} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        {getChild(child, params)}
      </Drawer>
    </div>,
    document.body
  );
};

export default SideModal;
