/**
 * Utility class for various string-related operations.
 */
export class StringUtils {
  /**
   * Converts a string to lowercase.
   *
   * @param text - The input string.
   * @returns The input string converted to lowercase.
   */
  lowerCase(text: string): string {
    return text.toLowerCase();
  }

  /**
   * Formats a hostname by removing "https://" and extracting the domain.
   *
   * @param hostname - The input hostname.
   * @returns The formatted domain from the hostname.
   */
  formatHostname(hostname: string): string {
    if (!hostname) return "";
    return hostname.replace("https://", "").split("/")[0];
  }

  /**
   * Capitalizes the first letter of a string.
   *
   * @param text - The input string.
   * @returns The input string with the first letter capitalized.
   */
  capitalizeFirstLetter = (text: string) => {
    try {
      return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
    } catch (e: any) {
      return "";
    }
  };

  /**
   * Gets the first letter of a string.
   *
   * @param text - The input string.
   * @returns The first letter of the input string.
   */
  getFirstLetter = (text: string | undefined): string => {
    if (!text) return "";
    if (typeof text !== "string") throw new TypeError();
    return text[0];
  };

  /**
   * Validates whether a string is a valid URL.
   *
   * @param urlString - The input string to be validated as a URL.
   * @returns True if the input string is a valid URL, false otherwise.
   */
  isValidUrl = (urlString: string) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
}
