import { RootState } from "@/services/redux/store";
import * as Sentry from "@sentry/browser";
import { useSelector } from "react-redux";

const getMessageToSend = (user: Dic<any>, message: string, siteId: string) =>
  JSON.stringify({ user: user.email, siteId, message });

const init = () => {
  Sentry.init({
    dsn: "https://062d15e0cd424e75aa66a24aff91162c@o435032.ingest.sentry.io/4504049898749952",
  } as Sentry.BrowserOptions);
};

const useSentry = () => {
  const user = useSelector((state: RootState) => state.login.user);
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const captureError = (message: string) => {
    const messageToSend = getMessageToSend(user, message, siteId);
    if (process.env.REACT_APP_ENV === "prod") {
      init();
      Sentry.captureMessage(messageToSend, {
        level: "error",
      });
    } else {
      console.error(messageToSend);
    }
  };

  const captureMessage = (message: string) => {
    const messageToSend = getMessageToSend(user, message, siteId);

    if (process.env.REACT_APP_ENV === "prod") {
      init();
      Sentry.captureMessage(messageToSend, {
        level: "warning",
        extra: { fullErrorMessage: message },
      });
    } else {
      console.error(messageToSend);
    }
  };

  const captureObject = (message: string, object: Dic<any>) => {
    if (process.env.REACT_APP_ENV === "prod") {
      init();
      Sentry.captureMessage(message, {
        level: "warning",
        extra: { ...object, user: user.email },
      });
    } else {
      console.error(message, { ...object, user: user.email });
    }
  };

  return { captureError, captureMessage, captureObject };
};

export default useSentry;
