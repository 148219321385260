import { Text } from "@/includes";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import { ALGORITHM_PARTS_ENUMS } from "../../enums";
import GlobalFilters from "../GlobalFilters";
import PromoteAndPinFilters from "../PromoteAndPinFilters";
import { IBlocksTab } from "./types";

const RuleFilters = lazy(() => import("../RuleFilters"));
const AdvancedSettings = lazy(() => import("../AdvancedSettings"));
const Exceptions = lazy(() => import("../Exceptions"));
const Promote = lazy(() => import("../Promote"));

const BlocksTab: React.FC<IBlocksTab> = ({
  tab,
  algorithmIdInRecoAndMerch,
  experienceType,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const [selectedBlock, setSelectedBlock] = useState<string>("");
  const handleChangeSelectedBlock = setSelectedBlock;

  const configureAlgorithmServices = useConfigureAlgorithm();
  const blocksSelector = (state: any) => state.context.blocks;
  const blocks = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    blocksSelector
  );

  const pinsSelector = (state: any) => state.context.pins;
  const pins = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    pinsSelector
  );

  const getTab = (
    tab: ALGORITHM_PARTS_ENUMS,
    selectedBlockId: string,
    algorithmIdInRecoAndMerch: ID | undefined,
    experienceType: "EMERCH" | "ALGO" | "MULTIPLY" | "EMAILING"
  ) => {
    if (selectedBlockId === "all") return <GlobalFilters />;
    if (selectedBlockId === "promote_and_pin_items")
      return <PromoteAndPinFilters />;
    switch (tab) {
      case ALGORITHM_PARTS_ENUMS.ADVANCE_SETTINGS:
        return <AdvancedSettings selectedBlockId={selectedBlockId} />;
      case ALGORITHM_PARTS_ENUMS.EXCEPTIONS:
        return (
          <Exceptions
            selectedBlockId={selectedBlockId}
            algorithmIdInRecoAndMerch={algorithmIdInRecoAndMerch}
            experienceType={experienceType}
          />
        );
      case ALGORITHM_PARTS_ENUMS.FILTERS:
        return <RuleFilters selectedBlockId={selectedBlockId} />;
      case ALGORITHM_PARTS_ENUMS.PROMOTES:
        return <Promote selectedBlockId={selectedBlockId} />;
      default:
        return <Box />;
    }
  };

  const blocksMap: Array<Dic<any>> = Object.values(blocks ?? {});
  useEffect(() => {
    if (
      (!selectedBlock && blocksMap.length > 0) ||
      (selectedBlock !== "all" &&
        selectedBlock !== "promote_and_pin_items" &&
        !blocksMap.map((block: Dic<any>) => block.id).includes(selectedBlock) &&
        blocksMap.length > 0)
    ) {
      handleChangeSelectedBlock(blocksMap[0].id);
    }
  }, [blocksMap]);

  if (!selectedBlock) return null;

  return (
    <Box>
      <Stack spacing={2}>
        {blocksMap.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              border: "1px solid",
              borderColor: theme.customPalette.others.background,
              p: 1,
              borderRadius: 2,
              width: "fit-content",
              maxWidth: "95%",
              overflowY: "auto",
            }}
          >
            {tab === "filters" && (
              <Box
                onClick={() => handleChangeSelectedBlock("all")}
                key="all"
                sx={{
                  transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
                  borderRadius: 2,
                  p: 1,
                  background:
                    selectedBlock === "all"
                      ? theme.customPalette.bluePurple.lighter
                      : "transparent",

                  cursor: "pointer",
                }}
              >
                <Tooltip title={t("filter_all_algos")}>
                  <Box>
                    <Text
                      customStyle={{
                        color:
                          selectedBlock === "all"
                            ? theme.customPalette.bluePurple.dark
                            : theme.customPalette.grey.default,
                        maxWidth: 230,
                      }}
                      noWrap
                    >
                      {t("tous")}
                    </Text>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {tab === "filters" && (
              <Box
                onClick={() =>
                  handleChangeSelectedBlock("promote_and_pin_items")
                }
                key="promote_and_pin_items"
                sx={{
                  transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
                  borderRadius: 2,
                  p: 1,
                  background:
                    selectedBlock === "promote_and_pin_items"
                      ? theme.customPalette.bluePurple.lighter
                      : "transparent",

                  cursor: "pointer",
                }}
              >
                <Tooltip title={t("filter_promote_and_pin_items")}>
                  <Box>
                    <Text
                      customStyle={{
                        color:
                          selectedBlock === "promote_and_pin_items"
                            ? theme.customPalette.bluePurple.dark
                            : theme.customPalette.grey.default,
                        maxWidth: 230,
                      }}
                      noWrap
                    >
                      {t("promote_and_pin_items")}
                    </Text>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {blocksMap.map((block: Dic<any>, index: number) => (
              <Box
                onClick={() => handleChangeSelectedBlock(block.id)}
                key={block.id}
                sx={{
                  transition: "background 0.3s cubic-bezier(0, 1.5, .8, 1)",
                  borderRadius: 2,
                  p: 1,
                  background:
                    block.id === selectedBlock
                      ? theme.customPalette.bluePurple.lighter
                      : "transparent",

                  cursor: "pointer",
                }}
              >
                <Tooltip title={block.name}>
                  <Box>
                    <Text
                      customStyle={{
                        color:
                          block.id === selectedBlock
                            ? theme.customPalette.bluePurple.dark
                            : theme.customPalette.grey.default,
                        maxWidth: 230,
                      }}
                      noWrap
                    >
                      {!!block.name
                        ? block.name
                        : `${t("products_group")} ${index + 1}`}
                    </Text>
                  </Box>
                </Tooltip>
              </Box>
            ))}
          </Stack>
        )}
        <Box>
          {getTab(
            tab,
            selectedBlock,
            algorithmIdInRecoAndMerch,
            experienceType
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default BlocksTab;
