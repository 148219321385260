import { DocumentNode } from "graphql";
import { client } from "@/index";
import { StatusType } from "@/utils/helpers/status/types";
import { Dispatch, SetStateAction } from "react";
import { StatusEnum } from "../status/enums";
import { FetchResult } from "apollo-link";

export const doMutation = (
  event: React.SyntheticEvent | null,
  gqlRequest: DocumentNode,
  variables: any,
  setStatus: Dispatch<SetStateAction<StatusType>>,
  thenFunction: (res: Dic<any>) => void
) => {
  if (event) {
    event.stopPropagation();
    event.preventDefault();
  }
  setStatus(StatusEnum.LOADING);
  client
    .mutate({
      mutation: gqlRequest,
      variables,
      fetchPolicy: "network-only",
    })
    .then((res) => {
      thenFunction(res);
      setStatus(StatusEnum.NORMAL);
    })
    .catch(() => setStatus(StatusEnum.ERROR));
};
