import { Box, Stack, Text, Tooltip } from "@includes";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { calculateTendance } from "./helpers";
import { ITendency } from "./types";

const Tendency: React.FC<ITendency> = ({ newValue, oldValue }) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { value, isPositive, hide } = useMemo(() => {
    if (newValue && oldValue) return calculateTendance(newValue, oldValue);
    return { value: "+ 0", isPositive: true, hide: true };
  }, [newValue, oldValue]);

  if (hide) return null;

  if (isPositive) {
    return (
      <div>
        <Tooltip title={t("tendency_tooltip")}>
          <Stack
            spacing={0.5}
            alignItems="center"
            direction="row"
            sx={{
              px: 1,
              py: 0.5,
              borderRadius: 2,
              background: theme.customPalette.success.complementary,
            }}
          >
            <Box
              component="img"
              src={`/${theme.customPalette.mode}/icons/tendency_positive.svg`}
            />
            <Text textType="success" variant="body2">
              {value}
            </Text>
          </Stack>
        </Tooltip>
      </div>
    );
  }

  return (
    <div>
      <Tooltip title={t("tendency_tooltip")}>
        <Stack
          spacing={0.5}
          alignItems="center"
          direction="row"
          sx={{
            px: 1,
            py: 0.5,
            borderRadius: 2,
            background: theme.customPalette.error.complementary,
          }}
        >
          <Box
            component="img"
            src={`/${theme.customPalette.mode}/icons/tendency_negative.svg`}
          />
          <Text textType="error" variant="body2">
            {value}
          </Text>
        </Stack>
      </Tooltip>
    </div>
  );
};

export default Tendency;
