import { gql } from "@apollo/client";

export const GET_ENGAGEMENT_MEASURE_DATA = gql`
  query Monitor(
    $siteId: SiteID!
    $devices: [String!]!
    $startDate: String!
    $endDate: String!
    $previousStartDate: String!
    $previousEndDate: String!
    $segment: String!
  ) {
    currentPeriod: monitor(
      siteId: $siteId
      segment: $segment
      devices: $devices
      query: COMMITMENT
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    previousPeriod: monitor(
      siteId: $siteId
      segment: $segment
      devices: $devices
      query: COMMITMENT
      startDate: $previousStartDate
      endDate: $previousEndDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
