import { useSelector } from "react-redux";
import { swap } from "react-grid-dnd";
import { RootState } from "@store";
import { FILTERED_PRODUCT_PROPS, comparisonUtils } from "@utils";
import { isNil } from "lodash";

const useDraggableGridViewLogic = (
  pushProduct: (id: string, index: number) => void,
  items: Array<any>,
  setItemsToShow: (items: Array<any>) => void
) => {
  const additionnalProperties = useSelector(
    (state: RootState) => state.product.properties
  );

  const onChange = (
    _sourceId: any,
    sourceIndex: number,
    targetIndex: number,
    _targetId: any
  ) => {
    if (!isNil(sourceIndex)) {
      pushProduct(items[sourceIndex]?.id, targetIndex + 1);
      const nextState = swap(items, sourceIndex, targetIndex);
      setItemsToShow(nextState);
    }
  };

  // rowHeight = fixedHeight + 40 * (number of additional props to show -> price, category,...)
  const rowHeight =
    280 +
    40 *
      (additionnalProperties?.filter(
        (p: string) =>
          !FILTERED_PRODUCT_PROPS?.find(comparisonUtils.areEquals(p))
      )?.length +
        1);

  return { rowHeight, onChange };
};

export default useDraggableGridViewLogic;
