import { SyncStatusEnum } from "@/components/_Organisms/SettingsSyncs/components/SyncsList/enums";
import { useTheme } from "@mui/material";

export const getColorsByStatus = (status: SyncStatusEnum) => {
  const theme = useTheme();
  if (status === SyncStatusEnum.RUNNING)
    return theme.customPalette.bluePurple.dark;
  if (status === SyncStatusEnum.FAILED) return theme.customPalette.error.main;
  if (status === SyncStatusEnum.SUCCEEDED)
    return theme.customPalette.success.main;
  return theme.customPalette.bluePurple.light;
};

export const getTextByStatus = (status: SyncStatusEnum) => {
  switch (status) {
    case SyncStatusEnum.RUNNING:
      return "sync_in_progress";
    case SyncStatusEnum.SCHEDULED:
      return "sync_in_pending";
    case SyncStatusEnum.FAILED:
      return "last_sync_failed";
    case SyncStatusEnum.SUCCEEDED:
      return "last_sync_succeeded";
    default:
      return "";
  }
};
