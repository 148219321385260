import { ClickAwayListener, Popper } from "@mui/material";
import { useEffect } from "react";
import SpringFade from "../SpringFade";
import { ICustomPopper } from "./types";

const addBackdropToDom = (onClick: () => void, backdrop: boolean) => {
  let obj = document.createElement("div");
  obj.id = "my-backdrop";
  obj.style.cssText = ` position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: ${backdrop ? "rgba(0, 0, 0, 0.5)" : "transparent"};
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    z-index: ${backdrop ? "1300" : "0"};`;

  obj.addEventListener("click", onClick);

  document.body.appendChild(obj);
};

const removeBackdropFromDom = () => {
  const backdrop = document.getElementById("my-backdrop");
  if (backdrop) backdrop.remove();
};

const CustomPopper: React.FC<ICustomPopper> = ({
  children,
  open,
  backdrop,
  handleClose,
  ...rest
}) => {
  useEffect(() => {
    if (open) addBackdropToDom(handleClose, !!backdrop);
    return () => removeBackdropFromDom();
  }, [open, backdrop]);
  return (
    <Popper
      transition
      placement="bottom-start"
      open={open}
      sx={{ zIndex: 1301 }}
      {...rest}
    >
      {({ TransitionProps }: any) => (
        <SpringFade {...TransitionProps}>
          <ClickAwayListener onClickAway={() => handleClose()}>
            <>{children}</>
          </ClickAwayListener>
        </SpringFade>
      )}
    </Popper>
  );
};

CustomPopper.defaultProps = {
  backdrop: false,
};

export default CustomPopper;
