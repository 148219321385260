import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Text } from "@/includes";
import { DesktopWrapper } from "../../Wrappers";
import React from "react";
import { IDataGridServerPagination } from "./types";

const DataGridServerPagination: React.FC<IDataGridServerPagination> = ({
  totalItems,
  pageSize,
  handleChangePageSize,
  next,
  previous,
  hasNextPage,
  hasPreviousPage,
  currentPage,
}) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      sx={{
        pl: 1,
        pr: 1,
        borderTop: `1px solid ${theme.customPalette.others.background}`,
        pt: 2,
        pb: 1,
      }}
    >
      <Text>
        {t("total_rows")}: {totalItems}
      </Text>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          onClick={previous}
          disabled={!hasPreviousPage}
          sx={{
            border: "1px solid",
            borderColor: hasPreviousPage
              ? theme.customPalette.grey.disabled
              : "inherit",
            "& svg": {
              color: hasPreviousPage
                ? theme.customPalette.grey.disabled
                : "inherit",
            },
            p: 1,
            borderRadius: 3,
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Text>{currentPage + 1}</Text>
        <IconButton
          onClick={next}
          disabled={!hasNextPage}
          sx={{
            border: "1px solid",
            borderColor: hasNextPage
              ? theme.customPalette.grey.disabled
              : "inherit",
            p: 1,
            "& svg": {
              color: hasNextPage
                ? theme.customPalette.grey.disabled
                : "inherit",
            },
            borderRadius: 3,
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Stack>
      <DesktopWrapper>
        <Stack spacing={1} direction="row" alignItems="center">
          <FormControl
            sx={{
              m: 1,
              width: 120,
            }}
            size="small"
          >
            <InputLabel id="demo-select-small">{t("page_size")}</InputLabel>
            <Select
              labelId="select-small"
              id="select-small"
              value={pageSize?.toString()}
              label={t("page_size")}
              onChange={(e: SelectChangeEvent) => {
                e.stopPropagation();
                handleChangePageSize(parseInt(e?.target?.value, 10));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Text>{t("rows_per_page")}</Text>
        </Stack>
      </DesktopWrapper>
    </Stack>
  );
};

export default DataGridServerPagination;
