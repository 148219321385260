export const labelOfAbTestEvolutionMetric = {
  CUMULATED_REVENUES_PER_VISITOR_BY_DAY: "revenues_per_visitor",
  CUMULATED_REVENUES_PER_TRANSACTION_BY_DAY: "revenues_per_transaction",
  CUMULATED_TRANSACTION_PER_VISITOR_BY_DAY: "transactions_per_visitor",
  CUMULATED_VISITORS_BY_DAY: "cumulated_exposed",
  CUMULATED_REVENUES_BY_DAY: "cumulated_revenues",
  CUMULATED_TRANSACTIONS_BY_DAY: "cumulated_transactions",
};

export enum AB_TEST_EVOLUTION_METRICS_ENUM {
  REVENUES_PER_VISITOR = "CUMULATED_REVENUES_PER_VISITOR_BY_DAY",
  REVENUES_PER_TRANSACTIONS = "CUMULATED_REVENUES_PER_TRANSACTION_BY_DAY",
  TRANSACTIONS_PER_VISITOR = "CUMULATED_TRANSACTION_PER_VISITOR_BY_DAY",
  EXPOSED = "CUMULATED_VISITORS_BY_DAY",
  REVENUES = "CUMULATED_REVENUES_BY_DAY",
  TRANSACTIONS = "CUMULATED_TRANSACTIONS_BY_DAY",
}

export const yAxisUnit = {
  CUMULATED_REVENUES_PER_VISITOR_BY_DAY: "currency",
  CUMULATED_REVENUES_PER_TRANSACTION_BY_DAY: "currency",
  CUMULATED_TRANSACTION_PER_VISITOR_BY_DAY: "float",
  CUMULATED_VISITORS_BY_DAY: "number",
  CUMULATED_REVENUES_BY_DAY: "currency",
  CUMULATED_TRANSACTIONS_BY_DAY: "number",
};
