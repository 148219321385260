import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query getCategories($catalogId: CatalogID!, $first: Int!) {
    categories(catalogId: $catalogId, first: $first, sort: "title") {
      edges {
        node {
          id
          title
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
