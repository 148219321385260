import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import _ from "lodash";

/**
 * Utility class for various object-related operations.
 */
export class ObjectUtils {
  /**
   * Sorts the keys of an object.
   *
   * @param json - An object.
   * @returns The object with all its keys sorted.
   */
  sortKeys = (json: Dic<any>) =>
    Object.keys(json)
      .sort()
      .reduce(
        (result: Dic<any>, key: string) =>
          Object.assign({}, result, { [key]: json[key] }),
        {}
      );

  /**
   * Checks if a nested object contains a property at the specified path.
   *
   * @param path - The path to the key ("a.b.c" ) for nested objects.
   * @param object - An object.
   * @returns True if the sub-object in the path contains the property, false otherwise.
   */
  hasProperty = (path: string) => (object: Dic<any>) => !!_.get(object, path);

  /**
   * Retrieves the property at the specified path in a nested object.
   *
   * @param path - The path to the key ("a.b.c" ) for nested objects.
   * @param object - An object.
   * @returns The property at the path.
   */
  getProperty = (path: string) => (object: Dic<any>) => _.get(object, path);

  /**
   * Checks if two objects are equal.
   *
   * @param obj1 - An object.
   * @param obj2 - Another object.
   * @returns True if the two objects are equal, false otherwise.
   */
  areObjectsEquals = (obj1: Dic<any>, obj2: Dic<any>) => {
    if (!obj1 && !obj2) return true;
    if (!obj1 || !obj2) return false;
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) return true;
    return _.isEqual(obj1, obj2);
  };

  /**
   * Finds the path to a key in an object.
   *
   * @param ob - An object.
   * @param key - A searched key in the object.
   * @returns The path to the key in the object (e.g., "a.b[1]").
   */
  findPath = (ob: Dic<any>, key: string) => {
    const path: Array<any> = [];
    const keyExists = (obj: Dic<any>) => {
      if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
        return false;
      } else if (obj.hasOwnProperty(key)) {
        return true;
      } else if (Array.isArray(obj)) {
        let parentKey = path.length ? path.pop() : "";

        for (let i = 0; i < obj.length; i++) {
          path.push(`${parentKey}[${i}]`);
          const result: any = keyExists(obj[i]);
          if (result) {
            return result;
          }
          path.pop();
        }
      } else {
        for (const k in obj) {
          path.push(k);
          const result: any = keyExists(obj[k]);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
      return false;
    };

    keyExists(ob);

    return path.join(".");
  };

  /**
   * Formats a key by replacing "." with "_".
   *
   * @param key - A key.
   * @returns The formatted key (e.g., "test.test" -> "test_test").
   */
  formatKey = (key: string) => key.split(".").join("_");

  /**
   * Formats all keys in an object by replacing "." with "_".
   *
   * @param obj - An object.
   * @returns The object with all its keys formatted.
   */
  formatKeys = (obj: Dic<any>) => {
    return Object.entries(obj)
      .map(([key, value]) => ({
        [this.formatKey(key)]: value,
      }))
      .reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key];
        return acc;
      }, {});
  };

  /**
   * Gets the first value of the object, considering the language if provided.
   *
   * @param object - An object.
   * @returns The first value of the object, in the given language if provided.
   */
  getFirstObjectValue = (object: Dic<any>) => {
    if (!object) return "";
    const language = potionsLocalStorage.langue ?? "fr";
    const keys = Object.keys(object);

    let res = Object.values(object)?.[0];

    keys.forEach((key) => {
      if (key?.toLocaleLowerCase()?.includes(language)) res = object[key];
    });

    return res;
  };

  /**
   * Calculates the maximum depth of a nested object.
   *
   * @param o - An object.
   * @param depth - The current depth of the object (used in recursion).
   * @returns The maximum depth of a nested object.
   */
  maxDepth = (o: Dic<any>, depth = 0): number => {
    if (typeof o !== "object") throw new TypeError();
    return Math.max(
      depth,
      ...Object.values(o)
        .filter((v) => typeof v === "object")
        .map((v: Dic<any>) => this.maxDepth(v, depth + 1))
    );
  };

  /**
   * Merges a nested object with two levels into a flatten object.
   *
   * @param data - A nested object with 2 levels.
   * @returns A flattened object.
   */
  mergeData = (data: Dic<Dic<any>>): Dic<any> => {
    const res = {};
    Object.keys(data).forEach((key: string) => {
      _.merge(res, data[key]);
    });
    return res;
  };

  /**
   * Renames keys in an object based on a dictionary of new keys.
   *
   * @param obj - An object.
   * @param newKeys - A dictionary { oldKey: newKey }.
   * @returns A new object with the renamed keys.
   */
  renameKeys = (obj: Dic<any>, newKeys: Dic<string>) => {
    const keyValues = Object.keys(obj).map((key) => {
      const newKey = newKeys[key] || key;
      return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
  };
}
