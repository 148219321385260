import { useTheme, Box } from "@mui/material";
import Text from "../../_Atoms/Text";
import { escapeRegExp } from "./formatters";

const Highlight = ({ search = "", children = "", customStyle = {} }: any) => {
  const theme = useTheme();
  const patt = new RegExp(`(${escapeRegExp(search)})`, "i");
  const parts = String(children).split(patt);

  if (search) {
    return (
      <Text noWrap customStyle={{ maxWidth: "100%" }}>
        {parts.map((part) =>
          patt.test(part) ? (
            <Box
              component="mark"
              key={part}
              sx={{
                backgroundColor: theme.customPalette.others.background,
                borderRadius: 2,
                padding: "4px 0",
                color: theme.customPalette.grey.default,
                fontFamily: "Poppins",
                whiteSpace: "nowrap",
              }}
            >
              {part}
            </Box>
          ) : (
            part
          )
        )}
      </Text>
    );
  }
  return (
    <Text noWrap customStyle={{ maxWidth: "100%" }}>
      {children}
    </Text>
  );
};

export default Highlight;
