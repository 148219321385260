import { gql } from "@apollo/client";

export const GET_CATALOG_ID = gql`
  query get($id: SiteID!) {
    site(siteId: $id) {
      catalogs {
        edges {
          node {
            id
            name
            settings
          }
        }
      }
    }
  }
`;
