import { gql } from "@apollo/client";

export const GET_EXPERIENCE = gql`
  query experience($id: ExperienceID!) {
    experience(experienceId: $id) {
      name
      deployedAt
      id
      uuid
      locationId
      settings
      isMonitorable
      collection {
        id
        name
        settings
      }
      type
    }
  }
`;
