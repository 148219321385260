import { CommonFieldTypes, EndIDFieldType } from "./enums";

export const getDefaultValueFromType = (type: string): any => {
  switch (type) {
    case CommonFieldTypes.STRING:
    case CommonFieldTypes.HOSTNAME:
      return "";
    case CommonFieldTypes.BOOLEAN:
      return false;
    case CommonFieldTypes.INT:
      return 0;
    default:
      return "";
  }
};
