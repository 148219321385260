import * as React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { SelectPaper, StyledTextField } from "../../../includes";
import { Chip } from "@mui/material";
import { IMultipleSelect, OptionType } from "./types";

const filter = createFilterOptions<OptionType>();

const MultipleSelect: React.FC<IMultipleSelect> = ({
  values,
  handleChange,
  disabled,
}) => {
  const [value, setValue] = React.useState<OptionType | null>(null);

  const [options, setOptions] = React.useState<Array<any>>(
    Array.isArray(values) ? values?.map((v: any) => ({ title: v })) : []
  );

  React.useEffect(() => {
    if (Array.isArray(options))
      handleChange(options?.map((o: Dic<string>) => o?.title));
  }, [options]);

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      value={options}
      PaperComponent={SelectPaper}
      onChange={(event, _newValue) => {
        const newValue = _newValue?.[_newValue?.length - 1];
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
          setOptions([
            ...options,
            {
              title: newValue.inputValue,
            },
          ]);
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option?.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      disableClearable
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      sx={{ minWidth: 200, maxWidth: 300 }}
      freeSolo
      renderTags={(value: any, getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            variant="outlined"
            label={option?.title}
            {...getTagProps({ index })}
            onDelete={(e: any) =>
              setOptions(
                options.filter((o: Dic<string>) => o?.title !== option?.title)
              )
            }
          />
        ))
      }
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label=""
          name=""
          value={options?.map((o: Dic<string>) => o?.title)?.join("")}
        />
      )}
    />
  );
};

MultipleSelect.defaultProps = {
  disabled: false,
};

export default MultipleSelect;
