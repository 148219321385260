import { setRole } from "@/services/redux/actions/user-actions";
import { RootState } from "@/services/redux/store";
import { ROLE_ENUM } from "@/utils/enums/roles";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const useRole = () => {
  const isSuperUser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const role = useSelector((state: RootState) => state.login.role);
  const accountId = useSelector((state: RootState) => state.account.accountId);
  const user = useSelector((state: RootState) => state.login.user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(user) && !!accountId) {
      const role =
        user.companyUsers?.find(
          (companyUser: any) => companyUser.companyId == accountId
        )?.role ?? ROLE_ENUM.READER;
      dispatch(setRole(role));
    }
  }, [accountId, user]);

  return { role, isSuperUser };
};

export default useRole;
