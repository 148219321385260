import { Box, useTheme } from "@mui/material";
import { IIcon } from "./types";

const Icon: React.FC<IIcon> = ({ backgroundColor, borderColor, children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: backgroundColor,
        width: "fit-content",
        padding: "4px",
        border: `1px solid ${borderColor}`,
        borderRadius: 2,
      }}
    >
      {children}
    </Box>
  );
};

export default Icon;
