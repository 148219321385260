import ReplayIcon from "@mui/icons-material/Replay";

interface IRefreshIcon {
  color?: string;
}

const RefreshIcon: React.FC<IRefreshIcon> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="refreshIcon"
    >
      <g id="Size=24px">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 5.75C8.54822 5.75 5.75 8.54822 5.75 12C5.75 15.4518 8.54822 18.25 12 18.25C14.4921 18.25 16.645 16.7915 17.6489 14.6782C17.8267 14.304 18.274 14.1448 18.6482 14.3226C19.0223 14.5003 19.1816 14.9477 19.0038 15.3218C17.7608 17.9384 15.0927 19.75 12 19.75C7.71979 19.75 4.25 16.2802 4.25 12C4.25 7.71979 7.71979 4.25 12 4.25C14.8695 4.25 17.3738 5.80993 18.7127 8.12445C18.9201 8.483 18.7976 8.94179 18.4391 9.1492C18.0805 9.35661 17.6217 9.23409 17.4143 8.87554C16.3325 7.00546 14.3124 5.75 12 5.75Z"
            fill={color}
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3863 3.91285C17.7612 3.7368 18.2079 3.89803 18.3839 4.27296L17.7469 4.57207C18.3839 4.27297 18.3839 4.27288 18.3839 4.27296L18.3855 4.27629L18.3891 4.2841L18.4024 4.31268C18.4138 4.33733 18.4301 4.37294 18.4506 4.41815C18.4915 4.50851 18.549 4.63747 18.6165 4.79393C18.7509 5.10577 18.927 5.53182 19.0896 5.98181C19.2503 6.42633 19.4072 6.91921 19.4932 7.35795C19.536 7.57618 19.5667 7.8079 19.563 8.0277C19.5595 8.22792 19.527 8.52761 19.3433 8.78994C19.1597 9.05224 18.8892 9.18532 18.7023 9.25703C18.497 9.33577 18.2687 9.38619 18.049 9.4207C17.6073 9.49009 17.0904 9.5114 16.6176 9.51257C16.139 9.51377 15.6784 9.49423 15.3393 9.47465C15.1691 9.46482 15.0282 9.45491 14.9293 9.4474C14.8798 9.44364 14.8408 9.44048 14.8137 9.43823L14.7823 9.43557L14.7737 9.43482L14.7703 9.43451C14.7702 9.4345 14.7701 9.43449 14.8372 8.6875L14.7703 9.43451C14.3577 9.39746 14.0531 9.03296 14.0902 8.62041C14.1272 8.20789 14.4916 7.90351 14.9041 7.94049C14.9041 7.94049 14.9042 7.9405 14.9041 7.94049L14.9056 7.94062L14.9118 7.94117L14.9382 7.94341C14.9618 7.94537 14.9972 7.94824 15.0428 7.9517C15.1341 7.95863 15.2659 7.96791 15.4257 7.97714C15.7467 7.99567 16.1753 8.01367 16.6139 8.01258C17.0583 8.01147 17.4865 7.99067 17.8163 7.93887C17.9146 7.92342 17.9948 7.90662 18.058 7.89002C18.052 7.82497 18.0403 7.74401 18.0212 7.64642C17.957 7.31892 17.83 6.90962 17.679 6.49178C17.5299 6.07942 17.3663 5.68295 17.2391 5.38784C17.1757 5.24083 17.1218 5.12022 17.0841 5.03687C17.0653 4.99522 17.0504 4.96295 17.0405 4.94142L17.0294 4.91738L17.0267 4.91173L17.0262 4.91056C17.0262 4.91059 17.0262 4.91053 17.0262 4.91056M17.3863 3.91285C17.0114 4.08889 16.8502 4.53564 17.0262 4.91056L17.3863 3.91285Z"
            fill={color}
          ></path>
        </g>
      </g>
    </svg>
  );
};

RefreshIcon.defaultProps = {
  color: "transparent",
};

export default RefreshIcon;
