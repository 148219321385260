import { useState, useEffect } from "react";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Collapse,
  IconButton,
  KeyboardArrowDownIcon,
  Text,
  Stack,
  Checkbox,
  Layer1Box,
  Layer2Box,
} from "@includes";
import ShortUniqueId from "short-unique-id";
import { useTheme } from "@mui/material";
import { IHideShowBlock } from "./types";

// A Block with Collapse to hide/show inner content
const HideShowBlock: React.FC<IHideShowBlock> = ({
  title,
  children,
  initialState,
  isCheckbox,
  disableBlock,
  slot,
  subtitle,
  titleTooltip,
  onBlocStateChange,
  icon,
  disabled,
}) => {
  const theme = useTheme();
  const [showBlock, setShowBlock] = useState<boolean>(!!initialState);

  useEffect(() => {
    setShowBlock(!!initialState);
  }, [initialState]);

  useEffect(() => {
    if (!showBlock && isCheckbox && !!disableBlock) {
      disableBlock();
    }
    if (onBlocStateChange) onBlocStateChange(!!showBlock);
  }, [showBlock]);

  const uid = new ShortUniqueId();

  const id = isCheckbox ? "temporary-products" : uid();

  const handleChange = () => {
    if (!disabled) setShowBlock(!showBlock);
  };

  return (
    <Stack sx={{ borderRadius: 3 }}>
      <Layer2Box
        sx={{
          borderBottomLeftRadius: !showBlock ? 3 : 0,
          borderBottomRightRadius: !showBlock ? 3 : 0,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          cursor: disabled ? "initial" : "pointer",
        }}
        onClick={handleChange}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              maxWidth: "calc(100% - 24px)",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Text variant="body1" noWrap customStyle={{ fontWeight: 700 }}>
                {title}
              </Text>
              {titleTooltip}
            </Stack>
          </Stack>
          {isCheckbox && (
            <Checkbox
              disabled={disabled}
              id={id}
              sx={{ p: "3px" }}
              checked={showBlock}
              onChange={handleChange}
            />
          )}
          {!isCheckbox && !icon && (
            <IconButton
              disabled={disabled}
              onClick={handleChange}
              sx={{ padding: 1 }}
            >
              {!showBlock ? <KeyboardArrowRight /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}

          {!isCheckbox && icon && !showBlock && (
            <IconButton onClick={handleChange} sx={{ padding: 1 }}>
              {icon}
            </IconButton>
          )}
        </Stack>
      </Layer2Box>
      <Collapse in={showBlock}>
        <Layer1Box
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: 0,
          }}
        >
          <Stack mx={2} mt={1} mb={2}>
            <Stack spacing={1}>
              {slot}
              <div>{children}</div>
            </Stack>
          </Stack>
        </Layer1Box>
      </Collapse>
    </Stack>
  );
};

HideShowBlock.defaultProps = {
  initialState: false,
  isCheckbox: false,
  disableBlock: () => null,
  slot: null,
  subtitle: undefined,
  titleTooltip: undefined,
  onBlocStateChange: () => null,
  icon: undefined,
  disabled: false,
};

export default HideShowBlock;
