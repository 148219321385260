import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@includes";
import EditSettingsDialog from "./components/EditSettingsDialog";
import { SettingsIcon } from "@/assets/icons";
import { IPreviewViewSettings } from "./types";

const PreviewViewSettings: React.FC<IPreviewViewSettings> = ({
  isSlider,
  deviceObj,
}) => {
  const { t }: i18translateType = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <>
      <EditSettingsDialog
        open={open}
        handleClose={handleClose}
        deviceObj={deviceObj}
        showDeviceSelector={!isSlider}
      />
      <Button
        id="settings"
        onClick={handleOpen}
        startIcon={<SettingsIcon />}
        btnVariant="outline"
      >
        {t("preview_settings")}
      </Button>
    </>
  );
};

PreviewViewSettings.defaultProps = {
  isSlider: false,
  deviceObj: undefined,
};

export default PreviewViewSettings;
