import AlgorithmRule from "./components/AlgorithmRule";
import AlgorithmProvider from "../../../hooks/useConfigureAlgorithm";
import { IAlgorithm } from "./types";

const Algorithm: React.FC<IAlgorithm> = ({
  ruleId,
  experienceType,
  slot,
  slotProps,
  handleChangeExperience,
  availableVariables,
  rule,
  experience,
}) => {
  return (
    <AlgorithmProvider>
      <AlgorithmRule
        handleChangeExperience={handleChangeExperience}
        ruleId={ruleId}
        experienceType={experienceType}
        slot={slot}
        slotProps={slotProps}
        availableVariables={availableVariables}
        rule={rule}
        experience={experience}
      />
    </AlgorithmProvider>
  );
};

export default Algorithm;
