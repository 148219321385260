export const getPaperStyle = (theme: any) => ({
  background: theme.customPalette.others.layer_1,
  border: "1px solid",
  borderColor: theme.customPalette.others.background,
  "& .MuiPickersDay-root": {
    background: "transparent",
  },
  "& .MuiPickersDay-root:hover": {
    background: theme.customPalette.others.background,
  },
  "& .MuiPickersDay-root.Mui-disabled": {
    color: theme.customPalette.others.background,
  },
  "& .MuiPickersDay-root.Mui-selected": {
    background: theme.customPalette.others.background,
    color: theme.customPalette.grey.default,
  },
  "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
    background: theme.customPalette.others.layer_2,
  },
  "& .MuiDateRangePickerDay-dayInsideRangeInterval": {
    color: theme.customPalette.grey.default,
  },
});
