import { Box } from "@/includes";
import { formatUtils } from "@/utils";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import EvolutionLegend from "../EvolutionLegend";
import { yAxisUnit } from "./enums";
import { EvolutionGraphProps } from "./types";
import { labelOfEvolutionMetric } from "../Evolution/enums";
import CustomTooltip from "../EvolutionTooltip";

const Graph = ({ data, evolutionMetric, compare }: EvolutionGraphProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: theme.customPalette.others.layer_1,
        width: "100%",
        "& .recharts-legend-item-text": {
          color: theme.customPalette.grey.default,
        },
        "& .recharts-cartesian-grid-horizontal line": {
          stroke: theme.customPalette.others.background,
        },
      }}
    >
      <ResponsiveContainer height={400} width="100%">
        <ComposedChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            fill="transparent"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            stroke={theme.customPalette.grey.default}
            axisLine={false}
            tickFormatter={(v) => {
              return dayjs(v).format("DD.MM");
            }}
          />

          <YAxis
            stroke={theme.customPalette.grey.default}
            axisLine={false}
            tickFormatter={(v) => {
              if (yAxisUnit[evolutionMetric] === "percent")
                return formatUtils.formatToPercent(v);
              return v;
            }}
          />
          <Area
            dot={data.length <= 45}
            strokeWidth={3}
            type="monotone"
            dataKey={"current"}
            stroke={theme.customPalette.bluePurple.light}
            fill={theme.customPalette.bluePurple.slighty}
          />
          {!compare && (
            <Line
              strokeDasharray="5 5"
              dot={data.length <= 45}
              strokeWidth={1}
              type="monotone"
              dataKey={`previous`}
              stroke={theme.customPalette.bluePurple.light}
              fill={theme.customPalette.bluePurple.slighty}
            />
          )}
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
            animationDuration={200}
            content={<CustomTooltip compare={compare} />}
          />
          <Legend
            content={
              <EvolutionLegend
                label={t(labelOfEvolutionMetric[evolutionMetric])}
                compare={compare}
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Graph;
