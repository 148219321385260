interface IEmailIcon {
  color?: string;
}

const NetworkIcon: React.FC<IEmailIcon> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <mask
        id="mask0_1138_37308"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="48"
        height="48"
      >
        <rect width="48" height="48" fill="#FAF9FA" />
      </mask>
      <g mask="url(#mask0_1138_37308)">
        <path
          d="M8 40C6.9 40 5.95867 39.6087 5.176 38.826C4.392 38.042 4 37.1 4 36V12C4 10.9 4.392 9.95867 5.176 9.176C5.95867 8.392 6.9 8 8 8H40C41.1 8 42.042 8.392 42.826 9.176C43.6087 9.95867 44 10.9 44 12V36C44 37.1 43.6087 38.042 42.826 38.826C42.042 39.6087 41.1 40 40 40H8ZM24 26L8 16V36H40V16L24 26ZM24 22L40 12H8L24 22ZM8 16V12V36V16Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

NetworkIcon.defaultProps = {
  color: "transparent",
};

export default NetworkIcon;
