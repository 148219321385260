import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }: any) => ({
  "& :-webkit-autofill": {
    WebkitBoxShadow: `0 0 0 1000px ${theme.customPalette.others.background} inset !important`,
  },
  "& .MuiInputBase-root": {
    backgroundColor: "transparent",
  },
  "& label.Mui-focused": {
    color: theme.customPalette.grey.default,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.customPalette.others.background,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.customPalette.grey.light,
    },
    "&:hover fieldset": {
      borderColor: theme.customPalette.grey.light,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.customPalette.grey.light,
    },
  },
}));

export default StyledTextField;
