import { QueryRowType } from "@/utils/types/graphql";
import { AB_TEST_EVOLUTION_METRICS_ENUM } from "../../enums";
import { GraphDataType } from "../../types";
import { ResponseAbTestEvolutionDataType } from "./types";

export const formatDataForGraph = (
  data: ResponseAbTestEvolutionDataType | null,
  metricLabel: AB_TEST_EVOLUTION_METRICS_ENUM,
  currency: string = "€"
): Array<GraphDataType> => {
  if (!data?.data) return [];
  return data.data.ATest.rows.map((row: QueryRowType, index) => {
    return {
      date: row[0].stringValue,
      aTest: row[1].floatValue ?? 0,
      bTest: data?.data.BTest.rows[index]?.[1]?.floatValue ?? 0,
      evolutionMetricLabel: metricLabel,
      currency,
    };
  });
};
