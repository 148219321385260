import { DesktopWrapper } from "@/components/Wrappers";
import { Box } from "@includes";
import { useTheme } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { OpenNavbarProps } from "./types";
import BackMenu from "@/assets/icons/BackMenu";
import OpenMenu from "@/assets/icons/OpenMenu";
import { useState } from "react";

const OpenNavbar = ({ open, setOpen }: OpenNavbarProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState<boolean>(false);
  return (
    <DesktopWrapper>
      <Box
        sx={{
          position: "absolute",
          right: "-10px",
          top: "5px",
          backgroundColor: theme.customPalette.others.layer_1,
          borderRadius: 2.5,
          cursor: "pointer",

          height: "24px",
          width: "24px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => setOpen((!open).toString())}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {open ? (
          <BackMenu
            color={
              hover
                ? theme.customPalette.bluePurple.dark
                : theme.customPalette.grey.default
            }
          />
        ) : (
          <OpenMenu
            color={
              hover
                ? theme.customPalette.bluePurple.dark
                : theme.customPalette.grey.default
            }
          />
        )}
      </Box>
    </DesktopWrapper>
  );
};

export default OpenNavbar;
