import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDebounce, useSetState, useThrottle } from "@hooks";
import { getCategories } from "../../helpers";
import { RootState } from "@store";

const useCategorySelectorLogic = (
  setCategoryId: (categoryId: string) => void
) => {
  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const [state, setState] = useState("");

  const [categories, setCategories] = useState<Array<any>>([]);

  const [selectedCategory, setSelectedCategory] = useSetState({
    title: "",
    id: "",
  });

  const [fetchState, setFetchState] = useSetState({
    cursor: "",
    hasNextPage: false,
  });

  const [search, setSearch] = useState("");

  const onSuccess = (
    data: Array<any>,
    cursor: string,
    hasNextPage: boolean
  ) => {
    setFetchState({ cursor, hasNextPage });
    setCategories(data);
    setState("");
  };

  const onError = () => {
    setState("error");
  };

  useEffect(() => {
    if (siteId)
      getCategories(
        { search: "", catalogId, cursor: fetchState.cursor },
        true,
        categories,
        onSuccess,
        onError
      );
  }, [siteId]);

  useEffect(() => {
    if (categories.length > 0 && !selectedCategory.id) {
      setSelectedCategory(categories[0]);
      setCategoryId(categories[0]?.id);
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategory) {
      setCategoryId(selectedCategory?.id);
      setSearch(
        `${selectedCategory.title} - ${selectedCategory?.id.replace(
          "gid://shopify/Collection/",
          ""
        )}`
      );
    }
  }, [selectedCategory]);

  const throttledValue = useThrottle(search);

  useDebounce(
    () => {
      getCategories(
        { throttledValue, catalogId, cursor: fetchState.cursor },
        true,
        categories,
        onSuccess,
        onError
      );
    },
    [throttledValue],
    600
  );

  const handleSearch = (e: any) => {
    setSearch(e?.target?.value);
  };

  const handleChangeSelectedCategory = (
    e: React.SyntheticEvent,
    value: any
  ) => {
    setSelectedCategory(value);
  };

  return {
    error: state === "error",
    loading: state === "loading",
    categories,
    selectedCategory,
    fetchState,
    handleSearch,
    search,
    handleChangeSelectedCategory,
    setSearch,
  };
};

export default useCategorySelectorLogic;
