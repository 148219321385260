import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSentry } from "@/hooks";
import { sendNotification } from "@/services/redux/actions";
import { useTranslation } from "react-i18next";

type SaveExperienceError = { message: string };

const ERROR_MESSAGES = (t: (text: string) => string): Dic<string> => ({
  FEED_NOT_FOUND: t("feed_not_found"),
  FEED_PERMISSION_MISSING: t("feed_permission_missing"),
  INVALID_TOKEN: t("invalid_token"),
  DUPLICATE_FEED_NAME: t("duplicate_feed_name_error"),
  UNKNOWN_ERROR: t("default_error"),
});

const useShowErrorMessage = () => {
  const { captureError } = useSentry();
  const dispatch = useDispatch();
  const { t }: i18translateType = useTranslation();
  const showErrorMessage = (error: SaveExperienceError): string | void => {
    if (typeof error === "object") {
      captureError(error.message);
      dispatch(
        sendNotification(
          "",
          t("error_saving_experience"),
          ERROR_MESSAGES(t)?.[error.message] ?? t("default_error"),
          "error"
        )
      );
    }
  };

  return { showErrorMessage };
};
export default useShowErrorMessage;
