import {
  AutoComplete,
  Box,
  SelectPaper,
  StyledTextField,
  Text,
} from "@/includes";
import HelpOutline from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useAlgorithms from "../../../../../hooks/useAlgorithms";
import { IAlgorithmSelector } from "./types";
import { isEmpty, some } from "lodash";
import { getTraduction } from "@/utils/helpers/traductions";

const AlgorithmSelector: React.FC<IAlgorithmSelector> = ({
  selectedAlgorithm,
  handleChangeAlgorithm,
  availableVariables = [],
  algorithmIdInRecoAndMerch,
  experienceType,
}) => {
  const { t }: i18translateType = useTranslation();
  const { algorithmId } = useParams<UrlParams>();
  const { allAlgorithms } = useAlgorithms(
    algorithmId ?? algorithmIdInRecoAndMerch?.toString()
  );

  const allAlgorithmsWithNone = useMemo(
    () => [
      ...(isEmpty(availableVariables)
        ? allAlgorithms
        : allAlgorithms.filter((algorithm: Dic<any>) => {
            const variables = algorithm?.settings?.required_input_variables
              ? Object.keys(algorithm.settings.required_input_variables)
              : [];
            const hasVariables =
              experienceType === "EMERCH" ? variables.length > 0 : true;
            return (
              hasVariables &&
              variables.filter(
                (variableName: string) =>
                  !availableVariables.includes(variableName)
              ).length === 0
            );
          })),
      { name: "None", id: "", description: t("none_algo_description") },
    ],
    [allAlgorithms, availableVariables]
  );

  const algorithm = allAlgorithmsWithNone.find(
    (algorithm: Dic<any>) =>
      algorithm?.id === selectedAlgorithm ||
      algorithm?.uuid === selectedAlgorithm
  );

  if (allAlgorithmsWithNone.length === 1 || !algorithm) return null;

  return (
    <AutoComplete
      size="small"
      sx={{ width: "100%" }}
      disableClearable
      value={algorithm}
      getOptionLabel={(option: Dic<any>) => option.name}
      limitTags={1}
      options={allAlgorithmsWithNone}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          variant="standard"
          label={"algorithm"}
          name={t("algorithm")}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Text variant="body2">{option.name}</Text>
            {option.description && (
              <Box>
                <Tooltip
                  placement="right"
                  sx={{ zIndex: 99999999 }}
                  title={option.description}
                >
                  <HelpOutline />
                </Tooltip>
              </Box>
            )}
          </Stack>
        </Box>
      )}
      onChange={handleChangeAlgorithm}
      PaperComponent={SelectPaper}
    />
  );
};

export default AlgorithmSelector;
