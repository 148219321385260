import { useBetween } from "use-between";
import { useSetState } from "@hooks";

const useError = () => {
  const [error, setError] = useSetState({
    collection: false,
    categories: false,
  });

  const setErrorCollection = () => setError({ collection: true });

  const setErrorCategories = () => setError({ categories: true });

  const removeCategoriesError = () => setError({ categories: false });

  const removeCollectionsError = () => setError({ collection: false });

  const removeErrors = () =>
    setError({
      collection: false,
      categories: false,
    });

  return {
    error,
    setErrorCollection,
    removeErrors,
    setErrorCategories,
    errorCollections: error.collection,
    errorCategories: error.categories,
    removeCategoriesError,
    removeCollectionsError,
  };
};

export const useSharedErrors = () => useBetween(useError);
