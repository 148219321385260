import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { Box, Error, Layer1Box, Text } from "@includes";
import { Skeleton, Tooltip, useTheme } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HOME_OVERVIEW_LABEL_ENUMS } from "../../enums";
import EvolutionGraph from "../EvolutionGraph";
import { formatDataForGraph } from "./formatters";
import { GET_HOME_EVOLUTION } from "./requests";
import { EvolutionProps, ResponseHomeEvolutionDataType } from "./types";

const Evolution = ({ dates, overviewSelected }: EvolutionProps) => {
  const { startDate, endDate } = dates;
  const { t } = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const [status, setStatus] = useState<StatusType>(StatusEnum.NORMAL);
  const [data, setData] = useState<object[]>([]);
  const theme = useTheme();
  const getData = () => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_HOME_EVOLUTION,
        variables: {
          siteId: parseInt(siteId),
          startDate: startDate,
          endDate: endDate,
          query: overviewSelected,
        },
        fetchPolicy: "cache-first",
      })
      .then((res: ResponseHomeEvolutionDataType) => {
        setData(formatDataForGraph(res?.data, t, overviewSelected, currency));
        setStatus(StatusEnum.NORMAL);
      })
      .catch(() => setStatus(StatusEnum.ERROR));
  };

  useEffect(() => {
    if (
      !isEmpty(startDate) &&
      !isEmpty(endDate) &&
      !isEmpty(overviewSelected)
    ) {
      getData();
    }
  }, [startDate, endDate, overviewSelected]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Layer1Box sx={{ height: "100%" }} justifyContent="space-between">
        <Tooltip title={t(HOME_OVERVIEW_LABEL_ENUMS[overviewSelected])}>
          <div>
            <Text variant="subtitle1" customStyle={{ mb: 2 }} noWrap>
              {t(HOME_OVERVIEW_LABEL_ENUMS[overviewSelected])}
            </Text>
          </div>
        </Tooltip>
        {status === StatusEnum.ERROR ? (
          <Error />
        ) : status === StatusEnum.LOADING || isEmpty(data) ? (
          <Skeleton
            width="100%"
            height={`calc(100% - ${theme.spacing(7)})`}
            animation="wave"
            variant="rounded"
            sx={{ bgcolor: (theme) => theme.customPalette.others.layer_2 }}
          />
        ) : (
          <EvolutionGraph data={data} overviewSelected={overviewSelected} />
        )}
      </Layer1Box>
    </Box>
  );
};

export default Evolution;
