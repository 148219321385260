import dayjs from "dayjs";

export const getEndDate = (abTest: Dic<any>) => {
  if (abTest?.stoppedAt) return dayjs(abTest.stoppedAt)?.format("YYYY-MM-DD");
  const startDate = dayjs(abTest?.startedAt);
  const endDate = dayjs().subtract(2, "days");
  if (endDate.isBefore(startDate)) {
    return dayjs().format("YYYY-MM-DD");
  }
  return endDate.format("YYYY-MM-DD");
};

export const hasData = (abTest: Dic<any>) => {
  const startDate = dayjs(abTest?.startedAt);
  if (abTest?.stoppedAt)
    return dayjs(abTest.stoppedAt).diff(startDate, "day") >= 2;
  const endDate = dayjs().subtract(2, "days");
  if (endDate.isBefore(startDate)) {
    return dayjs().diff(startDate, "day") >= 2;
  }
  return true;
};
