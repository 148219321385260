import { gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useSelector as useXstateSelector } from "@xstate/react";
import { RootState } from "@/services/redux/store";
import { client } from "@/index";
import { AutoComplete, SelectPaper, StyledTextField } from "@/includes";
import { ICategorySelector, category } from "./types";
import { GET_CATEGORIES } from "./requests";
import { isEmpty } from "lodash";
import { useConfigureMerchandising } from "../../hooks/useConfigureMerchandising";

const includedCategoriesSelector = (state: any) =>
  state.context.includedCategories;
const excludedCategoriesSelector = (state: any) =>
  state.context.excludedCategories;

const CategorySelector: React.FC<ICategorySelector> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const { t }: i18translateType = useTranslation();
  const experienceServices = useConfigureMerchandising();
  const catalogId = useSelector((state: RootState) => state.catalog.catalogId);
  const [selectableCategories, setSelectableCategories] = useState<
    Array<category>
  >([]);
  const [status, setStatus] = useState<"" | "error" | "loading">("");
  const includedCategories = useXstateSelector(
    experienceServices.experienceService,
    includedCategoriesSelector
  );

  const excludedCategories = useXstateSelector(
    experienceServices.experienceService,
    excludedCategoriesSelector
  );

  const isIncludedCategories =
    includedCategories?.length > 0 &&
    !includedCategories.includes("all") &&
    !isEmpty(includedCategories[0]);

  const handleChangeSelectableCategories = (res: Dic<any>) => {
    if (isIncludedCategories)
      setSelectableCategories(
        res.data.categories.edges
          .map((edge: Dic<any>) => ({
            ...edge.node,
          }))
          .filter((category: Dic<any>) =>
            includedCategories.find(
              (includedCategoryId: string) => category.id === includedCategoryId
            )
          )
      );
    else if (
      excludedCategories?.length > 0 &&
      includedCategories?.length > 0 &&
      !isEmpty(excludedCategories[0]) &&
      !isEmpty(includedCategories[0])
    )
      setSelectableCategories(
        res.data.categories.edges
          .map((edge: Dic<any>) => ({
            ...edge.node,
          }))
          .filter(
            (category: Dic<any>) =>
              !excludedCategories.find(
                (excludedCategoryId: string) =>
                  category.id === excludedCategoryId
              )
          )
      );
    else {
      setSelectableCategories(
        res.data.categories.edges.map((edge: Dic<any>) => ({
          ...edge.node,
        }))
      );
    }
  };

  const getCategories = (first = 500) => {
    setStatus("loading");
    client
      .query({ query: GET_CATEGORIES, variables: { first, catalogId } })
      .then((res) => {
        const totalCount = res.data.categories.totalCount;
        if (totalCount > first) getCategories(totalCount);
        else {
          handleChangeSelectableCategories(res);
          setStatus("");
        }
      })
      .catch(() => setStatus("error"));
  };

  useEffect(() => {
    if (catalogId) getCategories();
  }, [catalogId, includedCategories, excludedCategories]);

  useEffect(() => {
    if (selectableCategories?.length > 0)
      setSelectedCategory(selectableCategories[0]);
  }, [selectableCategories]);

  return (
    <AutoComplete
      sx={{ width: 350 }}
      disableClearable
      loading={status === "loading"}
      value={selectedCategory}
      options={selectableCategories}
      getOptionLabel={(option: category) =>
        `${option?.title} - ${option?.id?.replace(
          "gid://shopify/Collection/",
          ""
        )}`
      }
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("category")}
          name={t("category")}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {status === "loading" ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(e: React.SyntheticEvent, value: category) => {
        setSelectedCategory(value);
      }}
      PaperComponent={SelectPaper}
    />
  );
};

export default CategorySelector;
