import { EVOLUTION_METRICS_ENUM } from "../Evolution/enums";

export const getOptions = (
  t: (
    key: string | TemplateStringsArray | (string | TemplateStringsArray)[],
    options?: any
  ) => string
) => [
  { value: EVOLUTION_METRICS_ENUM.DISPLAYS_BY_DAY, label: t("number_display") },
  { value: EVOLUTION_METRICS_ENUM.USAGES_BY_DAY, label: t("number_use") },
  { value: EVOLUTION_METRICS_ENUM.CLOSURES_BY_DAY, label: t("number_closure") },
  {
    value: EVOLUTION_METRICS_ENUM.PERCENT_USAGES_BY_DAY,
    label: t("utilization_rate"),
  },
  {
    value: EVOLUTION_METRICS_ENUM.PERCENT_CLOSURES_BY_DAY,
    label: t("share_closure"),
  },
];
