export const getLocaleText = (t: any) => {
  return {
    // Root
    noRowsLabel: t("no_rows"),
    noResultsOverlayLabel: t("no_results_found"),

    // Density selector toolbar button text
    toolbarDensity: t("density"),
    toolbarDensityLabel: t("density"),
    toolbarDensityCompact: t("compact"),
    toolbarDensityStandard: t("standard"),
    toolbarDensityComfortable: t("comfortable"),

    // Columns selector toolbar button text
    toolbarColumns: t("columns"),
    toolbarColumnsLabel: t("select_columns"),

    // Filters toolbar button text
    toolbarFilters: t("filters"),
    toolbarFiltersLabel: t("filters"),
    toolbarFiltersTooltipHide: t("hide_filters"),
    toolbarFiltersTooltipShow: t("show_filters"),
    toolbarFiltersTooltipActive: (count: number) =>
      count !== 1
        ? `${count} ${t("active_filters")}`
        : `${count} ${t("active_filter")}`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: t("search"),
    toolbarQuickFilterLabel: t("search"),
    toolbarQuickFilterDeleteIconLabel: t("delete"),

    // Export selector toolbar button text
    toolbarExport: t("export"),
    toolbarExportLabel: t("export"),
    toolbarExportCSV: t("download_as_csv"),
    toolbarExportPrint: t("print"),
    toolbarExportExcel: t("download_as_excel"),

    // Columns panel text
    columnsPanelTextFieldLabel: t("find_column"),
    columnsPanelTextFieldPlaceholder: t("columns"),
    columnsPanelDragIconLabel: t("reorder_columns"),
    columnsPanelShowAllButton: t("show_all"),
    columnsPanelHideAllButton: t("hide_all"),

    // Filter panel text
    filterPanelAddFilter: t("add_filter"),
    filterPanelRemoveAll: t("remove_all"),
    filterPanelDeleteIconLabel: t("delete"),
    filterPanelLogicOperator: t("logic_operator"),
    filterPanelOperator: t("operator"),
    filterPanelOperatorAnd: t("and"),
    filterPanelOperatorOr: t("or"),
    filterPanelColumns: t("columns"),
    filterPanelInputLabel: t("value"),
    filterPanelInputPlaceholder: t("value"),

    // Filter operators text
    filterOperatorContains: t("contains"),
    filterOperatorEquals: t("equals"),
    filterOperatorStartsWith: t("starts_with"),
    filterOperatorEndsWith: t("ends_with"),
    filterOperatorIs: t("is"),
    filterOperatorNot: t("is_not"),
    filterOperatorAfter: t("is_after"),
    filterOperatorOnOrAfter: t("is_on_or_after"),
    filterOperatorBefore: t("is_before"),
    filterOperatorOnOrBefore: t("is_on_or_before"),
    filterOperatorIsEmpty: t("is_empty"),
    filterOperatorIsNotEmpty: t("is_not_empty"),
    filterOperatorIsAnyOf: t("is_any_of"),

    // Header filter operators text
    headerFilterOperatorContains: t("contains"),
    headerFilterOperatorEquals: t("equals"),
    headerFilterOperatorStartsWith: t("starts_with"),
    headerFilterOperatorEndsWith: t("ends_with"),
    headerFilterOperatorIs: t("is"),
    headerFilterOperatorNot: t("is_not"),
    headerFilterOperatorAfter: t("is_after"),
    headerFilterOperatorOnOrAfter: t("is_on_or_after"),
    headerFilterOperatorBefore: t("is_before"),
    headerFilterOperatorOnOrBefore: t("is_on_or_before"),
    headerFilterOperatorIsEmpty: t("is_empty"),
    headerFilterOperatorIsNotEmpty: t("is_not_empty"),
    headerFilterOperatorIsAnyOf: t("is_any_of"),
    "headerFilterOperator=": t("equals"),
    "headerFilterOperator!=": t("not_equals"),
    "headerFilterOperator>": t("greater_than"),
    "headerFilterOperator>=": t("greater_than_or_equal_to"),
    "headerFilterOperator<": t("less_than"),
    "headerFilterOperator<=": t("less_than_or_equal_to"),

    // Filter values text
    filterValueAny: t("any"),
    filterValueTrue: t("true"),
    filterValueFalse: t("false"),

    // Column menu text
    columnMenuLabel: t("menu"),
    columnMenuShowColumns: t("show_columns"),
    columnMenuManageColumns: t("manage_columns"),
    columnMenuFilter: t("filter"),
    columnMenuHideColumn: t("hide_column"),
    columnMenuUnsort: t("unsort"),
    columnMenuSortAsc: t("sort_by_asc"),
    columnMenuSortDesc: t("sort_by_desc"),

    // Column header text
    columnHeaderFiltersTooltipActive: (count: number) =>
      count !== 1
        ? `${count} ${t("active_filters")}`
        : `${count} ${t("active_filter")}`,
    columnHeaderFiltersLabel: t("show_filters"),
    columnHeaderSortIconLabel: t("sort"),

    // Rows selected footer text
    footerRowSelected: (count: number) =>
      count !== 1
        ? `${count.toLocaleString()} ${t("rows_selected")}`
        : `${count.toLocaleString()} ${t("row_selected")}`,

    // Total row amount footer text
    footerTotalRows: `${t("total_rows")}:`,

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount: number, totalCount: number) =>
      `${visibleCount.toLocaleString()} ${t(
        "of"
      )} ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: t("checkbox_selection"),
    checkboxSelectionSelectAllRows: t("select_all_rows"),
    checkboxSelectionUnselectAllRows: t("unselect_all_rows"),
    checkboxSelectionSelectRow: t("select_row"),
    checkboxSelectionUnselectRow: t("unselect_row"),

    // Boolean cell text
    booleanCellTrueLabel: t("yes"),
    booleanCellFalseLabel: t("no"),

    // Actions cell more text
    actionsCellMore: t("more"),

    // Column pinning text
    pinToLeft: t("pin_to_left"),
    pinToRight: t("pin_to_right"),
    unpin: t("unpin"),

    // Tree Data
    treeDataGroupingHeaderName: t("group"),
    treeDataExpand: t("see_children"),
    treeDataCollapse: t("hide_children"),

    // Grouping columns
    groupingColumnHeaderName: t("group"),
    groupColumn: (name: string) => `${t("group_by")} ${name}`,
    unGroupColumn: (name: string) => `${t("stop_grouping_by")} ${name}`,

    // Master/detail
    detailPanelToggle: t("detail_panel_toggle"),
    expandDetailPanel: t("expand"),
    collapseDetailPanel: t("collapse"),

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: t("row_reording"),

    // Aggregation
    aggregationMenuItemHeader: t("aggregation"),
    aggregationFunctionLabelSum: t("sum"),
    aggregationFunctionLabelAvg: t("avg"),
    aggregationFunctionLabelMin: t("min"),
    aggregationFunctionLabelMax: t("max"),
    aggregationFunctionLabelSize: t("size"),
  };
};
