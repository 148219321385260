export const getStyle = (tagVariant: string) => (theme: any) => {
  switch (tagVariant) {
    case "error":
      return {
        background: theme.customPalette.error.complementary,
        color: `${theme.customPalette.error.main} !important`,
        border: "none",
      };
    case "warning":
      return {
        background: theme.customPalette.warning.complementary,
        color: `${theme.customPalette.warning.main} !important`,
        border: "none",
      };

    case "layer_2":
      return {
        background: theme.customPalette.others.layer_2,
        color: `${theme.customPalette.grey.light} !important`,
        border: "none",
      };

    case "success":
      return {
        background: theme.customPalette.success.complementary,
        color: `${theme.customPalette.success.main} !important`,
        border: "none",
      };
    case "primary":
      return {
        background: theme.customPalette.bluePurple.lighter,
        color: `${theme.customPalette.bluePurple.dark} !important`,
        border: "none",
      };
    case "secondary":
      return {
        background: theme.customPalette.bluePurple.slighty,
        color: `${theme.customPalette.bluePurple.light} !important`,
        border: "none",
      };
    case "tertiary":
      return {
        background: theme.customPalette.citrus.lighter,
        color: `${theme.customPalette.citrus.dark} !important`,
        border: "none",
      };
    case "info":
      return {
        background: theme.customPalette.info.complementary,
        color: `${theme.customPalette.info.main} !important`,
        border: "none",
      };
    case "primary_border":
      return {
        background: theme.customPalette.bluePurple.lighter,
        color: `${theme.customPalette.bluePurple.dark} !important`,
        border: `1px solid ${theme.customPalette.bluePurple.dark}`,
      };
    case "secondary_border":
      return {
        background: theme.customPalette.bluePurple.slighty,
        color: `${theme.customPalette.bluePurple.light} !important`,
        border: `1px solid ${theme.customPalette.bluePurple.light}`,
      };
    case "tertiary_border":
      return {
        background: theme.customPalette.citrus.lighter,
        color: `${theme.customPalette.citrus.dark} !important`,
        border: `1px solid ${theme.customPalette.citrus.dark}`,
      };
    case "disabled":
      return {
        background: theme.customPalette.others.layer_1,
        color: `${theme.customPalette.others.backgrounf} !important`,
        border: "none",
      };
  }
};
