import { combineReducers } from "redux";
import loginReducer, {
  initialState as loginInitialState,
} from "./login-reducer";
import loadingReducer, {
  initialState as loadingInitialState,
} from "./loading-reducer";
import breadcrumbReducer, {
  initialState as breadcrumbInitialState,
} from "./breadcrumb-reducer";
import accountReducer, {
  initialState as accountInitialState,
} from "./account-reducer";
import siteReducer, { initialState as siteInitialState } from "./site-reducer";
import catalogReducer, {
  initialState as catalogInitialState,
} from "./catalog-reducer";
import developerModeReducer, {
  initialState as developerModeInitalState,
} from "./developer-mode-reducer";
import analyticsReducer, {
  initialState as analyticsInitialState,
} from "./analytics-reducer";
import drawerReducer, {
  initialState as drawerInitialState,
} from "./drawer-reducer";
import productReducer, {
  initialState as productInitialState,
} from "./product-reducer";
import notificationReducer, {
  initialState as notificationInitialState,
} from "./notifications-reducer";
import runsReducer, { initialState as runsInitialState } from "./run-reducer";

export const initialState = {
  runs: runsInitialState,
  notification: notificationInitialState,
  product: productInitialState,
  analytics: analyticsInitialState,
  login: loginInitialState,
  loading: loadingInitialState,
  breadcrumb: breadcrumbInitialState,
  account: accountInitialState,
  site: siteInitialState,
  drawer: drawerInitialState,
  catalog: catalogInitialState,
  developerMode: developerModeInitalState,
};

export default combineReducers({
  runs: runsReducer,
  notification: notificationReducer,
  developerMode: developerModeReducer,
  product: productReducer,
  analytics: analyticsReducer,
  login: loginReducer,
  loading: loadingReducer,
  breadcrumb: breadcrumbReducer,
  account: accountReducer,
  site: siteReducer,
  drawer: drawerReducer,
  catalog: catalogReducer,
});
