import { AutoComplete, SelectPaper, Error, StyledTextField } from "@includes";
import useCategorySelectorLogic from "./hooks/useCategorySelectorLogic";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ICategorySelector } from "./types";

const CategorySelector: React.FC<ICategorySelector> = ({
  setCategoryId,
  filter,
}) => {
  const { t }: i18translateType = useTranslation();
  const {
    search,
    loading,
    error,
    categories,
    selectedCategory,
    handleSearch,
    handleChangeSelectedCategory,
    setSearch,
  } = useCategorySelectorLogic(setCategoryId);

  if (error) {
    return <Error />;
  }

  return (
    <AutoComplete
      sx={{ width: 350, maxWidth: "min(80vw, 350px)" }}
      disableClearable
      loading={loading}
      value={selectedCategory}
      inputValue={search}
      getOptionLabel={(option: any) =>
        `${option?.title} - ${option?.id?.replace(
          "gid://shopify/Collection/",
          ""
        )}`
      }
      onClose={() => {
        setSearch(
          `${selectedCategory?.title} - ${selectedCategory?.id?.replace(
            "gid://shopify/Collection/",
            ""
          )}`
        );
      }}
      options={categories}
      renderInput={(params: any) => (
        <StyledTextField
          {...params}
          label={t("category")}
          name={t("category")}
          placeholder={`${t("category")}...`}
          value={search}
          onChange={handleSearch}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={handleChangeSelectedCategory}
      PaperComponent={SelectPaper}
    />
  );
};

CategorySelector.defaultProps = {
  filter: null,
};

export default CategorySelector;
