import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

/**
 * Utility class for working with dates.
 */
export class DatesUtils {
  /**
   * Get the name of the month in the chosen language.
   *
   * @param monthNumber - The number of the month (between 1 to 12).
   * @returns The name of the month.
   */
  getMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString(potionsLocalStorage.langue ?? "en", {
      month: "long",
    });
  }

  /**
   * Calculate and return the remaining time between the given date and the current date.
   *
   * @param date - The target date to calculate the remaining time from.
   * @param t - A translation function for displaying time units in different languages.
   * @returns An object containing the remaining time components.
   */
  getRemainingTime(date: string, t: (text: string) => string) {
    const date2 = dayjs(date);
    const date1 = dayjs();

    const milliseconds = date2.diff(date1);
    const secs = Math.floor(Math.abs(milliseconds) / 1000);
    const mins = Math.floor(secs / 60);
    const hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    const millisecs = Math.floor(Math.abs(milliseconds)) % 1000;

    const remainingHours = hours - days * 24;

    const remainingMinutes = mins - hours * 60;

    const multiple = (term: string, n: number) =>
      n !== 1 ? `${n} ${term}s` : `1 ${term}`;

    return {
      days: days,
      hours: hours % 24,
      hoursTotal: hours,
      minutesTotal: mins,
      minutes: mins % 60,
      seconds: secs % 60,
      secondsTotal: secs,
      milliSeconds: millisecs,
      remainingHours,
      remainingMinutes,
      get diffStr(): string {
        return `${!!this.days ? `${multiple(t("day"), this.days)},` : ``} ${
          !!this.remainingHours
            ? `${multiple(t("hour"), this.remainingHours)}`
            : ""
        } ${
          !!this.remainingMinutes && !this.days
            ? `, ${multiple(t("minute"), this.remainingMinutes)}`
            : ``
        } `;
      },
    };
  }

  /**
   * Return a date range translated by the number of days between startDate and endDate.
   *
   * @param startDate - The start date of the first date range.
   * @param endDate - The end date of the first date range.
   * @returns An array with 2 dates translated by the number of days between startDate and endDate.
   */
  getTranslatedDateRange = (startDate: Dayjs, endDate: Dayjs) => {
    const [date1, date2] = [startDate, endDate];
    if (isEmpty(date1) || isEmpty(date2)) return [null, null];
    const diffInDays = Math.floor(date2.diff(date1, "day", true)) + 1; // nb days between date1 and date2 = length of dateRange
    return [
      date1.subtract(diffInDays, "days"),
      date2.subtract(diffInDays, "days"),
    ];
  };

  /**
   * Return a string indicating how much time has passed since the given date.
   *
   * @param date - The date to compare against the current date.
   * @param t - A translation function for displaying time units in different languages.
   * @returns A string representation of the time passed.
   */
  howManyTimeSince = (date: string, t: (text: string) => string) => {
    const seconds = Math.floor(
      (new Date().getTime() - new Date(date).getTime()) / 1000
    );

    let interval = seconds / 31536000;

    if (interval > 1) {
      return `${Math.floor(interval)} ${t("year")}${
        Math.floor(interval) === 1 ? "" : "s"
      }`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)} ${t("month")}${
        Math.floor(interval) === 1 || potionsLocalStorage.langue !== "en"
          ? ""
          : "s"
      }`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)} ${t("day")}${
        Math.floor(interval) === 1 ? "" : "s"
      }`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)} ${t("hour")}${
        Math.floor(interval) === 1 ? "" : "s"
      }`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)} ${t("minute")}${
        Math.floor(interval) === 1 ? "" : "s"
      }`;
    }
    return `${Math.floor(seconds)} ${t("second")}${
      Math.floor(interval) === 1 ? "" : "s"
    }`;
  };
}
