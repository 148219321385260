export const FIRST = 50;

export const ALL_EXPERIENCE = (t: (text: string) => void) => ({
  isMonitorable: true,
  id: -1,
  name: t("all_experiences"),
  analyticsName: "all",
  type: "ALL",
  onDesktop: true,
  onMobile: true,
  settings: {},
});
