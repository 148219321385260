import {
  Box,
  Stack,
  useTheme,
  Grid,
  Tooltip,
  ClickAwayListener,
  SxProps,
} from "@mui/material";
import { Text } from "@includes";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CopyIcon } from "@/assets/icons";
import { ICopyText } from "./types";

const HoverCopyText: React.FC<ICopyText> = ({ text, noWrap, sx }) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();

  const [isCopied, setIsCopied] = useState(false);

  const copy = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 3500);
    });
  };

  return (
    <Stack
      alignItems="start"
      justifyContent="center"
      sx={{
        paddingLeft: "8px",
        paddingRight: "8px",
        height: "100%",
        width: "100%",
        cursor: "pointer",
        color: theme.customPalette.grey.disabled,
        "&:hover": {
          background: theme.customPalette.others.layer_1,
          color: theme.customPalette.grey.light,
        },
        ...sx,
      }}
      onClick={copy}
    >
      <Tooltip title={isCopied ? t("text_copied") : t("copy")}>
        <div>
          <Text noWrap={!!noWrap} variant="body2">
            {text}
          </Text>
        </div>
      </Tooltip>
    </Stack>
  );
};

export default HoverCopyText;
