import {
  SET_CATALOG_COLUMNS,
  SET_CATALOG_ID,
  CHANGE_CURRENCY,
} from "./action-types";

// eslint-disable-next-line import/prefer-default-export
export function setCatalogId(catalogId: number | null) {
  return {
    type: SET_CATALOG_ID,
    payload: { catalogId },
  };
}

export function setCatalogColumns(catalogColumns: Array<Dic<string>>) {
  return {
    type: SET_CATALOG_COLUMNS,
    payload: { catalogColumns },
  };
}

export function setCatalogCurrency(currency: string) {
  return {
    type: CHANGE_CURRENCY,
    payload: { currency },
  };
}
