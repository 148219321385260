import { useTheme, Stack, Box } from "@mui/material";
import { getNoRowsTexts } from "@/utils";
import { Text } from "@includes";
import { useTranslation } from "react-i18next";
import { INoRowsOverlay } from "./types";

const NoRowsOverlay: React.FC<INoRowsOverlay> = ({ list }) => {
  const theme = useTheme();
  const { t }: i18translateType = useTranslation();
  const { title, subtitle, subtitle2 } = getNoRowsTexts(list, t);
  return (
    <Stack
      sx={{ width: "100%", height: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{ width: 55, height: 55 }}
        component="img"
        src={`/${theme.customPalette.mode}/status/empty.svg`}
      />
      <Text variant="h3">{title}</Text>
      <Text variant="body2" textType="secondary">
        {subtitle}
      </Text>
      <Text variant="body2" textType="secondary">
        {subtitle2}
      </Text>
    </Stack>
  );
};

export default NoRowsOverlay;
