import { combineEpics, Epic } from "redux-observable";
import { mergeMap, filter, map, catchError } from "rxjs/operators";
import { of, from } from "rxjs";
import { isOfType } from "typesafe-actions";
import { gql } from "@apollo/client/core";
import { client } from "../../../index";
import { ERROR, GET_USER_INFOS } from "../actions/action-types";
import { setUserInfos } from "../actions/user-actions";

const GET_USER = gql`
  query getMyself {
    me {
      id
      email
      isSuperuser
      picture
      companyUsers(first: 100) {
        edges {
          node {
            companyId
            role
            company {
              theme
            }
          }
        }
      }
    }
  }
`;

const getUserInfosEpics: Epic<any, any, any> = (action$: any) =>
  action$.pipe(
    filter(isOfType(GET_USER_INFOS)),
    mergeMap((action: any) =>
      from(
        client.query({
          query: GET_USER,
          variables: {},
        })
      ).pipe(
        map((response: any) =>
          setUserInfos(
            response.data?.me?.email,
            response?.data?.me?.isSuperuser,
            response?.data?.me?.picture,
            response.data?.me?.id,
            response?.data?.me?.companyUsers?.edges?.map(
              (edge: any) => edge.node
            )
          )
        ),
        catchError((error: any) =>
          of({
            type: ERROR,
            payload: {
              errorLocation: "login",
              errorMessage: "An error has occurred",
            },
            error: true,
          })
        )
      )
    )
  );

export default combineEpics(getUserInfosEpics);
