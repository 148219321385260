import { AutoComplete, SelectPaper, StyledTextField } from "@/includes";
import { stringUtils } from "@/utils";
import { useTranslation } from "react-i18next";
import { ILogicalOperator } from "./types";
import { operators } from "./enums";

const LogicalOperator: React.FC<ILogicalOperator> = ({
  logicalOperator,
  handleChangeLogicalOperators,
}) => {
  const { t }: i18translateType = useTranslation();
  return (
    <AutoComplete
      size="small"
      onChange={(e: React.SyntheticEvent<Element, Event>, value: any) => {
        handleChangeLogicalOperators(value);
      }}
      value={logicalOperator}
      options={operators}
      disableClearable
      sx={{ width: 70 }}
      getOptionLabel={(option: string) =>
        stringUtils.capitalizeFirstLetter(t(option.replace("_all", "")))
      }
      PaperComponent={SelectPaper}
      renderInput={(params: any) => (
        <StyledTextField variant="standard" {...params} label="" />
      )}
    />
  );
};

export default LogicalOperator;
