import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { IconButton, Stack, Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Layer2Box from "../Layer2Box";
import { useSetState } from "@/hooks";
import { DesktopWrapper, MobileWrapper } from "../../Wrappers";
import { ISlider } from "./types";

let isScrolling = false;

const Slider: React.FC<ISlider> = ({
  children,
  maxProductPerRow,
  setMaxProductPerRow,
  totalItems,
}) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null);

  const [canScroll, setCanScroll] = useSetState({
    leftAmount: 0,
    rightAmount: totalItems - maxProductPerRow,
  });

  useEffect(() => {
    setCanScroll({ rightAmount: totalItems - maxProductPerRow });
  }, [maxProductPerRow, totalItems]);

  const handleScroll = (direction: string) => {
    if (!isScrolling) {
      isScrolling = true;
      const container = containerRef.current as any;
      const containerWidth = container?.clientWidth;
      if (direction === "right") {
        container.scrollLeft += containerWidth / maxProductPerRow;
        setCanScroll({
          leftAmount: canScroll.leftAmount + 1,
          rightAmount: canScroll.rightAmount - 1,
        });
      } else {
        container.scrollLeft -= containerWidth / maxProductPerRow;
        setCanScroll({
          leftAmount: canScroll.leftAmount - 1,
          rightAmount: canScroll.rightAmount + 1,
        });
      }
      setTimeout(() => {
        isScrolling = false;
      }, 200);
    } else {
      setTimeout(() => {
        handleScroll(direction);
      }, 500);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setMaxProductPerRow(Math.ceil(container.offsetWidth / 170) - 1);

      container.scrollLeft = 0;
    }
  }, [maxProductPerRow]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: "calc(100% - 16px)",
        margin: "5px",
        position: "relative",
      }}
    >
      <DesktopWrapper>
        <Layer2Box
          sx={{
            width: "40px",
            cursor: canScroll.leftAmount > 0 ? "pointer" : "inherit",
            position: "absolute",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background:
              canScroll.leftAmount > 0
                ? theme.customPalette.white.main
                : theme.customPalette.grey.white,
          }}
          onClick={() => canScroll.leftAmount > 0 && handleScroll("left")}
        >
          <ArrowBack
            sx={{
              fill:
                canScroll.leftAmount > 0
                  ? theme.customPalette.grey.light
                  : theme.customPalette.grey.lighter,
            }}
          />
        </Layer2Box>
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            boxSizing: "border-box",
            gap: "10px",
            width: "calc(100% - 100px)",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "row",
            scrollBehavior: "smooth",
            margin: "0 80px",
          }}
        >
          {children}
        </Box>
        <Layer2Box
          sx={{
            width: "40px",
            cursor: canScroll.rightAmount > 0 ? "pointer" : "inherit",
            position: "absolute",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            right: 0,
            background:
              canScroll.rightAmount > 0
                ? theme.customPalette.white.main
                : theme.customPalette.grey.white,
          }}
          onClick={() => canScroll.rightAmount > 0 && handleScroll("right")}
        >
          <ArrowForward
            sx={{
              fill:
                canScroll.rightAmount > 0
                  ? theme.customPalette.grey.light
                  : theme.customPalette.grey.lighter,
            }}
          />
        </Layer2Box>
      </DesktopWrapper>
      <MobileWrapper>
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            boxSizing: "border-box",
            gap: "10px",
            width: "100vw",
            overflowX: "auto",
            display: "flex",
            flexDirection: "row",
            scrollBehavior: "smooth",
          }}
        >
          {children}
        </Box>
      </MobileWrapper>
    </Stack>
  );
};

export default Slider;
