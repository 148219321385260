import { gql } from "@apollo/client";
import { ENTITIES_TYPE_REQUEST_WITH_SITE_ID } from "./enums";

export const getRequestForEntities = (typeEntities: string) => {
  if (ENTITIES_TYPE_REQUEST_WITH_SITE_ID.includes(typeEntities)) {
    return gql`
      query ${typeEntities}($siteId: SiteID, $first: Int!) {
        ${typeEntities}(siteId: $siteId, first: $first) {
          edges {
            node {
              id
              ${typeEntities === "integrations" ? "type" : "name"}
            }
          }
          totalCount
        }
      }
    `;
  }
  return gql`
    query ${typeEntities}($first: Int!) {
      ${typeEntities}(first: $first) {
        edges {
          node {
            id
            name
          }
        }
        totalCount
      }
    }
  `;
};
