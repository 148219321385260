import { styled } from "@mui/system";
import { Box } from "@mui/material";

const Layer2Box = styled(Box)(({ theme }: any) => ({
  padding: 12,
  borderRadius: 5,
  background: theme.customPalette.others.layer_2,
  border: "1px solid",
  borderColor: theme.customPalette.others.background,
}));

export default Layer2Box;
