import React, { useState } from "react";
import {
  Text,
  Stack,
  IconButton,
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
  Grid,
  Button,
} from "@includes";
import { Box, Collapse, Fade, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store";
import { useHistory } from "react-router";
import { canRedirect } from "./helpers";
import { useTranslation } from "react-i18next";
import { useViewport } from "@/hooks";
import { potionsLocalStorage } from "@/services/LocalStorage/localStorage";
import { AddIcon } from "@/assets/icons";
import { navigatorUtils } from "@/utils";
import { IBlockList } from "./types";

const BlockList: React.FC<IBlockList> = ({
  title,
  onClick,
  titleColor,
  items,
  type,
  child,
  hasMore,
  fetchMore,
  loading,
  titleIconSrc,
  canAddNew,
  countBgColor,
  size,
  id,
  subtitle,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const [showBlock, setShowBlock] = useState(true);

  const { isMobile } = useViewport();

  const accountId = useSelector((state: RootState) => state.account.accountId);

  const siteId = useSelector((state: RootState) => state.site.siteId);

  const history = useHistory();

  if (items?.length === 0) return null;

  const itemType = items?.[0]?.type;

  const handleCreateNewCollection = navigatorUtils.goTo(
    items?.[0].displayType === "conditional"
      ? `/${accountId}/${siteId}/rules/create`
      : `/${accountId}/${siteId}/collections/${items?.[0].type?.toLowerCase()}_collection?type=${items?.[0].type.toLowerCase()}`,
    history
  );

  return (
    <Fade in>
      <Stack
        id={id}
        direction="row"
        alignItems="flex-start"
        spacing={1}
        sx={{
          background: theme.customPalette.others.layer_1,
          p: 1,
          borderRadius: 2,
        }}
      >
        <IconButton
          onClick={() => setShowBlock((prevState) => !prevState)}
          sx={{ padding: 0, width: 24, height: 24 }}
        >
          {!showBlock ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Stack
          spacing={1}
          sx={{
            width: `calc(100% - 24px - 8px)`,
            boxSizing: "border-box",
          }}
        >
          <Stack>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box component="img" src={titleIconSrc} />
                <Text customStyle={{ color: titleColor }} variant="subtitle1">
                  {title}
                </Text>
              </Stack>
              <Text
                textType="secondary"
                customStyle={{
                  background: countBgColor,
                  padding: 1,
                  borderRadius: 2,
                  width: "8px",
                  height: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: titleColor,
                }}
              >
                {size?.toString()}
              </Text>
            </Stack>
            {!!subtitle && <Text textType="secondary">{subtitle}</Text>}
          </Stack>
          <Box>
            <Collapse in={showBlock}>
              <Stack
                sx={{
                  width: "100%",
                  flex: 1,
                  padding: 1,
                  paddingBottom: 0,
                  boxSizing: "border-box",
                }}
                alignItems="center"
                spacing={1}
              >
                <Grid
                  container
                  sx={{ width: "100%", marginLeft: "-48px" }}
                  columns={12}
                  spacing={2}
                >
                  {items?.map((item: any) => {
                    return (
                      <Grid key={item.id} item md={4} sm={6} xs={12}>
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            background: theme.customPalette.others.layer_2,
                            borderRadius: 3,
                            // margin: "8px 0",
                            padding: "12px !important",
                            border: `1px solid ${theme.customPalette.others.background}`,
                            boxSizing: "border-box",
                            cursor: canRedirect(item, type)
                              ? "pointer"
                              : "unset",
                            "&:hover": {
                              background: canRedirect(item, type)
                                ? theme.customPalette.others.background
                                : theme.customPalette.others.layer_2,
                            },
                          }}
                          onClick={onClick}
                        >
                          {child(item)}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "100%",
                    marginLeft: "-48px !important",
                    marginTop: "0 !important",
                  }}
                  columns={12}
                  spacing={2}
                >
                  {type === "rule" ? (
                    <>
                      <Grid item md={4} sm={6} xs={12}>
                        <Button
                          customStyle={{ width: "100%", ml: 3 }}
                          onClick={handleCreateNewCollection}
                          id="newCollection"
                          startIcon={<AddIcon />}
                        >
                          {t("new_rule")}
                        </Button>
                      </Grid>
                      {!isMobile && <Grid item md={4}></Grid>}

                      <Grid item md={4} sm={6} xs={12}>
                        {hasMore && (
                          <Button
                            customStyle={{ width: "100%" }}
                            id="seeMore"
                            onClick={fetchMore}
                            loading={loading}
                          >
                            {t("see_more")}
                          </Button>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item md={4} sm={6} xs={12}>
                        {canAddNew && (
                          <Button
                            customStyle={{ width: "100%" }}
                            onClick={() => {
                              potionsLocalStorage.set(
                                "ExperienceTab",
                                "Configure"
                              );
                              history.push(
                                `/${accountId}/${siteId}/experiences/${itemType?.toLowerCase()}/create`
                              );
                            }}
                            id="newExperience"
                            startIcon={<AddIcon />}
                          >
                            {t("new_experience")}
                          </Button>
                        )}
                      </Grid>
                      {!isMobile && <Grid item md={4}></Grid>}
                      <Grid item md={4} sm={6} xs={12}>
                        {hasMore && (
                          <Button
                            customStyle={{ width: "100%" }}
                            id="seeMore"
                            onClick={fetchMore}
                            loading={loading}
                          >
                            {t("see_more")}
                          </Button>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            </Collapse>
          </Box>
        </Stack>
      </Stack>
    </Fade>
  );
};

BlockList.defaultProps = {
  canAddNew: true,
  subtitle: undefined,
  onClick: () => null,
};

export default BlockList;
