import { useTranslation } from "react-i18next";
import { Text, Stack } from "@includes";

const CGUContent = () => {
  const { t }: i18translateType = useTranslation();
  return (
    <Stack sx={{ paddingLeft: 4, mt: 2 }}>
      <Text variant="body2">{t("CGU 2")}</Text>
      <br />
      <Text variant="body2">{t("CGU 3")}</Text>
      <br />

      <Text variant="body2">{t("CGU 4")}</Text>
      <br />
      <Text variant="body2">{t("CGU 5")}</Text>
      <br />
      <Text variant="body2">{t("CGU 6")}</Text>
      <br />

      <Text variant="body2">{t("CGU 7")}</Text>
      <br />

      <Text variant="body2">{t("CGU 8")}</Text>
      <br />

      <Text variant="body2">{t("CGU 9")}</Text>
      <br />

      <Text variant="body2">{t("CGU 10")}</Text>
      <br />

      <Text variant="body2">{t("CGU 11")}</Text>
      <br />

      <Text variant="body2">{t("CGU 12")}</Text>
      <br />

      <Text variant="body2">{t("CGU 13")}</Text>
      <br />

      <Text variant="body2">{t("CGU 14")}</Text>
      <br />

      <Text variant="body2">{t("CGU 15")}</Text>
      <br />

      <Text variant="body2">{t("CGU 16")}</Text>
      <br />

      <Text variant="body2">{t("CGU 16")}</Text>
      <br />

      <Text variant="body2">{t("CGU 18")}</Text>
      <br />

      <Text variant="body2">{t("CGU 19")}</Text>
      <br />

      <Text variant="body2">{t("CGU 20")}</Text>
      <br />

      <Text variant="body2">{t("CGU 21")}</Text>
      <br />

      <Text variant="body2">{t("CGU 22")}</Text>
      <br />

      <Text variant="body2">{t("CGU 23")}</Text>
      <br />

      <Text variant="body2">{t("CGU 24")}</Text>
      <br />

      <Text variant="body2">{t("CGU 25")}</Text>
      <br />

      <Text variant="body2">{t("CGU 26")}</Text>
      <br />

      <Text variant="body2">{t("CGU 27")}</Text>
      <br />

      <Text variant="body2">{t("CGU 28")}</Text>
      <br />

      <Text variant="body2">{t("CGU 29")}</Text>
      <br />

      <Text variant="body2">{t("CGU 30")}</Text>
      <br />

      <Text variant="body2">{t("CGU 31")}</Text>
      <br />

      <Text variant="body2">{t("CGU 32")}</Text>
      <br />

      <Text variant="body2">{t("CGU 33")}</Text>
      <br />

      <Text variant="body2">{t("CGU 34")}</Text>
      <br />

      <Text variant="body2">{t("CGU 35")}</Text>
      <br />

      <Text variant="body2">{t("CGU 36")}</Text>
      <br />
      <Text variant="body2">{t("CGU 37")}</Text>
      <br />

      <Text variant="body2">{t("CGU 38")}</Text>
      <br />
      <Text variant="body2">{t("CGU 39")}</Text>
      <br />

      <Text variant="body2">{t("CGU 40")}</Text>
      <br />

      <Text variant="body2">{t("CGU 41")}</Text>
      <br />

      <Text variant="body2">{t("CGU 42")}</Text>
      <br />

      <Text variant="body2">{t("CGU 43")}</Text>
      <br />
    </Stack>
  );
};

export default CGUContent;
