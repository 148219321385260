import { useTheme } from "@mui/material/styles";
import { DividerProps } from "./types";

const Divider = ({ customStyle, orientation }: DividerProps) => {
  const theme = useTheme();
  return (
    <hr
      data-testid="dividerTestId"
      style={{
        background: theme.customPalette.others.background,
        height: "2px",
        border: "none",
        rotate: orientation === "vertical" ? "90deg" : "unset",
        ...customStyle,
      }}
    />
  );
};

Divider.defaultProps = {
  customStyle: {},
  orientation: "horizontal",
};

export default Divider;
