import { useSelector } from "react-redux";
import { RootState } from "@store";
import { useEffect, useRef, useState } from "react";
import { excludedProps } from "./enums";
import { comparisonUtils } from "@utils";

const useProductCardLogic = (productPerRow: number, product: Dic<any>) => {
  const ref = useRef(null);

  const [isHover, setIsHover] = useState(false);

  const additionnalProperties = useSelector(
    (state: RootState) => state.product.properties
  );

  const [boxSize, setBoxSize] = useState(0);

  useEffect(() => {
    if (ref) setBoxSize((ref.current as any).clientWidth);
  }, [productPerRow]);

  const [src, setSrc] = useState("pin");
  const [pinSrc, setPinSrc] = useState("canPin");

  const hasPrice =
    additionnalProperties?.find((p: string) => p === "price") &&
    !!product?.price;

  const hasStrokePrice =
    additionnalProperties?.find((p: string) => p === "stroke_price") &&
    !!product?.stroke_price;

  const hasDiscountRate =
    additionnalProperties?.find((p: string) => p === "discount_rate") &&
    !!product?.discount_rate;

  const hasCategory =
    additionnalProperties?.find((p: string) => p === "category") &&
    !!product?.category;

  const hasTags =
    additionnalProperties?.find((p: string) => p === "tags") && !!product?.tags;

  const hasCategoriesIds =
    additionnalProperties?.find((p: string) => p === "categories_ids") &&
    !!product?.categories_ids;

  const productProps = additionnalProperties?.filter(
    (p: string) => !excludedProps.find(comparisonUtils.areEquals(p))
  );

  return {
    ref,
    boxSize,
    hasDiscountRate,
    hasStrokePrice,
    hasPrice,
    setSrc,
    setPinSrc,
    hasCategory,
    hasCategoriesIds,
    hasTags,
    pinSrc,
    src,
    productProps,
    isHover,
    setIsHover,
  };
};

export default useProductCardLogic;
