import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";

const GridToolbar = () => {
  return (
    <GridToolbarContainer sx={{ p: 1 }}>
      <GridToolbarColumnsButton placeholder={undefined} />
    </GridToolbarContainer>
  );
};

export default GridToolbar;
