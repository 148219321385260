import { styled } from "@mui/system";
import { Switch } from "@mui/material";

const StyledToggle = styled(Switch)(({ theme }: any) => ({
  "& .MuiSwitch-thumb": {
    background: theme.customPalette.grey.disabled,
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    background: theme.customPalette.bluePurple.dark,
  },
  "& .MuiSwitch-track": {
    background: `${theme.customPalette.others.background} !important`,
  },
}));

export default StyledToggle;
