import { Error, Layer1Box, Stack, Text } from "@/includes";
import { Box, Tooltip, useTheme } from "@mui/material";
import { CardProps } from "./types";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { StatusEnum } from "@/utils/helpers/status/enums";

const Card = ({
  icon,
  label,
  tooltip,
  value,
  compareValue,
  compare,
  loading,
  loadingCompare,
}: CardProps) => {
  const theme = useTheme();
  return (
    <Layer1Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "opacity 0.5s cubic-bezier(0, 1.5, .8, 1)",
        position: "relative",
      }}
    >
      <Stack sx={{ width: "100%" }} spacing={1}>
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {icon && (
              <Box
                component="img"
                src={`/${theme.customPalette.mode}/${icon}`}
              />
            )}
            <Text variant="body1">{label}</Text>
          </Stack>
          {!!tooltip && (
            <Tooltip title={tooltip}>
              <HelpOutlineIcon
                sx={{
                  width: 20,
                  height: 20,
                  color: theme.customPalette.grey.light,
                }}
              />
            </Tooltip>
          )}
        </Stack>
        {loading === StatusEnum.ERROR || loadingCompare === StatusEnum.ERROR ? (
          <Error />
        ) : (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Text variant="h2">{value}</Text>
              {compare && (
                <Stack spacing={1} direction="row" alignItems="center">
                  <Text variant="body1">VS</Text>
                  <Text variant="h2">{compareValue}</Text>
                </Stack>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Layer1Box>
  );
};

export default Card;
