import { gql } from "@apollo/client";

export const GET_ANALYTICS_INTEGRATION = gql`
  query Integrations($siteId: SiteID) {
    integrations(first: 1, filters: "category = analytics", siteId: $siteId) {
      edges {
        node {
          id
          category
          settings
        }
      }
    }
  }
`;
