import { Box, Text } from "@/includes";
import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import EvolutionTooltip from "../EvolutionTooltip";
import { EvolutionGraphProps } from "./types";
import { HOME_OVERVIEW_LEGEND_ENUMS } from "../../enums";

const EvolutionGraph = ({ data, overviewSelected }: EvolutionGraphProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: theme.customPalette.others.layer_1,
        width: "100%",
        "& .recharts-legend-item-text": {
          color: theme.customPalette.grey.default,
        },
        "& .recharts-cartesian-grid-horizontal line": {
          stroke: theme.customPalette.others.background,
        },
      }}
    >
      <ResponsiveContainer height={400} width="100%">
        <ComposedChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            fill="transparent"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            stroke={theme.customPalette.grey.default}
            axisLine={false}
            tickFormatter={(v) => {
              return dayjs(v).format("DD.MM");
            }}
          />

          <YAxis stroke={theme.customPalette.grey.default} axisLine={false} />
          <Area
            dot={data.length <= 45}
            strokeWidth={3}
            type="monotone"
            dataKey={"current"}
            stroke={theme.customPalette.bluePurple.light}
            fill={theme.customPalette.bluePurple.slighty}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
            animationDuration={200}
            content={<EvolutionTooltip />}
          />
          <Legend
            content={
              <Box component="ul">
                <Box
                  component="li"
                  sx={{
                    "&::marker": {
                      color: theme.customPalette.bluePurple.light,
                      fontSize: "1.5em",
                    },
                  }}
                >
                  <Text variant="body2">
                    {t(HOME_OVERVIEW_LEGEND_ENUMS[overviewSelected])}
                  </Text>
                </Box>
              </Box>
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EvolutionGraph;
