import { useHasProperty } from "@/hooks";
import { Loader } from "@/includes";
import { Stack } from "@mui/material";
import { useSelector as useXstateSelector } from "@xstate/react";
import { useEffect } from "react";
import { useParams } from "react-router";
import CategoriesSelector from "./components/CategoriesSelector";
import Rule from "./components/Rule";
import CollectionSelector from "./components/CollectionSelector";
import RunAndSave from "./components/RunAndSave";
import { IConfigure } from "./types";
import { useConfigureMerchandising } from "./hooks/useConfigureMerchandising";

const isLoadingSelector = (state: any) => state.context.isLoading;
const ruleSelector = (state: any) => state.context.collection;

const Configure: React.FC<IConfigure> = ({
  handleChangeExperience,
  experience,
}) => {
  const experienceServices = useConfigureMerchandising();
  const { send } = experienceServices.experienceService;
  const { experienceId } = useParams<UrlParams>();
  const { hasConditionalRules } = useHasProperty();

  const isLoading = useXstateSelector(
    experienceServices.experienceService,
    isLoadingSelector
  );

  const rule = useXstateSelector(
    experienceServices.experienceService,
    ruleSelector
  );

  const ruleId = rule?.id;

  useEffect(() => {
    send({ type: "SET_EXPERIENCE_ID", data: { id: experienceId } });
  }, []);

  if (isLoading) return <Loader />;

  return (
    <Stack spacing={2} pt={3}>
      <CategoriesSelector />
      {!hasConditionalRules ? (
        <>
          <CollectionSelector />
          <RunAndSave />
        </>
      ) : (
        <Rule
          ruleId={ruleId}
          handleChangeExperience={handleChangeExperience}
          rule={rule}
          experience={experience}
        />
      )}
    </Stack>
  );
};

export default Configure;
