import { Fade, Grow } from "@mui/material";
import { Suspense, useRef, useEffect } from "react";

interface ILazy {
  component: any;
  initialFallback?: JSX.Element;
}

const LazyLoading: React.FC<ILazy> = ({
  component,
  initialFallback = <></>,
}) => {
  const fallback = useRef(() => initialFallback);
  const Component = component;

  const updateFallback = async () => {
    const result = await component._payload._result;
    fallback.current = typeof result === "function" ? result : result.default;
  };

  // useEffect(() => {
  //   updateFallback();
  // }, [component]);

  return (
    <Suspense fallback={<fallback.current />}>
      <div style={{ height: "100%" }}>
        <Fade in timeout={500}>
          <div style={{ height: "100%" }}>
            <Component />
          </div>
        </Fade>
      </div>
    </Suspense>
  );
};

LazyLoading.defaultProps = {
  initialFallback: <></>,
};

export default LazyLoading;
