import { graphqlUtils } from "@/utils";
import DataGridServerPagination from "../DataGridServerPagination";
import { IDataGridServerPagination } from "./types";

const DataGridClientPagination: React.FC<IDataGridServerPagination> = ({
  currentPage,
  handleChangeCurrentPage,
  pageSize,
  handleChangePageSize,
  totalItems,
}) => {
  const handleNext = () => {
    handleChangeCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    handleChangeCurrentPage(currentPage - 1);
  };

  const hasNextPage =
    currentPage + 1 < graphqlUtils.getTotalPages(totalItems, pageSize);

  return (
    <DataGridServerPagination
      hasPreviousPage={currentPage > 0}
      hasNextPage={hasNextPage}
      pageSize={pageSize}
      handleChangePageSize={handleChangePageSize}
      totalItems={totalItems}
      next={handleNext}
      previous={handlePrevious}
      currentPage={currentPage}
    />
  );
};

export default DataGridClientPagination;
