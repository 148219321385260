/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from "@apollo/client/core";
import _ from "lodash";
import { createContext, useContext } from "react";
import { assign, ActorRefFrom, createMachine } from "xstate";
import { useInterpret } from "@xstate/react";
import { client } from "@/index";
import { arrayUtils } from "@utils";
import dayjs from "dayjs";
import { GET_EXPERIENCE } from "./requests";
import { Event } from "./types";

const getHasToSaveExperience = (clientData: Dic<any>, serverData: Dic<any>) => {
  if (!_.isEqual(clientData, serverData)) return true;
  return false;
};

const initialState = {
  hasToSaveExperience: false,
  id: "",
  name: `Nouvelle expérience - ${dayjs(new Date()).format("DD-MM-YYYY")}`,
  deployedAt: null,
  settings: {},
  type: "EMERCH",
  includedCategories: [],
  excludedCategories: [],
  collection: {},
  experienceInput: {},
  error: false,
  categoriesToSave: [],
  currentPushedProducts: {},
  isLoading: false,
  serverData: {
    includedCategories: [],
    excludedCategories: [],
    collectionId: null,
    name: "UNTITLED",
    type: "EMERCH",
  },
};

const getInitialCollectionParameter = (type: string) => {
  return "category_id";
};

const getExperience = (expId: string | null, type: string) => {
  if (!expId || expId === "create") {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          ...initialState,
          experienceInput: {
            name: "UNTITLED",
            type,
          },
          collection: {},
        });
      }, 100);
    });
  }
  return client
    .query({
      query: GET_EXPERIENCE,
      variables: { id: parseInt(expId, 10) },
      fetchPolicy: "no-cache",
    })
    .then(async (res: any) => {
      const { data } = res;
      if (data) {
        const { experience } = data;
        return {
          ...experience,
          experienceInput: {
            collectionId: experience.collection.id,
            includedCategories: experience.includedCategories ?? [],
            excludedCategories: experience.excludedCategories ?? [],
            name: experience.name ?? "UNTITLED",
            type: "EMERCH",
          },
          serverData: {
            collectionId: experience.collection.id,
            includedCategories: experience.includedCategories ?? [],
            excludedCategories: experience.excludedCategories ?? [],
            name: experience.name ?? "UNTITLED",
            type: "EMERCH",
          },
        };
      }
    });
};

const ExperienceMachine = createMachine<any, Event>({
  id: "ExperienceMachine",
  context: {
    ...initialState,
  },
  on: {
    SET_CATEGORIES_TO_SAVE: {
      actions: assign((context, event) => ({
        categoriesToSave: arrayUtils.arrayUniqueByKey(
          "id",
          event.data.categoriesToSave
        ),
        hasToSaveExperience: getHasToSaveExperience(
          context.experienceInput,
          context.serverData
        ),
      })),
    },
    SET_CURRENT_PUSHED_PRODUCTS: {
      actions: assign((context, event) => ({
        currentPushedProducts: event.data.currentPushedProducts,
        hasToSaveExperience: getHasToSaveExperience(
          context.experienceInput,
          context.serverData
        ),
      })),
    },
    SET_EXPERIENCE_ID: {
      actions: assign((context, event) => ({
        id: event.data.id,
        isLoading: true,
      })),
      target: "initializeExperience",
    },
    REFRESH: {
      actions: assign((context, event) => ({
        id: event.data.id,
        isLoading: false,
      })),
      target: "initializeExperience",
    },
    SET_PAGE_TYPE: {
      actions: assign((context, event) => ({
        pageType: event.data.pageType ?? "",
        experienceInput: {
          ...context.experienceInput,
          pageType: event.data.pageType ?? "",
        },
        hasToSaveExperience: getHasToSaveExperience(
          {
            ...context.experienceInput,
            pageType: event.data.pageType ?? "",
          },
          context.serverData
        ),
      })),
    },
    CHANGE_NAME: {
      actions: assign((context, event) => ({
        name: event.data.name ?? "UNTITLED",
        experienceInput: {
          ...context.experienceInput,
          name: event.data.name ?? "UNTITLED",
        },
        hasToSaveExperience: getHasToSaveExperience(
          {
            ...context.experienceInput,
            name: event.data.name ?? "UNTITLED",
          },
          context.serverData
        ),
      })),
    },
    SET_COLLECTION: {
      actions: assign((context, event) => {
        return {
          collection: event.data.collection,
          experienceInput: {
            ...context.experienceInput,
            collectionId: event.data.collection?.id,
          },
          hasToSaveExperience: getHasToSaveExperience(
            {
              ...context.experienceInput,
              includedCategories: [...(event.data.includedCategories ?? [])],
              collectionId: event.data.collection?.id,
            },
            context.serverData
          ),
        };
      }),
    },
    SET_INCLUDED_CATEGORIES: {
      actions: assign((context, event) => ({
        includedCategories: [...(event.data.includedCategories ?? [])],
        experienceInput: {
          ...context.experienceInput,
          includedCategories: [...(event.data.includedCategories ?? [])],
        },
        hasToSaveExperience: getHasToSaveExperience(
          {
            ...context.experienceInput,
            includedCategories: [...(event.data.includedCategories ?? [])],
          },
          context.serverData
        ),
      })),
    },
    SET_EXCLUDED_CATEGORIES: {
      actions: assign((context, event) => ({
        excludedCategories: [...(event.data.excludedCategories ?? [])],
        experienceInput: {
          ...context.experienceInput,
          excludedCategories: [...(event.data.excludedCategories ?? [])],
        },
        hasToSaveExperience: getHasToSaveExperience(
          {
            ...context.experienceInput,
            excludedCategories: [...(event.data.excludedCategories ?? [])],
          },
          context.serverData
        ),
      })),
    },
  },
  states: {
    initializeExperience: {
      invoke: {
        src: (context, event) => getExperience(context.id, context.type),
        onDone: {
          actions: assign((context, event) => {
            return {
              ...context,
              ...event.data,
              isLoading: false,
              hasToSaveExperience: false,
              categoriesToSave: [],
            };
          }),
        },
      },
    },
  },
});

interface ExperienceContextType {
  experienceService: ActorRefFrom<typeof ExperienceMachine>;
}

export const ExperienceContext = createContext({} as ExperienceContextType);

export default function ExperienceProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const experienceService = useInterpret(ExperienceMachine);

  return (
    <ExperienceContext.Provider value={{ experienceService }}>
      {children}
    </ExperienceContext.Provider>
  );
}

export function useConfigureMerchandising() {
  return useContext(ExperienceContext);
}
