import { useDispatch, useSelector } from "react-redux";
import { setDeveloperMode } from "@/services/redux/actions";
import { RootState } from "@/services/redux/store";
import StyledToggle from "../../_Atoms/StyledToggle";

const DeveloperModeToggle = () => {
  const developerMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );

  const dispatch = useDispatch();

  const handleChangeDeveloperMode = () => {
    dispatch(setDeveloperMode(!developerMode));
  };

  return (
    <StyledToggle
      size="small"
      checked={developerMode}
      onChange={handleChangeDeveloperMode}
    />
  );
};

export default DeveloperModeToggle;
