import BlocksTab from "@/components/_Routes/ConfigureAlgorithm/components/BlocksTab";
import Options from "@/components/_Routes/ConfigureAlgorithm/components/Options";
import SubAlgorithms from "@/components/_Routes/ConfigureAlgorithm/components/SubAlgorithms";
import {
  ALGORITHM_PARTS_ENUMS,
  ALGORITHM_PARTS_LABEL,
} from "@/components/_Routes/ConfigureAlgorithm/enums";
import HasCustomPlanWrapper from "@/components/_Templates/HasCustomPlanWrapper";
import { client } from "@/index";
import { Error, HideShowBlock } from "@includes";
import { Collapse, Stack } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import Loader from "../../../../_Atoms/Loader";
import Text from "../../../../_Atoms/Text";
import { Interpret } from "../../Interpret";
import RunAndManipulate from "../RunAndManipulate";
import { GET_EXPERIENCE, GET_RULE } from "./requests";
import { IConfigureAlgorithm } from "./types";
import ShortUniqueId from "short-unique-id";
import useVariables from "@/hooks/useVariables";
import { getTypesOfParameters } from "@/components/_Routes/ConfigureAlgorithm/components/Algorithm/helpers";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { getTypesOfAvailablesVariables } from "./helpers";

const contextSelector = (state: any) => state.context;

const AlgorithmRule: React.FC<IConfigureAlgorithm> = ({
  ruleId,
  experienceType,
  slot,
  slotProps,
  handleChangeExperience,
  availableVariables,
  rule,
  experience,
}) => {
  const interpreter = new Interpret();
  const { experienceId } = useParams<UrlParams>();
  const [parameters, setParameters] = useState<Dic<any>>({});
  const { allVariables } = useVariables();
  const { t }: i18translateType = useTranslation();
  const [status, setStatus] = useState<"" | "loading" | "error">("");
  const configureAlgorithmServices = useConfigureAlgorithm();
  const context: any = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    contextSelector
  );
  const getRuleContext = () =>
    interpreter.transformObjectIntoConditionalRule(context);

  const nbBlocks = Object.values(context.blocks)?.length ?? 0;

  useEffect(() => {
    try {
      handleChangeExperience({ ...experience, collection: getRuleContext() });
    } catch {
      setStatus("error");
    }
  }, [context]);

  const { send } = configureAlgorithmServices.algorithmService;

  const getRule = async (id: ID) => {
    setStatus("loading");
    client
      .query({
        query: GET_RULE,
        variables: { id },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        try {
          send({
            type: "SET_RULE",
            data: {
              rule: {
                ...interpreter.transformConditionalRuleIntoInterpretableObject(
                  res.data.collection
                ),
              },
            },
          });
          setParameters(
            interpreter.transformRequiredInputParametersIntoParameters(
              res.data.collection
            )
          );
          setStatus("");
        } catch {
          setStatus("error");
        }
      })
      .catch(() => setStatus("error"));
  };

  useEffect(() => {
    if (!isEmpty(rule)) {
      send({
        type: "SET_RULE",
        data: {
          rule: {
            ...interpreter.transformConditionalRuleIntoInterpretableObject(
              rule
            ),
          },
        },
      });
    }
    setParameters(
      interpreter.transformRequiredInputParametersIntoParameters(rule)
    );
  }, [rule]);

  const getExperience = () => {
    client
      .query({
        query: GET_EXPERIENCE,
        variables: { id: experienceId },
        fetchPolicy: "no-cache",
      })
      .then((res: Dic<any>) => {
        handleChangeExperience(res?.data);
      });
  };

  useEffect(() => {
    if (!isEmpty(experienceId) && experienceId !== "create") {
      getExperience();
    } else {
      send({ type: "INITIALIZE_BLOCK", data: {} });
      if (experienceType !== "EMERCH") {
        send({ type: "SET_RULE_SIZE", data: { size: 12 } });
      }
    }
  }, [experienceId]);

  useEffect(() => {
    if (!!allVariables) {
      if (experienceType === "MULTIPLY") {
        send({
          type: "SET_VARIABLES",
          data: {
            variables: availableVariables,
            variablesTypes: getTypesOfAvailablesVariables(
              allVariables,
              availableVariables
            ),
          },
        });
      } else if (experienceType === "EMERCH") {
        if (
          !isEmpty(availableVariables) &&
          context &&
          isEmpty(context.variables)
        ) {
          send({
            type: "SET_VARIABLES",
            data: {
              variables: availableVariables,
              variablesTypes: getTypesOfAvailablesVariables(
                allVariables,
                availableVariables
              ),
            },
          });
        }
      }
    }
  }, [availableVariables, allVariables]);

  useEffect(() => {
    if (experienceType === "EMAILING") {
      send({
        type: "SET_VARIABLES",
        data: {
          variables: Object.keys(parameters),
          variablesTypes: getTypesOfParameters(parameters),
        },
      });
    }
  }, [parameters]);

  if (status === "loading") return <Loader />;
  if (status === "error") return <Error />;
  return (
    <Stack spacing={2}>
      <SubAlgorithms
        availableVariables={availableVariables}
        algorithmIdInRecoAndMerch={ruleId}
        parameters={parameters}
        setParameters={setParameters}
        experienceType={experienceType}
      />
      <Collapse in={nbBlocks > 0}>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <HideShowBlock title={t(ALGORITHM_PARTS_LABEL.FILTERS)}>
            <Stack spacing={1}>
              <Text>{t("apply_filters")}</Text>
              <BlocksTab
                tab={ALGORITHM_PARTS_ENUMS.FILTERS}
                experienceType={experienceType}
              />
            </Stack>
          </HideShowBlock>
          <HasCustomPlanWrapper>
            <HideShowBlock title={t(ALGORITHM_PARTS_LABEL.PROMOTES)}>
              <Stack spacing={1}>
                <Text>{t("select_promotions")}</Text>
                <BlocksTab
                  tab={ALGORITHM_PARTS_ENUMS.PROMOTES}
                  experienceType={experienceType}
                />
              </Stack>
            </HideShowBlock>
          </HasCustomPlanWrapper>
          <HideShowBlock title={t(ALGORITHM_PARTS_LABEL.EXCEPTIONS)}>
            <Stack spacing={1}>
              <Text>{t("add_exceptions")}</Text>
              <BlocksTab
                tab={ALGORITHM_PARTS_ENUMS.EXCEPTIONS}
                algorithmIdInRecoAndMerch={ruleId}
                experienceType={experienceType}
              />
            </Stack>
          </HideShowBlock>
        </Stack>
      </Collapse>
      <Stack sx={{ mt: 2 }}>
        <Options parameters={parameters} experienceType={experienceType} />
      </Stack>

      {experienceType === "EMERCH" &&
        slot &&
        slot(() => <RunAndManipulate slotProps={slotProps} />)}
    </Stack>
  );
};

export default AlgorithmRule;
