import { client } from "@/services/graphql/apolloConfig";
import { RootState } from "@store";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GET_ANALYTICS_INTEGRATION } from "./requests";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { setCatalogCurrency } from "@/services/redux/actions/catalog-actions";
import { CURRENCY_CODE } from "./enums";

const useGetCurrency = () => {
  const { t }: i18translateType = useTranslation();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const currency = useSelector((state: RootState) => state.catalog.currency);
  const dispatch = useDispatch();
  useEffect(() => {
    if (siteId && isEmpty(currency))
      client
        .query({
          query: GET_ANALYTICS_INTEGRATION,
          variables: { siteId },
          fetchPolicy: "cache-first",
        })
        .then((res: any) => {
          const code: any =
            res?.data?.integrations?.edges[0]?.node?.settings?.currency_code ??
            "EUR";
          dispatch(setCatalogCurrency(CURRENCY_CODE[code]));
        });
  }, [currency, siteId]);

  return {};
};

export default useGetCurrency;
