import { QueryRowType } from "@/utils/types/graphql";
import { EvolutionDataType, GraphDataType } from "./types";
import { isEmpty } from "lodash";

export const formatDataForGraph = (
  data: EvolutionDataType | null,
  metricLabel: string
): Array<GraphDataType> => {
  if (isEmpty(data)) return [];
  return data.currentPeriod.rows.map((row: QueryRowType, index) => {
    return {
      date: row[0].stringValue,
      current: row[1].floatValue ?? 0,
      previous: data.previousPeriod.rows[index]?.[1]?.floatValue ?? 0,
      previousDate: data.previousPeriod.rows[index]?.[0]?.stringValue ?? "",
      evolutionMetricLabel: metricLabel,
    };
  });
};
