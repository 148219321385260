import { useTheme } from "@mui/material";
import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useMemo } from "react";
import { Stack, Text, Box, IconButton } from "../../../includes";
import { useTranslation } from "react-i18next";
import { useViewport } from "@/hooks";
import { DropDownIcon } from "@/assets/icons";
import { IPagination } from "./types";

// todo remove !!!
const Pagination: React.FC<IPagination> = ({
  totalPage,
  fetchedPage,
  currentPage,
  handleChange,
  pageSize,
  handleChangePageSize,
  nbItems,
}) => {
  const { t }: i18translateType = useTranslation();
  const theme = useTheme();
  const { isMobile } = useViewport();

  const nbPages = isMobile ? 2 : 5;

  const getNbPageToFetch = (newPage: number) => {
    if (fetchedPage === totalPage || newPage < fetchedPage) return 0;

    return 5;
  };

  const changePage = (value: number) => {
    handleChange(value, getNbPageToFetch(value));
  };

  const handlePrevious = () => {
    changePage(currentPage - 1);
  };

  const handleNext = () => {
    changePage(currentPage + 1);
  };

  const options = useMemo(() => {
    if (currentPage < nbPages) {
      let max = nbPages;
      if (totalPage < nbPages) {
        max = totalPage;
      }
      if (fetchedPage < nbPages && max < fetchedPage) {
        max = fetchedPage;
      }
      return Array.from({ length: max }, (_, i) => i + 1);
    }
    return Array.from({ length: fetchedPage ?? 1 }, (_, i) => i + 1)?.slice(
      currentPage - nbPages,
      currentPage + nbPages
    );
  }, [fetchedPage, totalPage, pageSize, nbItems]);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", position: "relative" }}
    >
      {options.length <= 1 ? null : (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ position: isMobile ? "relative" : "absolute" }}
        >
          <IconButton
            onClick={() => changePage(1)}
            sx={{ display: fetchedPage <= nbPages ? "none" : "flex" }}
          >
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
          <IconButton onClick={handlePrevious} disabled={currentPage === 1}>
            <DropDownIcon color={theme.customPalette.grey.default} />
          </IconButton>
          {options?.map((i: number) => (
            <Box
              key={i}
              sx={{
                background:
                  i === currentPage
                    ? theme.customPalette.others.layer_1
                    : "transparent",
                borderRadius: "50%",
                width: 15,
                height: 15,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                cursor: "pointer",
                border: "1px solid",
                borderColor:
                  i === currentPage
                    ? theme.customPalette.others.background
                    : "transparent",
                "&:hover": {
                  background: theme.customPalette.bluePurple.main,
                },
              }}
              onClick={() => changePage(i)}
            >
              <Text customStyle={{ color: theme.customPalette.grey.default }}>
                {i?.toString()}
              </Text>
            </Box>
          ))}
          <IconButton
            onClick={handleNext}
            disabled={currentPage === totalPage || currentPage === fetchedPage}
          >
            <DropDownIcon color={theme.customPalette.grey.default} />
          </IconButton>
          <IconButton
            onClick={() => changePage(fetchedPage)}
            sx={{ display: fetchedPage <= nbPages ? "none" : "flex" }}
          >
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        </Stack>
      )}

      <Stack
        sx={{ position: isMobile ? "relative" : "absolute", right: 0 }}
        justifyContent="flex-end"
        direction="row"
        alignItems="center"
      >
        <FormControl
          sx={{
            m: 1,
            width: 120,
          }}
          size="small"
        >
          <InputLabel id="demo-select-small">{t("page_size")}</InputLabel>
          <Select
            labelId="select-small"
            id="select-small"
            value={pageSize?.toString()}
            label={t("page_size")}
            onChange={(e: SelectChangeEvent) =>
              handleChangePageSize(parseInt(e?.target?.value, 10))
            }
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={500}>500</MenuItem>
          </Select>
        </FormControl>
        {!!nbItems && (
          <Text>
            {t("total_rows")}: {nbItems?.toString()}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};

export default Pagination;
