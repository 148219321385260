import { Paper, useTheme } from "@mui/material";

export function CustomPaper(props: any) {
  const theme = useTheme();
  return (
    <Paper
      {...props}
      sx={{
        background: theme.customPalette.others.layer_1,
        border: "1px solid",
        borderColor: theme.customPalette.others.background,
      }}
    />
  );
}
