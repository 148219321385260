import { useViewport } from "@/hooks";
import { Text } from "@/includes";
import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { labelOfAbTestEvolutionMetric } from "../../enums";
import { formatDataFromEvolutionMetric } from "./formatters";
import { TooltipProps } from "./types";

const EvolutionTooltip = ({ active, payload }: TooltipProps) => {
  const theme = useTheme();
  const isMobile = useViewport();
  const { t } = useTranslation();
  if (active && payload) {
    return (
      <Stack
        key={JSON.stringify(payload[0]?.payload?.evolutionMetricLabel)}
        sx={{
          padding: 1,
          background: theme.customPalette.others.layer_1,
          border: "1px solid",
          borderColor: theme.customPalette.others.background,
          borderRadius: 2,
          minWidth: isMobile ? "unset" : 300,
        }}
        justifyContent="start"
        alignItems="start"
        spacing={1}
      >
        <Text variant="body2">
          {payload[0]?.payload?.evolutionMetricLabel &&
            t(
              labelOfAbTestEvolutionMetric[
                payload[0].payload.evolutionMetricLabel
              ]
            )}
        </Text>
        <Stack
          sx={{
            borderLeft: `2px solid ${theme.customPalette.bluePurple.light}`,
            pl: 1,
          }}
        >
          <Text textType="secondary" variant="body3">
            {`${payload[0]?.payload?.date}`}
          </Text>
          <Text variant="body2">
            {formatDataFromEvolutionMetric(
              payload[0]?.payload?.aTest ?? 0,
              payload[0]?.payload?.evolutionMetricLabel,
              payload[0]?.payload?.currency
            )}
          </Text>
        </Stack>
        <Stack
          sx={{
            borderLeft: `2px solid ${theme.customPalette.bluePurple.dark}`,
            pl: 1,
          }}
        >
          <Text textType="secondary" variant="body3">
            {`${payload[0]?.payload?.date}`}
          </Text>
          <Text variant="body2">
            {formatDataFromEvolutionMetric(
              payload[0]?.payload?.bTest ?? 0,
              payload[0]?.payload?.evolutionMetricLabel,
              payload[0]?.payload?.currency
            )}
          </Text>
        </Stack>
      </Stack>
    );
  }
  return <Box />;
};

export default EvolutionTooltip;
