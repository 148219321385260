/**
 * Utility class for common operations on arrays.
 */
export class ArrayUtils {
  /**
   * Reorders an array by moving an item from the `startIndex` position to the `endIndex` position.
   *
   * @param array - The array to reorder.
   * @param startIndex - The index of the item to move.
   * @param endIndex - The final index of the moved item.
   * @returns The reordered array.
   */
  reorder = (array: Array<Dic<any>>, startIndex: number, endIndex: number) => {
    const result = Array.from(array);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Returns an array with unique elements based on a specified key.
   *
   * @param key - The key requiring uniqueness.
   * @param array - The array to filter.
   * @returns The array without duplicates for the given key.
   */
  arrayUniqueByKey = (key: string, array: Array<any>) => [
    ...new Map(array.map((item) => [item[key], item])).values(),
  ];

  /**
   * Divides an array into a list of arrays with a specified chunk size.
   *
   * @param array - The array to divide.
   * @param chunkSize - The size of the arrays created.
   * @returns An array containing arrays of size `chunkSize`.
   */
  divideListIntoSubList = (array: Array<any>, chunkSize: number) => {
    if (!Array.isArray(array) || typeof chunkSize !== "number")
      throw new TypeError();
    const newArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      newArray.push(chunk);
    }
    return newArray;
  };

  /**
   * Sorts an array of objects by one of its properties.
   *
   * @param property - The property to sort.
   * @param sortOrder - The sorting order (1 for ascending, -1 for descending).
   * @returns The sorted array.
   */
  dynamicSort = (property: string | number, sortOrder = 1) => {
    if (!["string", "number"].includes(typeof property)) throw new TypeError();
    let prop = property;
    if (typeof prop === "number" && sortOrder < 0) {
      sortOrder = -1;
      prop = prop.toString().substr(1);
    }
    return (a: any, b: any) =>
      (a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0) * sortOrder;
  };

  /**
   * Checks if a value is present in an array.
   *
   * @param arr1 - The array to search.
   * @param currentValue - The value to search for.
   * @returns `true` if the array contains the value, `false` otherwise.
   */
  isIn = (arr1: Array<any>) => (currentValue: any) =>
    arr1.find((item: any) => item === currentValue);

  /**
   * Checks if two arrays are equal (contain the same values).
   *
   * @param arr1 - The first array.
   * @param arr2 - The second array.
   * @returns `true` if the arrays are equal, `false` otherwise.
   */
  arrayEquals = (arr1: Array<any>, arr2: Array<any>) =>
    (!arr1 && !arr2) ||
    (arr2?.every(this.isIn(arr1)) && arr1?.every(this.isIn(arr2)));

  /**
   * Checks if two arrays are equal (same value at same location).
   *
   * @param arr1 - The first array.
   * @param arr2 - The second array.
   * @returns `true` if the arrays are equal, `false` otherwise.
   */
  arrayWithLocationEquals = (arr1: Array<any>, arr2: Array<any>) => {
    if (!arr1 && !arr2) return true;
    if (arr2?.every(this.isIn(arr1)) && arr1?.every(this.isIn(arr2))) {
      return arr2
        ?.map((value: any, index) => {
          return value === arr1[index];
        })
        .every((value) => value);
    }
    return false;
  };

  /**
   * Inserts an element into an array at the specified index.
   *
   * @param arr - The array to modify.
   * @param index - The index to insert the element.
   * @param newItem - The item to insert.
   * @returns The new array with the `newItem` at the specified position.
   */
  insert = (arr: Array<any>, index: number, newItem: any) => {
    if (!Array.isArray(arr)) throw new TypeError();
    if (index > arr.length) throw new Error("Index out of range");

    return [
      // part of the array before the specified index
      ...arr.slice(0, index),
      // inserted item
      newItem,
      // part of the array after the specified index
      ...arr.slice(index),
    ];
  };

  /**
   * Returns an array with unique elements based on the 'id' property.
   *
   * @param array - The array to make unique.
   * @returns The array with unique elements based on the 'id' property.
   */
  uniqueArray = (array: Array<any>) => this.arrayUniqueByKey("id", array);
}
