import { gql } from "@apollo/client";

export const GET_CATALOG = gql`
  query getSiteCatalogs($id: SiteID!, $first: Int!, $cursor: String) {
    site(siteId: $id) {
      catalogs {
        edges {
          node {
            id
            name
            products(first: $first, after: $cursor) {
              edges {
                node {
                  id
                  title
                  price
                  imgLink
                  available
                  category
                  categoryId
                  enrichedData
                  link
                }
              }
            }
          }
        }
      }
    }
  }
`;
