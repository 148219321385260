import * as React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/services/redux/store";
import { hideNotification } from "@actions";
import { Box, Slide, SlideProps, Snackbar, useTheme } from "@mui/material";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

// Mini popup like toast to notify users
export default function Notification() {
  const alert = useSelector((state: RootState) => state.notification);
  const { title, text, open, severity } = alert;
  const theme = useTheme();

  const dispatch = useDispatch();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideNotification());
  };

  const handleHideAfterTimeout = () => {
    setTimeout(() => dispatch(hideNotification()), 7000);
  };

  React.useEffect(() => {
    if (open) handleHideAfterTimeout();
  }, [open]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      sx={{
        "& .MuiPaper-root": {
          boxShadow: "0px 3px 8px 1px rgba(146,146,146,0.7)",
          borderRadius: 2,
          background: theme.customPalette.others.layer_1,
          borderBottom: `2px solid ${
            theme.customPalette[severity.toLowerCase()]?.complementary
          }`,
        },
      }}
    >
      <Alert
        severity={severity.toLowerCase() as AlertColor}
        onClose={handleClose}
      >
        <AlertTitle>{title}</AlertTitle>
        {text}
      </Alert>
    </Snackbar>
  );
}
