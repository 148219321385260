import { formatUtils } from "@/utils";

export const getValueByFormat = (
  format: any,
  currency: string = "€",
  newValue: number
) => {
  if (format == formatUtils.formatIntegerCurrency) {
    return formatUtils.formatIntegerCurrency(newValue, currency);
  }
  return format(newValue);
};
