import { RootState } from "@/services/redux/store";
import { IHasPages } from "@/utils/types/component";
import { useSelector } from "react-redux";

const DeveloperModeWrapper: React.FC<IHasPages> = ({ children }) => {
  const isSuperuser = useSelector(
    (state: RootState) => state.login.isSuperUser
  );
  const developerMode = useSelector(
    (state: RootState) => state.developerMode.isEnabled
  );

  if (developerMode && isSuperuser) return <>{children}</>;

  return <></>;
};

export default DeveloperModeWrapper;
