/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import { TextField, IconButton, Text } from "../../../includes";
import { IIntegerInput } from "./types";
import { DEFAULT_INPUT_WIDTH, FONT_SIZE } from "./enums";
import { arr } from "./helpers";

const IntegerInput: React.FC<IIntegerInput> = ({
  value,
  setValue,
  variant,
  max,
  min,
  disabled,
}) => {
  const theme = useTheme();

  const maxValue = max ?? Infinity;
  const minValue = min ?? 0;

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  useEffect(() => {
    if (value) setInputWidth((arr(value).length + 1) * (FONT_SIZE - 2));
  }, [value]);

  return (
    <TextField
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              sx={{ width: 33, height: 33 }}
              disabled={disabled}
              onClick={() => {
                if (value < maxValue) {
                  setValue(value + 1);
                }
              }}
            >
              <Text variant="body1">+</Text>
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              sx={{ width: 33, height: 33 }}
              disabled={disabled}
              onClick={() => {
                if (value > minValue) {
                  setValue(value - 1);
                }
              }}
            >
              <Text variant="body1">-</Text>
            </IconButton>
          </InputAdornment>
        ),
      }}
      size="small"
      sx={{
        borderRadius: 3,
        "&.MuiInputBase-input": {
          textAlign: "center",
          border: `1px solid ${theme.customPalette.others.background}`,
        },
        "& fieldset": {
          borderRadius: "10px",
          border: "none",
        },
        border: `1px solid ${theme.customPalette.others.background}`,
        background: theme.customPalette.others.layer_2,
        fontSize: FONT_SIZE,
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            webkitAppearance: "none",
            margin: 0,
          },
        "& input": {
          "-moz-appearance": "textfield",
        },
      }}
      type="number"
      value={value}
      variant={variant}
      inputProps={{
        style: { textAlign: "center", width: `${inputWidth}px` },
      }}
      onChange={(event) => {
        const newValue = parseInt(event.target.value, 10);
        if (newValue >= minValue && newValue <= maxValue)
          setValue(parseInt(event.target.value, 10));
        else if (newValue < minValue) {
          setValue(minValue);
        } else if (newValue > maxValue) setValue(maxValue);
      }}
    />
  );
};

IntegerInput.defaultProps = {
  variant: "outlined",
  max: Infinity,
  disabled: false,
};

export default IntegerInput;
