import { gql } from "@apollo/client";

export const GET_EXPERIENCES = gql`
  query getExperiences(
    $siteId: SiteID
    $cursor: String
    $first: Int!
    $search: String!
    $type: String
  ) {
    experiences(
      siteId: $siteId
      after: $cursor
      first: $first
      sort: "name"
      search: $search
      filters: "is_monitorable = true AND deployed_at != null"
      searchType: $type
    ) {
      edges {
        node {
          isMonitorable
          id
          name
          deployedAt
          type
          onMobile
          onDesktop
          analyticsName
          settings
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
