import { gql } from "@apollo/client";

export const GET_EXPERIENCE = gql`
  query experience($experienceId: ExperienceID!) {
    experience(experienceId: $experienceId) {
      name
      excludedCategories
      includedCategories
      deployedAt
      id
      uuid
      settings
      isMonitorable
      isConfigurable
      collection {
        id
        name
        settings
      }
      type
    }
  }
`;
