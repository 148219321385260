import {
  CHANGE_SITE,
  CREATE_SITE,
  SET_HAS_AB_TEST,
  SET_HAS_ANALYTICS,
  SET_HAS_CATALOG,
  SET_HAS_PAGES,
  SET_DEFAULT_EMAILING_INTEGRATION_ID,
  GENERATE_RESOURCES,
  RESOURCES_GENERATED,
  SET_CUSTOM_PLAN,
  SET_HAS_CONDITIONAL_RULES,
  SET_DEFAULT_CMS_INTEGRATION_ID,
  SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
  SET_HAS_REPORT,
  CHANGE_THEME,
  SET_HAS_SKU_REPORT,
  SET_HAS_ALGORITHM_BUILDER,
} from "./action-types";

export function generateResources() {
  return {
    type: GENERATE_RESOURCES,
  };
}

export function resourcesGenerated() {
  return {
    type: RESOURCES_GENERATED,
  };
}

export function setDefaultEmailingIntegrationId(
  defaultEmailingIntegrationId: ID
): any {
  return {
    type: SET_DEFAULT_EMAILING_INTEGRATION_ID,
    payload: {
      defaultEmailingIntegrationId,
    },
  };
}

export function setDefaultCmsIntegrationId(defaultCmsIntegrationId: ID): any {
  return {
    type: SET_DEFAULT_CMS_INTEGRATION_ID,
    payload: {
      defaultCmsIntegrationId,
    },
  };
}

export function setDefaultAnalyticsIntegrationId(
  defaultAnalyticsIntegrationId: ID
): any {
  return {
    type: SET_DEFAULT_ANALYTICS_INTEGRATION_ID,
    payload: {
      defaultAnalyticsIntegrationId,
    },
  };
}

export function userCreateSite(siteId: ID, siteName: string): any {
  return {
    type: CREATE_SITE,
    payload: {
      siteId,
      siteName,
    },
  };
}

export function userChangeSite(
  siteId: string,
  siteName: string,
  accountId: string,
  accountName: string,
  accountTheme: string,
  siteHasAlgorithmBuilder: boolean
): any {
  return {
    type: CHANGE_SITE,
    payload: {
      siteId,
      siteName,
      accountId,
      accountName,
      accountTheme,
      siteHasAlgorithmBuilder,
    },
  };
}

export function userChangeTheme(accountTheme: string): any {
  return {
    type: CHANGE_THEME,
    payload: {
      accountTheme,
    },
  };
}

export function setHasConditionalRules(hasConditionalRules: boolean) {
  return {
    type: SET_HAS_CONDITIONAL_RULES,
    payload: {
      hasConditionalRules,
    },
  };
}

export function setHasPages(hasPages: boolean) {
  return {
    type: SET_HAS_PAGES,
    payload: {
      hasPages,
    },
  };
}

export function setHasCatalog(hasCatalog: boolean) {
  return {
    type: SET_HAS_CATALOG,
    payload: {
      hasCatalog,
    },
  };
}

export function setHasReport(hasReport: boolean) {
  return {
    type: SET_HAS_REPORT,
    payload: {
      hasReport,
    },
  };
}

export function setHasAnalytics(hasAnalytics: boolean) {
  return {
    type: SET_HAS_ANALYTICS,
    payload: {
      hasAnalytics,
    },
  };
}

export function setHasCustomPlan(hasCustomPlan: boolean) {
  return {
    type: SET_CUSTOM_PLAN,
    payload: {
      hasCustomPlan,
    },
  };
}

export function setHasAbTest(hasAbtest: boolean) {
  return {
    type: SET_HAS_AB_TEST,
    payload: {
      hasAbtest,
    },
  };
}

export function setHasSkuReport(hasSkuReport: boolean) {
  return {
    type: SET_HAS_SKU_REPORT,
    payload: {
      hasSkuReport,
    },
  };
}

export function setHasAlgorithmBuilder(hasAlgorithmBuilder: boolean) {
  return {
    type: SET_HAS_ALGORITHM_BUILDER,
    payload: {
      hasAlgorithmBuilder,
    },
  };
}
