import { OverviewDataType } from "./types";

export const formatDataToOverview = (data: OverviewDataType) => {
  const ratio: any = {};
  data.users.headers.forEach((header: string, index: number) => {
    const usersValue = data.users.rows[0][index]?.floatValue;
    const exposedVisitorsValue =
      data.exposedVisitors.rows[0][index]?.floatValue;
    ratio[header] = exposedVisitorsValue
      ? (usersValue ?? 0) / exposedVisitorsValue
      : 0;
  });
  return ratio;
};
