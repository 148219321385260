import { useState } from "react";

const useSetState = (initialState: any = {}) => {
  const [state, regularSetState] = useState(initialState);

  const setState = (newState: any) => {
    regularSetState((prevState: any) => ({
      ...prevState,
      ...newState,
    }));
  };

  return [state, setState];
};

export default useSetState;
