import Run from "./components/Run";
import { IPreviewConditionalRules } from "./types";

const PreviewConditionalRules: React.FC<IPreviewConditionalRules> = ({
  settings,
  slot,
  params,
  setParams,
  popup,
}) => {
  return (
    <Run
      settings={settings}
      slot={slot}
      params={params}
      setParams={setParams}
      popup={popup}
    />
  );
};

export default PreviewConditionalRules;
