export const VISIBLE_FIELDS = [
  "image",
  "id",
  "title",
  "price",
  "category",
  "Availability",
  "Last 30 days views",
  "Status",
  "discountedPrice",
  "salePrice",
  "Last 14 days views",
  "Last 7 days views",
  "Yesterday views",
  "Visibility rank",
  "Visibility score",
  "Last 30 days transactions",
  "Last 14 days transactions",
  "Last 7 days transactions",
  "Yesterday transactions",
  "Popularity rank",
  "Popularity score",
  "Last 30 days revenue",
  "Last 14 days revenue",
  "Last 7 days revenue",
  "Yesterday revenue",
  "actions",
  "Stock per size",
];
