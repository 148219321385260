import { gql } from "@apollo/client";

export const GET_AB_TEST_EVOLUTION_DATA = gql`
  query Evolution(
    $siteId: SiteID!
    $devices: [String!]!
    $startDate: String!
    $endDate: String!
    $segmentA: String!
    $segmentB: String!
    $query: QueryByName!
  ) {
    ATest: monitor(
      siteId: $siteId
      segment: $segmentA
      devices: $devices
      query: $query
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
    BTest: monitor(
      siteId: $siteId
      segment: $segmentB
      devices: $devices
      query: $query
      startDate: $startDate
      endDate: $endDate
    ) {
      headers
      rows {
        floatValue
        stringValue
      }
    }
  }
`;
