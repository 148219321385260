import { QueryRowType } from "@/utils/types/graphql";
import { GraphDataType, HomeEvolutionDataType, QueryType } from "./types";
import { isEmpty } from "lodash";
import {
  HOME_OVERVIEW_LEGEND_ENUMS,
  HOME_OVERVIEW_QUERIES_ENUMS,
} from "../../enums";

export const formatDataForGraph = (
  data: HomeEvolutionDataType | null,
  t: (text: string) => string,
  overviewSelected: HOME_OVERVIEW_QUERIES_ENUMS,
  currency: string = "€"
): Array<GraphDataType> => {
  if (isEmpty(data)) return [];
  return data.monitor.rows.map((row: QueryRowType) => {
    return {
      date: row[0].stringValue,
      current: row[1].floatValue ?? 0,
      evolutionMetricLabel: t(HOME_OVERVIEW_LEGEND_ENUMS[overviewSelected]),
      currency,
    };
  });
};
