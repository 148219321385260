import React, { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";

interface IPersisted {
  key: string;
  initialValue: any;
}

/**
 * Custom React hook for managing state with persistence in localStorage.
 *
 * @param {object} options - Configuration options for the persisted state.
 * @param {string} options.key - The key under which the state is stored in localStorage.
 * @param {any} options.initialValue - The initial value for the state.
 * @returns {[any, React.Dispatch<React.SetStateAction<any>>]} - A tuple containing the state and its updater function.
 */
function usePersistedState({
  key,
  initialValue,
}: IPersisted): [any, React.Dispatch<React.SetStateAction<any>>] {
  const [savedValue, setSavedValue] = useLocalStorage(key, initialValue);
  const [state, setState] = useState(savedValue);

  // Effect to save the state to localStorage whenever it changes
  useEffect(() => {
    if (!key.includes("undefined")) setSavedValue(state);
  }, [state]);

  return [state, setState];
}

export default usePersistedState;
