import EngagementMeasure from "@/components/_Organisms/ReportExperience/components/EngagementMeasure";
import { useSetState } from "@/hooks";
import { EXPERIENCES_MAP, datesUtils } from "@/utils";
import { Box, Grid, Stack } from "@includes";
import { useSelector as useXstateSelector } from "@xstate/react";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import Header from "../Header";
import { useExperienceAnalytics } from "../../hooks/useExperienceAnalytics";
import KeyIndicators from "@/components/_Organisms/ReportExperience/components/KeyIndicators";
import Overview from "@/components/_Organisms/ReportExperience/components/Overview";
import Evolution from "@/components/_Organisms/ReportExperience/components/Evolution";
import HasSKUReportWrapper from "@/components/_Templates/HasSkuReportWrapper";
import BusinessContribution from "../BusinessContribution";

const Experience = () => {
  const ref = useRef(null);

  const experienceServices = useExperienceAnalytics();

  const { send } = experienceServices.experienceService;
  const filtersSelector = (state: any) => state.context.filters;
  const compareSelector = (state: any) => state.context.compare;
  const compareWithSelector = (state: any) => state.context.compareWith;

  const compare = useXstateSelector(
    experienceServices.experienceService,
    compareSelector
  );

  const compareWith = useXstateSelector(
    experienceServices.experienceService,
    compareWithSelector
  );

  const filters = useXstateSelector(
    experienceServices.experienceService,
    filtersSelector
  );

  const { dateRange, devices } = filters;

  const [experience, setExperience] = useSetState(null);

  const [dateMin, setDateMin] = useState<any>(null);

  const getStartDate = (deployedAt: Dayjs) => {
    if (deployedAt.isBefore(dayjs(dateRange[0]))) return dayjs(dateRange[0]);
    return deployedAt.isBefore(dayjs().subtract(32, "days"))
      ? dayjs().subtract(32, "days")
      : deployedAt;
  };

  const getDevices = (experience: Dic<any>) => {
    let res: Array<string> = [];
    if (experience.onMobile) res.push("mobile");
    if (experience.onDesktop) res.push("desktop");

    if (
      devices.every((device: string) => res.includes(device)) &&
      devices.length > 0
    )
      return devices;
    return res;
  };

  const getEndDate = (deployedAt: Dayjs) => {
    if (deployedAt.isBefore(dayjs(dateRange[1]))) return dayjs(dateRange[1]);
    return dayjs().subtract(2, "days");
  };

  useEffect(() => {
    if (experience) {
      const res = getDevices(experience);
      const deployedAt = dayjs(experience.deployedAt);
      const startDate = getStartDate(deployedAt);
      setDateMin(deployedAt);
      send({
        type: "FRONT_FILTERS",
        data: {
          frontFilters: {
            experience: `${EXPERIENCES_MAP?.[experience?.type]} ${
              experience?.analyticsName
            }`.trim(),
          },
        },
      });
      send({
        type: "FILTERS",
        data: {
          filters: {
            devices: res,
            dateRange: [startDate, getEndDate(deployedAt)],
          },
        },
      });
    }
  }, [experience]);

  const startDate = dateRange[0];
  const endDate = dateRange[1];
  const [previousStartDate, previousEndDate] =
    datesUtils.getTranslatedDateRange(dayjs(dateRange[0]), dayjs(dateRange[1]));

  const startDateCompare = compareWith?.dateRange[0];
  const endDateCompare = compareWith?.dateRange[1];
  const [previousStartDateCompare, previousEndDateCompare] =
    datesUtils.getTranslatedDateRange(
      dayjs(compareWith?.dateRange[0]),
      dayjs(compareWith?.dateRange[1])
    );

  const filtersData = {
    startDate,
    endDate,
    devices,
    previousStartDate,
    previousEndDate,
    experience: {
      type: experience?.type,
      analyticsName: experience?.analyticsName,
    },
  };
  const filtersDataCompare = {
    startDate: startDateCompare,
    endDate: endDateCompare,
    previousStartDate: previousStartDateCompare,
    previousEndDate: previousEndDateCompare,
    devices: compareWith?.devices,
    experience: {
      type: compareWith?.experience?.type,
      analyticsName: compareWith?.experience?.analyticsName,
    },
  };

  return (
    <Stack spacing={3} mt={1} ref={ref}>
      <Header
        setExperience={setExperience}
        experience={experience}
        boxRef={ref}
        loading={false}
        dateMin={dateMin}
      />

      <Stack spacing={2}>
        <Overview
          compare={compare}
          filters={filtersData}
          filtersCompare={filtersDataCompare}
        />
        <Box>
          <Grid container spacing={2}>
            {/* ----EVOLUTION------------------------------------------- */}
            {compare ? (
              <>
                <Grid item lg={6} sm={6} xs={12}>
                  <Evolution
                    filters={filtersData}
                    experienceName={experience.name}
                    compare={compare}
                  />
                </Grid>
                <Grid item lg={6} sm={6} xs={12}>
                  <Evolution
                    filters={filtersDataCompare}
                    experienceName={compareWith.experience.name}
                    compare={compare}
                  />
                </Grid>
              </>
            ) : (
              <Grid item lg={12} sm={12} xs={12}>
                <Evolution
                  filters={filtersData}
                  experienceName={compareWith.experience.name}
                  compare={compare}
                />
              </Grid>
            )}
            {/* ----KEY INDICATORS------------------------------------ */}
            {compare ? (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <KeyIndicators
                    experienceName={experience.name}
                    filters={filtersData}
                    experienceId={experience?.id}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <KeyIndicators
                    experienceName={compareWith.experience.name}
                    filters={filtersDataCompare}
                    experienceId={compareWith.experience?.id}
                  />
                </Grid>
              </>
            ) : (
              <Grid item lg={12} sm={12} xs={12}>
                <KeyIndicators
                  experienceName=""
                  filters={filtersData}
                  experienceId={experience?.id}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {/* ----ENGAGEMENT MEASURE---------------------------------- */}
        <EngagementMeasure
          filters={filtersData}
          filtersCompare={filtersDataCompare}
          compare={compare}
        />
        {/* ----BUSINESS CONTRIBUTION-------------------------------*/}
        <HasSKUReportWrapper>
          <BusinessContribution
            filters={filtersData}
            filtersCompare={filtersDataCompare}
            compare={compare}
          />
        </HasSKUReportWrapper>
      </Stack>
    </Stack>
  );
};

export default Experience;
