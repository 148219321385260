/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from "lodash";
import { createContext, useContext } from "react";
import { assign, DoneInvokeEvent, ActorRefFrom, createMachine } from "xstate";
import { useInterpret } from "@xstate/react";
import dayjs from "dayjs";

const initialState = {
  compare: false,
  compareWith: {
    experience: {},
    dateRange: [],
    devices: [],
  },
  filters: {
    devices: [],
    dateRange: [dayjs().subtract(32, "days"), dayjs().subtract(2, "days")],
    indicators: [],
  },
  frontFilters: {},
};

type Event = DoneInvokeEvent<any>;

const ExperienceMachine = createMachine<any, Event>({
  id: "ExperienceMachine",
  context: {
    ...initialState,
  },
  on: {
    COMPARE: {
      actions: assign((context, event) => ({
        compare: true,
        compareWith: event.data.compareWith,
      })),
    },
    COMPARE_WITH: {
      actions: assign((context, event) => ({
        compareWith: event.data.compareWith,
      })),
    },
    STOP_COMPARISON: {
      actions: assign((context, event) => ({
        compare: false,
      })),
    },
    FILTERS: {
      actions: assign((context, event) => ({
        filters: { ...context.filters, ...event.data.filters },
      })),
    },
    SET_DATE_RANGE: {
      actions: assign((context, event) => ({
        filters: { ...context.filters, dateRange: event.data.dateRange },
      })),
    },
    SET_DEVICES: {
      actions: assign((context, event) => ({
        filters: { ...context.filters, devices: event.data.devices },
      })),
    },
    SET_INDICATORS: {
      actions: assign((context, event) => ({
        filters: { ...context.filters, indicators: event.data.indicators },
      })),
    },
    FRONT_FILTERS: {
      actions: assign((context, event) => ({
        frontFilters: { ...event.data.frontFilters },
      })),
    },
  },
});

interface ExperienceContextType {
  experienceService: ActorRefFrom<typeof ExperienceMachine>;
}

export const ExperienceContext = createContext({} as ExperienceContextType);

export default function ExperienceProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const experienceService = useInterpret(ExperienceMachine);

  return (
    <ExperienceContext.Provider value={{ experienceService }}>
      {children}
    </ExperienceContext.Provider>
  );
}

export function useExperienceAnalytics() {
  return useContext(ExperienceContext);
}
