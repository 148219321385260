interface IOpenMenu {
  color: string;
}

const OpenMenu: React.FC<IOpenMenu> = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="chevronCircleRightIcon"
  >
    <g id="Size=16px, Property=Circle 1, Sens=Right">
      <path
        id="Stroke 1 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75822 13.2416C1.61671 12.1001 1.2334 10.3454 1.2334 7.99978C1.2334 5.65417 1.61671 3.89944 2.75822 2.75794C3.89972 1.61643 5.65445 1.23312 8.00006 1.23312C10.3457 1.23312 12.1004 1.61643 13.2419 2.75794C14.3834 3.89944 14.7667 5.65417 14.7667 7.99978C14.7667 10.3454 14.3834 12.1001 13.2419 13.2416C12.1004 14.3831 10.3457 14.7664 8.00006 14.7664C5.65445 14.7664 3.89972 14.3831 2.75822 13.2416ZM3.60675 12.3931C4.39241 13.1788 5.72101 13.5664 8.00006 13.5664C10.2791 13.5664 11.6077 13.1788 12.3934 12.3931C13.179 11.6074 13.5667 10.2788 13.5667 7.99978C13.5667 5.72073 13.179 4.39213 12.3934 3.60646C11.6077 2.8208 10.2791 2.43312 8.00006 2.43312C5.72101 2.43312 4.39241 2.8208 3.60675 3.60646C2.82108 4.39213 2.4334 5.72073 2.4334 7.99978C2.4334 10.2788 2.82108 11.6074 3.60675 12.3931Z"
        fill={color}
      ></path>
      <path
        id="Stroke 3 (Stroke)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54416 5.34697C6.73154 5.07367 7.10499 5.00401 7.3783 5.19139L7.03902 5.68625C7.3783 5.19139 7.37823 5.19134 7.3783 5.19139L7.38081 5.19311L7.38664 5.19713L7.40785 5.21184C7.42609 5.22453 7.45235 5.24291 7.48549 5.26637C7.55175 5.31326 7.64571 5.38059 7.75819 5.46352C7.98239 5.62881 8.28369 5.85862 8.58707 6.11361C8.88719 6.36586 9.20491 6.65568 9.45271 6.94073C9.57638 7.08299 9.69499 7.23804 9.78555 7.39832C9.87094 7.54945 9.96302 7.75988 9.96302 7.99958C9.96302 8.23926 9.87095 8.4497 9.78559 8.60083C9.69506 8.76113 9.57646 8.91622 9.45281 9.05853C9.20504 9.34369 8.88734 9.63368 8.58723 9.8861C8.28386 10.1413 7.98257 10.3712 7.75837 10.5366C7.64589 10.6196 7.55193 10.687 7.48568 10.7339C7.45254 10.7574 7.42628 10.7758 7.40804 10.7885L7.38683 10.8032L7.381 10.8073L7.37933 10.8084C7.37926 10.8085 7.37849 10.809 7.03902 10.3143L7.37849 10.809C7.10526 10.9965 6.73177 10.927 6.54429 10.6537C6.35682 10.3805 6.4263 10.0071 6.69947 9.81957C6.6995 9.81955 6.69945 9.81959 6.69947 9.81957L6.70041 9.81893L6.70454 9.81608L6.7222 9.80382C6.73801 9.79281 6.76168 9.77624 6.79208 9.7547C6.85292 9.71161 6.94053 9.64879 7.04592 9.57102C7.25748 9.41494 7.53718 9.20125 7.81482 8.96774C8.0957 8.73149 8.35901 8.48781 8.54698 8.27147C8.6412 8.16303 8.70417 8.07542 8.74073 8.01069C8.74294 8.00678 8.74498 8.00309 8.74685 7.99961C8.74499 7.99615 8.74297 7.99249 8.74077 7.98861C8.70424 7.92394 8.64129 7.83639 8.54708 7.72802C8.35913 7.51182 8.09585 7.26831 7.81497 7.03223C7.53735 6.79888 7.25765 6.58536 7.0461 6.4294C6.94071 6.3517 6.85311 6.28893 6.79227 6.24587C6.76187 6.22436 6.7382 6.2078 6.72239 6.1968L6.70473 6.18455L6.7006 6.1817L6.69976 6.18112C6.69973 6.18111 6.69978 6.18114 6.69976 6.18112M6.54416 5.34697C6.3568 5.62025 6.42651 5.99373 6.69976 6.18112L6.54416 5.34697Z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default OpenMenu;
