import { client } from "@/index";
import { RootState } from "@/services/redux/store";
import { StatusEnum } from "@/utils/helpers/status/enums";
import { StatusType } from "@/utils/helpers/status/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VariableType } from "@/utils/types/variables";
import { GET_VARIABLES } from "./requests";

const useVariables = () => {
  const [allVariables, setAllVariables] = useState<Array<VariableType>>([]);
  const [totalCount, setTotalCount] = useState<number>(100);
  const [status, setStatus] = useState<StatusType>(StatusEnum.LOADING);
  const [error, setError] = useState<string>("");
  const siteId = useSelector((state: RootState) => state.site.siteId);

  const handleChangeVariables = (res: Dic<any>) => {
    setError("");
    setAllVariables(
      res.data.variables.edges.map((edge: Dic<any>) => ({
        ...edge.node,
      }))
    );
    setTotalCount(res.data.variables.totalCount);
    setStatus(StatusEnum.NORMAL);
  };

  const getAllVariables = (first = 100) => {
    setStatus(StatusEnum.LOADING);
    client
      .query({
        query: GET_VARIABLES,
        variables: { first, siteId },
        fetchPolicy: "no-cache",
      })
      .then(handleChangeVariables)
      .catch((err: any) => {
        setError(err.toString());
        setStatus(StatusEnum.ERROR);
      });
  };

  useEffect(() => {
    if (allVariables.length < totalCount) {
      getAllVariables(totalCount + 5);
    }
  }, [totalCount]);

  const refetch = () => {
    getAllVariables(totalCount + 5);
  };

  return { allVariables, status, refetch, error };
};

export default useVariables;
