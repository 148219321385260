import {
  ApolloQueryResult,
  DocumentNode,
  FetchPolicy,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";
import { client } from "@/index";

/**
 * Utility class for Apollo Client library utility functions.
 */
export class ApolloUtils {
  /**
   * Perform a mutation using Apollo Client.
   *
   * @param mutation - The mutation document node.
   * @param variables - The variables for the mutation.
   * @param prefix - A prefix for the mutation (default is an empty string).
   * @returns A promise resolving to the result of the mutation.
   */
  mutate = async (
    mutation: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    variables: OperationVariables,
    prefix: string = ""
  ): Promise<any> => {
    return client.mutate({
      mutation,
      variables,
    });
  };

  /**
   * Fetch data without using Apollo Client cache.
   *
   * @param query - The query document node.
   * @param variables - The variables for the query.
   * @param fetchPolicy - The fetch policy for the query (default is "no-cache").
   * @returns A promise resolving to the result of the query.
   */
  fetchWithoutCache = async (
    query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
    variables: OperationVariables,
    fetchPolicy: FetchPolicy | undefined = "no-cache"
  ): Promise<ApolloQueryResult<any>> => {
    return await client.query({
      query,
      variables,
      fetchPolicy,
    });
  };

  /**
   * Check if there is a next page in the paginated data.
   *
   * @param data - The paginated data.
   * @returns True if there is a next page, false otherwise.
   */
  getHasNextPage = (data: any): boolean => !!data?.pageInfo?.hasNextPage;

  /**
   * Check if there is a previous page in the paginated data.
   *
   * @param data - The paginated data.
   * @returns True if there is a previous page, false otherwise.
   */
  getHasPreviousPage = (data: any): boolean =>
    !!data?.pageInfo?.hasPreviousPage;

  /**
   * Get the end cursor from the paginated data.
   *
   * @param data - The paginated data.
   * @returns The end cursor value or an empty string if not available.
   */
  getEndCursor = (data: any): string => data?.pageInfo?.endCursor ?? "";

  /**
   * Get the start cursor from the paginated data.
   *
   * @param data - The paginated data.
   * @returns The start cursor value or an empty string if not available.
   */
  getStartCursor = (data: any): string => data?.pageInfo?.startCursor ?? "";

  /**
   * Get pagination information from the paginated data.
   *
   * @param data - The paginated data.
   * @returns An object containing hasNextPage, hasPreviousPage, endCursor, and startCursor.
   */
  getPageInfos = (
    data: any
  ): {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    endCursor: string;
    startCursor: string;
  } => ({
    hasNextPage: this.getHasNextPage(data),
    hasPreviousPage: this.getHasPreviousPage(data),
    endCursor: this.getEndCursor(data),
    startCursor: this.getStartCursor(data),
  });

  /**
   * Calculate the total number of pages based on total items and page size.
   *
   * @param totalItems - The total number of items.
   * @param pageSize - The number of items per page.
   * @returns The total number of pages.
   */
  getTotalPages = (totalItems: number, pageSize: number): number => {
    const modulo = totalItems % pageSize;
    if (modulo === 0) return totalItems / pageSize; // exact number of pages by dividing
    return (totalItems - modulo) / pageSize + 1;
  };
}
