import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useSelector as useXStateSelector } from "@xstate/react";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { comparisonUtils } from "@/utils";
import { HideShowBlock, Tag, Text } from "@/includes";
import { useConfigureAlgorithm } from "../../../../../hooks/useConfigureAlgorithm";
import OptionSize from "../OptionSize";
import { OPTIONS } from "./enums";
import { OptionsProps } from "./types";
import { uniqBy } from "lodash";
import { getTraduction } from "@/utils/helpers/traductions";

const variablesSelector = (state: any) => state.context.variables;
const optionsSelector = (state: any) => state.context.options;

const Options = ({ parameters, experienceType }: OptionsProps) => {
  const { t }: i18translateType = useTranslation();

  const location = useLocation();

  const configureAlgorithmServices = useConfigureAlgorithm();

  const variables = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    variablesSelector
  );

  const { send } = configureAlgorithmServices.algorithmService;

  const options = useXStateSelector(
    configureAlgorithmServices.algorithmService,
    optionsSelector
  );

  const setOptions = (options: Array<string>) => {
    send({ type: "SET_OPTIONS", data: { options } });
  };

  const handleChange = (option: string, operation: "add" | "remove") => {
    if (operation === "remove")
      setOptions(options.filter(comparisonUtils.areDifferent(option)));
    else setOptions([...options, option]);
  };

  const filteredOptions =
    experienceType !== "EMAILING"
      ? OPTIONS.filter(
          (option: Dic<any>) =>
            !option.exceptions ||
            variables.some((variable: string) =>
              option.exceptions.find(
                (exception: string) => exception === variable
              )
            )
        )
      : [OPTIONS[0]];

  const handleChangeOptions = () => {
    if (
      filteredOptions.find(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "value",
          "exclude_cart_items"
        )
      ) &&
      location.pathname.includes("/create")
    )
      handleChange("exclude_cart_items", "add");

    if (
      filteredOptions.find(
        comparisonUtils.getValueFromDicAndCompareToConstant(
          "value",
          "exclude_viewing_items"
        )
      ) &&
      location.pathname.includes("/create")
    )
      handleChange("exclude_viewing_items", "add");
  };

  useEffect(handleChangeOptions, [parameters]);

  const titleDetail = useMemo(() => {
    if (options.length > 0)
      return `: ${options.map((option: string) => t(option)).join(", ")}`;
    return ` : ${t("no_options")}`;
  }, [options]);

  if (
    filteredOptions.length === 0 &&
    window.location.pathname.includes("merchandising")
  )
    return <></>;

  return (
    <HideShowBlock initialState title={`${t("Options")}`}>
      <Stack spacing={1}>
        <Stack spacing={2}>
          <Text>{t("options_subtitle")}</Text>
          <Stack sx={{ paddingLeft: 4 }}>
            {filteredOptions.map((option: Dic<any>) => {
              const isSelected = !!options.find(
                (o: string) => o === option.value
              );
              return (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={() =>
                        handleChange(
                          option.value,
                          isSelected ? "remove" : "add"
                        )
                      }
                    />
                  }
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Text>{getTraduction(t, option.value)}</Text>
                      {option.recommended && (
                        <Box>
                          <Tag tagVariant="info">{t("recommended")}</Tag>
                        </Box>
                      )}
                    </Stack>
                  }
                />
              );
            })}
            <OptionSize
              type="SET_RULE_SIZE"
              title={t("fixed_number")}
              tooltip={t("fixed_number_tooltip")}
              minValue={1}
              property="size"
              defaultValue={12}
              checkedValue={12}
            />
            <OptionSize
              type="SET_MIN_RULE_SIZE"
              title={t("min_number")}
              tooltip={t("min_number_tooltip")}
              minValue={2}
              property="min_size"
              defaultValue={-1}
              checkedValue={2}
            />
          </Stack>
        </Stack>
      </Stack>
    </HideShowBlock>
  );
};

export default Options;
