import { Grid, Skeleton, useTheme } from "@mui/material";

const Loading = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item lg={3} sm={6} xs={12}>
        <Skeleton
          height={110}
          variant="rounded"
          animation="wave"
          sx={{ bgcolor: theme.customPalette.others.layer_2 }}
        />
      </Grid>
      <Grid item lg={3} sm={6} xs={12}>
        <Skeleton
          sx={{ bgcolor: theme.customPalette.others.layer_2 }}
          height={110}
          variant="rounded"
          animation="wave"
        />
      </Grid>
    </Grid>
  );
};

export default Loading;
