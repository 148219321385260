/**
 * Utility class for navigator-related functions.
 */
export class NavigatorUtils {
  /**
   * Retrieves the language of the navigator.
   *
   * @returns The language code ('fr' or 'en').
   */
  getNavigatorLanguage(): string {
    if (!navigator) return "fr";
    if (["fr", "fr-FR", "fr-fr"].includes(navigator.language)) return "fr";
    return "en";
  }

  /**
   * Checks if the user's navigator is in dark mode.
   *
   * @returns True if the user's navigator is in dark mode, false otherwise.
   */
  isNavigatorInDarkMode(): boolean {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }

  /**
   * Navigates to the specified link, allowing for opening in a new tab on specific conditions.
   *
   * @param link - The redirection URL.
   * @param history - The history object from react-router.
   * @returns A function to be used as an event handler, allowing to open the URL in a new tab when ctrl + click on a link.
   */
  goTo = (link: string, history: any) => {
    return (event: any) => {
      event.preventDefault();

      if (event.ctrlKey || event.button === 1 || event.metaKey) {
        window.open(link, "_blank");
      } else if (event.type === "click") {
        history.push(link);
      }
    };
  };
}
